import useTranslation from "next-translate/useTranslation";
import Head from "next/head";
import Link from "next/link";
import { FC } from "react";
import { Layout } from "../../src/layout";

export type ErrorProps = {
  pageTitle?: string;
  useTranslationFrom?: string;
  errorTitle?: string;
  errorMessageBold?: string;
  errorMessage?: string;
  errorRouteLink?: string;
  errorRouteMessage?: string;
};

export const Error: FC<ErrorProps> = ({
  useTranslationFrom,
  pageTitle = "",
  errorMessageBold = "",
  errorTitle = "",
  errorMessage = "",
  errorRouteLink,
  errorRouteMessage,
}) => {
  const { t } = useTranslation(useTranslationFrom);
  const { t: tCommon } = useTranslation("common");

  return (
    <Layout>
      <Head>
        <title>{t(pageTitle)}</title>
      </Head>
      <div className="bg-primary text-primary">
        <div className="max-w-5xl mx-8 lg:mx-auto py-32">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="">
              <div className="flex items-center justify-center">
                <ul className="bg-electricpurple py-4 px-12 rounded-xl">
                  <li>
                    <h2 className="text-xl mb-12 mt-8">
                      {t(errorTitle) || "Something's gone wrong"}
                    </h2>
                  </li>
                  <li className="">
                    {errorMessageBold && <strong>{t(errorMessageBold)}</strong>}
                    {t(errorMessage) ||
                      "Please get in touch and one of our nice energy specialists will help you out."}
                  </li>
                  <li className="flex pt-4 pb-8"></li>
                  <li className="bg-white text-electricpurple py-8 mb-2 px-4 md:text-xl text-center font-bold text wrap-nowrap rounded-xl">
                    {errorRouteMessage && errorRouteLink ? (
                      <Link href={errorRouteLink}>{t(errorRouteMessage)}</Link>
                    ) : (
                      <Link href="mailto:heretohelp@octopusenergy.com">
                        {tCommon("errorEmail")}
                      </Link>
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
