import { useAuth } from "@core/auth";
import { LocalizationBannerWithDialog } from "@core/localization";
import { AppBanner } from "@core/molecules";
import { Box, Header } from "@octopus-energy/coral-mui";
import { storyblokClient } from "@octopus-energy/storyblok-cms";
import { useRouter } from "next/router";
import { FC, ReactNode, useEffect } from "react";
import { Footer, MinimalFooter } from "../footer";
import { GlobalOuttageAlert } from "../molecules";
import { MetaGen } from "../seo";
import { AuthedMenuItems } from "./AuthedMenuItems";
import { HEADER_MENU, LANG_MENU_ITEMS, MENU_BUTTON_LABELS } from "./constants";
import { useGlobalOutageAlert } from "./useGlobalOutageAlert";
import { useGlobalParameterState } from "@core/enrollment/globalParameters/useGlobalParametersState";

export type LayoutProps = {
  footerType?: "minimal" | "affiliate" | "normal" | "hidden";
  headerType?: "minimal" | "normal";
  children: ReactNode;
  removeHeaderOffset?: boolean;
};

export const Layout: FC<LayoutProps> = ({
  footerType = "normal",
  headerType = "normal",
  children,
  removeHeaderOffset = false,
}) => {
  const [{ authed }] = useAuth();
  const [globalOutageAlert, setGlobalOutageAlert] = useGlobalOutageAlert();
  const locale = useRouter().locale;
  const [{ navigatorState }] = useGlobalParameterState();

  useEffect(() => {
    storyblokClient
      .get("/cdn/stories/global-parameters", {})
      .then((response) => {
        setGlobalOutageAlert({
          showAlert: response.data.story.content.showGlobalBanner,
          alertContent: response.data.story.content.globalBannerContent,
        });
      });
  }, []);

  return (
    <>
      <a
        href="#main-content"
        className="skip-to-content"
        style={{
          position: "absolute",
          top: "10px",
          left: "10",
          backgroundColor: "#000",
          color: "#fff",
          padding: "12px 24px",
          zIndex: "100",
          textDecoration: "none",
          clipPath: "inset(50%)",
          transition: "opacity 0.3s ease, top 0.3s ease, left 0.3s ease",
        }}
        onFocus={(e) => {
          e.currentTarget.style.clipPath = "none";
          e.currentTarget.style.top = "20px";
          e.currentTarget.style.left = "20px";
        }}
        onBlur={(e) => {
          e.currentTarget.style.clipPath = "inset(50%)";
          e.currentTarget.style.top = "10px";
          e.currentTarget.style.left = "10px";
        }}
      >
        Skip to main content
      </a>

      {/* Default meta, this can be overwritten in children */}
      <MetaGen
        title="Octopus Energy US"
        description="Choose a low rate with an energy company you can trust. We offer multiple low-cost plans depending on where you live and 100% renewable energy."
        contentType="Website"
        image="/branding/social-share.jpg"
      >
        <meta httpEquiv="content-language" content={locale} />
      </MetaGen>
      <Header
        menuItems={HEADER_MENU}
        menuButtonLabels={MENU_BUTTON_LABELS[locale]}
        showAccountMenu={!navigatorState && headerType !== "minimal"}
        accountMenuProps={{
          linkHref: "/signin",
          showLink: !authed,
          buttonLabel: { en: "Account", es: "Cuenta" }[locale],
          authedMenuItems: <AuthedMenuItems />,
        }}
        showMainMenu={!navigatorState && headerType !== "minimal"}
        showLangMenu
        langMenuItems={LANG_MENU_ITEMS}
      />
      <Box
        id="main-content"
        component="main"
        paddingTop={
          removeHeaderOffset && !globalOutageAlert.showAlert ? 11 : 16
        }
      >
        <LocalizationBannerWithDialog>
          {globalOutageAlert.showAlert ? (
            <GlobalOuttageAlert
              globalBannerContent={globalOutageAlert.alertContent}
            />
          ) : (
            <AppBanner />
          )}
          {children}
        </LocalizationBannerWithDialog>
      </Box>
      {navigatorState ? (
        <MinimalFooter />
      ) : (
        {
          minimal: <MinimalFooter />,
          affiliate: <Footer />,
          normal: <Footer />,
          hidden: null,
        }[footerType]
      )}
    </>
  );
};
