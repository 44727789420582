import { OctopusProductId } from "./octopusProductConstants";

export enum ProductRateType {
  FIXED = "FIXED",
  TIME_OF_USE = "TIME_OF_USE",
}

export enum ProductPaymentSchedule {
  PREPAY = "PREPAY",
  POSTPAY = "POSTPAY",
}

export const productFeatureMap = {
  [OctopusProductId.OCTO_PLUS_12M]: {
    intelligentOctopus: false,
    paymentSchedule: ProductPaymentSchedule.POSTPAY,
    rateType: ProductRateType.FIXED,
    solarBuyback: true,
    terminationFee: true,
  },
  [OctopusProductId.OCTO_EV_12M]: {
    intelligentOctopus: true,
    paymentSchedule: ProductPaymentSchedule.POSTPAY,
    rateType: ProductRateType.FIXED,
    solarBuyback: true,
    terminationFee: true,
  },
  [OctopusProductId.OCTO_THERMOSTAT_12M]: {
    intelligentOctopus: true,
    paymentSchedule: ProductPaymentSchedule.POSTPAY,
    rateType: ProductRateType.FIXED,
    solarBuyback: true,
    terminationFee: true,
  },
  [OctopusProductId.OCTO_PLUS_24M]: {
    intelligentOctopus: false,
    paymentSchedule: ProductPaymentSchedule.POSTPAY,
    rateType: ProductRateType.FIXED,
    solarBuyback: true,
    terminationFee: false,
  },
  [OctopusProductId.OCTO_PLUS_36M]: {
    intelligentOctopus: false,
    paymentSchedule: ProductPaymentSchedule.POSTPAY,
    rateType: ProductRateType.FIXED,
    solarBuyback: true,
    terminationFee: true,
  },
  [OctopusProductId.OCTO_GO_12M]: {
    intelligentOctopus: false,
    paymentSchedule: ProductPaymentSchedule.PREPAY,
    rateType: ProductRateType.FIXED,
    solarBuyback: true,
    terminationFee: true,
  },
  [OctopusProductId.RIVIAN_TIME_OF_USE]: {
    intelligentOctopus: false,
    paymentSchedule: ProductPaymentSchedule.POSTPAY,
    rateType: ProductRateType.TIME_OF_USE,
    solarBuyback: true,
    terminationFee: true,
  },
};

/**
 * Function that returns true if the product's pricing rate is fixed at
 * a set price.
 */
export const isFixedRateProduct = (productId?: OctopusProductId): boolean => {
  if (!productId) {
    return false;
  }
  return productFeatureMap[productId]?.rateType === ProductRateType.FIXED;
};

/**
 * Function that returns true if the product's pricing rate is based on
 * the time of day that the product is used.
 */
export const isTimeOfUseRateProduct = (
  productId?: OctopusProductId
): boolean => {
  if (!productId) {
    return false;
  }
  return productFeatureMap[productId]?.rateType === ProductRateType.TIME_OF_USE;
};

/**
 * Function that returns true if the product charges the customer after
 * usage.
 */
export const isPostpayProduct = (productId?: OctopusProductId): boolean => {
  if (!productId) {
    return false;
  }
  return (
    productFeatureMap[productId]?.paymentSchedule ===
    ProductPaymentSchedule.POSTPAY
  );
};

/**
 * Function that returns true if the product charges the customer prior
 * to usage.
 */
export const isPrepayProduct = (
  productId?: OctopusProductId | null
): boolean => {
  if (!productId) {
    return false;
  }
  return (
    productFeatureMap[productId]?.paymentSchedule ===
    ProductPaymentSchedule.PREPAY
  );
};

/**
 * Function that returns true if the product includes purchasing the
 * customer's excess solar energy produced.
 */
export const isSolarBuybackProduct = (
  productId?: OctopusProductId
): boolean => {
  if (!productId) {
    return false;
  }
  return productFeatureMap[productId]?.solarBuyback;
};

/**
 * Function that returns true if the product has a termination fee
 */
export const hasTerminationFee = (productId: OctopusProductId): boolean => {
  if (!productId) {
    return false;
  }
  return productFeatureMap[productId]?.terminationFee;
};
