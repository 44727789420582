import { gql } from "@apollo/client";
import { createUseMutation } from "@octopus-energy/apollo-client";
import {
  InvalidatePaymentInstructionMutationMutation,
  InvalidatePaymentInstructionMutationMutationVariables,
} from "./__generated__/useInvalidatePaymentInstructionMutation.generated";

export const MUTATION_INVALIDATE_PAYMENT_INSTRUCTION = gql`
  mutation InvalidatePaymentInstructionMutation(
    $input: InvalidatePaymentInstructionInput!
  ) {
    invalidatePaymentInstruction(input: $input) {
      instruction {
        id
      }
    }
  }
`;

export const useInvalidatePaymentInstructionMutation = createUseMutation<
  InvalidatePaymentInstructionMutationMutation,
  InvalidatePaymentInstructionMutationMutationVariables
>(MUTATION_INVALIDATE_PAYMENT_INSTRUCTION);
