import useTranslation from "next-translate/useTranslation";
import { useEnrollment, useSelectedEnrollmentProductIsPrepay } from "..";
import { useCollectPayment } from "../../apiRequests";
import { CollectDepositStatusChoices } from "../../apiRequests/graphql-global-types";
import { useFeatureFlag } from "../../featureFlags";
import { useSnackbarNotification } from "../../molecules";
import { gqlFormatDate } from "../../utils";
import { useCollectDeposit } from "./useCollectDeposit";

const PREPAY_INITIAL_DEPOSIT_AMOUNT = 7500;

/**
 * Hook that checks if prepay or deposit is required and collects payment
 */
export const useCollectInitialPayment = () => {
  const enrollmentContext = useEnrollment();
  const productIsPrepay = useSelectedEnrollmentProductIsPrepay();
  const [collectPayment] = useCollectPayment();
  const depositAgreementsFeature = useFeatureFlag("depositAgreements");
  const collectDeposit = useCollectDeposit();

  const [snackbarNotification] = useSnackbarNotification();
  const { t } = useTranslation("enrollment/formFields");

  const collectInitialPayment = async ({
    accountNumber,
    ledgerId,
    authorizationHeader,
  }: {
    accountNumber: string;
    ledgerId: string;
    authorizationHeader: string;
  }) => {
    // if product is prepay collect initial prepay deposit
    if (productIsPrepay) {
      await collectPayment({
        variables: {
          input: {
            accountNumber,
            amount: PREPAY_INITIAL_DEPOSIT_AMOUNT,
            description: `${PREPAY_INITIAL_DEPOSIT_AMOUNT} initial prepay deposit`,
            paymentDate: gqlFormatDate(new Date()),
            idempotencyKey: `PREPAY-INITIAL-DEPOSIT-AMOUNT-${accountNumber}`,
            ledgerId,
          },
        },
        context: {
          headers: {
            Authorization: authorizationHeader,
          },
        },
      });
      // account requires deposit, collect it
    } else if (
      enrollmentContext.creditCheckStatus &&
      depositAgreementsFeature.active
    ) {
      const { status } = await collectDeposit({
        accountNumber,
        authorizationHeader,
      });
      if (status === CollectDepositStatusChoices.Failed) {
        snackbarNotification.error(t("depositDeclinedErrorMessage"), {
          autoHideDuration: null,
        });
        throw new Error(t("depositDeclinedErrorMessage"));
      }
    }
  };

  return collectInitialPayment;
};
