import { gql } from "@apollo/client";
import { createUseMutation } from "@octopus-energy/apollo-client";
import {
  DeleteThermostatTokenMutation,
  DeleteThermostatTokenMutationVariables,
} from "./__generated__/useDeleteThermostatTokenMutation.generated";

export const MUTATION_DELETE_THERMOSTAT_TOKEN = gql`
  mutation DeleteThermostatToken($input: DeleteThermostatTokenInput!) {
    deleteThermostatToken(input: $input) {
      status
    }
  }
`;

export const useDeleteThermostatTokenMutation = createUseMutation<
  DeleteThermostatTokenMutation,
  DeleteThermostatTokenMutationVariables
>(MUTATION_DELETE_THERMOSTAT_TOKEN);
