import { formatISO } from "date-fns";
import { useFormik } from "formik";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import { RefObject } from "react";
import SignatureCanvas from "react-signature-canvas";
import { useBooleanState } from "react-use-object-state";
import * as Yup from "yup";
import {
  obtainNewKrakenToken,
  sendFileToS3,
  useCreateAccountFileAttachmentMutation,
} from "../../apiRequests";
import {
  Category,
  LinkedObjectType,
} from "../../apiRequests/graphql-global-types";
import { handleError } from "../../error";
import { convertBase64ToBlob } from "../../utils";
import { useCollectInitialPayment } from "../finalize/useCollectInitialPayment";
import { useGlobalParameterState } from "../globalParameters/useGlobalParametersState";
import { useEnrollment } from "../index";
import { useCreateAccountMeta } from "./useCreateAccountMeta";

type NavigatorFormValues = {
  signature: null;
  name: string;
  date: Date | null;
};

const useStoreSignatureInS3 = () => {
  const { krakenAccountID } = useEnrollment();
  const [createFileAttachment] = useCreateAccountFileAttachmentMutation();
  return async (
    signatureRef: RefObject<SignatureCanvas>,
    authToken: string
  ) => {
    const signatureBlob = await convertBase64ToBlob(
      signatureRef.current?.toDataURL("image/png") as string
    ).then((blob) => {
      return blob;
    });
    const { data } = await createFileAttachment({
      variables: {
        input: {
          filename: `SIGNATURE.png`,
          accountNumber: krakenAccountID as string,
          category: Category.FileAttachmentCustomerSignature,
        },
      },
      context: {
        headers: {
          authorization: authToken,
        },
      },
    });
    await sendFileToS3(
      data?.createAccountFileAttachment?.postRequest?.url as string,
      data?.createAccountFileAttachment?.postRequest?.fields as string,
      signatureBlob
    );
  };
};

const useAddSignedNameAndDateToMetaData = () => {
  const { krakenAccountID } = useEnrollment();
  const { addMetaToAccount } = useCreateAccountMeta();
  return async (values: NavigatorFormValues, authToken: string) => {
    await addMetaToAccount({
      data: [
        {
          key: "NavigatorSignedName",
          value: values.name,
        },
        {
          key: "NavigatorSignedDate",
          value: values.date ? formatISO(values.date) : "",
        },
      ],
      identifier: krakenAccountID as string,
      linkedObjectType: LinkedObjectType.Account,
      token: authToken,
    });
  };
};

const useValidationSchema = () => {
  const { t } = useTranslation("enrollment/formFields");
  return Yup.object().shape({
    name: Yup.string()
      .min(2, t("navigator_field_name_error"))
      .required(t("navigator_field_name_error")),
    date: Yup.date()
      .nullable()
      .min(new Date(Date.now() - 86_400_000), t("navigator_field_date_error"))
      .required(t("navigator_field_date_error")),
  });
};

export type UseNavigatorFormProps = {
  signatureRef: RefObject<SignatureCanvas>;
};

export const useNavigatorForm = ({ signatureRef }: UseNavigatorFormProps) => {
  const { krakenAccountID, ledgerId } = useEnrollment();
  const router = useRouter();
  const loading = useBooleanState(false);
  const [{ skipPayment }] = useGlobalParameterState();
  const collectInitialPayment = useCollectInitialPayment();
  const storeSignatureInS3 = useStoreSignatureInS3();
  const addSignedNameAndDateToMetaData = useAddSignedNameAndDateToMetaData();
  const formik = useFormik<NavigatorFormValues>({
    initialValues: {
      signature: null,
      name: "",
      date: null,
    },
    validationSchema: useValidationSchema(),
    onSubmit: async (values) => {
      if (!krakenAccountID || !ledgerId) {
        return;
      }
      loading.setTrue();
      try {
        const orgToken = await obtainNewKrakenToken().then(
          (data) => data.token
        );
        if (orgToken) {
          try {
            const res = await Promise.allSettled([
              storeSignatureInS3(signatureRef, orgToken),
              addSignedNameAndDateToMetaData(values, orgToken),
              !skipPayment &&
                collectInitialPayment({
                  accountNumber: krakenAccountID,
                  ledgerId,
                  authorizationHeader: orgToken,
                }),
            ]);
            if (res[0].status === "rejected") {
              throw new Error("Failed to store customer signature in s3.");
            }
            if (res[1].status === "rejected") {
              throw new Error("Failed store name and date in metadata");
            }
            router.push({
              pathname: "/join/check-your-email",
            });
          } catch (error) {
            handleError(error);
          }
        }
      } catch (error) {
        handleError(error);
      } finally {
        loading.setFalse();
      }
    },
  });

  return { formik, loading: loading.state };
};
