var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};

// src/Lottie/Lottie.tsx
import { useEffect as useEffect3 } from "react";

// src/Lottie/useLottie.tsx
import lottie from "lottie-web";
import {
  useEffect,
  useRef,
  useState
} from "react";
import { jsx } from "react/jsx-runtime";
var useLottie = (props, style) => {
  const _a = props, {
    animationData,
    loop,
    autoplay,
    initialSegment,
    onComplete,
    onLoopComplete,
    onEnterFrame,
    onSegmentStart,
    onConfigReady,
    onDataReady,
    onDataFailed,
    onLoadedImages,
    onDOMLoaded,
    onDestroy,
    lottieRef: lottieRef,
    renderer,
    name,
    assetsPath,
    rendererSettings
  } = _a, rest = __objRest(_a, [
    "animationData",
    "loop",
    "autoplay",
    "initialSegment",
    "onComplete",
    "onLoopComplete",
    "onEnterFrame",
    "onSegmentStart",
    "onConfigReady",
    "onDataReady",
    "onDataFailed",
    "onLoadedImages",
    "onDOMLoaded",
    "onDestroy",
    // Specified here to take them out from the 'rest'
    "lottieRef",
    "renderer",
    "name",
    "assetsPath",
    "rendererSettings"
  ]);
  const [animationLoaded, setAnimationLoaded] = useState(false);
  const animationInstanceRef = useRef();
  const animationContainer = useRef(null);
  const play = () => {
    var _a2;
    (_a2 = animationInstanceRef.current) == null ? void 0 : _a2.play();
  };
  const stop = () => {
    var _a2;
    (_a2 = animationInstanceRef.current) == null ? void 0 : _a2.stop();
  };
  const pause = () => {
    var _a2;
    (_a2 = animationInstanceRef.current) == null ? void 0 : _a2.pause();
  };
  const setSpeed = (speed) => {
    var _a2;
    (_a2 = animationInstanceRef.current) == null ? void 0 : _a2.setSpeed(speed);
  };
  const goToAndPlay = (value, isFrame) => {
    var _a2;
    (_a2 = animationInstanceRef.current) == null ? void 0 : _a2.goToAndPlay(value, isFrame);
  };
  const goToAndStop = (value, isFrame) => {
    var _a2;
    (_a2 = animationInstanceRef.current) == null ? void 0 : _a2.goToAndStop(value, isFrame);
  };
  const setDirection = (direction) => {
    var _a2;
    (_a2 = animationInstanceRef.current) == null ? void 0 : _a2.setDirection(direction);
  };
  const playSegments = (segments, forceFlag) => {
    var _a2;
    (_a2 = animationInstanceRef.current) == null ? void 0 : _a2.playSegments(segments, forceFlag);
  };
  const setSubframe = (useSubFrames) => {
    var _a2;
    (_a2 = animationInstanceRef.current) == null ? void 0 : _a2.setSubframe(useSubFrames);
  };
  const getDuration = (inFrames) => {
    var _a2;
    return (_a2 = animationInstanceRef.current) == null ? void 0 : _a2.getDuration(inFrames);
  };
  const destroy = () => {
    var _a2;
    (_a2 = animationInstanceRef.current) == null ? void 0 : _a2.destroy();
    animationInstanceRef.current = void 0;
  };
  const loadAnimation = (forcedConfigs = {}) => {
    var _a2;
    if (!animationContainer.current) {
      return;
    }
    (_a2 = animationInstanceRef.current) == null ? void 0 : _a2.destroy();
    const config = __spreadProps(__spreadValues(__spreadValues({}, props), forcedConfigs), {
      container: animationContainer.current
    });
    animationInstanceRef.current = lottie.loadAnimation(config);
    setAnimationLoaded(Boolean(animationInstanceRef.current));
    return () => {
      var _a3;
      (_a3 = animationInstanceRef.current) == null ? void 0 : _a3.destroy();
      animationInstanceRef.current = void 0;
    };
  };
  useEffect(() => {
    const onUnmount = loadAnimation();
    return () => onUnmount == null ? void 0 : onUnmount();
  }, [animationData, loop]);
  useEffect(() => {
    if (!animationInstanceRef.current) {
      return;
    }
    animationInstanceRef.current.autoplay = Boolean(autoplay);
  }, [autoplay]);
  useEffect(() => {
    if (!animationInstanceRef.current) {
      return;
    }
    if (!initialSegment) {
      animationInstanceRef.current.resetSegments(true);
      return;
    }
    if (!Array.isArray(initialSegment) || !initialSegment.length) {
      return;
    }
    if (animationInstanceRef.current.currentRawFrame < initialSegment[0] || animationInstanceRef.current.currentRawFrame > initialSegment[1]) {
      animationInstanceRef.current.currentRawFrame = initialSegment[0];
    }
    animationInstanceRef.current.setSegment(
      initialSegment[0],
      initialSegment[1]
    );
  }, [initialSegment]);
  useEffect(() => {
    const partialListeners = [
      { name: "complete", handler: onComplete },
      { name: "loopComplete", handler: onLoopComplete },
      { name: "enterFrame", handler: onEnterFrame },
      { name: "segmentStart", handler: onSegmentStart },
      { name: "config_ready", handler: onConfigReady },
      { name: "data_ready", handler: onDataReady },
      { name: "data_failed", handler: onDataFailed },
      { name: "loaded_images", handler: onLoadedImages },
      { name: "DOMLoaded", handler: onDOMLoaded },
      { name: "destroy", handler: onDestroy }
    ];
    const listeners = partialListeners.filter(
      (listener) => listener.handler != null
    );
    if (!listeners.length) {
      return;
    }
    const deregisterList = listeners.map(
      /**
       * Handle the process of adding an event listener
       * @param {Listener} listener
       * @return {Function} Function that deregister the listener
       */
      (listener) => {
        var _a2;
        (_a2 = animationInstanceRef.current) == null ? void 0 : _a2.addEventListener(
          listener.name,
          () => listener.handler
        );
        return () => {
          var _a3;
          (_a3 = animationInstanceRef.current) == null ? void 0 : _a3.removeEventListener(
            listener.name,
            () => listener.handler
          );
        };
      }
    );
    return () => {
      deregisterList.forEach((deregister) => deregister());
    };
  }, [
    onComplete,
    onLoopComplete,
    onEnterFrame,
    onSegmentStart,
    onConfigReady,
    onDataReady,
    onDataFailed,
    onLoadedImages,
    onDOMLoaded,
    onDestroy
  ]);
  const View = /* @__PURE__ */ jsx("div", __spreadValues({ style, ref: animationContainer }, rest));
  return {
    View,
    play,
    stop,
    pause,
    setSpeed,
    goToAndStop,
    goToAndPlay,
    setDirection,
    playSegments,
    setSubframe,
    getDuration,
    destroy,
    animationContainerRef: animationContainer,
    animationLoaded,
    animationItem: animationInstanceRef.current
  };
};

// src/Lottie/useLottieInteractivity.tsx
import { useEffect as useEffect2 } from "react";
function getContainerVisibility(container) {
  const { top, height } = container.getBoundingClientRect();
  const current = window.innerHeight - top;
  const max = window.innerHeight + height;
  return current / max;
}
function getContainerCursorPosition(container, cursorX, cursorY) {
  const { top, left, width, height } = container.getBoundingClientRect();
  const x = (cursorX - left) / width;
  const y = (cursorY - top) / height;
  return { x, y };
}
var useInitInteractivity = ({
  wrapperRef,
  animationItem,
  mode,
  actions
}) => {
  useEffect2(() => {
    const wrapper = wrapperRef.current;
    if (!wrapper || !animationItem || !actions.length) {
      return;
    }
    animationItem.stop();
    const scrollModeHandler = () => {
      let assignedSegment = null;
      const scrollHandler = () => {
        const currentPercent = getContainerVisibility(wrapper);
        const action = actions.find(
          ({ visibility }) => visibility && currentPercent >= visibility[0] && currentPercent <= visibility[1]
        );
        if (!action) {
          return;
        }
        if (action.type === "seek" && action.visibility && action.frames.length === 2) {
          const frameToGo = action.frames[0] + Math.ceil(
            (currentPercent - action.visibility[0]) / (action.visibility[1] - action.visibility[0]) * action.frames[1]
          );
          animationItem.goToAndStop(
            frameToGo - animationItem.firstFrame - 1,
            true
          );
        }
        if (action.type === "loop") {
          if (assignedSegment === null) {
            animationItem.playSegments(action.frames, true);
            assignedSegment = action.frames;
          } else {
            if (assignedSegment !== action.frames) {
              animationItem.playSegments(
                action.frames,
                true
              );
              assignedSegment = action.frames;
            } else if (animationItem.isPaused) {
              animationItem.playSegments(
                action.frames,
                true
              );
              assignedSegment = action.frames;
            }
          }
        }
        if (action.type === "play" && animationItem.isPaused) {
          animationItem.resetSegments(true);
          animationItem.play();
        }
        if (action.type === "stop") {
          animationItem.goToAndStop(
            action.frames[0] - animationItem.firstFrame - 1,
            true
          );
        }
      };
      document.addEventListener("scroll", scrollHandler);
      return () => {
        document.removeEventListener("scroll", scrollHandler);
      };
    };
    const cursorModeHandler = () => {
      const handleCursor = (_x, _y) => {
        let x = _x;
        let y = _y;
        if (x !== -1 && y !== -1) {
          const pos = getContainerCursorPosition(wrapper, x, y);
          x = pos.x;
          y = pos.y;
        }
        const action = actions.find(({ position }) => {
          if (position && Array.isArray(position.x) && Array.isArray(position.y)) {
            return x >= position.x[0] && x <= position.x[1] && y >= position.y[0] && y <= position.y[1];
          }
          if (position && !Number.isNaN(position.x) && !Number.isNaN(position.y)) {
            return x === position.x && y === position.y;
          }
          return false;
        });
        if (!action) {
          return;
        }
        if (action.type === "seek" && action.position && Array.isArray(action.position.x) && Array.isArray(action.position.y) && action.frames.length === 2) {
          const xPercent = (x - action.position.x[0]) / (action.position.x[1] - action.position.x[0]);
          const yPercent = (y - action.position.y[0]) / (action.position.y[1] - action.position.y[0]);
          animationItem.playSegments(action.frames, true);
          animationItem.goToAndStop(
            Math.ceil(
              (xPercent + yPercent) / 2 * (action.frames[1] - action.frames[0])
            ),
            true
          );
        }
        if (action.type === "loop") {
          animationItem.playSegments(action.frames, true);
        }
        if (action.type === "play") {
          if (animationItem.isPaused) {
            animationItem.resetSegments(false);
          }
          animationItem.playSegments(action.frames);
        }
        if (action.type === "stop") {
          animationItem.goToAndStop(action.frames[0], true);
        }
      };
      const mouseMoveHandler = (ev) => {
        handleCursor(ev.clientX, ev.clientY);
      };
      const mouseOutHandler = () => {
        handleCursor(-1, -1);
      };
      wrapper.addEventListener("mousemove", mouseMoveHandler);
      wrapper.addEventListener("mouseout", mouseOutHandler);
      return () => {
        wrapper.removeEventListener("mousemove", mouseMoveHandler);
        wrapper.removeEventListener("mouseout", mouseOutHandler);
      };
    };
    switch (mode) {
      case "scroll":
        return scrollModeHandler();
      case "cursor":
        return cursorModeHandler();
    }
  }, [mode, animationItem]);
};
var useLottieInteractivity = ({
  actions,
  mode,
  lottieObj
}) => {
  const { animationItem, View, animationContainerRef } = lottieObj;
  useInitInteractivity({
    actions,
    animationItem,
    mode,
    wrapperRef: animationContainerRef
  });
  return View;
};

// src/Lottie/Lottie.tsx
var Lottie = (props) => {
  var _b, _c, _d;
  const _a = props, { style, interactivity } = _a, lottieProps = __objRest(_a, ["style", "interactivity"]);
  const {
    View,
    play,
    stop,
    pause,
    setSpeed,
    goToAndStop,
    goToAndPlay,
    setDirection,
    playSegments,
    setSubframe,
    getDuration,
    destroy,
    animationContainerRef,
    animationLoaded,
    animationItem
  } = useLottie(lottieProps, style);
  useEffect3(() => {
    if (props.lottieRef) {
      props.lottieRef.current = {
        play,
        stop,
        pause,
        setSpeed,
        goToAndPlay,
        goToAndStop,
        setDirection,
        playSegments,
        setSubframe,
        getDuration,
        destroy,
        animationContainerRef,
        animationLoaded,
        animationItem
      };
    }
  }, [(_b = props.lottieRef) == null ? void 0 : _b.current]);
  return useLottieInteractivity({
    lottieObj: {
      View,
      play,
      stop,
      pause,
      setSpeed,
      goToAndStop,
      goToAndPlay,
      setDirection,
      playSegments,
      setSubframe,
      getDuration,
      destroy,
      animationContainerRef,
      animationLoaded,
      animationItem
    },
    actions: (_c = interactivity == null ? void 0 : interactivity.actions) != null ? _c : [],
    mode: (_d = interactivity == null ? void 0 : interactivity.mode) != null ? _d : "scroll"
  });
};

// src/useGetLottieFile.ts
import axios from "axios";
import { useEffect as useEffect4, useState as useState2 } from "react";
var LOTTIE_BASE_PATH = "//s3.amazonaws.com/a.storyblok.com/f/141754";
var LOTTIE_FILE_FORMAT = ".json";
var useGetLottieFile = (path) => {
  const [lottieData, setLottieData] = useState2(null);
  const fetchLottieData = (path2) => {
    axios.get(`https:${LOTTIE_BASE_PATH}${path2}${LOTTIE_FILE_FORMAT}`).then((res) => {
      setLottieData(res.data);
    }).catch((e) => console.error(e));
  };
  useEffect4(() => {
    fetchLottieData(path);
  }, [path]);
  return lottieData;
};

// src/lottieComponents/AppPageLottie/AppPageLottie.tsx
import { jsx as jsx2 } from "react/jsx-runtime";
var AppPageLottie = (props) => {
  const animationData = useGetLottieFile("/x/a262e8aea4/app_page_lottie");
  if (!animationData)
    return null;
  return /* @__PURE__ */ jsx2(Lottie, __spreadProps(__spreadValues({}, props), { animationData }));
};

// src/lottieComponents/Constantine404Lottie/Constantine404Lottie.tsx
import { jsx as jsx3 } from "react/jsx-runtime";
var Constantine404Lottie = (props) => {
  const animationData = useGetLottieFile("/x/8d76765770/constantine_404");
  if (!animationData)
    return null;
  return /* @__PURE__ */ jsx3(Lottie, __spreadProps(__spreadValues({}, props), { animationData }));
};

// src/lottieComponents/ConstantineAboutLottie/ConstantineAboutLottie.tsx
import { jsx as jsx4 } from "react/jsx-runtime";
var ConstantineAboutLottie = (props) => {
  const animationData = useGetLottieFile("/x/cb6ca801b5/constantine_about");
  if (!animationData)
    return null;
  return /* @__PURE__ */ jsx4(Lottie, __spreadProps(__spreadValues({}, props), { animationData }));
};

// src/lottieComponents/ConstantineBounce/ConstantineBounce.tsx
import { jsx as jsx5 } from "react/jsx-runtime";
var ConstantineBounce = (props) => {
  const animationData = useGetLottieFile("/x/d3e7b9270d/constantine_bounce");
  if (!animationData)
    return null;
  return /* @__PURE__ */ jsx5(Lottie, __spreadProps(__spreadValues({}, props), { animationData }));
};

// src/lottieComponents/ConstantineBounce360/ConstantineBounce360.tsx
import { jsx as jsx6 } from "react/jsx-runtime";
var ConstantineBounce360 = (props) => {
  const animationData = useGetLottieFile(
    "/x/161147fc27/constantine_bounce_360"
  );
  if (!animationData)
    return null;
  return /* @__PURE__ */ jsx6(Lottie, __spreadProps(__spreadValues({}, props), { animationData }));
};

// src/lottieComponents/ConstantineCoin/ConstantineCoin.tsx
import { jsx as jsx7 } from "react/jsx-runtime";
var ConstantineCoin = (props) => {
  const animationData = useGetLottieFile("/x/f1f2fe3db2/constantine_coin");
  if (!animationData)
    return null;
  return /* @__PURE__ */ jsx7(Lottie, __spreadProps(__spreadValues({}, props), { animationData }));
};

// src/lottieComponents/ConstantineCowboyLottie/ConstantineCowboyLottie.tsx
import { jsx as jsx8 } from "react/jsx-runtime";
var ConstantineCowboyLottie = (props) => {
  const animationData = useGetLottieFile("/x/78a1dec647/constantine_cowboy");
  if (!animationData)
    return null;
  return /* @__PURE__ */ jsx8(Lottie, __spreadProps(__spreadValues({}, props), { animationData }));
};

// src/lottieComponents/ConstantineFigure8/ConstantineFigure8.tsx
import { jsx as jsx9 } from "react/jsx-runtime";
var ConstantineFigure8 = (props) => {
  const animationData = useGetLottieFile("/x/81039cc5e6/constantine_figure8");
  if (!animationData)
    return null;
  return /* @__PURE__ */ jsx9(Lottie, __spreadProps(__spreadValues({}, props), { animationData }));
};

// src/lottieComponents/ConstantineFigure8FullScreen/ConstantineFigure8FullScreen.tsx
import { jsx as jsx10 } from "react/jsx-runtime";
var ConstantineFigure8FullScreen = (props) => {
  const animationData = useGetLottieFile(
    "/x/543471ca95/constantine_figure8_fullscreen"
  );
  if (!animationData)
    return null;
  return /* @__PURE__ */ jsx10(Lottie, __spreadProps(__spreadValues({}, props), { animationData }));
};

// src/lottieComponents/ConstantineHello/ConstantineHello.tsx
import { jsx as jsx11 } from "react/jsx-runtime";
var ConstantineHello = (props) => {
  const animationData = useGetLottieFile("/x/cce5a30c5f/constantine_hello");
  if (!animationData)
    return null;
  return /* @__PURE__ */ jsx11(Lottie, __spreadProps(__spreadValues({}, props), { animationData }));
};

// src/lottieComponents/ConstantineLegalLottie/ConstantineLegalLottie.tsx
import { jsx as jsx12 } from "react/jsx-runtime";
var ConstantineLegalLottie = (props) => {
  const animationData = useGetLottieFile("/x/2a0fe32319/constantine_legal");
  if (!animationData)
    return null;
  return /* @__PURE__ */ jsx12(Lottie, __spreadProps(__spreadValues({}, props), { animationData }));
};

// src/lottieComponents/ConstantineLoginPeakLottie/ConstantineLoginPeakLottie.tsx
import { useEffect as useEffect5, useRef as useRef2 } from "react";
import { jsx as jsx13 } from "react/jsx-runtime";
var ConstantineLoginPeakLottie = ({
  coverEyes
}) => {
  const lottieRef = useRef2(null);
  const animationData = useGetLottieFile("/x/9a3e28f092/constantine_peak");
  useEffect5(() => {
    var _a, _b, _c, _d;
    if (coverEyes) {
      (_a = lottieRef == null ? void 0 : lottieRef.current) == null ? void 0 : _a.setDirection(1);
      (_b = lottieRef == null ? void 0 : lottieRef.current) == null ? void 0 : _b.goToAndPlay(0, true);
      return;
    }
    if (!coverEyes) {
      (_c = lottieRef == null ? void 0 : lottieRef.current) == null ? void 0 : _c.setDirection(-1);
      (_d = lottieRef == null ? void 0 : lottieRef.current) == null ? void 0 : _d.goToAndPlay(7, true);
    }
  }, [coverEyes]);
  useEffect5(() => {
    var _a;
    (_a = lottieRef == null ? void 0 : lottieRef.current) == null ? void 0 : _a.goToAndStop(0, true);
  }, [animationData]);
  if (!animationData)
    return null;
  return /* @__PURE__ */ jsx13(
    Lottie,
    {
      lottieRef,
      animationData,
      loop: false,
      autoPlay: false,
      onComplete: () => {
        var _a;
        if (coverEyes) {
          (_a = lottieRef == null ? void 0 : lottieRef.current) == null ? void 0 : _a.goToAndStop(7, true);
          return;
        }
      }
    }
  );
};

// src/lottieComponents/ConstantinePartyLottie/ConstantinePartyLottie.tsx
import { jsx as jsx14 } from "react/jsx-runtime";
var ConstantinePartyLottie = (props) => {
  const animationData = useGetLottieFile("/x/bbf9c2edc0/constantine_party");
  if (!animationData)
    return null;
  return /* @__PURE__ */ jsx14(Lottie, __spreadProps(__spreadValues({}, props), { animationData }));
};

// src/lottieComponents/ConstantinePhone/ConstantinePhone.tsx
import { jsx as jsx15 } from "react/jsx-runtime";
var ConstantinePhone = (props) => {
  const animationData = useGetLottieFile("/x/fb8bc9c6e2/constantine_phone");
  if (!animationData)
    return null;
  return /* @__PURE__ */ jsx15(Lottie, __spreadProps(__spreadValues({}, props), { animationData }));
};

// src/lottieComponents/ConstantineQuestions/ConstantineQuestions.tsx
import { jsx as jsx16 } from "react/jsx-runtime";
var ConstantineQuestions = (props) => {
  const animationData = useGetLottieFile("/x/41515c4de5/constantine_questions");
  if (!animationData)
    return null;
  return /* @__PURE__ */ jsx16(Lottie, __spreadProps(__spreadValues({}, props), { animationData }));
};

// src/lottieComponents/ConstantineSplat/ConstantineSplat.tsx
import { jsx as jsx17 } from "react/jsx-runtime";
var ConstantineSplat = (props) => {
  const animationData = useGetLottieFile("/x/97f6979345/constantine_splat");
  if (!animationData)
    return null;
  return /* @__PURE__ */ jsx17(Lottie, __spreadProps(__spreadValues({}, props), { animationData }));
};

// src/lottieComponents/ConstantineWhyLottie/ConstantineWhyLottie.tsx
import { jsx as jsx18 } from "react/jsx-runtime";
var ConstantineWhyLottie = (props) => {
  const animationData = useGetLottieFile("/x/3e46c767c4/constantine_why");
  if (!animationData)
    return null;
  return /* @__PURE__ */ jsx18(Lottie, __spreadProps(__spreadValues({}, props), { animationData }));
};

// src/lottieComponents/ConstantineWorkLottie/ConstantineWorkLottie.tsx
import { jsx as jsx19 } from "react/jsx-runtime";
var ConstantineWorkLottie = (props) => {
  const animationData = useGetLottieFile("/x/67f5c28f1c/constantine_work");
  if (!animationData)
    return null;
  return /* @__PURE__ */ jsx19(Lottie, __spreadProps(__spreadValues({}, props), { animationData }));
};

// src/lottieComponents/HomeLottie/HomeLottie.tsx
import { useRef as useRef3 } from "react";
import { jsx as jsx20 } from "react/jsx-runtime";
var HomeLottie = (props) => {
  const animationData = useGetLottieFile("/x/69bf9773aa/home_hero");
  const lottieRef = useRef3(null);
  if (!animationData)
    return null;
  const loopSet = () => {
    var _a;
    (_a = lottieRef == null ? void 0 : lottieRef.current) == null ? void 0 : _a.goToAndPlay(168, true);
  };
  return /* @__PURE__ */ jsx20(
    Lottie,
    __spreadProps(__spreadValues({
      lottieRef
    }, props), {
      animationData,
      style: props.style,
      className: props.className,
      onLoopComplete: loopSet
    })
  );
};

// src/lottieComponents/HomeLottieXmas/HomeLottieXmas.tsx
import { jsx as jsx21 } from "react/jsx-runtime";
var HomeLottieXmas = (props) => {
  const animationData = useGetLottieFile("/x/9b16c30282/home_hero_christmas");
  if (!animationData)
    return null;
  return /* @__PURE__ */ jsx21(Lottie, __spreadProps(__spreadValues({}, props), { animationData }));
};

// src/lottieComponents/MapLottie/MapLottie.tsx
import { jsx as jsx22 } from "react/jsx-runtime";
var MapLottie = (props) => {
  const animationData = useGetLottieFile("/x/35473f14fb/map");
  if (!animationData)
    return null;
  return /* @__PURE__ */ jsx22(Lottie, __spreadProps(__spreadValues({}, props), { animationData }));
};

// src/lottieComponents/OctopusLogoLottie/OctopusLogoLottie.tsx
import { useEffect as useEffect6, useRef as useRef4, useState as useState3 } from "react";

// src/lottieComponents/OctopusLogoLottie/OctopusLogoLottieSessionUtils.ts
var HAS_SEEN_KEY = "hasSeenConstantineLogo";
var setHasSeenSession = () => {
  if (typeof window !== "undefined") {
    return window.sessionStorage.setItem(HAS_SEEN_KEY, "true");
  }
};
var getHasSeenSession = () => {
  if (typeof window !== "undefined") {
    const getStorage = window.sessionStorage.getItem(HAS_SEEN_KEY) || void 0;
    if (getStorage === "true") {
      return true;
    }
    return false;
  }
};

// src/lottieComponents/OctopusLogoLottie/OctopusLogoLottie.tsx
import { jsx as jsx23 } from "react/jsx-runtime";
var OctopusLogoLottie = (props) => {
  const [playing, setPlaying] = useState3(false);
  const lottieRef = useRef4(null);
  const checkSessionHasSeenConstantine = props.viewMultiple ? false : getHasSeenSession();
  const animationData = useGetLottieFile(
    props.inverted ? "/x/175d4d9863/logoswimlr" : "/x/e31f82ca1f/logoswimrl"
  );
  useEffect6(() => {
    var _a, _b;
    if (animationData && checkSessionHasSeenConstantine) {
      (_a = lottieRef == null ? void 0 : lottieRef.current) == null ? void 0 : _a.goToAndStop(336, true);
      return;
    }
    if (animationData) {
      (_b = lottieRef == null ? void 0 : lottieRef.current) == null ? void 0 : _b.goToAndPlay(0, true);
      setHasSeenSession();
    }
  }, [animationData]);
  if (!animationData)
    return null;
  return /* @__PURE__ */ jsx23(
    Lottie,
    {
      lottieRef,
      loop: false,
      autoPlay: false,
      animationData
    }
  );
};
export {
  AppPageLottie,
  Constantine404Lottie,
  ConstantineAboutLottie,
  ConstantineBounce,
  ConstantineBounce360,
  ConstantineCoin,
  ConstantineCowboyLottie,
  ConstantineFigure8,
  ConstantineFigure8FullScreen,
  ConstantineHello,
  ConstantineLegalLottie,
  ConstantineLoginPeakLottie,
  ConstantinePartyLottie,
  ConstantinePhone,
  ConstantineQuestions,
  ConstantineSplat,
  ConstantineWhyLottie,
  ConstantineWorkLottie,
  HomeLottie,
  HomeLottieXmas,
  MapLottie,
  OctopusLogoLottie,
  useGetLottieFile
};
//! goToAndStop must be relative to the start of the current segment
