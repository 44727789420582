import { gql } from "@apollo/client";
import { createUseQuery } from "@octopus-energy/apollo-client";
import {
  EfLsQuery,
  EfLsQueryVariables,
} from "./__generated__/queryEFLs.generated";

export const QUERY_EFLS = gql`
  query EFLs(
    $productId: ID
    $loadZone: LoadZone
    $serviceProvider: ServiceProvider
  ) {
    efls(
      productId: $productId
      loadZone: $loadZone
      serviceProvider: $serviceProvider
    ) {
      id
      serviceProvider
      loadZone
      language
      url
      productCode
      productDisplayName
    }
  }
`;

export const useQueryEFLs = createUseQuery<EfLsQuery, EfLsQueryVariables>(
  QUERY_EFLS
);
