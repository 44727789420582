import {
  AddressTdspMappingOutputType,
  PremiseType,
  ShellAccountEnrollmentTypeEnum,
} from "@core/apiRequests/graphql-global-types";
import { useSnackbarNotification } from "@core/molecules";
import useTranslation from "next-translate/useTranslation";
import { useFormContext } from "react-hook-form";
import { useEnrollment } from "../enrollmentUtils";
import { CreditCheckType } from "../getReady";
import { QuoteFormValues } from "./QuotePage";
import { useSetQuoteData } from "./useSetQuoteData";

export const useSelectQuotePageAddress = (pushToNextStep = false) => {
  const { t } = useTranslation("enrollment/formFields");
  const enrollment = useEnrollment();
  const form = useFormContext<QuoteFormValues>();
  const [snackbarNotification] = useSnackbarNotification();
  const setQuoteData = useSetQuoteData();

  return async (address: AddressTdspMappingOutputType | null) => {
    if (!address) {
      return;
    }

    // block all non-residential addresses
    if (
      address.premiseType &&
      address.premiseType !== PremiseType.Residential
    ) {
      if (address.premiseType === PremiseType.SmallNonResidential) {
        snackbarNotification.error(
          t("validation_servicePremiseTypeSmallResidential"),
          {
            "data-cy": "snackbar-alert-invalid-premise-type",
          }
        );
      } else {
        snackbarNotification.error(
          t("validation_servicePremiseTypeLargeResidential"),
          {
            "data-cy": "snackbar-alert-invalid-premise-type",
          }
        );
      }
      return;
    }

    const previousQuoteInvalid =
      enrollment.formData.getReady?.esiId !== address.esiId;
    if (previousQuoteInvalid) {
      enrollment.setQuoteCode(undefined);
    }

    // set context
    enrollment.setFormData((prevState) => ({
      ...prevState,
      getReady: {
        service_Address: address.address || "",
        service_AptSuite: address.address2 || "",
        service_City: address.city || "",
        service_State: address.state || "",
        service_ZipCode: address.postCode || "",
        esiId: address.esiId || "",
        loadZone: address.loadZone || null,
        serviceProvider: address.serviceProvider || null,
        // Everything below here is just setting the default values for formData
        service_SwitchType: ShellAccountEnrollmentTypeEnum.PendingSwitchRequest,
        service_SwitchDate: undefined,
        firstName: "",
        lastName: "",
        emailAddress: "",
        phoneNumber: "",
        DOB: "",
        socialSecurityNumber: "",
        searchByESI: false,
        criticalCare: false,
        creditCheckType: CreditCheckType.RUN_CREDIT_CHECK,
        premiseType: null,
        meterType: null,
      },
    }));

    if (address.esiId && address.address && address.city) {
      form.setValue("esiId", address.esiId);
      form.setValue("service_Address", address.address);
      form.setValue("service_City", address.city);
      form.clearErrors("service_Address");
      form.clearErrors("service_City");
      form.trigger();
    }
    form.setValue("service_AptSuite", address.address2 || "");

    // Upon successful address verification, proceed to the finalize step
    if (pushToNextStep) {
      setQuoteData(address.esiId);
    }
  };
};
