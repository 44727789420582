import { useCustomerIsRivian } from "@core/enrollment";
import { Maybe } from "@core/types";
import { Typography, TypographyProps } from "@octopus-energy/coral-mui";
import useTranslation from "next-translate/useTranslation";
import { FC } from "react";

export type ProductCardRateBreakdownProps = {
  minPrice?: number | string | null;
  align?: TypographyProps["align"];
  variant?: TypographyProps["variant"];
  peakPrice?: Maybe<number | string>;
};

export const ProductCardRateBreakdown: FC<ProductCardRateBreakdownProps> = ({
  minPrice,
  align,
  variant = "body2",
  peakPrice,
}) => {
  const { t } = useTranslation("product/productCard");
  const customerIsRivian = useCustomerIsRivian();

  return (
    <Typography align={align} mt={0} variant={variant} data-cy="unit-cost">
      {customerIsRivian ? t("onPeakRate") : t("unitCost")}:{" "}
      <Typography component="span" fontWeight="bold" variant={variant}>
        {customerIsRivian ? peakPrice : minPrice}¢/kWh
      </Typography>
    </Typography>
  );
};
