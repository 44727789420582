export const APPLE_APP_STORE_LINK =
  "https://apps.apple.com/us/app/octopus-energy/id1093452209";
export const GOOGLE_PLAY_LINK =
  "https://play.google.com/store/apps/details?id=energy.octopus.octopusenergy.android&gl=us&pli=1";
export const TWITTER_REFERRAL_LINK =
  "https://twitter.com/intent/tweet?text=https%3A%2F%2Foctopusenergy.com%2Ffriend-referral";
export const FACEBOOK_REFERRAL_LINK =
  "https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Foctopusenergy.com%2Ffriend-referral";
export const WHATSAPP_REFERRAL_LINK =
  "https://wa.me/?text=https%3A%2F%2Foctopusenergy.com%2Ffriend-referral";
export const EMAIL_REFERRAL_LINK =
  "mailto:?subject=$100 credit when you switch to an Intelligent plan with Octopus Energy &body=https://octopusenergy.com/friend-referral";
