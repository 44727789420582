import { useAddAccountCampaignMutation } from "@core/apiRequests";
import { AccountCampaignChoices } from "@core/apiRequests/graphql-global-types";
import { useCustomerAcceptQuoteMutation } from "@core/apiRequests/renewal/useCustomerAcceptQuoteMutation";
import { useCustomerRevokeFutureAgreements } from "@core/apiRequests/renewal/useCustomerRevokeFutureAgreementsMutation";
import { Locale } from "@core/constants";
import { useOctopusProduct } from "@core/enrollment";
import { handleApolloMutationError } from "@core/error";
import {
  TERMS_OF_SERVICE_URL_ENGLISH,
  TERMS_OF_SERVICE_URL_SPANISH,
  YOUR_RIGHTS_AS_A_CUSTOMER_URL_ENGLISH,
  YOUR_RIGHTS_AS_A_CUSTOMER_URL_SPANISH,
} from "@core/legal";
import { usePortalAccountNumber } from "@core/portalUtils";
import { OctopusProductId } from "@core/product";
import { Maybe } from "@core/types";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  LoadingButton,
  Typography,
} from "@octopus-energy/coral-mui";
import { formatISO, set } from "date-fns";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import { FC, InputHTMLAttributes, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useBooleanState } from "react-use-object-state";
import * as Yup from "yup";
import { useEvaluateContractStatus } from "./useEvaluateContractStatus";

type RenewalAgreementsProps = {
  disabled?: boolean;
  productId: OctopusProductId;
  renewalQuoteCode: Maybe<string>;
  renewalStartDate: Date | undefined;
  withEV?: boolean;
  withThermostat?: boolean;
};

export type RenewalAgreementsFormPartial = {
  productConsent: boolean;
  acceptEflYracTos: boolean;
};

type RenewalFormValues = {
  productConsent: boolean;
  acceptEflYracTos: boolean;
};

const useValidation = (): Yup.SchemaOf<RenewalFormValues> => {
  const { t } = useTranslation("enrollment/formFields");

  return Yup.object().shape({
    productConsent: Yup.bool().oneOf([true]).required(),
    acceptEflYracTos: Yup.bool()
      .oneOf([true], t("finaliseConsentEflYracTosPrompt"))
      .required(),
  });
};

const defaultValues: RenewalFormValues = {
  productConsent: true,
  acceptEflYracTos: false,
};

export const RenewalAgreementsForm: FC<RenewalAgreementsProps> = ({
  disabled,
  productId,
  renewalQuoteCode,
  renewalStartDate,
  withEV = undefined,
  withThermostat = undefined,
}) => {
  const { t } = useTranslation("enrollment/formFields");
  const { t: tAccount } = useTranslation("account/accountNumber");
  const accountNumber = usePortalAccountNumber();
  const selectedProduct = useOctopusProduct(productId);
  const krakenId = selectedProduct?.krakenID;
  const router = useRouter();
  const locale = router.locale;
  const [customerAcceptQuote] = useCustomerAcceptQuoteMutation();
  const [customerRevokeAgreements] = useCustomerRevokeFutureAgreements();
  const { renewalAgreement } = useEvaluateContractStatus();
  const loading = useBooleanState(false);
  const [addAccountCampaign] = useAddAccountCampaignMutation();

  const form = useForm<RenewalFormValues>({
    mode: "onChange",
    resolver: yupResolver(useValidation()),
    defaultValues,
  });

  useEffect(() => {
    form.trigger();
  }, [disabled]);

  const setRenewalAccountCampaign = async () => {
    if (withEV || withThermostat) {
      addAccountCampaign({
        variables: {
          input: {
            accountNumber,
            campaign: withEV
              ? AccountCampaignChoices.Octo12Ev
              : AccountCampaignChoices.Octo12Therm,
          },
        },
      });
    }
  };

  const handleCustomerRevokeFutureAgreements = () => {
    customerRevokeAgreements({
      variables: {
        input: {
          accountNumber,
        },
      },
      onError: handleApolloMutationError,
    });
  };

  const handleSubmitRenewalForm = form.handleSubmit(async () => {
    if (form.formState.isValid && renewalQuoteCode && krakenId) {
      loading.setTrue();
      if (renewalAgreement?.id) {
        handleCustomerRevokeFutureAgreements();
      }

      customerAcceptQuote({
        variables: {
          input: {
            accountNumber,
            code: renewalQuoteCode,
            productId: krakenId,
            effectiveFrom:
              renewalStartDate &&
              formatISO(
                set(new Date(renewalStartDate), {
                  hours: 0,
                  minutes: 0,
                  seconds: 0,
                  milliseconds: 0,
                })
              ),
          },
        },
        onError: handleApolloMutationError,
        onCompleted: (data) => {
          if (data.customerAcceptQuote?.agreement?.id) {
            setRenewalAccountCampaign();
            router.replace(`/account/${accountNumber}?renewalComplete=true`);
          }
        },
      });
    }
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmitRenewalForm}>
        <FormGroup sx={{ mt: 1 }}>
          <FormControlLabel
            control={
              <Checkbox
                required
                inputProps={
                  {
                    "data-cy": "service-terms-checkbox",
                  } as InputHTMLAttributes<HTMLInputElement>
                }
                data-cy="service-terms"
                {...form.register("acceptEflYracTos")}
                checked={form.watch().acceptEflYracTos}
              />
            }
            label={
              <Typography variant="body2" data-cy="service-terms-label">
                {t("finaliseAcceptEflYracTos1")}{" "}
                <a
                  href={
                    selectedProduct?.serviceProviders[0]?.[
                      locale === Locale.ENGLISH ? "efl_en" : "efl_es"
                    ]?.url || "/404"
                  }
                  target="blank"
                  style={{ color: "white", textDecoration: "underline" }}
                >
                  <strong>EFL</strong>
                </a>
                {", "}
                <a
                  href={
                    locale === Locale.ENGLISH
                      ? YOUR_RIGHTS_AS_A_CUSTOMER_URL_ENGLISH
                      : YOUR_RIGHTS_AS_A_CUSTOMER_URL_SPANISH
                  }
                  target="_blank"
                  style={{ color: "white", textDecoration: "underline" }}
                >
                  <strong>YRAC</strong>
                </a>
                {", "}
                {t("finaliseAcceptEflYracTos2")}{" "}
                <a
                  href={
                    locale === Locale.ENGLISH
                      ? TERMS_OF_SERVICE_URL_ENGLISH
                      : TERMS_OF_SERVICE_URL_SPANISH
                  }
                  target="_blank"
                  style={{ color: "white", textDecoration: "underline" }}
                >
                  <strong>{t("finaliseAcceptEflYracTos3")}</strong>
                </a>
              </Typography>
            }
          />
        </FormGroup>
        <LoadingButton
          type="submit"
          loading={loading.state}
          fullWidth
          sx={{ mt: 3 }}
          disabled={disabled || !form.formState.isValid}
        >
          {tAccount("confirmRenewal")}
        </LoadingButton>
      </form>
    </FormProvider>
  );
};
