import { Box, Container, Grid, Stack } from "@mui/material";
import {
  Button,
  DesktopDatePicker,
  LoadingButton,
  ScrollableTextContainer,
  SignatureField,
  TextField,
  TextFieldProps,
  Typography,
} from "@octopus-energy/coral-mui";
import useTranslation from "next-translate/useTranslation";
import { useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import { navigatorTerms } from "./navigatorTerms";
import { useNavigatorForm } from "./useNavigatorForm";

export const NavigatorAgreement = () => {
  const { t, lang } = useTranslation("enrollment/formFields");
  const [signatureEmpty, setSignatureEmpty] = useState<boolean>(true);
  const signatureRef = useRef<SignatureCanvas>(null);

  const { formik, loading } = useNavigatorForm({
    signatureRef,
  });

  return (
    <Container
      sx={{ width: "100%", minHeight: "85vh" }}
      data-cy="navigator-container"
    >
      <Box
        marginRight="-50vw"
        marginLeft="-50vw"
        position="relative"
        left="50%"
        right="50%"
        width="100vw"
      >
        <Container maxWidth="lg" sx={{ margin: "auto" }}>
          <Box width="100%" marginTop={4} marginBottom={10}>
            <Typography
              variant="h1"
              textAlign="center"
              marginBottom={6}
              color="tertiary"
              data-cy="navigator-heading"
            >
              {t("navigator_heading")}
            </Typography>
            <ScrollableTextContainer>
              {navigatorTerms.map((content, idx) => {
                return (
                  <Typography
                    variant={content.variant}
                    textAlign={content.alignment}
                    fontWeight={content.fontWeight}
                    marginBottom={4}
                    key={idx}
                  >
                    {lang === "en" && content.content_en}
                    {lang === "es" && content.content_es}
                  </Typography>
                );
              })}
            </ScrollableTextContainer>
          </Box>
          <form onSubmit={formik.handleSubmit} name="navigatorForm">
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              wrap="nowrap"
              direction="row"
              marginTop={4}
              marginBottom={4}
              width="100%"
            >
              <Grid item xs={8} paddingRight={3}>
                <TextField
                  data-cy="navigator-name"
                  variant="outlined"
                  name="name"
                  label={t("navigator_field_name")}
                  sx={{ width: "100%" }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={
                    Boolean(formik.errors.name && formik.touched.name)
                      ? formik.errors.name
                      : " "
                  }
                  error={Boolean(formik.errors.name && formik.touched.name)}
                />
              </Grid>
              <Grid item xs={4}>
                <DesktopDatePicker
                  label={t("navigator_field_date")}
                  onChange={(value) => {
                    formik.setFieldValue("date", value);
                  }}
                  minDate={new Date()}
                  value={formik.values.date}
                  renderInput={(params: TextFieldProps) => (
                    <TextField
                      data-cy="navigator-date"
                      {...params}
                      name="date"
                      onBlur={formik.handleBlur}
                      variant="outlined"
                      sx={{ width: "100%" }}
                      helperText={
                        Boolean(formik.errors.date && formik.touched.date)
                          ? formik.errors.date
                          : " "
                      }
                      error={Boolean(formik.errors.date && formik.touched.date)}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Box marginTop={4} marginBottom={4} width="100%">
              <SignatureField
                penColor="black"
                canvasProps={{ width: 1000, height: 200 }}
                ref={signatureRef}
                label={t("navigator_field_signature")}
                onEnd={() => {
                  setSignatureEmpty(signatureRef.current?.isEmpty() || false);
                }}
              />
            </Box>
            <Stack direction="row" spacing={3} justifyContent="center" my={4}>
              <LoadingButton
                data-cy="navigator-switch-button"
                disabled={signatureEmpty || !(formik.isValid && formik.dirty)}
                type="submit"
                loading={loading}
              >
                {t("navigator_field_button")}
              </LoadingButton>
              <Button variant="outlined" onClick={signatureRef?.current?.clear}>
                {t("navigatorFieldButtonClear")}
              </Button>
            </Stack>
          </form>
        </Container>
      </Box>
    </Container>
  );
};
