import { useQueryAccount } from "@core/apiRequests";
import {
  accountHasEV,
  accountHasThermostat,
  usePortalAccountNumber,
} from "@core/portalUtils";
import {
  Box,
  Grid,
  Skeleton,
  Stack,
  Typography,
} from "@octopus-energy/coral-mui";
import useTranslation from "next-translate/useTranslation";
import { useEvaluateContractStatus } from "./useEvaluateContractStatus";
import { useEnrollment } from "@core/enrollment";

const cityStateZipRegex = /,(?=\s?[A-Za-z]+,\s?[A-Za-z]{2},?\s?\d{5})/;

export const RenewalAccountInfo = () => {
  const { t } = useTranslation("account/accountNumber");
  const { t: lang } = useTranslation("enrollment/plans");
  const { t: langProductCard } = useTranslation("product/productCard");

  const { currentProductName, currentProductRate } =
    useEvaluateContractStatus();
  const { monthlyKwhUsage } = useEnrollment();

  const accountNumber = usePortalAccountNumber();
  const queryAccount = useQueryAccount({
    variables: {
      accountNumber,
    },
  });
  const withEV = accountHasEV(queryAccount.data);
  const withThermostat = accountHasThermostat(queryAccount.data);
  const loading = queryAccount.loading;
  const accountDetails = queryAccount.data?.account;
  const estimatedMonthlyCost = (
    (currentProductRate * Number(monthlyKwhUsage)) /
    100
  ).toFixed(0);

  return (
    <>
      {loading ? (
        <Skeleton width="100%" height={100} variant="rectangular" />
      ) : (
        <Box
          maxWidth="lg"
          bgcolor="background.paper"
          borderRadius={3}
          my={4}
          mx="auto"
          p={3}
        >
          <Typography variant="h3" fontWeight={300} mb={3}>
            {t("currentPlan")}
          </Typography>
          <Stack>
            <Grid container xs={12} spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography variant="h3">
                  {currentProductName?.split(" ")[0]}
                  <Typography variant="h3" fontWeight={300} component="span">
                    {currentProductName?.split(" ")[1]}
                  </Typography>
                </Typography>
                <Typography
                  variant="body1"
                  component="h2"
                  color={
                    !withThermostat && !withEV ? "textPrimary" : "tertiary"
                  }
                  data-cy="product-title-variant"
                >
                  <strong>
                    {withThermostat && langProductCard("withThermostat")}
                  </strong>
                  <strong>{withEV && langProductCard("withEV")}</strong>
                  <strong>
                    {" "}
                    {!withThermostat && !withEV && lang("basicPlan")}
                  </strong>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Stack spacing={-0.5}>
                  <Typography variant="h3">${estimatedMonthlyCost}</Typography>
                  <Typography variant="body2">
                    {t("monthlyEstimate")}
                  </Typography>
                  <Typography variant="body2">
                    {t("yourRate")}:{" "}
                    <Typography component="span" fontWeight="medium">
                      {Number(currentProductRate).toFixed(1)}
                      ¢/kWh
                    </Typography>
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
            <Grid container xs={12} spacing={2} mt={1}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2">{t("serviceAddress")}</Typography>
                {accountDetails?.properties?.length !== 0 && (
                  <Stack>
                    {accountDetails?.properties?.map((property) => (
                      <Box key={property?.id}>
                        <Typography
                          variant={
                            (property?.address
                              ?.split(cityStateZipRegex)
                              .slice(0, 1)
                              .toString().length || 0) > 19
                              ? "h4"
                              : "h3"
                          }
                          fontWeight="medium"
                          component="h3"
                        >
                          {
                            property?.address
                              ?.split(cityStateZipRegex)
                              .slice(0, 1)
                              .toString() as string
                          }
                        </Typography>
                        <Typography
                          variant={
                            (property?.address
                              ?.split(cityStateZipRegex)
                              .slice(0, 1)
                              .toString().length || 0) > 19
                              ? "h4"
                              : "h3"
                          }
                          fontWeight="medium"
                          component="h3"
                        >
                          {
                            property?.address
                              ?.split(cityStateZipRegex)
                              .slice(1)
                              .toString() as string
                          }
                        </Typography>
                      </Box>
                    ))}
                  </Stack>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Stack>
                  <Typography variant="body2">
                    {t("yourAccountNumber")}
                  </Typography>
                  <Typography variant="h3">{accountDetails?.number}</Typography>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </Box>
      )}
    </>
  );
};
