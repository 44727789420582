import { gql } from "@apollo/client";
import {
  createUseQuery,
  createUseQueryFunction,
} from "@octopus-energy/apollo-client";
import {
  GetAffiliateLinkQuery,
  GetAffiliateLinkQueryVariables,
} from "./__generated__/useQueryGetAffiliateLink.generated";

export const QUERY_GET_AFFILIATE_LINK = gql`
  query GetAffiliateLink($subdomain: String!) {
    affiliateLink(subdomain: $subdomain) {
      id
      subdomain
      organisation {
        id
        salesChannel
      }
    }
  }
`;

export const useQueryGetAffiliateLink = createUseQuery<
  GetAffiliateLinkQuery,
  GetAffiliateLinkQueryVariables
>(QUERY_GET_AFFILIATE_LINK);

export const useQueryGetAffiliateLinkFunction = createUseQueryFunction<
  GetAffiliateLinkQuery,
  GetAffiliateLinkQueryVariables
>(QUERY_GET_AFFILIATE_LINK);
