import { TextField } from "@octopus-energy/coral-mui";
import { differenceInYears, parse } from "date-fns";
import useTranslation from "next-translate/useTranslation";
import { useFormContext } from "react-hook-form";
import * as Yup from "yup";

export const useDOBValidation = () => {
  const { t } = useTranslation("enrollment/formFields");
  return Yup.string()
    .nullable()
    .test("dob-under-18", t("validation_dobUnderAge"), (value) => {
      const dob = new Date(value as string | number | Date);
      const age = differenceInYears(new Date(), dob);
      return age >= 18;
    })
    .test("dob-over-120", t("validation_notDate"), (value) => {
      const dob = new Date(value as string | number | Date);
      const age = differenceInYears(new Date(), dob);
      return age < 120;
    })
    .required(t("validation_dobNotEntered"))
    .typeError(t("validation_notDate"))
    .test("dob-year", t("validation_notDate"), (value) => {
      if (value === null || value === undefined || value === "") return true;
      const year = parse(value, "MM/dd/yyyy", new Date()).getFullYear();
      return year >= 1000 && year < 10000;
    });
};

export type DateOfBirthFormPartial = {
  DOB: string;
};

export const DateOfBirth = () => {
  const { t } = useTranslation("enrollment/formFields");
  const {
    watch,
    trigger,
    setValue,
    formState: { errors },
  } = useFormContext<DateOfBirthFormPartial>();

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputDate = e.target.value;
    const formattedValue = inputDate
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "$1/$2")
      .replace(/(\d{2})(\d)/, "$1/$2")
      .slice(0, 10);
    setValue("DOB", formattedValue);
    trigger("DOB");
  };

  return (
    <TextField
      required
      fullWidth
      name="DOB"
      label={t("labels_dob")}
      type="text"
      value={watch().DOB}
      onChange={handleOnChange}
      data-cy="date-picker"
      helperText={errors.DOB?.message || ""}
      error={Boolean(errors.DOB)}
    />
  );
};
