var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};

// src/lib/createUseMutation.ts
import {
  useMutation
} from "@apollo/client";
import { merge } from "lodash";
var createUseMutation = (mutation, defaultOptions) => {
  return (options) => useMutation(mutation, merge(defaultOptions, options));
};

// src/lib/createUseQuery.ts
import {
  useQuery
} from "@apollo/client";
import { merge as merge2 } from "lodash";
var createUseQuery = (query, defaultOptions) => {
  return (options) => useQuery(query, merge2(defaultOptions, options));
};

// src/lib/createUseQueryFunction.ts
import { merge as merge3 } from "lodash";

// src/provider/apolloClient.ts
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ApolloLink
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
var krakenEnvLink = createHttpLink({
  uri: process.env.NEXT_PUBLIC_KRAKEN_GRAPHQL_URI
});
var cmsLink = createHttpLink({
  uri: process.env.NEXT_PUBLIC_CMS_PUBLIC_URI
});
var CMS = "cms";
var AUTH_TOKEN = "authToken";
var getAuthToken = () => {
  if (typeof window !== "undefined") {
    return window.sessionStorage.getItem(AUTH_TOKEN) || "";
  }
};
var krakenProdLink = createHttpLink({
  uri: "https://api.oeus-kraken.energy/v1/graphql/"
});
var KRAKEN_PROD = "krakenProd";
var authLink = setContext((_, { headers }) => {
  const token = getAuthToken();
  return {
    headers: __spreadValues({
      authorization: token || ""
    }, headers)
  };
});
var apolloClient = new ApolloClient({
  link: ApolloLink.split(
    (operation) => operation.getContext().clientName === CMS,
    cmsLink,
    // <= apollo will send to this if clientName is "cms"
    authLink.concat(
      ApolloLink.split(
        (operation) => operation.getContext().clientName === KRAKEN_PROD,
        authLink.concat(krakenProdLink),
        // <= apollo will send to this if clientName is "krakenProd"
        authLink.concat(krakenEnvLink)
        // <= otherwise will send to this
      )
    )
  ),
  cache: new InMemoryCache()
});

// src/provider/ApolloProvider.tsx
import { ApolloProvider as ApolloClientProvider } from "@apollo/client";
import { jsx } from "react/jsx-runtime";
var ApolloProvider = ({ children }) => {
  return /* @__PURE__ */ jsx(ApolloClientProvider, { client: apolloClient, children });
};

// src/lib/createUseQueryFunction.ts
var createUseQueryFunction = (query, defaultOptions) => {
  return () => (options) => apolloClient.query(__spreadValues({
    query
  }, merge3(defaultOptions, options)));
};
export {
  ApolloProvider,
  KRAKEN_PROD,
  apolloClient,
  createUseMutation,
  createUseQuery,
  createUseQueryFunction
};
