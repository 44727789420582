import { FormikHelpers, useFormik } from "formik";
import * as Yup from "yup";
import { LanguagePreferenceChoices } from "../../apiRequests/graphql-global-types";
import { useRouter } from "next/router";
import { useEnrollment } from "../enrollmentUtils";
import useTranslation from "next-translate/useTranslation";
import {
  CreatePasswordFormPartial,
  confirmPasswordValidation,
  passwordValidation,
} from "./CreatePassword";

type BillingAddressFormData = {
  billing_firstName: string;
  billing_lastName: string;
  billing_Address: string;
  billing_AptSuite: string;
  billing_City: string;
  billing_State: string;
  billing_ZipCode: string;
};

export type FinalizeFormData = BillingAddressFormData &
  CreatePasswordFormPartial & {
    acceptEflYracTos: boolean;
    acceptFraudPrevention: boolean;
    acceptEFL: boolean;
    productConsentActive: boolean;
    productConsent: boolean;
    paperlessBilling: boolean;
    languagePreferenceChoice: LanguagePreferenceChoices;
    autoTopUpAmount: string;
    differentBilling: boolean;
    autopay: boolean;
    cardNumberEmpty: boolean;
    cvcNumberEmpty: boolean;
    expiredEmpty: boolean;
    cardNumberError: string;
    cvcError: string;
    expiredError: string;
  };

const useValidationSchema = () => {
  const { t } = useTranslation("prepay");
  return Yup.object().shape({
    password: passwordValidation,
    confirmPassword: confirmPasswordValidation,
    acceptEflYracTos: Yup.bool().oneOf(
      [true],
      "finaliseConsentEflYracTosPrompt"
    ),
    acceptFraudPrevention: Yup.bool().oneOf(
      [true],
      "finaliseConsentFraudPreventionPrompt"
    ),
    productConsent: Yup.bool().when("productConsentActive", {
      is: true,
      then: Yup.bool().oneOf([true], "finalise_consentProductTerms_prompt"),
    }),
    // new
    autoTopUpAmount: Yup.number().required(t("required")),
    billing_firstName: Yup.string().when("differentBilling", {
      is: true,
      then: Yup.string()
        .min(1, "validation_firstName")
        .required("validation_firstName"),
    }),
    billing_lastName: Yup.string().when("differentBilling", {
      is: true,
      then: Yup.string()
        .min(1, "validation_lastName")
        .required("validation_lastName"),
    }),
    billing_Address: Yup.string().when("differentBilling", {
      is: true,
      then: Yup.string().required("validation_billingAddressNotValid"),
    }),
    billing_AptSuite: Yup.string(),
    billing_City: Yup.string().when("differentBilling", {
      is: true,
      then: Yup.string()
        .min(1, "validation_billingCityNotValid")
        .required("validation_billingCityNotValid"),
    }),
    billing_State: Yup.string().when("differentBilling", {
      is: true,
      then: Yup.string()
        .min(1, "validation_billingStateNotValid")
        .required("validation_billingStateNotValid"),
    }),
    billing_ZipCode: Yup.string().when("differentBilling", {
      is: true,
      then: Yup.string()
        .min(5, "validation_billingZipNotValid")
        .max(5, "validation_billingZipNotValid")
        .required("validation_billingZipNotValid"),
    }),
    autopay: Yup.boolean(),
  });
};

type UseFinalizeFormProps = {
  onSubmit: (
    values: FinalizeFormData,
    { setErrors }: FormikHelpers<FinalizeFormData>
  ) => Promise<void>;
};

export const useFinalizeForm = ({ onSubmit }: UseFinalizeFormProps) => {
  const { locale } = useRouter();
  const enrollment = useEnrollment();

  const initialValues: FinalizeFormData = {
    password: "",
    confirmPassword: "",
    acceptEflYracTos: false,
    acceptFraudPrevention: false,
    acceptEFL: false,
    productConsentActive: false,
    productConsent: false,
    paperlessBilling: true,
    languagePreferenceChoice:
      locale === "es"
        ? LanguagePreferenceChoices.Spanish
        : LanguagePreferenceChoices.English,
    autoTopUpAmount: enrollment.prepayAutoTopUpAmount.toString(),
    autopay: true,
    billing_firstName: enrollment.formData.getReady?.firstName || "",
    billing_lastName: enrollment.formData.getReady?.lastName || "",
    billing_Address: enrollment.formData.getReady?.service_Address || "",
    billing_AptSuite: enrollment.formData.getReady?.service_AptSuite || "",
    billing_City: enrollment.formData.getReady?.service_City || "",
    billing_State: enrollment.formData.getReady?.service_State || "",
    billing_ZipCode: enrollment.formData.getReady?.service_ZipCode || "",
    differentBilling: false,
    cardNumberEmpty: true,
    cvcNumberEmpty: true,
    expiredEmpty: true,
    cardNumberError: "",
    cvcError: "",
    expiredError: "",
  };

  return useFormik({
    initialValues,
    validationSchema: useValidationSchema(),
    onSubmit,
  });
};
