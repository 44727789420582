import { Maybe } from "@core/types";
import {
  IconEV,
  IconQuestionOutlined,
  IconThermostat,
} from "@krakentech/icons";
import {
  Box,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  TypographyProps,
} from "@octopus-energy/coral-mui";
import useTranslation from "next-translate/useTranslation";
import { FC } from "react";

export type CompatibilityProps = {
  mt?: number;
  variant?: TypographyProps["variant"];
  withEV?: Maybe<boolean>;
  withThermostat?: Maybe<boolean>;
};

export const Compatibility: FC<CompatibilityProps> = ({
  mt,
  variant = "body2",
  withEV,
  withThermostat,
}) => {
  const { t } = useTranslation("product/productCard");

  return (
    <Stack direction="row" mt={mt} alignItems="center">
      <Box alignItems="center" maxWidth={22} mr={-0.5}>
        {withEV && <IconEV size={16} />}
        {withThermostat && <IconThermostat size={16} />}
      </Box>
      <Typography
        variant={variant}
        ml={3.5}
        data-cy="compatibility-bullet-text"
      >
        {withEV && t("teslaCompatible")}
        {withThermostat && t("compatibleThermostats")}
      </Typography>
      <Tooltip
        title={
          (withEV && t("onlyTesla")) ||
          (withThermostat && t("compatibleOnlyList"))
        }
        arrow
        sx={{ overflow: "hidden" }}
      >
        <IconButton>
          <IconQuestionOutlined size={18} />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};
