import { isClientSide } from "@core/utils";
import { useRouter } from "next/router";
import { useContext } from "react";
import {
  CREDIT_CHECK_FAILURE_INITIAL_DEPOSIT_AMOUNT,
  CREDIT_CHECK_PASS_INITIAL_DEPOSIT_AMOUNT,
  CreditCheckStatus,
  PREPAY_INITIAL_DEPOSIT_AMOUNT,
} from ".";
import { ProductListType } from "../krakenHelpers";
import { OctopusProductId, isPrepayProduct } from "../product";
import { useOctopusProducts } from "../product/useOctopusProducts";
import { Maybe } from "../types";
import { EnrollmentContext } from "./enrollmentContext";
import { CreditCheckType, getReadyFormInitialValues } from "./getReady";
import { useGlobalParameterState } from "./globalParameters/useGlobalParametersState";
import { useQueryAddressLookup } from "@core/apiRequests";
import { MeterTypeOptions } from "@core/apiRequests/graphql-global-types";

/**
 * Hook for using the entire enrollment context. This might become legacy after refactoring enrollment.
 */
export const useEnrollment = () => useContext(EnrollmentContext);

/**
 * This hook checks if the route contains /join/enrollment within the url.
 * Basically confirming if the client is in enrollment or not.
 *
 * @returns boolean
 */

export const useIsEnrollment = () => {
  const { pathname } = useRouter();
  const isEnrollment: boolean = pathname.includes("/join/enrollment");

  return { isEnrollment };
};

/**
 * Hook for setting the selected product ID and starting enrollment. This might become legacy after refactoring enrollment.
 */
export const useSelectProductAndStartEnrollment = () => {
  const router = useRouter();
  const {
    setSelectedPlanID,
    setWithEV,
    setWithThermostat,
    setCreditCheckType,
  } = useEnrollment();
  const { isEnrollment } = useIsEnrollment();
  const enrollment = useEnrollment();

  return (
    productId: Maybe<OctopusProductId>,
    withEV: Maybe<boolean> = false,
    withThermostat: Maybe<boolean> = false
  ) => {
    if (!productId) {
      return;
    }
    setSelectedPlanID(productId);
    setWithEV(withEV);
    setWithThermostat(withThermostat);
    setCreditCheckType(
      isPrepayProduct(productId)
        ? CreditCheckType.SKIP_WITH_DEPOSIT
        : CreditCheckType.RUN_CREDIT_CHECK
    );

    if (!isEnrollment) {
      if (
        !enrollment.formData.getReady &&
        typeof router.query.zipcode === "string"
      ) {
        enrollment.setFormData({
          getReady: {
            ...getReadyFormInitialValues,
            service_ZipCode: router.query.zipcode,
          },
        });
      }
      router.push("/join/enrollment/get-ready", undefined, {
        shallow: true,
      });
    }
  };
};

/**
 * Function for returning the selected product during enrollment. This might become legacy after refactoring enrollment.
 */
export const getSelectedEnrollmentProduct = (
  octopusProducts: ProductListType,
  selectedPlanID: OctopusProductId | null
) => {
  return octopusProducts.find((p) => p.productID === selectedPlanID);
};

/**
 * Hook for returning an Octopus product matching the productId. This might become legacy after refactoring enrollment.
 */
export const useOctopusProduct = (productId: OctopusProductId) => {
  const [{ octopusProducts }] = useOctopusProducts();
  return getSelectedEnrollmentProduct(octopusProducts, productId);
};

/**
 * Hook for returning the selected product during enrollment. This might become legacy after refactoring enrollment.
 */
export const useSelectedEnrollmentProduct = () => {
  const { selectedPlanID } = useEnrollment();
  const [{ octopusProducts }] = useOctopusProducts();
  if (!selectedPlanID) {
    return undefined;
  }
  return getSelectedEnrollmentProduct(octopusProducts, selectedPlanID);
};

/**
 * Hook for returning if the selected product during enrollment matches the octopus product id. This might become legacy after refactoring enrollment.
 */
export const useSelectedEnrollmentProductIs = (
  octopusProductId: OctopusProductId
) => {
  return useSelectedEnrollmentProduct()?.productID === octopusProductId;
};

/**
 * Hook for returning if the selected product during enrollment is prepay or not. This might become legacy after refactoring enrollment.
 */
export const useSelectedEnrollmentProductIsPrepay = () => {
  const productId = useSelectedEnrollmentProduct()?.productID;
  if (!productId) {
    return false;
  }
  return isPrepayProduct(productId);
};

/**
 * Hook for returning the initial payment amount.
 */
export const useInitialPaymentAmount = () => {
  const productIsPrepay = useSelectedEnrollmentProductIsPrepay();
  const enrollment = useEnrollment();
  if (productIsPrepay) {
    return PREPAY_INITIAL_DEPOSIT_AMOUNT;
  }
  if (enrollment.creditCheckStatus === CreditCheckStatus.PASS) {
    return CREDIT_CHECK_PASS_INITIAL_DEPOSIT_AMOUNT;
  }
  return CREDIT_CHECK_FAILURE_INITIAL_DEPOSIT_AMOUNT;
};

/**
 * Hook for calculating total payment due
 */
export const useTotalPaymentDue = () => {
  return useInitialPaymentAmount() / 100;
};

// Utils for declinedButAllowedToContinue
export const setCardDeclinedButAllowedToContinue = () => {
  if (isClientSide()) {
    return window.localStorage.setItem("declinedButAllowedToContinue", "true");
  }
};

export const getCardDeclinedButAllowedToContinue = () => {
  if (isClientSide()) {
    return window.localStorage.getItem("declinedButAllowedToContinue");
  }
};

export const removeCardDeclinedButAllowedToContinue = () => {
  if (isClientSide()) {
    return window.localStorage.removeItem("declinedButAllowedToContinue");
  }
};

// Utils for affiliate redirects
export const setAffiliateRedirect = (route: string) => {
  if (isClientSide()) {
    return window.sessionStorage.setItem("affiliateRedirect", route);
  }
};

export const getAffiliateRedirect = () => {
  if (isClientSide()) {
    return window.sessionStorage.getItem("affiliateRedirect");
  }
};

export const removeAffiliateRedirect = () => {
  if (isClientSide()) {
    return window.sessionStorage.removeItem("affiliateRedirect");
  }
};

export const useCustomerIsPTC = () => {
  const [{ subDomain }] = useGlobalParameterState();
  return subDomain === "ptc";
};

export const useCustomerIsRivian = () => {
  const [{ subDomain }] = useGlobalParameterState();
  return subDomain === "rivian";
};

export const setCustomerIsAffiliateSale = (subDomain: boolean) => {
  if (isClientSide()) {
    return window.localStorage.setItem("isAffiliateSale", subDomain.toString());
  }
};

export const getCustomerIsAffiliateSale = () => {
  if (isClientSide()) {
    return JSON.parse(
      window.localStorage.getItem("isAffiliateSale") || "false"
    );
  }
};

export const removeCustomerIsAffiliateSale = () => {
  if (isClientSide()) {
    return window.localStorage.removeItem("isAffiliateSale");
  }
};

/**
 * Hook to check if the meter type is AMSM (Manual meter).
 */

export const useIsAmsmMeter = () => {
  const enrollment = useEnrollment();

  const { data } = useQueryAddressLookup({
    variables: {
      esiId: enrollment.formData.getReady?.esiId,
    },
  });

  const isAmsmMeter =
    data?.addressLookup?.[0]?.meterType === MeterTypeOptions.Amsm;

  return { isAmsmMeter };
};
