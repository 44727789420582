import {
  AddressTdspMappingOutputType,
  MeterTypeOptions,
} from "@core/apiRequests/graphql-global-types";
import { useSnackbarNotification } from "@core/molecules";
import useTranslation from "next-translate/useTranslation";
import { useEnrollment } from "../enrollmentUtils";
import { isPrepayProduct } from "./../../product/octopusProductFeatures";
import { GetReadyFormData, useGetReadyFormContext } from "./useGetReadyForm";
import { useProcessQuoteAndRunCreditCheck } from "./useProcessQuoteAndRunCreditCheck";

export const useSelectGetReadyAddress = (pushToNextStep = false) => {
  const { t } = useTranslation("enrollment/formFields");
  const enrollment = useEnrollment();
  const form = useGetReadyFormContext();
  const [snackbarNotification] = useSnackbarNotification();
  const processQuoteAndRunCreditCheck = useProcessQuoteAndRunCreditCheck();
  const prepayProduct =
    enrollment.selectedPlanID && isPrepayProduct(enrollment.selectedPlanID);

  return async (address: AddressTdspMappingOutputType | null) => {
    if (!address) {
      return;
    }

    // block prepay with AMSM meter
    if (
      prepayProduct &&
      address.meterType &&
      address.meterType === MeterTypeOptions.Amsm
    ) {
      snackbarNotification.error(t("notEligibleForPrepaidPlan"));

      return;
    }

    // block all non-residential addresses
    if (
      address.premiseType &&
      address.premiseType.includes("NON_RESIDENTIAL")
    ) {
      snackbarNotification.error(t("validation_nonResidential"), {
        "data-cy": "snackbar-alert-invalid-premise-type",
      });
      return;
    }

    // set context
    const activeData: GetReadyFormData = form.getValues();

    enrollment.setFormData((prevState) => ({
      ...prevState,
      getReady: {
        ...activeData,
        service_Address: address.address || "",
        service_AptSuite: address.address2 || "",
        service_City: address.city || "",
        service_State: address.state || "",
        service_ZipCode: address.postCode || "",
        esiId: address.esiId || "",
        loadZone: address.loadZone || null,
        serviceProvider: address.serviceProvider || null,
      },
    }));
    enrollment.setCreditCheckType(activeData.creditCheckType);
    if (address.esiId && address.address && address.city) {
      form.setValue("esiId", address.esiId);
      form.setValue("service_Address", address.address);
      form.setValue("service_City", address.city);
      form.clearErrors("service_Address");
      form.clearErrors("service_City");
      form.trigger();
    }
    form.setValue("service_AptSuite", address.address2 || "");

    // Upon successful address verification, proceed to the finalize step
    if (pushToNextStep) {
      processQuoteAndRunCreditCheck(form.getValues());
    }
  };
};
