import { Maybe } from "@core/types";
import { Stack, Typography } from "@krakentech/coral";
import useTranslation from "next-translate/useTranslation";
import { FC } from "react";

export type EnrollmentProductDescriptionProps = {
  withThermostat?: Maybe<boolean>;
};

export const EnrollmentProductDescription: FC<
  EnrollmentProductDescriptionProps
> = ({ withThermostat = false }) => {
  const { t } = useTranslation("product/productCard");

  return (
    <Stack direction="vertical">
      <Typography>
        <strong>{t("aboutIO")}</strong>
      </Typography>
      <Typography>
        {withThermostat
          ? t("ioIsSmartFeatureThermostat")
          : t("ioIsSmartFeatureEV")}{" "}
        <strong>
          {withThermostat
            ? t("discountedRateThermostat")
            : t("discountedRateEV")}
        </strong>
      </Typography>
      <Typography>
        {withThermostat
          ? t("adjustThermostatButYouAreInControl")
          : t("ioPairsWithEV")}
      </Typography>
    </Stack>
  );
};
