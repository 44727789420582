import { gql } from "@apollo/client";
import { createUseMutation } from "@octopus-energy/apollo-client";
import {
  CreateSmartDeviceTokenMutation,
  CreateSmartDeviceTokenMutationVariables,
} from "./__generated__/useCreateSmartDeviceTokenMutation.generated";

export const MUTATION_CREATE_SMART_DEVICE_TOKEN = gql`
  mutation CreateSmartDeviceToken($input: CreateSmartDeviceTokenInput!) {
    createSmartDeviceToken(input: $input) {
      state
    }
  }
`;

export const useCreateSmartDeviceTokenMutation = createUseMutation<
  CreateSmartDeviceTokenMutation,
  CreateSmartDeviceTokenMutationVariables
>(MUTATION_CREATE_SMART_DEVICE_TOKEN);
