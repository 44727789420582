import { isClientSide } from "@core/utils";
import type { ISOCountryCodes } from "./LocalizationTypes";

// Utils for storing user preferences in browser storage

export const LOCATION_PREFERENCES = "locationPreferences";

export type LocationPreferences = {
  userRequestedToHideDialog: boolean;
  userRequestedRedirect: boolean;
  regionToRedirectTo: ISOCountryCodes | null;
};

export const getLocationPreferences = () => {
  if (isClientSide()) {
    const data = window.localStorage.getItem(LOCATION_PREFERENCES) || null;
    if (data) {
      return JSON.parse(data) as LocationPreferences;
    }
    return null;
  }
};

export const storeLocationPreferences = (preferences: LocationPreferences) => {
  if (isClientSide()) {
    window.localStorage.setItem(
      LOCATION_PREFERENCES,
      JSON.stringify(preferences)
    );
  }
};

export const removeLocationPreferences = () => {
  if (isClientSide()) {
    window.localStorage.removeItem(LOCATION_PREFERENCES);
  }
};
