export enum NodeEnv {
  PROD = "production",
  DEV = "development",
}

export const getNodeEnv = () => {
  return process.env.NODE_ENV as NodeEnv;
};

export const isProdEnv = () => {
  return getNodeEnv() === NodeEnv.PROD;
};

export const isDevEnv = () => {
  return getNodeEnv() === NodeEnv.DEV;
};
