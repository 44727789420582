import { gql } from "@apollo/client";
import { createUseQuery } from "@octopus-energy/apollo-client";
import {
  IsOnSupplyQuery,
  IsOnSupplyQueryVariables,
} from "./__generated__/useQueryIsOnSupply.generated";

export const QUERY_IS_ON_SUPPLY = gql`
  query IsOnSupply($accountNumber: String!) {
    account(accountNumber: $accountNumber) {
      id
      isOnSupply
    }
  }
`;

export const useQueryIsOnSupply = createUseQuery<
  IsOnSupplyQuery,
  IsOnSupplyQueryVariables
>(QUERY_IS_ON_SUPPLY);
