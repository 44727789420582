/**
 * Function that returns true if a string exactly matches a Rexexp.
 * Returns false if match is not exact.
 */
export const matchExact = (regexp: RegExp, str: string) => {
  const matches = str.match(regexp);
  return Boolean(matches && str === matches[0]);
};

/**
 * Function that capitalizes the first letter in a string.
 */
export const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

/**
 * Function that adds padding to beginning of single number and returns a string.
 */
export const zeroPad = (num: number, numPadToAdd: number, padType: string) => {
  if (num.toString.length == 1) {
    return String(num).padStart(numPadToAdd, padType);
  }
};
