import { useMediaQuery, Theme } from "@mui/material";
import { useState, useEffect } from "react";

enum ScreenTypes {
  MOBILE = "MOBILE",
  TABLET = "TABLET",
  SMALL_SCREEN = "SMALL_SCREEN",
  LARGE_SCREEN = "LARGE_SCREEN",
}

/**
 *
 * Reusable util for identifying breakpoints within the MUI Theme.
 * Returns object of which breakpoint the device meets and also a enum of which criteria is met.
 *
 *
 * Statements for each:
 * isMobile: < sm breakpoint
 * isTablet:  < md breakpoint
 * isSmallScreen: > md breakpoint
 * isLargeScreen: > lg breakpoint
 *
 * @returns `{
 *  isMobile: boolean;
 *  isTablet: boolean;
 *  isSmallScreen: boolean;
 *  isLargeScreen: boolean;
 *  breakpoint: "MOBILE" | "TABLET" | "SMALL_SCREEN" | "LARGE_SCREEN"
 * }`
 */

export const useDeviceBreakpoint = () => {
  const [breakpoint, setBreakpoint] = useState<ScreenTypes | null>(null);

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const isTablet = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("md")
  );

  const isLargeScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("lg")
  );

  useEffect(() => {
    if (isLargeScreen) {
      setBreakpoint(ScreenTypes.LARGE_SCREEN);
      return;
    }
    if (isSmallScreen) {
      setBreakpoint(ScreenTypes.SMALL_SCREEN);
      return;
    }
    if (isTablet) {
      setBreakpoint(ScreenTypes.TABLET);
      return;
    }
    if (isMobile) {
      setBreakpoint(ScreenTypes.MOBILE);
      return;
    }
  }, [isMobile, isTablet, isSmallScreen, isLargeScreen]);

  return { isMobile, isTablet, isSmallScreen, isLargeScreen, breakpoint };
};
