import { gql } from "@apollo/client";
import { createUseMutation } from "@octopus-energy/apollo-client";
import {
  AddAccountCampaignMutation,
  AddAccountCampaignMutationVariables,
} from "./__generated__/useAddAccountCampaignMutation.generated";

export const MUTATION_ADD_ACCOUNT_CAMPAIGN = gql`
  mutation AddAccountCampaign($input: AddAccountCampaignInput!) {
    addAccountCampaign(input: $input) {
      campaignAdded
    }
  }
`;

export const useAddAccountCampaignMutation = createUseMutation<
  AddAccountCampaignMutation,
  AddAccountCampaignMutationVariables
>(MUTATION_ADD_ACCOUNT_CAMPAIGN);
