// Refresh JWT token storage methods
export const REFRESH_TOKEN = "refreshToken";

export const getRefreshToken = () => {
  if (typeof window !== "undefined") {
    return window.localStorage.getItem(REFRESH_TOKEN) || "";
  }
};

export const storeRefreshToken = (refreshToken: string) => {
  if (typeof window !== "undefined") {
    window.localStorage.setItem(REFRESH_TOKEN, refreshToken);
  }
};

export const removeRefreshToken = () => {
  if (typeof window !== "undefined") {
    window.localStorage.removeItem(REFRESH_TOKEN);
  }
};

// JWT token storage methods
export const AUTH_TOKEN = "authToken";

export const getAuthToken = () => {
  if (typeof window !== "undefined") {
    return window.sessionStorage.getItem(AUTH_TOKEN) || "";
  }
};

export const storeAuthToken = (authToken: string) => {
  if (typeof window !== "undefined") {
    window.sessionStorage.setItem(AUTH_TOKEN, authToken);
  }
};

export const removeAuthToken = () => {
  if (typeof window !== "undefined") {
    window.sessionStorage.removeItem(AUTH_TOKEN);
  }
};
