import { gql } from "@apollo/client";
import {
  createUseQuery,
  createUseQueryFunction,
} from "@octopus-energy/apollo-client";
import {
  AddressLookupQuery,
  AddressLookupQueryVariables,
} from "./__generated__/useQueryAddressLookup.generated";

export const QUERY_ADDRESS_LOOKUP = gql`
  query AddressLookup(
    $address: String
    $address2: String
    $city: String
    $state: String
    $postcode: String
    $esiId: String
  ) {
    addressLookup(
      address: $address
      address2: $address2
      city: $city
      state: $state
      postCode: $postcode
      esiId: $esiId
    ) {
      address
      address2
      state
      city
      state
      postCode
      esiId
      premiseType
      meterType
      serviceProvider
      loadZone
    }
  }
`;

export const useQueryAddressLookup = createUseQuery<
  AddressLookupQuery,
  AddressLookupQueryVariables
>(QUERY_ADDRESS_LOOKUP);

export const useQueryAddressLookupFunction = createUseQueryFunction<
  AddressLookupQuery,
  AddressLookupQueryVariables
>(QUERY_ADDRESS_LOOKUP);
