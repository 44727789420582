import { gql } from "@apollo/client";
import { createUseQuery } from "@octopus-energy/apollo-client";
import {
  GetProviderAuthDetailsQuery,
  GetProviderAuthDetailsQueryVariables,
} from "./__generated__/useQueryGetProviderAuthDetails.generated";

export const QUERY_GET_PROVIDER_AUTH_DETAILS = gql`
  query GetProviderAuthDetails(
    $provider: ProviderChoices!
    $deviceType: KrakenFlexDeviceTypes!
    $clientType: ClientType!
    $accountNumber: String
    $propertyId: Int
  ) {
    providerAuthDetails(
      provider: $provider
      deviceType: $deviceType
      clientType: $clientType
      accountNumber: $accountNumber
      propertyId: $propertyId
    ) {
      oauthUri
    }
  }
`;

export const useQueryGetProviderAuthDetails = createUseQuery<
  GetProviderAuthDetailsQuery,
  GetProviderAuthDetailsQueryVariables
>(QUERY_GET_PROVIDER_AUTH_DETAILS);
