import { yupResolver } from "@hookform/resolvers/yup";
import { IconHidePassword, IconShowPassword } from "@krakentech/icons";
import { IconButton, InputAdornment } from "@mui/material";
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  TextField,
} from "@octopus-energy/coral-mui";
import { useRouter } from "next/router";
import { FC, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useBooleanState } from "react-use-object-state";
import * as Yup from "yup";
import { useLogin } from ".";
import { handleError } from "../error";

const translations = {
  enterValidEmail: {
    id: "enterValidEmail",
    defaultMessage: "Please enter a valid email",
    description: "Email field error message",
  },
  enterValidPassword: {
    id: "enterValidPassword",
    defaultMessage: "Please enter a valid password",
    description: "Password field error message",
  },
  emailLabelTranslation: {
    id: "email",
    defaultMessage: "Email",
    description: "Label for email field",
  },
  passwordLabelTranslation: {
    id: "password",
    defaultMessage: "Password",
    description: "Label for password field",
  },
  passwordReset: {
    id: "passwordReset",
    defaultMessage: "I've forgotten my password",
    description: "Copy for link to reset password",
  },
  signin: {
    id: "signin",
    defaultMessage: "Sign In",
    description: "Copy for the login form submit button",
  },
};

interface SignInUserFormProps {
  eyesClosed: ReturnType<typeof useBooleanState>;
}

type SignInUserFormValues = {
  email: string;
  password: string;
};

const useValidationSchema = (): Yup.SchemaOf<SignInUserFormValues> => {
  const { formatMessage: f } = useIntl();
  return Yup.object().shape({
    email: Yup.string()
      .email()
      .required(f(translations.enterValidEmail))
      .min(1)
      .label("Email"),
    password: Yup.string()
      .required(f(translations.enterValidPassword))
      .min(1)
      .label("Password"),
  });
};

export const SignInUserForm: FC<SignInUserFormProps> = ({ eyesClosed }) => {
  const { formatMessage: f } = useIntl();
  const [show, setShow] = useState(false);
  const login = useLogin();
  const [submitting, setSubmitting] = useState(false);
  const router = useRouter();
  const {
    formState: { errors },
    handleSubmit,
    register,
    setError,
    clearErrors,
  } = useForm<SignInUserFormValues>({
    mode: "onTouched",
    resolver: yupResolver(useValidationSchema()),
  });

  const handleSubmitForm: SubmitHandler<SignInUserFormValues> = async (
    values
  ) => {
    setSubmitting(true);
    login(values).catch((err: Error) => {
      setSubmitting(false);
      if (err.message !== "Invalid data") {
        handleError(err);
      }
      if (err) {
        setError("email", {
          message: f(translations.enterValidEmail),
        });
        setError("password", {
          message: f(translations.enterValidPassword),
        });
      }
    });
  };

  return (
    <form onSubmit={handleSubmit(handleSubmitForm)} data-cy="sign-in-form">
      <Stack spacing={3.5}>
        <TextField
          label={f(translations.emailLabelTranslation)}
          {...register("email")}
          onChange={(e) => {
            clearErrors();
            register("email").onChange(e);
          }}
          error={Boolean(errors.email)}
          helperText={errors.email && f(translations.enterValidEmail)}
          type="email"
          sx={{
            width: 380,
          }}
        />
        <TextField
          fullWidth
          label={f(translations.passwordLabelTranslation)}
          {...register("password")}
          onChange={(e) => {
            clearErrors();
            register("password").onChange(e);
          }}
          onFocus={() => eyesClosed.setTrue()}
          onBlur={(e) => {
            eyesClosed.setFalse();
            register("password").onBlur(e);
          }}
          error={Boolean(errors.password)}
          helperText={errors.password && f(translations.enterValidPassword)}
          type={show ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => {
                    setShow(!show);
                  }}
                  edge="end"
                  sx={{ color: "primary.main" }}
                >
                  {show ? <IconHidePassword /> : <IconShowPassword />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        mt={4}
      >
        <Button type="submit" disabled={submitting} data-cy="sign-in-button">
          {submitting ? (
            <Box display="flex" mx={1.5} my={-0.25}>
              <CircularProgress size={29} />
            </Box>
          ) : (
            f(translations.signin)
          )}
        </Button>
      </Box>
      <Box>
        <Button
          type="button"
          fullWidth
          variant="text"
          size="small"
          sx={{ "&:hover": { backgroundColor: "transparent" }, my: 3 }}
          onClick={() => {
            router.push("/password-reset");
          }}
        >
          {f(translations.passwordReset)}
        </Button>
      </Box>
    </form>
  );
};
