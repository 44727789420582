import { gql } from "@apollo/client";
import { createUseMutation } from "@octopus-energy/apollo-client";
import {
  AddPropertyToShellAccountMutation,
  AddPropertyToShellAccountMutationVariables,
} from "./__generated__/useAddPropertyToShellAccountMutation.generated";

export const MUTATION_ADD_PROPERTY_TO_SHELL_ACCOUNT = gql`
  mutation AddPropertyToShellAccount(
    $input: AddPropertyToShellAccountInputType!
  ) {
    addPropertyToShellAccount(input: $input) {
      addPropertyToShellAccountData {
        esiId
      }
    }
  }
`;

export const useAddPropertyToShellAccountMutation = createUseMutation<
  AddPropertyToShellAccountMutation,
  AddPropertyToShellAccountMutationVariables
>(MUTATION_ADD_PROPERTY_TO_SHELL_ACCOUNT);
