import { useEnrollment, useOctopusProduct } from "@core/enrollment";
import { LegalLinks } from "@core/legal";
import {
  OctopusProductId,
  ProductCardBullets,
  isPrepayProduct,
} from "@core/product";
import {
  Accordion,
  Card,
  Container,
  Stack as CoralStack,
} from "@krakentech/coral";
import { Box, Grid, Stack, Typography } from "@octopus-energy/coral-mui";
import useTranslation from "next-translate/useTranslation";
import { FC } from "react";
import { useEvaluateContractStatus } from "./useEvaluateContractStatus";

export type RenewalCompletePlanCardProps = {
  productId: OctopusProductId;
  renewalProductRate: number;
  withEV: boolean;
  withThermostat: boolean;
};

export const RenewalCompletePlanCard: FC<RenewalCompletePlanCardProps> = ({
  productId,
  renewalProductRate,
  withEV = false,
  withThermostat = false,
}) => {
  const { t } = useTranslation("account/accountNumber");
  const { t: langProductCard } = useTranslation("product/productCard");
  const { t: langPlans } = useTranslation("enrollment/plans");
  const { renewalProductName } = useEvaluateContractStatus();
  const { monthlyKwhUsage } = useEnrollment();
  const octopusProduct = useOctopusProduct(productId);
  const isPrepay = isPrepayProduct(octopusProduct?.productID);
  const estimatedMonthlyCost = (
    (renewalProductRate * Number(monthlyKwhUsage)) /
    100
  ).toFixed(0);

  return (
    <Box
      maxWidth="md"
      width="100%"
      bgcolor="background.paper"
      borderRadius={3}
      mx="auto"
      p={3}
    >
      <Typography variant="h3" fontWeight={300} mb={3}>
        {t("yourPlan")}
      </Typography>
      <Grid container xs={12} spacing={2}>
        <Grid item xs={12} sm={6}>
          <Stack>
            <Typography variant="h3">
              {renewalProductName?.split(" ")[0]}
              <Typography variant="h3" fontWeight={300} component="span">
                {renewalProductName?.split(" ")[1]}
              </Typography>
            </Typography>
            <Typography
              variant="body1"
              color={!withThermostat && !withEV ? "textPrimary" : "tertiary"}
              data-cy="product-title-variant"
            >
              <strong>
                {withThermostat && langProductCard("withThermostat")}
              </strong>
              <strong>{withEV && langProductCard("withEV")}</strong>
              <strong>
                {" "}
                {!withThermostat && !withEV && langPlans("basicPlan")}
              </strong>
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Stack spacing={-0.5}>
            <Typography variant="h3">${estimatedMonthlyCost}</Typography>
            <Typography variant="body2">{t("monthlyEstimate")}</Typography>
            <Typography variant="body2">
              {langProductCard("unitCost")}:{" "}
              <Typography component="span" fontWeight="medium">
                {Number(renewalProductRate).toFixed(1)}
                ¢/kWh
              </Typography>
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Accordion title={langProductCard("planDetails")}>
            <CoralStack direction="vertical">
              <Container marginTop="md">
                <Accordion
                  title={langProductCard("howAreMonthlyCostsCalculated")}
                >
                  <CoralStack direction="vertical">
                    <Typography>
                      {" "}
                      {langProductCard("whatWeUseToCalculate")}
                    </Typography>
                    <Stack>
                      <Typography>1.</Typography>
                      <Typography>{langProductCard("energyRate")}</Typography>
                    </Stack>
                    <Stack>
                      <Typography>2.</Typography>
                      <Typography>
                        {langProductCard("tdspDeliveryCharges")}
                      </Typography>
                    </Stack>
                    <Stack>
                      <Typography>3.</Typography>
                      <Typography>
                        {langProductCard("subscriptionFee")}
                      </Typography>
                    </Stack>
                  </CoralStack>
                </Accordion>
              </Container>
              {(withThermostat || withEV) && (
                <Card theme="midLight" borderless>
                  <CoralStack direction="vertical">
                    <Typography>
                      <strong>{langProductCard("aboutIO")}</strong>
                    </Typography>
                    <Typography>
                      {withThermostat
                        ? langProductCard("ioIsSmartFeatureThermostat")
                        : langProductCard("ioIsSmartFeatureEV")}{" "}
                      <strong>
                        {withThermostat
                          ? langProductCard("discountedRateThermostat")
                          : langProductCard("discountedRateEV")}
                      </strong>
                    </Typography>
                    <Typography>
                      {withThermostat
                        ? langProductCard("adjustThermostatButYouAreInControl")
                        : langProductCard("ioPairsWithEV")}
                    </Typography>
                  </CoralStack>
                </Card>
              )}
              <Card theme="midLight" borderless>
                <CoralStack direction="vertical" gap="md">
                  <Typography>
                    <strong>{langProductCard("planDetails")}</strong>
                  </Typography>
                  {!withThermostat && !withEV && (
                    <>
                      <Typography>
                        {langProductCard("octo12LongTermValue")}
                      </Typography>
                      <Typography>
                        {langProductCard("octo12CanUpgrade")}
                      </Typography>
                    </>
                  )}
                  <ProductCardBullets
                    isPrepay={isPrepay}
                    withEV={withEV}
                    withThermostat={withThermostat}
                  />
                </CoralStack>
              </Card>
              <Card theme="midLight" borderless>
                <CoralStack direction="vertical" gap="md">
                  <Typography>
                    <strong>{langProductCard("planDocuments")}</strong>
                  </Typography>
                  <LegalLinks
                    serviceProviders={octopusProduct?.serviceProviders}
                    disclosures={{ allDisclosures: true }}
                    color="light"
                    icon={true}
                    ioAgreement={withEV || withThermostat ? true : false}
                  />
                </CoralStack>
              </Card>
            </CoralStack>
          </Accordion>
        </Grid>
      </Grid>
    </Box>
  );
};
