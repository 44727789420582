import { useQueryAccount } from "@core/apiRequests";
import { usePortalAccountNumber } from "@core/portalUtils";
import { Box, Grid, Stack, Typography } from "@octopus-energy/coral-mui";
import useTranslation from "next-translate/useTranslation";
import { FC } from "react";

const cityStateZipRegex = /,(?=\s?[A-Za-z]+,\s?[A-Za-z]{2},?\s?\d{5})/;

export const RenewalCompleteCustomerDetailsCard: FC = () => {
  const { t } = useTranslation("account/accountNumber");
  const accountNumber = usePortalAccountNumber();
  const queryAccount = useQueryAccount({
    variables: {
      accountNumber,
    },
  });
  const accountDetails = queryAccount.data?.account;

  return (
    <Box
      maxWidth="md"
      width="100%"
      bgcolor="background.paper"
      borderRadius={3}
      mx="auto"
      p={3}
    >
      <Typography variant="h3" fontWeight={300} mb={3}>
        {t("customerDetailsSummary")}
      </Typography>
      <Grid container xs={12} spacing={2}>
        <Grid item xs={12} sm={6}>
          <Stack>
            <Typography variant="body2">{t("yourAccountNumber")}</Typography>
            <Typography variant="h3">{accountDetails?.number}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body2">{t("serviceAddress")}</Typography>
          {accountDetails?.properties?.length !== 0 && (
            <Stack>
              {accountDetails?.properties?.map((property) => (
                <Box key={property?.id}>
                  <Typography
                    variant={
                      (property?.address
                        ?.split(cityStateZipRegex)
                        .slice(0, 1)
                        .toString().length || 0) > 19
                        ? "h4"
                        : "h3"
                    }
                    fontWeight="medium"
                    component="h3"
                  >
                    {
                      property?.address
                        ?.split(cityStateZipRegex)
                        .slice(0, 1)
                        .toString() as string
                    }
                  </Typography>
                  <Typography
                    variant={
                      (property?.address
                        ?.split(cityStateZipRegex)
                        .slice(0, 1)
                        .toString().length || 0) > 19
                        ? "h4"
                        : "h3"
                    }
                    fontWeight="medium"
                    component="h3"
                  >
                    {
                      property?.address
                        ?.split(cityStateZipRegex)
                        .slice(1)
                        .toString() as string
                    }
                  </Typography>
                </Box>
              ))}
            </Stack>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
