import { useQueryAddressLookupFunction } from "@core/apiRequests";
import { AddressTdspMappingOutputType } from "@core/apiRequests/graphql-global-types";
import { useSnackbarNotification } from "@core/molecules";
import { isTruthy } from "@core/utils";
import useTranslation from "next-translate/useTranslation";
import { Dispatch, SetStateAction, useState } from "react";
import { useFormContext } from "react-hook-form";
import { v4 } from "uuid";
import { ServiceAddressPartial } from "./ServiceAddress";

export const addUniqueKeysToAddresses = (
  addresses: (AddressTdspMappingOutputType | null)[]
) => addresses.filter(isTruthy).map((a) => ({ ...a, uuid: v4() }));

type VerifyAddressInput = Omit<
  ServiceAddressPartial,
  "serviceProvider" | "loadZone"
>;

export const useVerifyAddress = (
  setVerifyType: Dispatch<SetStateAction<"esiId" | "address" | null>>
) => {
  const { t } = useTranslation("enrollment/formFields");
  const [suggestedAddresses, setSuggestedAddresses] = useState<
    ReturnType<typeof addUniqueKeysToAddresses>
  >([]);
  const [snackbarNotification] = useSnackbarNotification();
  const queryAddressLookup = useQueryAddressLookupFunction();
  const { setError } = useFormContext<ServiceAddressPartial>();

  const verifyAddress = async (values: VerifyAddressInput) => {
    const searchByAddress = async (values: VerifyAddressInput) => {
      try {
        const {
          data: { addressLookup },
        } = await queryAddressLookup({
          variables: {
            postcode: values.service_ZipCode,
            address: values.service_Address,
            address2: values.service_AptSuite,
            state: values.service_State,
            city: values.service_City,
          },
        });
        // no addresses
        if (!addressLookup?.length) {
          setError("service_Address", {
            message: t("validation_NoAddressFound"),
          });
          snackbarNotification.error(t("validation_NoAddressFound"));
          // if address, open up address selection dialog
        } else {
          setVerifyType("address");
          setSuggestedAddresses(addUniqueKeysToAddresses(addressLookup));
        }
      } catch (error) {
        if (error instanceof Error) {
          snackbarNotification.error(error.message, {
            autoHideDuration: null,
          });
        }
      }
    };
    const searchByEsiId = async (values: VerifyAddressInput) => {
      try {
        const {
          data: { addressLookup },
        } = await queryAddressLookup({ variables: { esiId: values.esiId } });
        if (!addressLookup?.length) {
          throw new Error("No addresses found");
        }
        setVerifyType("esiId");
        setSuggestedAddresses(addUniqueKeysToAddresses(addressLookup));
      } catch (error) {
        setError("esiId", { message: t("invalidEsiId") });
        snackbarNotification.error(t("invalidEsiId"));
      }
    };

    return values.searchByESI ? searchByEsiId(values) : searchByAddress(values);
  };
  return { verifyAddress, suggestedAddresses };
};
