import axios from "axios";
import { useEffect, useState } from "react";
import { ObtainKrakenJsonWebToken } from "../graphql-global-types";

export const obtainNewKrakenToken = () => {
  return axios
    .post<{ token: ObtainKrakenJsonWebToken["token"] }>("/api/kra/to")
    .then((response) => response.data);
};

export const useObtainNewKrakenToken = () => {
  const [token, setToken] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    obtainNewKrakenToken()
      .then(({ token }) => {
        setToken(token);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      })
      .finally(() => setLoading(false));
  }, []);

  return { token, loading, error };
};
