import { createUseMutation } from "@octopus-energy/apollo-client";
import { gql } from "@apollo/client";
import {
  EmailQuoteMutation,
  EmailQuoteMutationVariables,
} from "./__generated__/useEmailQuoteMutation.generated";

export const MUTATION_EMAIL_QUOTE = gql`
  mutation emailQuote($input: EmailQuoteInput!) {
    emailQuote(input: $input) {
      scheduled
    }
  }
`;

export const useEmailQuoteMutation = createUseMutation<
  EmailQuoteMutation,
  EmailQuoteMutationVariables
>(MUTATION_EMAIL_QUOTE);
