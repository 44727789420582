import { isPrepayProduct } from "@core/product";
import {
  Box,
  Button,
  CircularProgress,
  Typography,
} from "@octopus-energy/coral-mui";
import { AnimatePresence, motion } from "framer-motion";
import useTranslation from "next-translate/useTranslation";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { useEnrollment } from "..";
import { useSelectedEnrollmentProduct } from "../enrollmentUtils";
import { CommunicationsPreferences } from "./CommunicationsPreferences";
import { CreatePassword } from "./CreatePassword";
import {
  EnrollmentAgreementsFormPartial,
  EnrollmentFinalAgreements,
} from "./EnrollmentFinalAgreements";
import { PaperlessPreferences } from "./PaperlessPreferences";
import { useFinalizeForm } from "./useFinalizeForm";

type AgreementsCheckboxErrorMessagesProps = {
  acceptEflYracTosError?: React.ReactNode;
  acceptFraudPreventionError?: React.ReactNode;
  productConsentError?: React.ReactNode;
};

export const AgreementsCheckboxErrorMessages = ({
  acceptEflYracTosError,
  acceptFraudPreventionError,
  productConsentError,
}: AgreementsCheckboxErrorMessagesProps) => {
  const fadeDown = {
    hidden: {
      opacity: 0,
      top: -30,
    },
    show: {
      opacity: 1,
      top: 0,
    },
  };
  const {
    formState: { errors },
  } =
    useFormContext<
      Pick<
        EnrollmentAgreementsFormPartial,
        "acceptEflYracTos" | "acceptFraudPrevention" | "productConsent"
      >
    >();
  return (
    <div className="pb-8">
      <div className="error_msg_checkbox" data-cy="error-msg">
        <AnimatePresence>
          {(errors.acceptEflYracTos || acceptEflYracTosError) && (
            <motion.div
              data-cy="accept-terms-of-service"
              initial="hidden"
              animate="show"
              exit="hidden"
              variants={fadeDown}
              className="z-0 relative w-full text-warning text-xs"
            >
              {errors.acceptEflYracTos?.message || acceptEflYracTosError}
            </motion.div>
          )}
          {(errors.acceptFraudPrevention || acceptFraudPreventionError) && (
            <motion.div
              data-cy="accept-fraud-prevention"
              initial="hidden"
              animate="show"
              exit="hidden"
              variants={fadeDown}
              className="z-0 relative w-full text-warning text-xs"
            >
              {errors.acceptFraudPrevention?.message ||
                acceptFraudPreventionError}
            </motion.div>
          )}
          {(errors.productConsent || productConsentError) && (
            <motion.div
              initial="hidden"
              animate="show"
              exit="hidden"
              variants={fadeDown}
              className="z-0 relative w-full text-warning text-xs"
            >
              {errors.productConsent?.message || productConsentError}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export const PasswordAndAgreementsForm: FC<{
  formik: ReturnType<typeof useFinalizeForm>;
  submitting: boolean;
}> = ({ formik, submitting }) => {
  const { t } = useTranslation("enrollment/passwordAndAgreements");
  const enrollmentContext = useEnrollment();
  const selectedproductId = useSelectedEnrollmentProduct()?.productID;
  const currentProductIsPrepay = isPrepayProduct(selectedproductId);
  const {
    cardNumberEmpty,
    cvcNumberEmpty,
    expiredEmpty,
    cardNumberError,
    cvcError,
    expiredError,
  } = formik.values;

  const creditCardFieldEmpty =
    cardNumberEmpty || cvcNumberEmpty || expiredEmpty;

  const creditCardHasErrors = cardNumberError || cvcError || expiredError;

  const paymentInvalid =
    !enrollmentContext.financialConnectionsDetails?.id &&
    (creditCardFieldEmpty || Boolean(creditCardHasErrors));

  return (
    <>
      <Typography variant="h3" mt={2}>
        {t("finalizeAccount")}
      </Typography>
      <Typography variant="h4" mt={4} mb={2}>
        Set up a password
      </Typography>
      <Typography variant="caption" data-cy="username">
        {t("username")}
      </Typography>
      <Typography mb={2} variant="h6" data-cy="username-email">
        <b>{enrollmentContext.formData.getReady?.emailAddress}</b>
      </Typography>

      <CreatePassword formik={formik} />

      <Box mt={5}>
        <CommunicationsPreferences
          value={formik.values.languagePreferenceChoice}
          onChange={formik.handleChange}
        />
      </Box>
      {!currentProductIsPrepay && (
        <Box mt={3}>
          <PaperlessPreferences
            checkboxProps={{
              checked: formik.values.paperlessBilling,
              onChange: formik.handleChange,
              onBlur: formik.handleBlur,
              value: formik.values.paperlessBilling,
            }}
          />
        </Box>
      )}

      <Box mt={6}>
        <EnrollmentFinalAgreements
          specificProductConsentCheckboxProps={{
            checked: formik.values.productConsent,
            onChange: formik.handleChange,
            onBlur: formik.handleBlur,
            value: formik.values.productConsent,
          }}
          serviceTermsCheckboxProps={{
            checked: formik.values.acceptEflYracTos,
            onChange: formik.handleChange,
            onBlur: formik.handleBlur,
            value: formik.values.productConsent,
          }}
          fraudPreventionCheckboxProps={{
            checked: formik.values.acceptFraudPrevention,
            onChange: formik.handleChange,
            onBlur: formik.handleBlur,
            value: formik.values.productConsent,
          }}
          currentProductIsPrepay={currentProductIsPrepay}
        />
      </Box>
      <AgreementsCheckboxErrorMessages
        acceptEflYracTosError={
          formik.errors.acceptEflYracTos &&
          formik.touched.acceptEflYracTos &&
          t(formik.errors.acceptEflYracTos)
        }
        acceptFraudPreventionError={
          formik.errors.acceptFraudPrevention &&
          formik.touched.acceptFraudPrevention &&
          t(formik.errors.acceptFraudPrevention)
        }
        productConsentError={
          formik.errors.productConsent &&
          formik.touched.productConsent &&
          t(formik.errors.productConsent)
        }
      />
      <Box mt={2}>
        <Button
          type="submit"
          data-cy="next-step"
          fullWidth
          disabled={!formik.isValid || submitting || paymentInvalid}
        >
          {submitting ? (
            <CircularProgress size={24} color="primary" />
          ) : (
            t("placeOrder")
          )}
        </Button>
      </Box>
    </>
  );
};
