import {
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@octopus-energy/coral-mui";

const NUM_COLUMNS = 6;
const NUM_ROWS = 14;

const SkeletonCell = () => (
  <TableCell>
    <Skeleton />
  </TableCell>
);

export const HistoricalPricingSkeleton = () => {
  return (
    <Stack mt={1} mb={6} alignItems="center">
      <Skeleton variant="text" height={100} width="50%" sx={{ mb: 2 }} />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {new Array(NUM_COLUMNS).map((_, index) => (
                <SkeletonCell key={index} />
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {[...Array(NUM_ROWS)].map((_, rowIndex) => (
              <TableRow key={rowIndex}>
                {[...Array(NUM_COLUMNS)].map((_, colIndex) => (
                  <SkeletonCell key={`${rowIndex}-${colIndex}`} />
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};
