import { gql } from "@apollo/client";
import { createUseMutation } from "@octopus-energy/apollo-client";
import {
  UpdateCommsPreferencesMutation,
  UpdateCommsPreferencesMutationVariables,
} from "./__generated__/useUpdateCommsPreferencesMutation.generated";

export const MUTATION_UPDATE_COMMS_PREFERENCES = gql`
  mutation UpdateCommsPreferences(
    $input: UpdateAccountUserCommsPreferencesMutationInput!
  ) {
    updateCommsPreferences(input: $input) {
      commsPreferences {
        isOptedInToSmsMessages
      }
    }
  }
`;
export const useUpdateCommsPreferencesMutation = createUseMutation<
  UpdateCommsPreferencesMutation,
  UpdateCommsPreferencesMutationVariables
>(MUTATION_UPDATE_COMMS_PREFERENCES);
