import { StripeError } from "@stripe/stripe-js";
import useTranslation from "next-translate/useTranslation";
import { useState } from "react";
import { Maybe } from "../types";

/**
 * For some errors that could be handled programmatically, a short string indicating the [error code](https://stripe.com/docs/error-codes) reported.
 */
export enum StripeErrorCode {
  API_KEY_EXPIRED = "api_key_expired",
  CARD_DECLINED = "card_declined",
  EXPIRED_CARD = "expired_card",
  INVALID_CVC = "invalid_cvc",
  INCORRECT_CVC = "incorrect_cvc",
  INVALID_NUMBER = "invalid_number",
  INCORRECT_NUMBER = "incorrect_number",
  PROCESSING_ERROR = "processing_error",
  VERIFICATION_ERROR = "payment_method_skip_verification_failed",
  ROUTING_NUMBER_INVALID = "routing_number_invalid",
  ACCOUNT_NUMBER_INVALID = "account_number_invalid",
  MAX_PAYMENT_METHODS = "customer_max_payment_methods",
  INCORRECT_ADDRESS = "incorrect_address",
  INCORRECT_ZIP = "incorrect_zip",
  POSTAL_CODE_INVALID = "postal_code_invalid",
}

/**
 * For card errors resulting from a card issuer decline, a short string indicating the [card issuer’s reason for the decline](https://stripe.com/docs/declines#issuer-declines) if they provide one.
 */
export enum StripeErrorDeclineCode {
  INSUFFICIENT_FUNDS = "insufficient_funds",
  GENERIC_DECLINE = "generic_decline",
  LOST_CARD = "lost_card",
  STOLEN_CARD = "stolen_card",
}

/**
 * Function that returns the translation key for a stripe error message
 */
export const getStripeErrorMessageTranslation = (stripeError: StripeError) => {
  switch (stripeError.code) {
    case StripeErrorCode.CARD_DECLINED:
      return stripeError.decline_code ===
        StripeErrorDeclineCode.INSUFFICIENT_FUNDS
        ? "cardInsufficientFundsError"
        : "cardDeclinedError";
    case StripeErrorCode.EXPIRED_CARD:
      return "cardExpiredError";
    case StripeErrorCode.INCORRECT_CVC:
      return "cardIncorrectSecurityCodeError";
    case StripeErrorCode.INVALID_CVC:
      return "cardIncorrectSecurityCodeError";
    case StripeErrorCode.INCORRECT_NUMBER:
      return "cardInvalidNumberError";
    case StripeErrorCode.INVALID_NUMBER:
      return "cardInvalidNumberError";
    case StripeErrorCode.PROCESSING_ERROR:
      return "cardProcessingError";
    case StripeErrorCode.ACCOUNT_NUMBER_INVALID:
      return "accountNumberInvalid";
    case StripeErrorCode.ROUTING_NUMBER_INVALID:
      return "routingNumberInvalid";
    case StripeErrorCode.VERIFICATION_ERROR:
      return "verificationError";
    case StripeErrorCode.MAX_PAYMENT_METHODS:
      return "maxPaymentMethodsError";
    case StripeErrorCode.API_KEY_EXPIRED:
      return "apiKeyExpired";
    case StripeErrorCode.INCORRECT_ADDRESS:
      return "incorrectAddress";
    case StripeErrorCode.INCORRECT_ZIP:
      return "incorrectZip";
    case StripeErrorCode.POSTAL_CODE_INVALID:
      return "incorrectZip";
    default:
      return "verificationError";
  }
};

/**
 * Hook that manages stripe error state and returns a translated stripe error message
 */
export const useStripeError = () => {
  const { t } = useTranslation("paymentGateway");
  const [stripeError, setStripeError] = useState<Maybe<StripeError>>();
  return {
    stripeError,
    setStripeError,
    stripeErrorMessage: stripeError?.code
      ? t(getStripeErrorMessageTranslation(stripeError))
      : undefined,
  };
};
