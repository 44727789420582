import { Box, Typography } from "@octopus-energy/coral-mui";
import useTranslation from "next-translate/useTranslation";

export const MinimalFooter = () => {
  const { t } = useTranslation("common");
  return (
    <Box
      sx={{ backgroundColor: "#180048", width: "100% !important" }}
      component="footer"
      width="100%"
      padding="1.5rem"
    >
      <Typography variant="body1" textAlign="center">
        ©{new Date().getUTCFullYear()} Octopus Energy LLC.{" "}
        {t("footer-rights-text")}. REP License #10262
      </Typography>
    </Box>
  );
};
