import { gql } from "@apollo/client";
import { createUseMutation } from "@octopus-energy/apollo-client";
import {
  CreateAccountFileAttachmentMutation,
  CreateAccountFileAttachmentMutationVariables,
} from "./__generated__/useCreateAccountFileAttachmentMutation.generated";

export const MUTATION_CREATE_FILE_ATTACHMENT = gql`
  mutation createAccountFileAttachment(
    $input: CreateAccountFileAttachmentInput!
  ) {
    createAccountFileAttachment(input: $input) {
      postRequest {
        url
        fields
        key
      }
    }
  }
`;

export const useCreateAccountFileAttachmentMutation = createUseMutation<
  CreateAccountFileAttachmentMutation,
  CreateAccountFileAttachmentMutationVariables
>(MUTATION_CREATE_FILE_ATTACHMENT);
