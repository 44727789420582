import { FormControlLabel, FormGroup } from "@mui/material";
import { Checkbox, CheckboxProps, Typography } from "@octopus-energy/coral-mui";
import useTranslation from "next-translate/useTranslation";
import { InputHTMLAttributes } from "react";
import { useFormContext } from "react-hook-form";

export type DifferentBillingCheckboxFormPartial = {
  differentBilling: boolean;
};

export const DifferentBillingCheckbox = ({
  checkboxProps,
}: {
  checkboxProps?: CheckboxProps;
}) => {
  const { t } = useTranslation("enrollment/formFields");
  const { register, watch } =
    useFormContext<DifferentBillingCheckboxFormPartial>();
  return (
    <FormGroup>
      <FormControlLabel
        data-cy="different-billing-checkbox-label"
        control={
          <Checkbox
            {...register("differentBilling")}
            checked={watch().differentBilling}
            {...checkboxProps}
            inputProps={
              {
                "data-cy": "different-billing-checkbox",
              } as InputHTMLAttributes<HTMLInputElement>
            }
          />
        }
        label={
          <Typography variant="body1">{t("payment_billingCheck")}</Typography>
        }
      />
    </FormGroup>
  );
};
