import { gql } from "@apollo/client";
import { createUseQuery } from "@octopus-energy/apollo-client";
import {
  GetAvailableServiceDatesQuery,
  GetAvailableServiceDatesQueryVariables,
} from "./__generated__/useQueryAvailableServiceDates.generated";

export const GET_AVAILABLE_SERVICE_DATES_QUERY = gql`
  query getAvailableServiceDates($esiID: String!) {
    availableServiceDates(esiId: $esiID)
  }
`;

export const useQueryAvailableServiceDates = createUseQuery<
  GetAvailableServiceDatesQuery,
  GetAvailableServiceDatesQueryVariables
>(GET_AVAILABLE_SERVICE_DATES_QUERY);
