import { useQueryAddressLookup } from "@core/apiRequests";
import {
  LoadZone,
  MeterTypeOptions,
  PremiseType,
  ServiceProvider,
} from "@core/apiRequests/graphql-global-types";
import { isPrepayProduct } from "@core/product";
import {
  Button,
  Grid,
  OctopusEnergyThemeColor,
  TextField,
} from "@octopus-energy/coral-mui";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import { FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import * as Yup from "yup";
import { useEnrollment } from "../enrollmentUtils";
import { AddressAutocomplete } from "./AddressAutocomplete";

export const useServiceAddressValidation = () => {
  const { t } = useTranslation("enrollment/formFields");
  const { selectedPlanID } = useEnrollment();
  const prepayProduct = selectedPlanID && isPrepayProduct(selectedPlanID);

  return {
    meterType: Yup.lazy((value) => {
      if (prepayProduct && value === MeterTypeOptions.Amsm) {
        return Yup.mixed()
          .oneOf([null, MeterTypeOptions.Amsr], t("notEligibleForPrepaidPlan"))
          .nullable();
      } else {
        return Yup.mixed().nullable();
      }
    }),

    searchByESI: Yup.boolean(),
    premiseType: Yup.lazy((value) => {
      if (value?.includes("NON_RESIDENTIAL")) {
        return Yup.mixed()
          .oneOf(
            [null, PremiseType.Residential],
            t("validation_nonResidential")
          )
          .nullable();
      } else {
        return Yup.mixed().nullable();
      }
    }),
    service_Address: Yup.string().when("searchByESI", {
      is: false,
      then: Yup.string()
        .min(1, t("validation_serviceAddressNotValid"))
        .required(t("validation_serviceAddressNotValid")),
    }),
    service_AptSuite: Yup.string(),
    service_City: Yup.string().when("searchByESI", {
      is: false,
      then: Yup.string()
        .min(1, t("validation_serviceCityNotValid"))
        .required(t("validation_serviceCityNotValid")),
    }),
    service_State: Yup.string().when("searchByESI", {
      is: false,
      then: Yup.string()
        .min(1, t("validation_serviceStateNotValid"))
        .required(t("validation_serviceStateNotValid")),
    }),
    service_ZipCode: Yup.string().when("searchByESI", {
      is: false,
      then: Yup.string()
        .min(5, t("validation_serviceZipCodeNotValid"))
        .max(5, t("validation_serviceZipCodeNotValid"))
        .required(t("validation_serviceZipCodeNotEntered")),
    }),
    esiId: Yup.string().when("searchByESI", {
      is: true,
      then: Yup.string()
        .min(7, t("esiIdTooShort"))
        .max(40, t("labels_addressEsi"))
        .required(t("labels_addressEsi")),
    }),
  };
};

export type ServiceAddressPartial = {
  searchByESI: boolean;
  service_Address: string;
  service_AptSuite: string;
  service_City: string;
  service_State: string;
  service_ZipCode: string;
  esiId: string;
  premiseType: PremiseType | null;
  meterType: MeterTypeOptions | null;
  serviceProvider: ServiceProvider | null;
  loadZone: LoadZone | null;
};

export type FindAddressProps = {
  disabled?: boolean;
};

export const SearchByAddress: FC<{ disabled?: boolean }> = ({
  disabled = false,
}) => {
  const { t } = useTranslation("enrollment/formFields");
  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<ServiceAddressPartial>();

  const clearServiceDetails = () => {
    setValue("esiId", "");
    setValue("serviceProvider", null);
    setValue("loadZone", null);
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <AddressAutocomplete disabled={disabled} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            {...register("service_AptSuite")}
            label={t("labels_addressApt")}
            data-cy="apartment-suite-number"
            disabled={disabled}
            fullWidth
            error={Boolean(errors.service_AptSuite)}
            helperText={errors.service_AptSuite?.message || " "}
            InputLabelProps={{
              shrink: Boolean(watch().service_AptSuite),
            }}
            onChange={clearServiceDetails}
            sx={{
              ".Mui-disabled": {
                color: OctopusEnergyThemeColor.PURPLE_HAZE,
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <TextField
            {...register("service_City")}
            label={t("labels_addressCity")}
            data-cy="city"
            disabled={disabled}
            fullWidth
            error={Boolean(errors.service_City)}
            helperText={errors.service_City?.message || " "}
            InputLabelProps={{ shrink: Boolean(watch().service_City) }}
            onChange={clearServiceDetails}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            {...register("service_State")}
            label={t("labels_addressState")}
            data-cy="state"
            defaultValue="TX"
            disabled
            fullWidth
            error={Boolean(errors.service_State)}
            helperText={errors.service_State?.message || " "}
            InputLabelProps={{
              shrink: Boolean(watch().service_State),
            }}
            onChange={clearServiceDetails}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <TextField
            {...register("service_ZipCode")}
            label={t("labels_addressZipCode")}
            data-cy="zip-code"
            disabled
            fullWidth
            error={Boolean(errors.service_ZipCode)}
            helperText={errors.service_ZipCode?.message || " "}
            InputLabelProps={{
              shrink: Boolean(watch().service_ZipCode),
            }}
            onChange={clearServiceDetails}
          />
        </Grid>
      </Grid>
      <Button
        variant="text"
        onClick={() => {
          setValue("searchByESI", true);
        }}
        sx={{ mt: 2 }}
        data-cy="search-by-esi-id"
      >
        {t("labels_addressEsiPrompt")}
      </Button>
    </>
  );
};

export const SearchByEsiId: FC<FindAddressProps> = ({ disabled = false }) => {
  const { t } = useTranslation("enrollment/formFields");
  const {
    clearErrors,
    register,
    getValues,
    setError,
    setValue,
    formState: { errors },
  } = useFormContext<ServiceAddressPartial>();
  const activeData = getValues();
  const { selectedPlanID } = useEnrollment();
  const prepayProduct = selectedPlanID && isPrepayProduct(selectedPlanID);
  const router = useRouter();

  const { data } = useQueryAddressLookup({
    variables: {
      esiId: activeData.esiId || null,
    },
    skip: !activeData.esiId || activeData.esiId.length < 7,
  });

  const checkForEsiIdError = (value: string) => {
    if (value.length < 7) {
      return setError("esiId", {
        message: t("esiIdTooShort"),
      });
    }

    if (
      !data?.addressLookup?.[0]?.premiseType &&
      !data?.addressLookup?.[0]?.postCode &&
      !data?.addressLookup?.[0]?.meterType
    ) {
      return;
    }

    if (
      prepayProduct &&
      data?.addressLookup?.[0]?.meterType === MeterTypeOptions.Amsm
    ) {
      return setError("esiId", {
        message: t("notEligibleForPrepaidPlan"),
      });
    }

    if (data?.addressLookup?.[0]?.premiseType?.includes("NON_RESIDENTIAL")) {
      return setError("esiId", {
        message: t("validation_nonResidential"),
      });
    }

    if (data?.addressLookup?.[0]?.postCode !== activeData.service_ZipCode) {
      return setError("esiId", {
        message: t("esiIdZipCodeMismatch"),
      });
    }

    return clearErrors("esiId");
  };

  useEffect(() => {
    if (activeData.esiId && data) {
      checkForEsiIdError(activeData.esiId);
    }
  }, [activeData.esiId, data]);
  return (
    <>
      <TextField
        {...register("esiId")}
        label={t("labels_addressEsi")}
        fullWidth
        InputLabelProps={{ shrink: Boolean(activeData.esiId) }}
        error={Boolean(errors.esiId)}
        helperText={errors.esiId?.message || " "}
        data-cy="esi-id"
        disabled={disabled}
        onChange={(e) => {
          clearErrors("esiId");
          const input = e.target.value.trim();
          setValue("esiId", input);
          checkForEsiIdError(input);
        }}
        onBlur={() => {
          if (router.pathname.startsWith("/join/enrollment/quote")) {
            return;
          }

          if (
            !errors.esiId &&
            data?.addressLookup?.length &&
            data?.addressLookup?.[0]?.address &&
            data?.addressLookup?.[0]?.city
          ) {
            return;
          } else {
            setValue("service_Address", "");
            setValue("service_AptSuite", "");
            setValue("service_City", "");
          }
        }}
      />
      <Button
        variant="text"
        onClick={() => {
          setValue("searchByESI", false);
        }}
        data-cy="search-by-address"
        sx={{ mt: 2 }}
      >
        {t("goBackAddress")}
      </Button>
    </>
  );
};
