import { gql } from "@apollo/client";
import { createUseMutation } from "@octopus-energy/apollo-client";
import {
  CollectAdHocPaymentForInstructionMutation,
  CollectAdHocPaymentForInstructionMutationVariables,
} from "./__generated__/useCollectAdHocPaymentForInstructionMutation.generated";

export const MUTATION_COLLECT_AD_HOC_PAYMENT_FOR_INSTRUCTION = gql`
  mutation collectAdHocPaymentForInstruction(
    $input: CollectAdHocPaymentForInstructionInput!
  ) {
    collectAdHocPaymentForInstruction(input: $input) {
      payment {
        wasScheduled
      }
    }
  }
`;

export const useCollectAdHocPaymentForInstructionMutation = createUseMutation<
  CollectAdHocPaymentForInstructionMutation,
  CollectAdHocPaymentForInstructionMutationVariables
>(MUTATION_COLLECT_AD_HOC_PAYMENT_FOR_INSTRUCTION);
