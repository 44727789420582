import { useEnrollmentV2 } from "@core/apiRequests/enrollment/useEnrollmentV2";
import { AccountCampaignChoices } from "@core/apiRequests/graphql-global-types";
import { useIsPostCodeQuotableFunction } from "@core/apiRequests/product/useQueryIsPostCodeQuotable";
import { Layout } from "@core/layout";
import { yupResolver } from "@hookform/resolvers/yup";
import { Alert } from "@krakentech/coral";
import { IconInfo } from "@krakentech/icons";
import { Box, Collapse, Theme, useMediaQuery } from "@mui/material";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { toggleButtonGroupClasses } from "@mui/material/ToggleButtonGroup";
import {
  Button,
  Container,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@octopus-energy/coral-mui";
import { Translate } from "next-translate";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import * as React from "react";
import { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useBooleanState } from "react-use-object-state";
import * as Yup from "yup";
import { EnrollmentBanner } from "../EnrollmentBanner";
import { useCustomerIsPTC, useEnrollment } from "../enrollmentUtils";
import {
  getReadyFormInitialValues,
  serviceAddressInitialValues,
  useVerifyAddress,
} from "../getReady";
import {
  SearchByAddress,
  SearchByEsiId,
  ServiceAddressPartial,
  useServiceAddressValidation,
} from "../getReady/ServiceAddress";
import { VerifyAddressDialog } from "../getReady/VerifyAddressDialog";
import { MultipleTdspAlert } from "../MultipleTdspAlert";
import { EmailAddressFormPartial, EmailQuote } from "./EmailQuote";
import { QuoteCard } from "./QuoteCard";
import {
  SmartDevicesCheckboxes,
  SmartDevicesChexboxesFormPartial,
} from "./SmartDevicesCheckboxes";
import { useSetQuoteData } from "./useSetQuoteData";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    margin: theme.spacing(0.5),
    border: 0,
    borderRadius: 8,
    [`&.${toggleButtonGroupClasses.disabled}`]: {
      border: 0,
    },
  },
}));

export enum QuoteLevel {
  LOW = "low",
  MEDIUM = "medium",
  HIGH = "high",
  HIGHER = "higher",
  USER_INPUT = "userInput",
}

const energyLevelsMap: { [char: number | string]: QuoteLevel } = {
  500: QuoteLevel.LOW,
  1000: QuoteLevel.MEDIUM,
  2000: QuoteLevel.HIGH,
  4000: QuoteLevel.HIGHER,
  0: QuoteLevel.USER_INPUT,
};

const getQuoteLevelTranslations = (t: Translate, level: QuoteLevel) => {
  const quoteLevelsMap = {
    [QuoteLevel.LOW]: {
      level: t("low"),
      kwh: 500,
      bedrooms: t("studio_one_bedroom"),
      footage: `500 to 900`,
      residents: `1-2`,
    },
    [QuoteLevel.MEDIUM]: {
      level: t("medium"),
      kwh: 1000,
      bedrooms: `Have 2-3 ${t("bedrooms")}`,
      footage: `1,000 to 1,600`,
      residents: `3-4`,
    },
    [QuoteLevel.HIGH]: {
      level: t("high"),
      kwh: 2000,
      bedrooms: `Have 3-4 ${t("bedrooms")}`,
      footage: `2,000 to 2,500`,
      residents: `4-5`,
    },
    [QuoteLevel.HIGHER]: {
      level: t("higher"),
      kwh: 4000,
      bedrooms: `Have 3-4 ${t("bedrooms")}`,
      footage: `3,000 to 4,000+`,
      residents: `4-5+`,
    },
    [QuoteLevel.USER_INPUT]: {
      level: "userInput",
      kwh: 0,
      bedrooms: "",
      footage: "",
      residents: "",
    },
  };

  return quoteLevelsMap[level];
};

export type QuoteFormValues = ServiceAddressPartial &
  SmartDevicesChexboxesFormPartial &
  EmailAddressFormPartial & {
    userInput?: number;
  };

export const useDefaultValues = (): QuoteFormValues => {
  const enrollment = useEnrollment();
  const [{ smartDevices }] = useEnrollmentV2();

  const devicesDefaultValues: SmartDevicesChexboxesFormPartial = {
    smartThermostat: smartDevices.includes(
      AccountCampaignChoices.SmartThermostat
    ),
    solarPanels: smartDevices.includes(AccountCampaignChoices.SolarPanels),
    storageBattery: smartDevices.includes(AccountCampaignChoices.HomeBattery),
    electricVehicle: smartDevices.includes(
      AccountCampaignChoices.ElectricVehicle
    ),
  };
  const emailDefaultValues: EmailAddressFormPartial = {
    email: enrollment.formData.getReady?.emailAddress || "",
  };
  const defaultValues: QuoteFormValues = enrollment.formData.getReady
    ? {
        ...enrollment.formData.getReady,
        ...devicesDefaultValues,
        ...emailDefaultValues,
      }
    : {
        ...serviceAddressInitialValues,
        ...devicesDefaultValues,
        ...emailDefaultValues,
      };
  return defaultValues;
};

export const QuotePage = () => {
  const defaultValues = useDefaultValues();
  const [, { setSmartDevices }] = useEnrollmentV2();
  const { t } = useTranslation("enrollment/quote");
  const { t: tFormFields } = useTranslation("enrollment/formFields");
  const enrollment = useEnrollment();
  const router = useRouter();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const customerIsPTC = useCustomerIsPTC();
  const monthlyKwhUsageMapped = energyLevelsMap[enrollment.monthlyKwhUsage];
  const [energyLevel, setEnergyLevel] = useState<QuoteLevel | null>(
    monthlyKwhUsageMapped ? monthlyKwhUsageMapped : null
  );
  const [userUsageInput, setUserUsageInput] = useState<number | string | null>(
    monthlyKwhUsageMapped ? null : enrollment.monthlyKwhUsage
  );
  const [userInputToggle, setUserInputToggle] = useState<string | null>(
    monthlyKwhUsageMapped ? null : "userInput"
  );
  const [isFieldTouched, setIsFieldTouched] = useState(false);
  const editingZipCode = useBooleanState(false);
  const pushToNextStep = useBooleanState(false);
  const customerHasChosenToEnterAddress = useBooleanState(
    Boolean(router.query.enterAddressEarly) || false
  );
  const serviceAddressValidation = useServiceAddressValidation();
  const checkZipCode = useIsPostCodeQuotableFunction();

  const schema = Yup.object().shape({
    email: Yup.string().email(tFormFields("validation_emailNotValid")),
    userInput:
      userInputToggle === "userInput"
        ? Yup.number().positive().integer().required()
        : Yup.string().nullable(),
    ...(enrollment.zipCodeHasMultipleTdspsOrLoadZones &&
    customerHasChosenToEnterAddress.state
      ? {
          ...serviceAddressValidation,
        }
      : {
          service_ZipCode: Yup.string()
            .min(5, tFormFields("validation_serviceZipCodeNotValid"))
            .max(5, tFormFields("validation_serviceZipCodeNotValid"))
            .required(tFormFields("validation_serviceZipCodeNotEntered")),
        }),
  });

  const form = useForm<QuoteFormValues>({
    mode: "onTouched",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const incompleteAddress =
    !form.watch().service_Address ||
    !form.watch().service_City ||
    !form.watch().service_State ||
    !form.watch().service_ZipCode;

  const toggleOptions = [
    { label: t("low"), value: QuoteLevel.LOW, testValue: "low" },
    { label: t("medium"), value: QuoteLevel.MEDIUM, testValue: "medium" },
    { label: t("high"), value: QuoteLevel.HIGH, testValue: "high" },
    { label: t("higher"), value: QuoteLevel.HIGHER, testValue: "higher" },
    {
      label: "Actual Usage",
      value: QuoteLevel.USER_INPUT,
      testValue: "actual-usage",
    },
  ];

  //Don't map last item (Actual usage) if mobile
  const toggleOptionsToMap = isMobile
    ? toggleOptions.slice(0, -1)
    : toggleOptions;

  useEffect(() => {
    if (enrollment.formData.getReady?.service_ZipCode) {
      form.setValue(
        "service_ZipCode",
        enrollment.formData.getReady?.service_ZipCode
      );
      checkZipCode({
        variables: {
          postcode: enrollment.formData.getReady?.service_ZipCode,
        },
      }).then((data) => {
        enrollment.setZipCodeHasMultipleTdspsOrLoadZones(
          data.data.isPostCodeQuotable?.hasMultipleTdspsOrLoadZones || false
        );
      });
    }
  }, [enrollment.formData.getReady?.service_ZipCode]);

  const [verifyType, setVerifyType] = useState<"esiId" | "address" | null>(
    null
  );
  const { verifyAddress, suggestedAddresses } = useVerifyAddress(setVerifyType);
  const setQuoteData = useSetQuoteData();

  const handleChangeEnergyLevel = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    const newRate = (event.currentTarget as HTMLInputElement).value;
    setEnergyLevel(newRate as QuoteLevel);

    if (newRate === QuoteLevel.USER_INPUT) {
      setUserInputToggle("userInput");
    } else {
      setUserUsageInput(null);
      setUserInputToggle(null);
    }
  };

  const handleChangeZipCode = () => {
    checkZipCode({
      variables: {
        postcode: form.watch().service_ZipCode,
      },
    }).then((data) => {
      enrollment.setFormData({
        getReady: {
          ...getReadyFormInitialValues,
          service_ZipCode: form.watch().service_ZipCode,
        },
      });
      enrollment.setZipCodeHasMultipleTdspsOrLoadZones(
        data.data.isPostCodeQuotable?.hasMultipleTdspsOrLoadZones || false
      );
    });
    editingZipCode.setFalse();
  };

  const handleSubmitForm: SubmitHandler<QuoteFormValues> = async (values) => {
    const monthlyKwhUsage =
      userUsageInput ||
      getQuoteLevelTranslations(t, (energyLevel as QuoteLevel) ?? "low").kwh;
    // If anything changed on this page, render the previous quote invalid.
    const previousQuoteInvalid =
      enrollment.monthlyKwhUsage !== monthlyKwhUsage ||
      values.email !== enrollment.formData.getReady?.emailAddress ||
      enrollment.formData.getReady.service_ZipCode !== values.service_ZipCode;
    if (previousQuoteInvalid) {
      enrollment.setQuoteCode(undefined);
    }
    enrollment.setFormData((prev) => ({
      ...prev,
      getReady: {
        ...(prev.getReady || getReadyFormInitialValues),
        service_ZipCode: values.service_ZipCode,
        emailAddress: values.email,
      },
    }));

    enrollment.setMonthlyKwhUsage(
      userUsageInput ||
        getQuoteLevelTranslations(t, (energyLevel as QuoteLevel) ?? "low").kwh
    );
    enrollment.setCustomerHasEnteredAddressEarly(
      customerHasChosenToEnterAddress.state
    );

    // Store smart device states in enrollment context
    const smartDevices: AccountCampaignChoices[] = [];
    values.electricVehicle &&
      smartDevices.push(AccountCampaignChoices.ElectricVehicle);
    values.smartThermostat &&
      smartDevices.push(AccountCampaignChoices.SmartThermostat);
    values.solarPanels && smartDevices.push(AccountCampaignChoices.SolarPanels);
    values.storageBattery &&
      smartDevices.push(AccountCampaignChoices.HomeBattery);
    setSmartDevices(smartDevices);

    if (
      enrollment.zipCodeHasMultipleTdspsOrLoadZones &&
      customerHasChosenToEnterAddress.state
    ) {
      if (!incompleteAddress || values.esiId) {
        if (values.esiId && values.searchByESI) {
          verifyAddress(values);
          setVerifyType("esiId");
          return pushToNextStep.setTrue();
        } else {
          verifyAddress(values);
          setVerifyType("address");
          return pushToNextStep.setTrue();
        }
      }
    } else {
      setQuoteData(values.esiId);
    }
  };

  return (
    <Layout>
      <EnrollmentBanner />
      <Container maxWidth="md" sx={{ mb: 20 }} data-cy="quote-container">
        {enrollment.zipCodeHasMultipleTdspsOrLoadZones && (
          <Box display="flex" justifyContent="center" my={2} maxWidth="md">
            {customerIsPTC ? (
              <Alert severity="info" icon={<IconInfo color="currentColor" />}>
                <Typography>{t("ptcUsersWrongLoadzones")}</Typography>
              </Alert>
            ) : (
              <MultipleTdspAlert
                onClick={customerHasChosenToEnterAddress.setTrue}
              />
            )}
          </Box>
        )}
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(handleSubmitForm)}>
            <Stack>
              <Box
                mr={isMobile ? 0 : 6}
                mt={isMobile ? 0 : 6}
                mb={isMobile ? 4 : 8}
              >
                <Typography
                  data-cy="quote-page-header"
                  variant={isMobile ? "h3" : "h1"}
                  component="h1"
                >
                  {t("letsFindOutHowYouCouldSave")}
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography variant="h5" data-cy="quote-where-do-you-live-text">
                  {t("whereDoYouLive")}
                </Typography>
              </Box>
              {enrollment.zipCodeHasMultipleTdspsOrLoadZones &&
              customerHasChosenToEnterAddress.state ? (
                <Box maxWidth="sm" mb={2}>
                  <Typography mb={2}>
                    {t("liveInAreaWithMultipleLZ")}
                  </Typography>
                  <Collapse in={!form.watch().searchByESI}>
                    <SearchByAddress />
                  </Collapse>
                  <Collapse in={form.watch().searchByESI}>
                    <SearchByEsiId />
                  </Collapse>
                </Box>
              ) : (
                <TextField
                  {...form.register("service_ZipCode")}
                  label={tFormFields("labels_addressZipCode")}
                  data-cy="zip-code"
                  fullWidth
                  required
                  error={Boolean(form.formState.errors.service_ZipCode)}
                  helperText={
                    form.formState.errors.service_ZipCode?.message || " "
                  }
                  InputLabelProps={{
                    shrink:
                      Boolean(form.watch().service_ZipCode) || isFieldTouched,
                  }}
                  onFocus={() => {
                    setIsFieldTouched(true);
                    editingZipCode.setTrue();
                  }}
                  onBlur={handleChangeZipCode}
                  sx={{ mb: 3, maxWidth: { xs: "90%", sm: "50%" } }}
                />
              )}
              <Stack>
                <Box mb={2}>
                  <Typography variant="h5" data-cy="quote-energy-use">
                    {t("howMuchEnergyDoYouUse")}
                  </Typography>
                </Box>
                {isMobile && userInputToggle && (
                  <Box mb={2}>
                    <Typography variant="h5" component="span" fontWeight={400}>
                      {t("enterCustomAmount")}
                      <Typography
                        component="span"
                        onClick={() => {
                          setUserInputToggle(null);
                        }}
                      >
                        <b>
                          <u>{t("useSetAmount")}</u>
                        </b>
                      </Typography>
                    </Typography>
                  </Box>
                )}
                {isMobile && !userInputToggle && (
                  <Box mb={2}>
                    <Typography variant="h5" component="span" fontWeight={400}>
                      {t("ifYouKnowUsage")}
                      <Typography
                        component="span"
                        onClick={() => {
                          setUserInputToggle("userInput");
                        }}
                      >
                        <b>
                          <u>{t("enterActualAmount")}</u>
                        </b>
                      </Typography>
                    </Typography>
                  </Box>
                )}
                {((isMobile && !userInputToggle) || !isMobile) && (
                  <Paper
                    elevation={0}
                    sx={(theme) => ({
                      display: "flex",
                      border: `3px solid ${theme.palette.secondary.main}`,
                      flexWrap: "wrap",
                      background: `${theme.palette.background.default}`,
                    })}
                  >
                    <StyledToggleButtonGroup
                      fullWidth
                      onChange={handleChangeEnergyLevel}
                      size={isMobile ? "small" : undefined}
                      data-cy="quote-button-group"
                      value={energyLevel}
                    >
                      {toggleOptionsToMap.map(({ value, label, testValue }) => (
                        <ToggleButton
                          data-cy={`quote-button-${testValue}`}
                          value={
                            value === QuoteLevel.USER_INPUT
                              ? "userInput"
                              : value
                          }
                          sx={{
                            py: 2,
                            px: isMobile ? 0 : "auto",
                            "&.MuiToggleButtonGroup-grouped": {
                              borderRadius: "12px !important",
                            },
                          }}
                        >
                          <Typography noWrap variant="h5">
                            {label}
                          </Typography>
                        </ToggleButton>
                      ))}
                    </StyledToggleButtonGroup>
                  </Paper>
                )}
              </Stack>

              {userInputToggle ? (
                <Stack width="100%" mt={1}>
                  <TextField
                    {...form.register("userInput")}
                    data-cy="quote-user-input"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    placeholder="Monthly kWh"
                    fullWidth
                    value={userUsageInput}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setUserUsageInput(event.target.value);
                    }}
                    error={Boolean(form.formState.errors.userInput)}
                    helperText={
                      form.formState.errors.userInput ? t("enter_number") : " "
                    }
                  />
                </Stack>
              ) : (
                <QuoteCard
                  quoteData={getQuoteLevelTranslations(
                    t,
                    (energyLevel as QuoteLevel) ?? "low"
                  )}
                />
              )}
              <SmartDevicesCheckboxes />
              {!customerIsPTC && <EmailQuote />}
              <Button
                data-cy="quote-submit-button"
                type="submit"
                fullWidth
                color="primary"
                variant="contained"
                disabled={
                  enrollment.zipCodeHasMultipleTdspsOrLoadZones &&
                  customerHasChosenToEnterAddress.state
                    ? Boolean(incompleteAddress && !form.watch().esiId)
                    : editingZipCode.state
                }
                sx={{ mt: 6 }}
              >
                {t("getQuote")}
              </Button>
            </Stack>
            <VerifyAddressDialog
              open={Boolean(verifyType)}
              setVerifyType={setVerifyType}
              title={
                verifyType === "address"
                  ? tFormFields("verify_AddressNotif")
                  : tFormFields("verify_ESINotif")
              }
              suggestedAddresses={suggestedAddresses}
              pushToNextStep={pushToNextStep.state}
            />
          </form>
        </FormProvider>
      </Container>
    </Layout>
  );
};
