import { atom, useRecoilState } from "recoil";
import { ProductListType } from "../krakenHelpers";

export const octopusProductsAtom = atom<{
  octopusProducts: ProductListType;
}>({
  key: "octopusProducts",
  default: {
    octopusProducts: [],
  },
});

export const useOctopusProducts = () => useRecoilState(octopusProductsAtom);
