import { Alert, Box, Typography } from "@octopus-energy/coral-mui";
import useTranslation from "next-translate/useTranslation";
import { CreditCheckStatus, useEnrollment } from "..";
import { IconWarning } from "@krakentech/icons";

export const CreditCheckFailureAlert = () => {
  const { t } = useTranslation("enrollment/formFields");
  const { creditCheckStatus } = useEnrollment();

  if (
    creditCheckStatus === CreditCheckStatus.PASS ||
    creditCheckStatus === CreditCheckStatus.OPT_OUT
  ) {
    return null;
  }

  return (
    <Box
      width="100%"
      mt={3}
      mb={0}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Alert
        severity="warning"
        variant="outlined"
        icon={<IconWarning />}
        sx={{ borderRadius: "1rem", borderWidth: "2px" }}
      >
        <Typography
          variant="body1"
          m={0}
          p={0}
          data-cy="credit-score-failure-alert"
        >
          {t(
            creditCheckStatus === CreditCheckStatus.FAIL
              ? "credit_check_lowscore"
              : "credit_check_error"
          )}
        </Typography>
      </Alert>
    </Box>
  );
};
