import { gql } from "@apollo/client";
import { createUseQuery } from "@octopus-energy/apollo-client";
import {
  AccountIoEligibilityQuery,
  AccountIoEligibilityQueryVariables,
} from "./__generated__/useQueryAccountIoEligibility.generated";

export const QUERY_ACCOUNT_IO_ELIGIBILITY = gql`
  query accountIoEligibility($accountNumber: String!) {
    accountIoEligibility(accountNumber: $accountNumber) {
      isEligibleForIo
    }
  }
`;

export const useQueryAccountIoEligibility = createUseQuery<
  AccountIoEligibilityQuery,
  AccountIoEligibilityQueryVariables
>(QUERY_ACCOUNT_IO_ELIGIBILITY);
