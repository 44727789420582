import React, { createContext, FC, useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import {
  CreditCheckStatus,
  PREPAY_INITIAL_DEPOSIT_AMOUNT,
} from "./enrollmentConstants";
import { CreditCheckType } from "./getReady";
import { useEnrollmentContextValues } from "./useEnrollmentContextValues";

type T_EnrollmentContext = Omit<
  ReturnType<typeof useEnrollmentContextValues>,
  "octopusProducts" | "setEnrollmentContextValues"
> & {
  loading: boolean;
  resetEnrollment: VoidFunction;
};

export const EnrollmentContext = createContext<T_EnrollmentContext>(
  {} as T_EnrollmentContext
);

type EnrollmentProviderProps = {
  children: React.ReactNode;
};

const ENROLLMENT_CONTEXT = "enrollmentContext";
const INITIAL_ENROLLMENT_CONTEXT_VALUES = {
  formData: {},
  selectedPlanID: null,
  octopusProducts: [],
  creditCheckStatus: CreditCheckStatus.PASS,
  creditCheckType: CreditCheckType.RUN_CREDIT_CHECK,
  krakenAccountID: null,
  ledgerId: null,
  paymentMethodId: "",
  prepayAutoTopUpAmount: PREPAY_INITIAL_DEPOSIT_AMOUNT,
  monthlyKwhUsage: 1000,
  withEV: false,
  withThermostat: false,
  showCalculatingQuote: true,
  quoteCode: "",
  quoteFromEmail: false,
  zipCodeHasMultipleTdspsOrLoadZones: false,
  customerHasEnteredAddressEarly: false,
  financialConnectionsDetails: undefined,
};

export const EnrollmentProvider: FC<EnrollmentProviderProps> = ({
  children,
}) => {
  const [loading, setLoading] = useState<boolean>(true);

  const {
    formData,
    selectedPlanID,
    octopusProducts,
    creditCheckStatus,
    creditCheckType,
    krakenAccountID,
    ledgerId,
    paymentMethodId,
    prepayAutoTopUpAmount,
    monthlyKwhUsage,
    withEV,
    withThermostat,
    quoteCode,
    quoteFromEmail,
    zipCodeHasMultipleTdspsOrLoadZones,
    customerHasEnteredAddressEarly,
    financialConnectionsDetails,
    setEnrollmentContextValues,
    setFormData,
    setSelectedPlanID,
    setCreditCheckStatus,
    setCreditCheckType,
    setKrakenAccountID,
    setLedgerId,
    setPaymentMethodId,
    setPrepayAutoTopUpAmount,
    setMonthlyKwhUsage,
    setWithEV,
    setWithThermostat,
    setQuoteCode,
    setQuoteFromEmail,
    setZipCodeHasMultipleTdspsOrLoadZones,
    setCustomerHasEnteredAddressEarly,
    setFinancialConnectionsDetails,
  } = useEnrollmentContextValues();

  useEffect(() => {
    const rawEnrollmentContextFromBrowserStorage =
      window.sessionStorage.getItem(ENROLLMENT_CONTEXT);

    const initialEnrollmentContext = rawEnrollmentContextFromBrowserStorage
      ? JSON.parse(rawEnrollmentContextFromBrowserStorage)
      : INITIAL_ENROLLMENT_CONTEXT_VALUES;

    if (initialEnrollmentContext) {
      setEnrollmentContextValues(initialEnrollmentContext);
    }
    setLoading(false);
  }, []);

  // This state gets stored in session to persist on a page refresh
  const enrollmentContextToStoreInBrowserStorage = JSON.stringify({
    formData,
    selectedPlanID,
    octopusProducts,
    creditCheckStatus,
    krakenAccountID,
    ledgerId,
    paymentMethodId,
    prepayAutoTopUpAmount,
    monthlyKwhUsage,
    withEV,
    withThermostat,
    quoteCode,
    quoteFromEmail,
    zipCodeHasMultipleTdspsOrLoadZones,
    customerHasEnteredAddressEarly,
  });

  const setEnrollmentContextInBrowserStorage = useDebouncedCallback(() => {
    window.sessionStorage.setItem(
      ENROLLMENT_CONTEXT,
      enrollmentContextToStoreInBrowserStorage
    );
  }, 1000);

  useEffect(() => {
    setEnrollmentContextInBrowserStorage();
  }, [enrollmentContextToStoreInBrowserStorage]);

  //Context Store
  const enrollmentStore = {
    // States
    formData,
    selectedPlanID,
    creditCheckStatus,
    creditCheckType,
    krakenAccountID,
    ledgerId,
    paymentMethodId,
    prepayAutoTopUpAmount,
    monthlyKwhUsage,
    withEV,
    withThermostat,
    quoteCode,
    quoteFromEmail,
    zipCodeHasMultipleTdspsOrLoadZones,
    customerHasEnteredAddressEarly,
    financialConnectionsDetails,

    //Update States
    setKrakenAccountID,
    setSelectedPlanID,
    setCreditCheckStatus,
    setCreditCheckType,
    setLedgerId,
    setPaymentMethodId,
    setPrepayAutoTopUpAmount,
    setMonthlyKwhUsage,
    setFormData,
    setWithEV,
    setWithThermostat,
    setQuoteCode,
    setQuoteFromEmail,
    setZipCodeHasMultipleTdspsOrLoadZones,
    setCustomerHasEnteredAddressEarly,
    setFinancialConnectionsDetails,

    resetEnrollment: () => {
      setEnrollmentContextValues(INITIAL_ENROLLMENT_CONTEXT_VALUES);
    },

    loading,
  };

  return (
    <EnrollmentContext.Provider value={enrollmentStore}>
      {children}
    </EnrollmentContext.Provider>
  );
};
