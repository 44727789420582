import { ISOCountryCodes } from "./LocalizationTypes";

export const REGION_URLS: { [key: string]: string } = {
  [ISOCountryCodes.DE]: "https://www.octopusenergy.de/",
  [ISOCountryCodes.ES]: "https://octopusenergy.es/",
  [ISOCountryCodes.IT]: "https://octopusenergy.it/",
  [ISOCountryCodes.JP]: "https://octopusenergy.co.jp/",
  [ISOCountryCodes.GB]: "https://octopus.energy/",
  [ISOCountryCodes.NZ]: "https://octopusenergy.nz/",
  [ISOCountryCodes.FR]: "https://octopusenergy.fr/",
};

export const REGION_LANG: {
  [key: string]: {
    mainMessage: string;
    subMessage: string;
    yes: string;
    no: string;
    remember: string;
  };
} = {
  GLOBAL: {
    mainMessage: "It looks like you're not visiting from the US.",
    subMessage: "Would you like to visit another region of Octopus Energy?",
    yes: "Yes please",
    no: "No thanks",
    remember: "Remember my choice",
  },
  [ISOCountryCodes.DE]: {
    mainMessage: "Sieht so aus, also ob du aus Deutschland zu Besuch bist.",
    subMessage: "Möchtest du Octopus DE besuchen?",
    yes: "Ja, bitte",
    no: "Nein danke",
    remember: "An Auswahl erinnern",
  },
  [ISOCountryCodes.ES]: {
    mainMessage: "Parece que nos estás visitando desde España.",
    subMessage: "¿Te gustaría visitar la web de Octopus Energy España?",
    yes: "Si, por favor",
    no: "No, gracias",
    remember: "Recuerda mi elección",
  },
  [ISOCountryCodes.IT]: {
    mainMessage: "Sei attualmente sul sito di Octopus Energy USA.",
    subMessage: "Vuoi visitare il sito italiano?",
    yes: "Sì, grazie!",
    no: "No, grazie!",
    remember: "Ricorda la mia risposta",
  },
  [ISOCountryCodes.JP]: {
    mainMessage: "日本からのアクセスですか？",
    subMessage: "オクトパスエナジージャパンのページに切り替えますか？",
    yes: "はい",
    no: "いいえ",
    remember: "この設定で登録する",
  },
  [ISOCountryCodes.GB]: {
    mainMessage: "It looks like you're visiting from the UK.",
    subMessage: "Would you like to visit Octopus Energy UK?",
    yes: "Yes please",
    no: "No thanks",
    remember: "Remember my choice",
  },
  [ISOCountryCodes.NZ]: {
    mainMessage: "It looks like you're visiting from New Zealand.",
    subMessage: "Would you like to visit Octopus Energy New Zealand?",
    yes: "Yes please",
    no: "No thanks",
    remember: "Remember my choice",
  },
  [ISOCountryCodes.FR]: {
    mainMessage: "Il semble que vous visitez notre site depuis la France.",
    subMessage: "Vous souhaitez visiter Octopus Energy France?",
    yes: "Oui s'il vous plaît",
    no: "Non, merci",
    remember: "Rappelez-vous mon choix",
  },
};
