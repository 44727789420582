import {
  AlertColor,
  Button,
  SnackbarOrigin,
  SnackbarProps,
} from "@mui/material";
import { Alert, Snackbar } from "@octopus-energy/coral-mui";
import useTranslation from "next-translate/useTranslation";
import { FC, ReactNode } from "react";
import { atom, useRecoilState } from "recoil";

/**
 * Recoil atom for storing snackbar notification state
 *
 * @example
 * const [state, setState] = useRecoilState(snackbarNotificationAtom);
 */
export const snackbarNotificationAtom = atom<{
  variant: AlertColor;
  open: boolean;
  message: string;
  anchorOrigin: SnackbarOrigin;
  autoHideDuration: SnackbarProps["autoHideDuration"];
  "data-cy"?: string;
}>({
  key: "snackbarNotification",
  default: {
    variant: "info",
    open: false,
    message: "",
    autoHideDuration: 6000,
    anchorOrigin: {
      vertical: "top",
      horizontal: "center",
    },
  },
});

/**
 * Hook to set the state of the global snackbar notification.
 *
 * @example
 * const [{ info, success, warning, error }] = useSnackbarNotification();
 */
export const useSnackbarNotification = () => {
  const [state, setState] = useRecoilState(snackbarNotificationAtom);

  const showNotification =
    (variant: AlertColor) =>
    (
      message: string,
      options?: {
        anchorOrigin?: SnackbarOrigin;
        autoHideDuration?: SnackbarProps["autoHideDuration"];
        "data-cy"?: string;
      }
    ) => {
      setState({
        variant,
        message,
        open: true,
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
        autoHideDuration: 6000,
        ...options,
      });
    };

  const info = showNotification("info");
  const success = showNotification("success");
  const warning = showNotification("warning");
  const error = showNotification("error");

  const closeNotification = () => {
    setState({
      ...state,
      open: false,
      "data-cy": undefined,
    });
  };

  const context = {
    ...state,
    info,
    success,
    warning,
    error,
    closeNotification,
  };

  return [context, setState] as [typeof context, typeof setState];
};

/**
 * Snackbar notification alert
 *
 * @example
 * <SnackbarNotification />
 */
export const SnackbarNotification = () => {
  const [
    { open, message, variant, closeNotification, anchorOrigin, ...props },
  ] = useSnackbarNotification();
  const { t } = useTranslation("common");

  return (
    <Snackbar
      anchorOrigin={anchorOrigin}
      open={open}
      autoHideDuration={6000}
      onClose={closeNotification}
      data-testid="snackbar-notification"
    >
      <Alert
        icon={false}
        action={
          <Button
            color="inherit"
            size="small"
            variant="text"
            onClick={closeNotification}
            sx={{
              py: 0,
              fontSize: "1rem",
              fontWeight: "bold",
            }}
          >
            {t("dismiss")}
          </Button>
        }
        variant="filled"
        severity={variant}
        data-testid="snackbar-notification-alert"
        data-cy={props["data-cy"] || "snackbar-notification-alert"}
        sx={{
          borderRadius: "8px",
          alignItems: "center",
        }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

/**
 * Provider for rendering a snackbar notification UI component
 *
 * @example
 * <SnackbarNotificationProvider >...</SnackbarNotificationProvider>
 */
export const SnackbarNotificationProvider: FC<{ children: ReactNode }> = ({
  children,
}) => (
  <>
    <SnackbarNotification />
    {children}
  </>
);
