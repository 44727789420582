import { gql } from "@apollo/client";
import { createUseMutation } from "@octopus-energy/apollo-client";
import {
  CreateDepositAgreementMutation,
  CreateDepositAgreementMutationVariables,
} from "./__generated__/useCreateDepositAgreementMutation.generated";

export const MUTATION_CREATE_DEPOSIT_AGREEMENT = gql`
  mutation createDepositAgreement($input: CreateDepositAgreementInput!) {
    createDepositAgreement(input: $input) {
      depositAgreement {
        depositRequired
        depositAmount
      }
    }
  }
`;

export const useCreateDepositAgreementMutation = createUseMutation<
  CreateDepositAgreementMutation,
  CreateDepositAgreementMutationVariables
>(MUTATION_CREATE_DEPOSIT_AGREEMENT);
