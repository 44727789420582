import { Alert, Link } from "@krakentech/coral";
import { IconInfo } from "@krakentech/icons";
import { Typography } from "@octopus-energy/coral-mui";
import useTranslation from "next-translate/useTranslation";
import type { FC } from "react";

type MultipleTdspAlertProps = {
  onClick: () => void;
};

export const MultipleTdspAlert: FC<MultipleTdspAlertProps> = ({ onClick }) => {
  const { t } = useTranslation("enrollment/quote");

  return (
    <Alert severity="info" icon={<IconInfo color="currentColor" />}>
      <Typography>
        <strong>{t("information")}</strong> {t("multipleProviders")}{" "}
        <Link color="inherit" onClick={onClick}>
          {t("pleaseEnterAddressOrEsiId")}
        </Link>
      </Typography>
    </Alert>
  );
};
