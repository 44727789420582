import { gql } from "@apollo/client";
import { createUseMutation } from "@octopus-energy/apollo-client";
import {
  CreateAccountMutation,
  CreateAccountMutationVariables,
} from "./__generated__/useCreateAccountMutation.generated";

export const MUTATION_CREATE_ACCOUNT = gql`
  mutation createAccount($input: CreateAccountInput!) {
    createAccount(input: $input) {
      account {
        id
        number
      }
    }
  }
`;

export const useCreateAccountMutation = createUseMutation<
  CreateAccountMutation,
  CreateAccountMutationVariables
>(MUTATION_CREATE_ACCOUNT);
