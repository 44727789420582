import {
  CodeType,
  ReferralCodeStatusType,
} from "@core/apiRequests/graphql-global-types";
import { Maybe } from "../types";

export const FRIEND_REFERRAL = "friendReferral";
export const USER_FRIEND_REFERRAL = "userFriendReferral";
export const CODE_TYPE_APPLIED = "codeTypeApplied";

export type FriendReferral = ReferralCodeStatusType & {
  code: string;
};

// Utils For Refer A Friend Landing Page:
export const getFriendReferral = (): Maybe<FriendReferral> => {
  if (typeof window !== "undefined") {
    return JSON.parse(window.sessionStorage.getItem(FRIEND_REFERRAL) || "null");
  }
};

export const setFriendReferral = (friendReferral: FriendReferral) => {
  if (typeof window !== "undefined") {
    return window.sessionStorage.setItem(
      FRIEND_REFERRAL,
      JSON.stringify(friendReferral)
    );
  }
};

export const removeFriendReferral = () => {
  if (typeof window !== "undefined") {
    return window.sessionStorage.removeItem(FRIEND_REFERRAL);
  }
};

// Utils For Refer A Friend During Enrollment:

export const getCodeTypeApplied = (): Maybe<CodeType | "error"> => {
  if (typeof window !== "undefined") {
    return window.localStorage.getItem(CODE_TYPE_APPLIED) as CodeType | null;
  }
};

export const setCodeTypeApplied = (codeType: CodeType | "error") => {
  if (typeof window !== "undefined") {
    return window.localStorage.setItem(CODE_TYPE_APPLIED, codeType);
  }
};

export const getUserFriendReferral = () => {
  if (typeof window !== "undefined") {
    return window.localStorage.getItem(USER_FRIEND_REFERRAL) || "";
  }
};

export const setUserFriendReferral = (friendReferral: string) => {
  if (typeof window !== "undefined") {
    return window.localStorage.setItem(USER_FRIEND_REFERRAL, friendReferral);
  }
};

export const removeUserFriendReferral = () => {
  if (typeof window !== "undefined") {
    return window.localStorage.removeItem(USER_FRIEND_REFERRAL);
  }
};

// Extract referral code from URL returned by API
export const extractReferralCode = (code: Maybe<string>) => {
  return code?.split("/").pop();
};

// Format referral code to display as shown in Figma file - xxx-xxx-123 -> XxxXxx123
export const formatDisplayCode = (code: Maybe<string>) => {
  const formattedStrings = code
    ?.split("-")
    .map((code) => {
      return code[0].toUpperCase() + code.slice(1);
    })
    .map((code, index) => {
      if (index !== 0) {
        return `-${code}`;
      }
      return code;
    })
    .join("");
  return formattedStrings;
};
