import { useQueryProperties } from "@core/apiRequests";
import { AgreementType } from "@core/apiRequests/graphql-global-types";
import { useQueryGetEligibilityStatus } from "@core/apiRequests/renewal/useQueryGetEligibilityStatus";
import { isProdEnv } from "@core/env";
import { handleError } from "@core/error";
import { formatDistanceToNow, usePortalAccountNumber } from "@core/portalUtils";
import { OctopusProductId } from "@core/product";
import { Maybe } from "@core/types";
import { add, formatISO } from "date-fns";

export const RENEWAL_WINDOW = isProdEnv() ? 60 : 365;

export type UseEvaluateContractStatusProps = {
  esiId: Maybe<string>;
  agreementLoading: boolean;
  currentAgreement: Maybe<AgreementType>;
  currentContractIsMTM: boolean;
  currentProductName: Maybe<string>;
  currentProductRate: number;
  currentProductId: OctopusProductId | undefined;
  currentValidFrom: Date | undefined;
  currentValidTo: Date | undefined;
  daysLeftOnCurrentContract: Maybe<number>;
  isEligibleForRenewal: Maybe<boolean>;
  refetch: VoidFunction;
  renewalAgreement: Maybe<AgreementType>;
  renewalContractIsMTM: boolean;
  renewalProductName: Maybe<string>;
  renewalProductRate: number;
  renewalProductId: OctopusProductId | undefined;
  renewalValidFrom: Date | undefined;
  renewalValidTo: Date | undefined;
  renewalWindow: number;
};

export const useEvaluateContractStatus = (): UseEvaluateContractStatusProps => {
  const accountNumber = usePortalAccountNumber();

  const {
    data,
    loading: agreementLoading,
    refetch,
  } = useQueryProperties({
    variables: {
      accountNumber,
      includeSubscriptionFees: true,
      includeFutureActiveAgreements: true,
    },
    onError: handleError,
    skip: !accountNumber,
  });

  const esiId = data?.properties?.[0]?.meterPoints?.[0]?.esiId;

  // The most recent agreement returned from the API is the renewalAgreement,
  // and the last agreement is the currentAgreement
  // By using the reverse() method, the logic for finding the currentAgreement and
  // the renewalAgreement is cleaner and requires fewer conditionals
  const returnedAgreements =
    data?.properties?.[0]?.meterPoints?.[0]?.agreements;

  // The agreements array returned is considered immutable, so we need to copy it
  // into a separate array to reverse
  const totalAgreements =
    returnedAgreements && [...returnedAgreements].reverse();

  // Current agreement variables
  const currentAgreement = totalAgreements?.[0] as AgreementType;
  const currentProductName = currentAgreement?.product?.displayName;
  const currentProductRate = currentAgreement?.totalApplicableRate;
  const currentProductId = currentAgreement?.product?.code as
    | OctopusProductId
    | undefined;
  const currentContractIsMTM =
    currentProductId === ("OCTOPUS-MONTH-TO-MONTH" as unknown) ||
    currentProductId === ("OCTOGO-MTM-FIXED" as unknown) ||
    currentProductId === ("OCTOPUS-MONTH-TO-MONTH-INITIAL" as unknown) ||
    currentProductId === ("OCTOGO-MTM-FIXED-INITIAL" as unknown) ||
    currentProductId === ("OCTOPUS-MONTH-TO-MONTH-LUBBOCK-DREP" as unknown);

  // Current agreement timing variables and functions
  const currentValidFrom = currentAgreement?.validFrom;
  const currentValidTo =
    currentAgreement && currentAgreement?.validTo && !currentContractIsMTM
      ? currentAgreement?.validTo
      : formatISO(add(new Date(), { days: 1 }));
  const daysLeftOnCurrentContract = currentContractIsMTM
    ? 0
    : formatDistanceToNow(currentValidTo);

  // Renewal Window Constant
  const renewalWindow = RENEWAL_WINDOW;

  // Check renewal eligibility
  const { data: eligibilityData } = useQueryGetEligibilityStatus({
    variables: {
      id: data?.properties?.[0]?.id || "",
    },
    onError: handleError,
    skip: !data?.properties?.[0]?.id,
  });

  const isEligibleForRenewal = isProdEnv()
    ? eligibilityData?.property?.meterPoints?.[0]?.agreements?.[0]
        ?.isEligibleForRenewal
    : true;

  // Renewal agreement variables
  const renewalAgreement =
    totalAgreements && totalAgreements.length > 1
      ? (totalAgreements[totalAgreements?.length - 1] as AgreementType)
      : null;
  const renewalProductName = renewalAgreement?.product?.displayName;
  const renewalProductRate = renewalAgreement?.totalApplicableRate;
  const renewalProductId = renewalAgreement?.product?.code as
    | OctopusProductId
    | undefined;
  const renewalContractIsMTM =
    renewalProductId === ("OCTOPUS-MONTH-TO-MONTH" as unknown) ||
    renewalProductId === ("OCTOGO-MTM-FIXED" as unknown) ||
    renewalProductId === ("OCTOPUS-MONTH-TO-MONTH-INITIAL" as unknown) ||
    renewalProductId === ("OCTOGO-MTM-FIXED-INITIAL" as unknown) ||
    renewalProductId === ("OCTOPUS-MONTH-TO-MONTH-LUBBOCK-DREP" as unknown);

  // Current agreement timing variables and functions
  const renewalValidFrom =
    renewalAgreement?.validFrom && renewalAgreement?.validFrom;
  const renewalValidTo =
    renewalAgreement && renewalAgreement?.validTo && !renewalContractIsMTM
      ? renewalAgreement?.validTo
      : add(new Date(renewalValidFrom), { days: 1 });

  return {
    esiId,
    agreementLoading,
    currentAgreement,
    currentContractIsMTM,
    currentProductName,
    currentProductRate,
    currentProductId,
    currentValidFrom,
    currentValidTo,
    daysLeftOnCurrentContract,
    isEligibleForRenewal,
    refetch,
    renewalAgreement,
    renewalContractIsMTM,
    renewalProductName,
    renewalProductRate,
    renewalProductId,
    renewalValidFrom,
    renewalValidTo,
    renewalWindow,
  };
};
