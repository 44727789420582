import { useEnrollment } from "@core/enrollment";
import { useEnrollmentV2 } from "./useEnrollmentV2";

// This is a temporary hook to reset both legacy + v2 enrollment context from a single location
// After legacy context is removed, this hook can also be removed and v2 reset can be called
// directly.
export const useResetEnrollment = () => {
  const { resetEnrollment: resetLegacyEnrollment } = useEnrollment();
  const [, { reset: resetEnrollmentV2 }] = useEnrollmentV2();
  return () => {
    resetLegacyEnrollment();
    resetEnrollmentV2();
  };
};
