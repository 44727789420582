import { gql } from "@apollo/client";
import { createUseMutation } from "@octopus-energy/apollo-client";
import {
  RequestPasswordResetMutation,
  RequestPasswordResetMutationVariables,
} from "./__generated__/useRequestPasswordResetMutation.generated";

export const MUTATION_REQUEST_PASSWORD_RESET = gql`
  mutation RequestPasswordReset($input: RequestPasswordResetInput!) {
    requestPasswordReset(input: $input) {
      email
    }
  }
`;

export const useRequestPasswordResetMutation = createUseMutation<
  RequestPasswordResetMutation,
  RequestPasswordResetMutationVariables
>(MUTATION_REQUEST_PASSWORD_RESET);
