import { useResetEnrollment } from "@core/apiRequests/enrollment/useResetEnrollment";
import {
  getReadyFormInitialValues,
  removeCustomerIsAffiliateSale,
  useEnrollment,
  useSetAffiliateSubDomain,
} from "@core/enrollment";
import { handleError } from "@core/error";
import { useEnrollmentsFeature } from "@core/featureFlags";
import { styled } from "@mui/material/styles";
import { Box, LoadingButton, Typography } from "@octopus-energy/coral-mui";
import { Field, Form, Formik } from "formik";
import { AnimatePresence, motion } from "framer-motion";
import useTranslation from "next-translate/useTranslation";
import Router from "next/router";
import { useState } from "react";
import { useBooleanState } from "react-use-object-state";
import * as Yup from "yup";
import { EnrollmentOutageDialog } from "../EnrollmentOutageDialog";
import { useSnackbarNotification } from "../SnackbarNotification";
import { useIsPostCodeQuotableFunction } from "@core/apiRequests/product/useQueryIsPostCodeQuotable";

const zipCodeSchema = Yup.object().shape({
  zipCode: Yup.string()
    .required("join_zipcode_notValid")
    .test(
      "test 5-digits and spaces only, no letters or special chars",
      "join_zipcode_notValid",
      (val) => {
        const regExp = /^\s*\d{5}\s*$/;
        return regExp.test(val as string);
      }
    ),
});

function routerPush(quoteFeature: boolean) {
  //Check if there is a flag key and pass it along
  const queryObject = {} as { [key: string]: null | undefined | string };
  if (Router.query.key) queryObject.key = Router.query.key as string;

  queryObject.referrer =
    (Router.query.referrer as string | undefined) || "zipcode";
  // End Key Pass

  queryObject.code = (Router.query.code as string | undefined) || undefined;

  if (quoteFeature) {
    Router.push({
      pathname: "/join/enrollment/quote",
      query: queryObject,
    });
  } else {
    Router.push({
      pathname: "/join/plans",
      query: queryObject,
    });
  }
}

const ErrorBox = styled(motion.div)(({ theme }) => ({
  zIndex: "0",
  position: "relative",
  pt: 1,
  width: "100%",
  color: theme.palette.warning.main,
}));

const fadeDown = {
  hidden: {
    opacity: 0,
    top: -30,
  },
  show: {
    opacity: 1,
    top: 0,
  },
};

export type ZipCodeFormProps = {
  cta: string;
  placeholder?: string;
  label?: string;
  errorMsg?: string;
  center?: boolean;
  onSuccess?: (zipcode: string) => void;
  noHeading?: boolean;
  affiliate?: string | null;
};

export const ZipCodeForm = ({
  cta,
  placeholder,
  label,
  errorMsg,
  center,
  onSuccess,
  noHeading,
  affiliate,
}: ZipCodeFormProps) => {
  const { t } = useTranslation("enrollment/formFields");
  const [processing, setProcessing] = useState(false);
  const enrollment = useEnrollment();
  const resetEnrollment = useResetEnrollment();
  const enrollmentsFeature = useEnrollmentsFeature();
  const enrollmentOuttageDialogOpen = useBooleanState(false);
  const [notification] = useSnackbarNotification();
  const subDomain = affiliate || undefined;
  const setSubDomain = useSetAffiliateSubDomain();

  const checkProductAvailability = useIsPostCodeQuotableFunction();

  return (
    <>
      <Box
        pt={2}
        display="flex"
        flexDirection="column"
        alignItems={center ? "center" : "flex-start"}
        width="100%"
        data-cy="zipcode-form"
      >
        {!noHeading && (
          <Typography variant="body2">
            {label || t("join_zipCode_notice")}
          </Typography>
        )}
        <Formik
          initialValues={{
            zipCode: "",
          }}
          onFocus={() => {
            resetEnrollment();
          }}
          validationSchema={zipCodeSchema}
          onSubmit={(values, { setErrors }) => {
            const zipCode = values.zipCode.trim();
            if (!enrollmentsFeature.active) {
              enrollmentOuttageDialogOpen.setTrue();
              return;
            }
            setProcessing(true);

            checkProductAvailability({
              variables: { postcode: zipCode },
            })
              .then(({ data }) => {
                if (!data.isPostCodeQuotable?.isQuotable) {
                  throw new Error("No products found for this zip code.");
                }

                resetEnrollment();
                removeCustomerIsAffiliateSale();
                enrollment.setFormData({
                  getReady: {
                    ...getReadyFormInitialValues,
                    service_ZipCode: zipCode,
                  },
                });
                if (data.isPostCodeQuotable.hasMultipleTdspsOrLoadZones) {
                  enrollment.setZipCodeHasMultipleTdspsOrLoadZones(
                    data.isPostCodeQuotable.hasMultipleTdspsOrLoadZones
                  );
                }

                if (onSuccess) {
                  onSuccess(zipCode);
                  return;
                }

                if (affiliate) {
                  setSubDomain({
                    subDomain,
                    navigatorState: false,
                    onSuccess: () => {
                      routerPush(true);
                    },
                  });
                } else {
                  routerPush(true);
                }
              })
              .catch((error) => {
                if (error.networkError) {
                  handleError(error);
                  notification.error(t("join_zipcode_error_contactUs"));
                } else if (
                  error.message.includes("No products found for this zip code.")
                ) {
                  setErrors({
                    zipCode: "join_zipcode_notAvailable",
                  });
                } else {
                  setErrors({
                    zipCode: "join_zipcode_error_contactUs",
                  });
                }
              })
              .finally(() => {
                setProcessing(false);
              });
          }}
        >
          {({ errors, touched }) => (
            <Box component={motion.div} initial={{ minHeight: "70px" }}>
              <Form style={{ position: "relative" }}>
                <Box
                  display="flex"
                  width="auto"
                  justifyContent="space-between"
                  zIndex={10}
                  borderRadius="12px"
                  mx={0.25}
                  mt={noHeading ? 0 : 1}
                  mb={1}
                  sx={{
                    maxWidth: { xs: "100%", sm: "435px" },
                    backgroundColor: "white",
                    overflow: "hidden",
                    "&:focus-within": {
                      boxShadow: "0 0 0 2px white",
                    },
                  }}
                >
                  <Box
                    display="flex"
                    color="black"
                    p={2.45}
                    mr={0}
                    zIndex={10}
                    overflow="hidden"
                    width="100%"
                    sx={{
                      "*": {
                        outline: "none",
                        fontSize: "1.25rem",
                      },
                    }}
                  >
                    <label
                      htmlFor="zipcode"
                      style={{ display: "none" }}
                      id="zipcode-label"
                    >
                      Zip code
                    </label>
                    <Field
                      name="zipCode"
                      id="zipcode"
                      placeholder={placeholder || t("join_zipCode_placeholder")}
                      data-cy="zip-code-input"
                      style={{ width: "100%" }}
                      aria-labelledby="zipcode-label"
                    />
                  </Box>
                  <Box position="relative">
                    <LoadingButton
                      type="submit"
                      data-cy="zip-code-submit"
                      loading={processing}
                      sx={{
                        borderRadius: 0,
                        height: "100%",
                      }}
                    >
                      <Typography
                        variant="body2"
                        component="span"
                        fontWeight="medium"
                        fontSize="1.25rem"
                        whiteSpace="nowrap"
                      >
                        {t(cta)}
                      </Typography>
                    </LoadingButton>
                  </Box>
                </Box>
                <AnimatePresence>
                  <Box position="absolute" top="100%" left={0} py={1.2}>
                    {errors.zipCode && touched.zipCode && (
                      <ErrorBox
                        initial="hidden"
                        animate="show"
                        exit="hidden"
                        variants={fadeDown}
                        style={{ order: "3", paddingLeft: "1rem" }}
                      >
                        <Typography variant="body2">
                          {errorMsg || t(errors.zipCode)}
                        </Typography>
                      </ErrorBox>
                    )}
                  </Box>
                </AnimatePresence>
              </Form>
            </Box>
          )}
        </Formik>
      </Box>
      <EnrollmentOutageDialog
        open={enrollmentOuttageDialogOpen.state}
        onClose={enrollmentOuttageDialogOpen.setFalse}
      />
    </>
  );
};
