import { useQueryAccountViewer } from "@core/apiRequests";
import { usePortalAccountNumber } from "@core/portalUtils";
import {
  NavigationAccountMenuItem,
  OctopusEnergyThemeColor,
} from "@octopus-energy/coral-mui";
import useTranslation from "next-translate/useTranslation";
import Link from "next/link";
import type { FC } from "react";
import { useAuth, useLogout } from "../auth";

export const AuthedMenuItems: FC = () => {
  const [{ authed }] = useAuth();
  const handleLogout = useLogout();
  const { t } = useTranslation("common");
  const accountNumber = usePortalAccountNumber();
  const { data } = useQueryAccountViewer({ skip: !authed });

  const multipleAccounts = Boolean((data?.viewer?.accounts?.length || 0) > 1);

  return (
    <>
      {!multipleAccounts && !accountNumber && (
        <Link
          href={{
            pathname: `/account/${data?.viewer?.accounts?.[0]?.number}`,
          }}
          passHref
        >
          <NavigationAccountMenuItem
            data-cy="account-overview-button"
            variant="text"
          >
            {t("accountOverview")}
          </NavigationAccountMenuItem>
        </Link>
      )}
      {multipleAccounts && (
        <Link
          href={{
            pathname: "/dashboard",
          }}
          passHref
        >
          <NavigationAccountMenuItem variant="text" data-cy="accounts-button">
            {t("accounts")}
          </NavigationAccountMenuItem>
        </Link>
      )}
      {accountNumber && (
        <>
          <Link
            href={{
              pathname: `/account/${accountNumber}`,
            }}
          >
            <NavigationAccountMenuItem
              variant="text"
              data-cy="account-overview-button"
            >
              {t("accountOverview")}
            </NavigationAccountMenuItem>
          </Link>
        </>
      )}
      <NavigationAccountMenuItem
        variant="text"
        component="a"
        onClick={handleLogout}
        data-cy="sign-out-menu-button"
        sx={{
          color: `${OctopusEnergyThemeColor.SOHO_LIGHTS_PINK} !important`,
        }}
      >
        {t("button_signOut")}
      </NavigationAccountMenuItem>
    </>
  );
};
