import {
  NavigationMenuItems,
  NavigationMenuItemPriority,
} from "@octopus-energy/coral-mui";

enum Locale {
  ENGLISH = "en",
  SPANISH = "es",
}

export const HEADER_MENU: NavigationMenuItems = [
  {
    url: "/",
    priority: NavigationMenuItemPriority.PRIMARY,
    dataCy: "global-home-link",
    label: {
      [Locale.ENGLISH]: "Home",
      [Locale.SPANISH]: "Inicio",
    },
  },
  {
    url: "/join/enrollment/quote?referrer=zipcode&code=",
    priority: NavigationMenuItemPriority.PRIMARY,
    dataCy: "global-quote-link",
    label: {
      [Locale.ENGLISH]: "Quote",
      [Locale.SPANISH]: "Cotización",
    },
  },
  {
    url: "/smart-features",
    dataCy: "global-smart-features-link",
    isExternal: true,
    priority: NavigationMenuItemPriority.SECONDARY,
    label: {
      [Locale.ENGLISH]: "Smart Products",
      [Locale.SPANISH]: "Productos inteligentes",
    },
  },
  {
    url: "/help-and-faqs",
    dataCy: "global-faq-link",
    priority: NavigationMenuItemPriority.SECONDARY,
    label: {
      [Locale.ENGLISH]: "Help & FAQs",
      [Locale.SPANISH]: "Ayuda y preguntas frecuentes",
    },
  },
  {
    url: "/blog",
    dataCy: "global-blog-link",
    priority: NavigationMenuItemPriority.SECONDARY,
    label: {
      [Locale.ENGLISH]: "Blog",
      [Locale.SPANISH]: "Blog",
    },
  },
];

export const MENU_BUTTON_LABELS = {
  [Locale.ENGLISH]: {
    active: "Back",
    notActive: "Menu",
  },
  [Locale.SPANISH]: {
    active: "Volver",
    notActive: "Menú",
  },
};

export const LANG_MENU_ITEMS = [
  {
    locale: "en",
    label: "English",
    dataCy: "language-preference-english",
  },
  {
    locale: "es",
    label: "Español",
    dataCy: "language-preference-spanish",
  },
];
