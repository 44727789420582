import { gql } from "@apollo/client";
import { createUseQuery } from "@octopus-energy/apollo-client";
import {
  DefaultPaymentInstructionQuery,
  DefaultPaymentInstructionQueryVariables,
} from "./__generated__/useQueryDefaultPaymentInstruction.generated";

export const QUERY_DEFAULT_PAYMENT_INSTRUCTION = gql`
  query defaultPaymentInstruction(
    $accountNumber: String!
    $instructionType: PaymentType
  ) {
    defaultPaymentInstruction(
      accountNumber: $accountNumber
      instructionType: $instructionType
    ) {
      id
      validFrom
      vendor
      instructionType
      accountHolder
      status
      cardNumber
      cardPaymentNetwork
      cardType
      cardExpiryMonth
      cardExpiryYear
      maskedAccountIdentifier
    }
  }
`;

export const useQueryDefaultPaymentInstruction = createUseQuery<
  DefaultPaymentInstructionQuery,
  DefaultPaymentInstructionQueryVariables
>(QUERY_DEFAULT_PAYMENT_INSTRUCTION);
