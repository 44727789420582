import { gql } from "@apollo/client";
import { createUseQuery } from "@octopus-energy/apollo-client";
import {
  GetReferralCodeStatusQuery,
  GetReferralCodeStatusQueryVariables,
} from "./__generated__/useCheckReferralCode.generated";

export const GET_REFERRAL_CODE_STATUS = gql`
  query getReferralCodeStatus($code: String!) {
    getReferralCodeStatus(code: $code) {
      isValid
      description
      descriptionInSpanish
      maxRecurrence
      codeType
    }
  }
`;

export const useGetReferralStatus = createUseQuery<
  GetReferralCodeStatusQuery,
  GetReferralCodeStatusQueryVariables
>(GET_REFERRAL_CODE_STATUS);
