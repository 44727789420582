import { atom, useRecoilState } from "recoil";

type Auth = {
  loading: boolean;
  authed: boolean;
  jwtTokenExpiresIn?: number;
};

export const authAtom = atom<Auth>({
  key: "auth",
  default: {
    loading: true,
    authed: false,
    jwtTokenExpiresIn: undefined,
  },
});

/**
 * Hook that returns the current authed state of the portal
 */
export const useAuth = () => useRecoilState(authAtom);
