import { useGlobalOutageAlert } from "@core/layout/useGlobalOutageAlert";
import { Stack, Typography } from "@mui/material";
import useTranslation from "next-translate/useTranslation";
import { usePathname } from "next/navigation";
import { useGlobalParameterState } from "./globalParameters/useGlobalParametersState";

export const EnrollmentBanner = () => {
  const pathname = usePathname();
  const { t } = useTranslation("enrollment/banner");
  const subDomain = useGlobalParameterState()[0].subDomain || "";
  const [globalOutageAlert] = useGlobalOutageAlert();

  const contentHome = {
    freeecobee: t("ecobeeHome"),
    "oeus-direct-mail-nooff": (
      <>
        {t("directMailNoOfferHome1")} <b>{t("directMailNoOfferHome2")}</b>
      </>
    ),
    "oeus-direct-mail-off": (
      <>
        {t("directMailOfferHome1")} <b>{t("directMailOfferHome2")}</b>
      </>
    ),
    "us-fan-club": (
      <>
        {t("fanClubHome1")} <b>{t("fanClubHome1")}</b>
      </>
    ),
  }[subDomain];

  const contentQuoting = {
    "oeus-direct-mail-nooff": (
      <>
        {t("directMailNoOfferHome1")} <b>{t("directMailNoOfferHome2")}</b>
      </>
    ),
    "oeus-direct-mail-off": (
      <>
        {t("directMailOfferHome1")} <b>{t("directMailOfferHome2")}</b>
      </>
    ),
  }[subDomain];

  const contentPlans = {
    "oeus-direct-mail-nooff": (
      <>
        {t("directMailNoOfferHome1")} <b>{t("directMailNoOfferHome2")}</b>
      </>
    ),
    "oeus-direct-mail-off": (
      <>
        {t("directMailOfferHome1")} <b>{t("directMailOfferHome2")}</b>
      </>
    ),
  }[subDomain];

  const contentEnrollment = {
    freeecobee: (
      <>
        {t("enrollment1")} <b>{t("enrollment2")}</b>
      </>
    ),
    "oeus-direct-mail-nooff": (
      <>
        {t("enrollment1")} <b>{t("enrollment2")}</b>
      </>
    ),
    "oeus-direct-mail-off": (
      <>
        {t("enrollment1")} <b>{t("enrollment2")}</b>
      </>
    ),
    "us-fan-club": (
      <>
        {t("enrollment1")} <b>{t("enrollment2")}</b>
      </>
    ),
  }[subDomain];

  const contentSuccess = {
    freeecobee: t("ecobeeSuccess"),
    "oeus-direct-mail-nooff": t("directMailNoOfferSuccess"),
    "oeus-direct-mail-off": t("directMailOfferSuccess"),
    "us-fan-club": (
      <>
        {t("fanClubSuccess1")} <b>{t("fanClubSuccess1")}</b>
      </>
    ),
  }[subDomain];

  const content =
    (pathname === "/" && contentHome) ||
    (pathname === "/join/enrollment/quote" && contentQuoting) ||
    (pathname === "/join/plans" && contentPlans) ||
    (pathname === "/join/enrollment/[stage]" && contentEnrollment) ||
    (pathname === "/success" && contentSuccess);

  if (!content) {
    return null;
  }

  return (
    <Stack
      sx={{
        bgcolor: "secondary.main",
      }}
      p={2}
      alignItems="center"
      textAlign="center"
      mb={4}
      position="relative"
      top={globalOutageAlert.showAlert ? 8 : -72}
    >
      <Typography>{content}</Typography>
    </Stack>
  );
};
