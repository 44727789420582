import { gql } from "@apollo/client";
import { createUseMutation } from "@octopus-energy/apollo-client";
import {
  ToggleThermostatControlMutation,
  ToggleThermostatControlMutationVariables,
} from "./__generated__/useToggleThermostatControlMutation.generated";

export const MUTATION_TOGGLE_THERMOSTAT_CONTROL = gql`
  mutation ToggleThermostatControl($input: ToggleThermostatControlInput!) {
    toggleThermostatControl(input: $input) {
      thermostat {
        enabled
        id
      }
    }
  }
`;

export const useToggleThermostatControlMutation = createUseMutation<
  ToggleThermostatControlMutation,
  ToggleThermostatControlMutationVariables
>(MUTATION_TOGGLE_THERMOSTAT_CONTROL);
