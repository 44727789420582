import { Dialog, DialogContent, Typography } from "@octopus-energy/coral-mui";
import useTranslation from "next-translate/useTranslation";

export type EnrollmentOutageDialogProps = {
  open: boolean;
  onClose: VoidFunction;
};

export const EnrollmentOutageDialog = ({
  open,
  onClose,
}: EnrollmentOutageDialogProps) => {
  const { t } = useTranslation("enrollment/formFields");
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <DialogContent
        sx={{
          p: 5,
        }}
        data-cy="enrollments-outage"
      >
        <Typography variant="h5" py={1} lineHeight={1.5}>
          {t("enrollmentsUpSoon")}
        </Typography>
      </DialogContent>
    </Dialog>
  );
};
