import { gql } from "@apollo/client";
import { createUseMutation } from "@octopus-energy/apollo-client";
import {
  EnrollmentAccountResetPasswordMutationMutation,
  EnrollmentAccountResetPasswordMutationMutationVariables,
} from "./__generated__/useEnrollmentAccountResetPasswordMutation.generated";

export const MUTATION_ENROLLMENT_ACCOUNT_RESET_PASSWORD = gql`
  mutation EnrollmentAccountResetPasswordMutation(
    $input: EnrollmentAccountResetPasswordInputType!
  ) {
    requestEnrollmentAccountPasswordReset(input: $input) {
      resetStatus
    }
  }
`;

export const useEnrollmentAccountResetPasswordMutation = createUseMutation<
  EnrollmentAccountResetPasswordMutationMutation,
  EnrollmentAccountResetPasswordMutationMutationVariables
>(MUTATION_ENROLLMENT_ACCOUNT_RESET_PASSWORD);
