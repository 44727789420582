import { useRouter } from "next/router";
import { storeAuthToken, useAuth } from ".";
import { useObtainMasqueradeTokenMutation } from "../apiRequests";
import { handleApolloMutationError } from "../error";
import { useSnackbarNotification } from "../molecules";

export const useMasquerade = () => {
  const [notification] = useSnackbarNotification();
  const [, setAuthed] = useAuth();
  const router = useRouter();

  const [obtainMasqueradeToken] = useObtainMasqueradeTokenMutation({
    onCompleted: ({ masqueradeAuthentication }) => {
      if (masqueradeAuthentication === null) {
        notification.error("Error: Invalid Response");
        throw new Error("Invalid Response");
      }
      if (masqueradeAuthentication?.errors) {
        notification.error(
          masqueradeAuthentication?.errors[0]?.messages[0] || "Invalid Response"
        );
        throw new Error(masqueradeAuthentication?.errors[0]?.messages[0]);
      }
      if (masqueradeAuthentication?.token === null) {
        notification.error("Error: No token generated");
        throw new Error("No token generated");
      }

      storeAuthToken(masqueradeAuthentication?.token || "");
      setAuthed({
        authed: true,
        loading: false,
      });

      notification.success("Sign in successful");
      // Timeout here so Ops can see ghost constantine even if it loads quick :D
      setTimeout(() => {
        router.replace("/dashboard");
      }, 500);
    },
    onError: handleApolloMutationError,
  });

  return { obtainMasqueradeToken };
};
