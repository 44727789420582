import { IconWind } from "@krakentech/icons";
import { Stack, Typography } from "@octopus-energy/coral-mui";
import useTranslation from "next-translate/useTranslation";
import { FC } from "react";

export type FullyRenewableProps = {
  mt?: number;
};

export const FullyRenewable: FC<FullyRenewableProps> = ({ mt }) => {
  const { t } = useTranslation("product/productCard");

  return (
    <Stack
      direction="row"
      alignItems="center"
      mt={mt}
      data-cy="fully-renewable"
    >
      <IconWind data-cy="fully-renewable-bullet-image" size={16} />
      <Typography variant="body2" ml={3}>
        <Typography variant="body2" component="span" color="success">
          {t("hundredPercentRenewable")}
        </Typography>{" "}
        {t("electricity")}
      </Typography>
    </Stack>
  );
};
