import { useRouter } from "next/router";
import { useEffect } from "react";
import { gTagPageView } from "./gtagUtils";

/**
 * Hook that tracks google analytics on routing page change
 * @example
 * useGoogleTrackPageView();
 */
export const useGoogleTrackPageView = () => {
  const router = useRouter();

  useEffect(() => {
    router.events.on("routeChangeComplete", gTagPageView);
    return () => {
      router.events.off("routeChangeComplete", gTagPageView);
    };
  }, [router.events]);
};
