import { gql } from "@apollo/client";
import { createUseMutation } from "@octopus-energy/apollo-client";
import {
  ApplyReferralCodeMutation,
  ApplyReferralCodeMutationVariables,
} from "./__generated__/useApplyReferralCodeMutation.generated";

export const MUTATION_APPLY_REFERRAL_CODE = gql`
  mutation ApplyReferralCode($input: ApplyRewardInputType!) {
    applyReferralCode(input: $input) {
      rewardOutput {
        rewardApplied
        rewardDescription
        rewardDescriptionInSpanish
        rewardStatus
      }
    }
  }
`;

export const useApplyReferralCodeMutation = createUseMutation<
  ApplyReferralCodeMutation,
  ApplyReferralCodeMutationVariables
>(MUTATION_APPLY_REFERRAL_CODE);
