import { gql } from "@apollo/client";
import { createUseMutation } from "@octopus-energy/apollo-client";
import {
  GetEmbeddedSecretForNewPaymentInstructionMutation,
  GetEmbeddedSecretForNewPaymentInstructionMutationVariables,
} from "./__generated__/useGetEmbeddedSecretForNewPaymentInstructionMutation.generated";

export const MUTATION_GET_EMBEDDED_SECRET_FOR_NEW_PAYMENT_INSTRUCTION = gql`
  mutation GetEmbeddedSecretForNewPaymentInstruction(
    $input: GetEmbeddedSecretForNewPaymentInstructionInput!
  ) {
    getEmbeddedSecretForNewPaymentInstruction(input: $input) {
      secretKey
    }
  }
`;

export const useGetEmbeddedSecretForNewPaymentInstructionMutation =
  createUseMutation<
    GetEmbeddedSecretForNewPaymentInstructionMutation,
    GetEmbeddedSecretForNewPaymentInstructionMutationVariables
  >(MUTATION_GET_EMBEDDED_SECRET_FOR_NEW_PAYMENT_INSTRUCTION);
