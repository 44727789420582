import { isClientSide } from "@core/utils";
import { useEffect } from "react";
import { atom, useRecoilState } from "recoil";
import { useDebouncedCallback } from "use-debounce";
import { AccountCampaignChoices } from "../graphql-global-types";

const ENROLLMENT_KEY = "enrollmentV2";

type EnrollmentState = { smartDevices: AccountCampaignChoices[] };

const DEFAULT_STATE: EnrollmentState = {
  smartDevices: [],
};

// Returns enrollment state from browser storage or default state values
const getDefaultState = (): EnrollmentState => {
  const browserStorageState = isClientSide()
    ? window.sessionStorage.getItem(ENROLLMENT_KEY)
    : null;
  if (!browserStorageState) {
    return DEFAULT_STATE;
  }
  return JSON.parse(browserStorageState) as EnrollmentState;
};

const enrollmentV2Atom = atom<EnrollmentState>({
  key: ENROLLMENT_KEY,
  default: getDefaultState(),
});

// State hook for accessing + updating enrollment state
export const useEnrollmentV2 = () => {
  const [state, setState] = useRecoilState(enrollmentV2Atom);

  const setProperty =
    <T>(propertyKey: keyof EnrollmentState) =>
    (newValue: T) => {
      setState((prevState) => ({
        ...prevState,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [propertyKey]: newValue as any,
      }));
    };

  return [
    state,
    {
      setSmartDevices: setProperty<AccountCampaignChoices[]>("smartDevices"),
      reset: () => setState(DEFAULT_STATE),
    },
  ] as const;
};

// Hook to sync browser storage with changing enrollment state
export const useSyncEnrollmentStateWithBrowserStorage = () => {
  const [state] = useRecoilState(enrollmentV2Atom);
  const stringifiedState = JSON.stringify(state);

  const setEnrollmentStateInBrowserStorage = useDebouncedCallback(() => {
    if (isClientSide()) {
      window.sessionStorage.setItem(ENROLLMENT_KEY, stringifiedState);
    }
  }, 1000);

  useEffect(() => {
    setEnrollmentStateInBrowserStorage();
  }, [stringifiedState]);
};
