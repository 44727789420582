import { gql } from "@apollo/client";
import { createUseMutation } from "@octopus-energy/apollo-client";
import {
  AcquireOauthTokensMutation,
  AcquireOauthTokensMutationVariables,
} from "./__generated__/useAcquireOauthTokensMutation.generated";

export const MUTATION_ACQUIRE_OAUTH_TOKENS = gql`
  mutation AcquireOauthTokens($input: AcquireOauthTokensInput!) {
    acquireOauthTokens(input: $input) {
      tokensAcquired
    }
  }
`;

export const useAcquireOauthTokensMutation = createUseMutation<
  AcquireOauthTokensMutation,
  AcquireOauthTokensMutationVariables
>(MUTATION_ACQUIRE_OAUTH_TOKENS);
