import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import useTranslation from "next-translate/useTranslation";
import { TextField, TextFieldProps } from "@octopus-energy/coral-mui";
import StripeInput from "./StripeInput";

export type StripeElement =
  | typeof CardCvcElement
  | typeof CardExpiryElement
  | typeof CardNumberElement;

type StripeTextFieldProps<T extends StripeElement> = Omit<
  TextFieldProps,
  "onChange" | "inputComponent" | "inputProps"
> & {
  inputProps?: React.ComponentProps<T>;
  labelErrorMessage?: string;
  onChange?: React.ComponentProps<T>["onChange"];
  stripeElement?: T;
};

const stripeTextDefaultProps = {
  inputProps: {},
  labelErrorMessage: "",
  onChange: () => {},
  stripeElement: null,
};

export const StripeTextField = <T extends StripeElement>(
  props: StripeTextFieldProps<T>
): JSX.Element => {
  const {
    helperText,
    InputLabelProps,
    InputProps = {},
    inputProps,
    error,
    labelErrorMessage,
    stripeElement,
    ...other
  } = props;

  const { t } = useTranslation("enrollment/formFields");

  return (
    <TextField
      fullWidth
      InputLabelProps={{
        ...InputLabelProps,
        shrink: true,
      }}
      error={error}
      InputProps={{
        ...InputProps,
        // @ts-ignore
        inputProps: {
          ...inputProps,
          ...InputProps.inputProps,
          component: stripeElement,
        },
        inputComponent: StripeInput,
      }}
      helperText={error ? t(labelErrorMessage ?? "") : " "}
      {...other}
    />
  );
};

StripeTextField.defaultProps = stripeTextDefaultProps;

export const StripeTextFieldNumber = (
  props: StripeTextFieldProps<typeof CardNumberElement>
): JSX.Element => {
  const { t } = useTranslation("enrollment/formFields");
  return (
    <StripeTextField
      {...props}
      label={t("payment_creditcard")}
      stripeElement={CardNumberElement}
      labelErrorMessage="validation_creditCardNotValid"
    />
  );
};

StripeTextFieldNumber.defaultProps = stripeTextDefaultProps;

export const StripeTextFieldExpiry = (
  props: StripeTextFieldProps<typeof CardExpiryElement>
): JSX.Element => {
  const { t } = useTranslation("enrollment/formFields");
  return (
    <StripeTextField
      {...props}
      label={t("payment_expiration")}
      stripeElement={CardExpiryElement}
      labelErrorMessage="validation_expiryNotValid"
    />
  );
};

StripeTextFieldExpiry.defaultProps = stripeTextDefaultProps;

export const StripeTextFieldCVC = (
  props: StripeTextFieldProps<typeof CardCvcElement>
): JSX.Element => {
  const { t } = useTranslation("enrollment/formFields");
  return (
    <StripeTextField
      {...props}
      label={t("payment_securityCode")}
      stripeElement={CardCvcElement}
      labelErrorMessage="validation_cvvNotValid"
    />
  );
};

StripeTextFieldCVC.defaultProps = stripeTextDefaultProps;
