import { gql } from "@apollo/client";
import { createUseMutation } from "@octopus-energy/apollo-client";
import {
  MasqueradeAuthenticationMutation,
  MasqueradeAuthenticationMutationVariables,
} from "./__generated__/useObtainMasqueradeTokenMutation.generated";

export const MUTATION_OBTAIN_MASQUERADE_TOKEN = gql`
  mutation masqueradeAuthentication(
    $masqueradeToken: String!
    $userId: String!
  ) {
    masqueradeAuthentication(
      masqueradeToken: $masqueradeToken
      userId: $userId
    ) {
      token
      errors {
        field
        messages
      }
    }
  }
`;

export const useObtainMasqueradeTokenMutation = createUseMutation<
  MasqueradeAuthenticationMutation,
  MasqueradeAuthenticationMutationVariables
>(MUTATION_OBTAIN_MASQUERADE_TOKEN);
