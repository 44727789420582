import { gql } from "@apollo/client";
import { createUseMutation } from "@octopus-energy/apollo-client";
import {
  RecordDepositAgreementAcceptedMutation,
  RecordDepositAgreementAcceptedMutationVariables,
} from "./__generated__/useRecordDepositAgreementAccepted.generated";

export const MUTATION_RECORD_DEPOSIT_AGREEMENT_ACCEPTED = gql`
  mutation recordDepositAgreementAccepted($input: DepositAgreementInput!) {
    recordDepositAgreementAccepted(input: $input) {
      isRecorded
    }
  }
`;

export const useRecordDepositAgreementAcceptedMutation = createUseMutation<
  RecordDepositAgreementAcceptedMutation,
  RecordDepositAgreementAcceptedMutationVariables
>(MUTATION_RECORD_DEPOSIT_AGREEMENT_ACCEPTED);
