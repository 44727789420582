import { gql } from "@apollo/client";
import { createUseMutation } from "@octopus-energy/apollo-client";
import {
  UpdateMetaDataMutationMutation,
  UpdateMetaDataMutationMutationVariables,
} from "./__generated__/useCreateMetaDataMutation.generated";

export const MUTATION_CREATE_META_DATA = gql`
  mutation UpdateMetaDataMutation($input: MetadataInput!) {
    updateMetadata(input: $input) {
      metadata {
        key
        value
      }
    }
  }
`;

export const useCreateMetaDataMutation = createUseMutation<
  UpdateMetaDataMutationMutation,
  UpdateMetaDataMutationMutationVariables
>(MUTATION_CREATE_META_DATA);
