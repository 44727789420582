import { getClientLocation } from "@core/apiRequests";
import { handleError } from "@core/error";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { getLocationPreferences } from "./localizationUtils";
import { useLocation } from "./useLocation";
import { REGION_URLS } from "./regionData";

/**
 * Root hook for finding the clients location, will set in atom, for use throughout app.
 */

export const useCheckLocation = () => {
  const [location, setLocation] = useLocation();
  const router = useRouter();

  const init = () => {
    if (!location.responseCache) {
      const userPrefs = getLocationPreferences();

      if (userPrefs) {
        const { userRequestedRedirect, regionToRedirectTo } = userPrefs;

        // Redirect user to their requested region
        if (userRequestedRedirect && regionToRedirectTo) {
          router.push(REGION_URLS[regionToRedirectTo]);
          return;
        }
      }
      getClientLocation()
        .then((response) => {
          const { ip, country } = response.data;
          setLocation({
            ...location,
            ...userPrefs,
            ipAddress: ip,
            clientCountry: country,
          });
        })
        .catch((e) => {
          handleError(e);
        });
    }
  };

  useEffect(() => {
    init();
  }, []);
};
