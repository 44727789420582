import { gql } from "@apollo/client";
import { createUseQuery } from "@octopus-energy/apollo-client";
import {
  IsAutopayEnabledQuery,
  IsAutopayEnabledQueryVariables,
} from "./__generated__/useQueryIsAutoPayEnabled.generated";

export const QUERY_IS_AUTO_PAY_ENABLED = gql`
  query isAutopayEnabled(
    $accountNumber: String!
    $considerFutureSchedule: Boolean
  ) {
    isAutopayEnabled(
      accountNumber: $accountNumber
      considerFutureSchedule: $considerFutureSchedule
    ) {
      isEnabled
    }
  }
`;

export const useQueryIsAutoPayEnabled = createUseQuery<
  IsAutopayEnabledQuery,
  IsAutopayEnabledQueryVariables
>(QUERY_IS_AUTO_PAY_ENABLED);
