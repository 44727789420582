import { createUseMutation } from "@octopus-energy/apollo-client";
import { gql } from "@apollo/client";
import {
  CreateQuoteMutation,
  CreateQuoteMutationVariables,
} from "./__generated__/useCreateQuoteMutation.generated";

export const MUTATION_CREATE_QUOTE = gql`
  mutation createQuote($input: CreateQuoteInput!) {
    createQuote(input: $input) {
      quote {
        quoteId
        code
        quotedProducts {
          product {
            code
            displayName
            description
            fullName
            id
            term
            efls {
              id
              url
              version
              loadZone
              serviceProvider
              language
            }
          }
          estimatedDetails {
            kwhUsage
            dayPercentage
            nightPercentage
            usagePeriod
            monthlyCostEstimate
            annualCostEstimate
            applicableRates {
              totalApplicableRate
              totalApplicableRateWithMonthlySubscriptionFees
              kwhUsage
              serviceProvider
              serviceProviderConsumptionRate
              serviceProviderStandingRate
              loadZone
              totalApplicableDayRate
              totalApplicableNightRate
              totalApplicableDayRateWithMonthlySubscriptionFees
              totalApplicableNightRateWithMonthlySubscriptionFees
              loadZoneDayTimeConsumptionRate
              loadZoneNightTimeConsumptionRate
              loadZoneConsumptionRate
              serviceProviderStandingRateUnitType
              serviceProviderConsumptionRateUnitType
              loadZoneDayTimeRateUnitType
              loadZoneNightTimeRateUnitType
              loadZoneConsumptionRateUnitType
              evDiscountRate
              thermostatDiscountRate
              totalApplicableRateWithEv
              totalApplicableRateWithThermostat
              totalApplicableRateWithMonthlySubscriptionFeesEv
              totalApplicableRateWithMonthlySubscriptionFeesThermostat
              __typename
            }
          }
          expiresAt
          loadZone
          serviceProvider
        }
      }
    }
  }
`;

export const useCreateQuoteMutation = createUseMutation<
  CreateQuoteMutation,
  CreateQuoteMutationVariables
>(MUTATION_CREATE_QUOTE);
