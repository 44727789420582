import { PaymentMethods } from "@core/apiRequests/graphql-global-types";
import { BillingAddress } from "@core/enrollment/finalize/BillingAddress";
import { CreditCardDetailEnrollment } from "@core/enrollment/finalize/payment/CreditCardDetailEnrollment";
import { handleError } from "@core/error";
import { useFeatureFlag } from "@core/featureFlags";
import { useCreateQuote } from "@core/krakenHelpers/useCreateQuote";
import { useStripeError } from "@core/stripe";
import { styled } from "@mui/material/styles";
import {
  AutoTopUp,
  Box,
  Checkbox,
  Collapse,
  Container,
  FormControlLabel,
  FormGroup,
  Stack,
  TextFieldProps,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@octopus-energy/coral-mui";
import { AnimatePresence, motion } from "framer-motion";
import useTranslation from "next-translate/useTranslation";
import Head from "next/head";
import { InputHTMLAttributes, useEffect, useState } from "react";
import { useBooleanState } from "react-use-object-state";
import {
  CreditCheckStatus,
  useEnrollment,
  useSelectedEnrollmentProduct,
  useSelectedEnrollmentProductIsPrepay,
} from "..";
import { isPrepayProduct, ProductSelectedCard } from "../../product";
import { useSetGlobalParametersState } from "../globalParameters";
import { useGlobalParameterState } from "../globalParameters/useGlobalParametersState";
import { AutopaySelection } from "./AutopaySelection";
import { CreditCheckFailureAlert } from "./CreditCheckFailureAlert";
import CustomerDetailsSummary from "./CustomerDetailsSummary";
import { DifferentBillingCheckbox } from "./DifferentBillingCheckbox";
import { PasswordAndAgreementsForm } from "./PasswordAndAgreementsForm";
import { BankAccountPaymentMethod } from "./payment/BankAccountPaymentMethod";
import { useFinalizeForm } from "./useFinalizeForm";
import { useSubmitFinalize } from "./useSubmitFinalize";

import { useDeviceBreakpoint } from "@core/utils/useDeviceBreakpoint";
import Paper from "@mui/material/Paper";
import { toggleButtonGroupClasses } from "@mui/material/ToggleButtonGroup";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    margin: theme.spacing(0.5),
    border: 0,
    borderRadius: 8,
    [`&.${toggleButtonGroupClasses.disabled}`]: {
      border: 0,
    },
  },
}));

export const Finalize = () => {
  const { isMobile } = useDeviceBreakpoint();
  const submitting = useBooleanState(false);
  const enrollment = useEnrollment();
  const { t } = useTranslation("enrollment/finalize");
  const { t: prepayTranslation } = useTranslation("prepay");
  const [{ navigatorState }] = useGlobalParameterState();
  const setParameter = useSetGlobalParametersState();
  const [createQuote] = useCreateQuote();
  const quoteHasChanged = useBooleanState(false);
  const skipPayment = useBooleanState(false);
  const { stripeErrorMessage, setStripeError } = useStripeError();
  const selectedProduct = useSelectedEnrollmentProduct();
  const productIsPrepay = useSelectedEnrollmentProductIsPrepay();
  const showCreditCheckFailureAlert =
    !productIsPrepay &&
    (enrollment.creditCheckStatus === CreditCheckStatus.ERROR ||
      enrollment.creditCheckStatus === CreditCheckStatus.FAIL);
  const { active: financialConnectionsActive } = useFeatureFlag(
    "stripeFinancialConnections"
  );
  const showPaymentOptionsTabs =
    financialConnectionsActive &&
    !isPrepayProduct(enrollment.selectedPlanID) &&
    enrollment.creditCheckStatus === CreditCheckStatus.PASS;

  const [paymentMethod, setPaymentMethod] = useState<PaymentMethods>(
    showPaymentOptionsTabs
      ? PaymentMethods.DirectDebit
      : PaymentMethods.CreditCard
  );

  const handleSubmit = useSubmitFinalize({
    onChangeSubmitting: submitting.setState,
    onStripeError: setStripeError,
  });

  const formik = useFinalizeForm({
    onSubmit: handleSubmit,
  });

  const selectedEnrollmentProduct = useSelectedEnrollmentProduct();
  const textFieldProps: TextFieldProps = {
    onChange: formik.handleChange,
    onBlur: formik.handleBlur,
  };

  const updateBillingInfo = () => {
    if (formik.values.differentBilling) {
      formik.values.billing_firstName =
        enrollment.formData.getReady?.firstName || "";
      formik.values.billing_lastName =
        enrollment.formData.getReady?.lastName || "";
      formik.values.billing_Address =
        enrollment.formData.getReady?.service_Address || "";
      formik.values.billing_AptSuite =
        enrollment.formData.getReady?.service_AptSuite || "";
      formik.values.billing_City =
        enrollment.formData.getReady?.service_City || "";
      formik.values.billing_State =
        enrollment.formData.getReady?.service_State || "";
      formik.values.billing_ZipCode =
        enrollment.formData.getReady?.service_ZipCode || "";
    } else {
      formik.values.billing_firstName = "";
      formik.values.billing_lastName = "";
      formik.values.billing_Address = "";
      formik.values.billing_AptSuite = "";
      formik.values.billing_City = "";
      formik.values.billing_State = "";
      formik.values.billing_ZipCode = "";
    }
  };

  useEffect(() => {
    if (
      !enrollment.customerHasEnteredAddressEarly &&
      enrollment.zipCodeHasMultipleTdspsOrLoadZones
    ) {
      try {
        // Create new quote
        createQuote({
          postcode: enrollment.formData.getReady?.service_ZipCode,
          esiId: enrollment.formData.getReady?.esiId,
          kwhConsumption: enrollment.monthlyKwhUsage,
        }).then(() => {
          quoteHasChanged.setTrue();
        });
      } catch (e) {
        handleError(e);
      }
    }
  }, [
    enrollment.customerHasEnteredAddressEarly,
    enrollment.zipCodeHasMultipleTdspsOrLoadZones,
  ]);

  const paymentOptions = [
    {
      label: "Bank account",
      value: PaymentMethods.DirectDebit,
      testValue: "bank-account",
    },
    {
      label: "Credit card",
      value: PaymentMethods.CreditCard,
      testValue: "credit-card",
    },
  ];

  return (
    <Container maxWidth="lg">
      <Head>
        <title>{t("reviewYourOrder")} | Octopus Energy</title>
      </Head>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit(e);
        }}
      >
        <AnimatePresence>
          <motion.div
            initial="hidden"
            animate="show"
            exit="hidden"
            variants={{
              hidden: {
                opacity: 0,
              },
              show: {
                opacity: 1,
              },
            }}
            className="w-full flex flex-col justify-center"
            key="main"
          >
            <Typography variant="h3" component="h1" my={3}>
              {t("reviewYourOrder")}
            </Typography>
            {selectedEnrollmentProduct && (
              <ProductSelectedCard
                displayName={selectedEnrollmentProduct.displayName}
                withEV={enrollment.withEV}
                withThermostat={enrollment.withThermostat}
                quoteHasChanged={quoteHasChanged.state}
                serviceProviders={selectedProduct?.serviceProviders || []}
              />
            )}
            <CustomerDetailsSummary />
            {showCreditCheckFailureAlert && <CreditCheckFailureAlert />}

            {/* Navigator skip payment */}
            {navigatorState && !productIsPrepay && (
              <FormGroup sx={{ mt: 4 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={skipPayment.state}
                      onChange={() => {
                        setParameter({
                          navigatorState: navigatorState,
                          skipPayment: !skipPayment.state,
                        });
                        skipPayment.toggle();
                      }}
                      inputProps={
                        {
                          "data-cy": "navigator-skip-payment-checkbox",
                        } as InputHTMLAttributes<HTMLInputElement>
                      }
                      data-cy="navigator-skip-payment"
                    />
                  }
                  label={
                    <>
                      <Typography
                        variant="body1"
                        data-cy="navigator-skip-payment-label"
                      >
                        {t("navigatorSkipPayment")}
                      </Typography>
                      {skipPayment.state && (
                        <motion.div
                          initial="hidden"
                          animate="show"
                          exit="hidden"
                          variants={{
                            hidden: {
                              opacity: 0,
                              top: -30,
                            },
                            show: {
                              opacity: 1,
                              top: 0,
                            },
                          }}
                        >
                          <Typography
                            variant="body2"
                            color="tertiary"
                            mt={1}
                            sx={{
                              display: "flex",
                              width: { sm: "70%", xs: "100%" },
                            }}
                          >
                            <Typography
                              component="span"
                              variant="body2"
                              color="white"
                            >
                              *
                            </Typography>
                            {t("navigatorSkipPaymentDisclaimer")}
                          </Typography>
                        </motion.div>
                      )}
                    </>
                  }
                />
              </FormGroup>
            )}
            <Collapse in={!skipPayment.state} unmountOnExit>
              <Typography variant="h3" mt={6}>
                {t("addPaymentDetails")}
              </Typography>
              <Stack mt={2} spacing={2}>
                {showPaymentOptionsTabs ? (
                  <>
                    <Paper
                      elevation={0}
                      sx={(theme) => ({
                        display: "flex",
                        border: `3px solid ${theme.palette.secondary.main}`,
                        flexWrap: "wrap",
                        background: `${theme.palette.background.default}`,
                        width: "fit-content",
                      })}
                    >
                      <StyledToggleButtonGroup
                        onChange={(e, newValue) => setPaymentMethod(newValue)}
                        data-cy="payment-option-group"
                        value={paymentMethod}
                      >
                        {paymentOptions.map(({ value, label, testValue }) => (
                          <ToggleButton
                            data-cy={`payment-option-${testValue}`}
                            value={value}
                            sx={{
                              py: 2,
                              px: isMobile ? 1 : 2,
                              "&.MuiToggleButtonGroup-grouped": {
                                borderRadius: "12px !important",
                              },
                            }}
                          >
                            <Typography noWrap variant="h5">
                              {label}
                            </Typography>
                          </ToggleButton>
                        ))}
                      </StyledToggleButtonGroup>
                    </Paper>
                    {paymentMethod === PaymentMethods.CreditCard ? (
                      <CreditCardDetailEnrollment formik={formik} />
                    ) : (
                      <BankAccountPaymentMethod />
                    )}
                  </>
                ) : (
                  <CreditCardDetailEnrollment formik={formik} />
                )}
              </Stack>
              {paymentMethod === PaymentMethods.CreditCard && (
                <>
                  <Box mt={2}>
                    <DifferentBillingCheckbox
                      checkboxProps={{
                        onClick: updateBillingInfo,
                        checked: !formik.values.differentBilling,
                        onChange: () =>
                          formik.setFieldValue(
                            "differentBilling",
                            !formik.values.differentBilling
                          ),
                        onBlur: formik.handleBlur,
                        value: formik.values.differentBilling,
                      }}
                    />
                  </Box>
                  <Collapse
                    in={formik.values.differentBilling}
                    data-cy="different-billing-address-collapse"
                  >
                    <BillingAddress
                      firstNameTextFieldProps={{
                        name: "billing_firstName",
                        value: formik.values.billing_firstName,
                        error: Boolean(
                          formik.errors.billing_firstName &&
                            formik.touched.billing_firstName
                        ),
                        helperText:
                          formik.errors.billing_firstName &&
                          formik.touched.billing_firstName
                            ? t(formik.errors.billing_firstName)
                            : " ",
                        ...textFieldProps,
                      }}
                      lastNameTextFieldProps={{
                        name: "billing_lastName",
                        value: formik.values.billing_lastName,
                        error: Boolean(
                          formik.errors.billing_lastName &&
                            formik.touched.billing_lastName
                        ),
                        helperText:
                          formik.errors.billing_lastName &&
                          formik.touched.billing_lastName
                            ? t(formik.errors.billing_lastName)
                            : " ",
                        ...textFieldProps,
                      }}
                      billingAddressTextFieldProps={{
                        name: "billing_Address",
                        value: formik.values.billing_Address,
                        error: Boolean(
                          formik.errors.billing_Address &&
                            formik.touched.billing_Address
                        ),
                        helperText:
                          formik.errors.billing_Address &&
                          formik.touched.billing_Address
                            ? t(formik.errors.billing_Address)
                            : " ",
                        ...textFieldProps,
                      }}
                      billingApartmentSuiteTextFieldProps={{
                        name: "billing_AptSuite",
                        value: formik.values.billing_AptSuite,
                        error: Boolean(
                          formik.errors.billing_AptSuite &&
                            formik.touched.billing_AptSuite
                        ),
                        ...textFieldProps,
                      }}
                      billingCityTextFieldProps={{
                        name: "billing_City",
                        value: formik.values.billing_City,
                        error: Boolean(
                          formik.errors.billing_City &&
                            formik.touched.billing_City
                        ),
                        helperText:
                          formik.errors.billing_City &&
                          formik.touched.billing_City
                            ? t(formik.errors.billing_City)
                            : " ",
                        ...textFieldProps,
                      }}
                      billingStateTextFieldProps={{
                        name: "billing_State",
                        value: formik.values.billing_State,
                        error: Boolean(
                          formik.errors.billing_State &&
                            formik.touched.billing_State
                        ),
                        helperText:
                          formik.errors.billing_State &&
                          formik.touched.billing_State
                            ? t(formik.errors.billing_State)
                            : " ",
                        ...textFieldProps,
                      }}
                      billingZipCodeTextFieldProps={{
                        name: "billing_ZipCode",
                        value: formik.values.billing_ZipCode,
                        error: Boolean(
                          formik.errors.billing_ZipCode &&
                            formik.touched.billing_ZipCode
                        ),
                        helperText:
                          formik.errors.billing_ZipCode &&
                          formik.touched.billing_ZipCode
                            ? t(formik.errors.billing_ZipCode)
                            : " ",
                        ...textFieldProps,
                      }}
                    />
                  </Collapse>
                </>
              )}
              {productIsPrepay && (
                <Box mt={4}>
                  <AutoTopUp
                    title={prepayTranslation("autoTopUpAmount")}
                    description={prepayTranslation("changeAtAnyTime")}
                    amount={formik.values.autoTopUpAmount}
                    onChange={formik.handleChange}
                    errorMessage={
                      formik.errors.autoTopUpAmount as string | undefined
                    }
                  />
                </Box>
              )}
              {!productIsPrepay && (
                <>
                  <Typography variant="h4" mt={4} mb={2}>
                    {t("autopaySettings")}
                  </Typography>
                  <AutopaySelection
                    autopay={formik.values.autopay}
                    onBlur={formik.handleBlur}
                    onChange={() => {
                      formik.setFieldValue("autopay", !formik.values.autopay);
                    }}
                  />
                </>
              )}
            </Collapse>
            <Box mt={4} data-cy="password-and-agreements">
              <PasswordAndAgreementsForm
                formik={formik}
                submitting={submitting.state}
              />
            </Box>
            {stripeErrorMessage && (
              <Typography
                data-cy="stripe-error"
                mt={2}
                color="error"
                variant="body1"
              >
                {stripeErrorMessage}
              </Typography>
            )}
          </motion.div>
        </AnimatePresence>
      </form>
    </Container>
  );
};
