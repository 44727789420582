import { gql } from "@apollo/client";
import { createUseMutation } from "@octopus-energy/apollo-client";
import {
  RunCreditCheckMutation,
  RunCreditCheckMutationVariables,
} from "./__generated__/useRunCreditCheckMutation.generated";

export const MUTATION_RUN_CREDIT_CHECK = gql`
  mutation RunCreditCheck($input: RunCreditCheckInput!) {
    runCreditCheck(input: $input) {
      id
      possibleErrors {
        code
      }
      passedCreditCheck
    }
  }
`;

export const useRunCreditCheckMutation = createUseMutation<
  RunCreditCheckMutation,
  RunCreditCheckMutationVariables
>(MUTATION_RUN_CREDIT_CHECK);
