var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));

// src/icons/Circle.tsx
import { SvgIcon } from "@mui/material";
import { jsx, jsxs } from "react/jsx-runtime";
var Circle = (props) => {
  return /* @__PURE__ */ jsxs(SvgIcon, __spreadProps(__spreadValues({}, props), { viewBox: "0 0 24 24", fill: "none", children: [
    /* @__PURE__ */ jsx("path", { d: "M24 24H0V0h24v24z", fill: "none" }),
    /* @__PURE__ */ jsx("circle", { cx: "12", cy: "12", r: "8" })
  ] }));
};

// src/icons/ConstantineSolid.tsx
import { SvgIcon as SvgIcon2 } from "@mui/material";
import { jsx as jsx2 } from "react/jsx-runtime";
var ConstantineSolid = (props) => {
  return /* @__PURE__ */ jsx2(SvgIcon2, __spreadProps(__spreadValues({}, props), { viewBox: "0 0 21 27", fill: "none", children: /* @__PURE__ */ jsx2("path", { d: "M18.2893 18.7208C18.5904 17.1275 20.6976 14.8544 20.9618 11.7298C21.3058 7.62995 19.4504 0.350525 10.3949 0.350525C1.33929 0.350525 0 7.87031 0 11.0087C0 15.2047 2.75845 17.3748 2.62944 18.865C2.64172 18.8718 2.65401 18.8856 2.6663 18.8925C2.73388 20.5063 1.21642 20.6024 1.30243 21.5982C1.37001 22.3742 3.33594 22.5665 4.69366 21.7424C4.47864 22.2025 4.17761 22.6214 3.80285 22.8549C3.10249 23.2876 2.53728 23.5005 2.53728 23.9812C2.53728 24.4619 4.63837 25.6843 6.79475 24.4962C7.43368 24.146 7.89445 23.7683 8.24463 23.3975C8.25692 23.4799 8.26306 23.5554 8.27535 23.6241C8.37979 24.3108 8.73611 26.2268 10.2904 26.2268C11.8447 26.2268 12.1581 24.5512 12.4161 23.5554C12.4345 23.473 12.4591 23.3906 12.4775 23.3082C12.8277 23.7134 13.3192 24.1254 14.0134 24.51C16.1698 25.698 18.2709 24.4756 18.2709 23.9949C18.2709 23.5142 17.7057 23.3082 17.0053 22.8687C16.606 22.6214 16.2927 22.1751 16.0776 21.6806C17.4354 22.6008 19.5242 22.4223 19.5917 21.6188C19.6778 20.6024 18.0866 20.5269 18.234 18.7963C18.2463 18.7551 18.2647 18.7414 18.2893 18.7208Z" }) }));
};

// src/icons/TriangleRulerIcon.tsx
import { SvgIcon as SvgIcon3 } from "@mui/material";
import { jsx as jsx3, jsxs as jsxs2 } from "react/jsx-runtime";
var TriangleRulerIcon = ({
  dataCy,
  width = 17,
  height = 17
}) => {
  return /* @__PURE__ */ jsxs2(SvgIcon3, { "data-cy": dataCy, width, height, viewBox: "0 0 17 17", children: [
    /* @__PURE__ */ jsx3(
      "path",
      {
        d: "M0 0V17H17L0 0ZM3.21744 7.73111L9.56201 14.074H3.21744V7.73111Z",
        fill: "#FFBAFF"
      }
    ),
    /* @__PURE__ */ jsx3("path", { d: "M1.68822 15.8711H0V16.1836H1.68822V15.8711Z", fill: "#FA98FF" }),
    /* @__PURE__ */ jsx3("path", { d: "M1.68822 14.6953H0V15.0079H1.68822V14.6953Z", fill: "#FA98FF" }),
    /* @__PURE__ */ jsx3("path", { d: "M1.68822 13.5195H0V13.8321H1.68822V13.5195Z", fill: "#FA98FF" }),
    /* @__PURE__ */ jsx3("path", { d: "M1.68822 12.3398H0V12.6524H1.68822V12.3398Z", fill: "#FA98FF" }),
    /* @__PURE__ */ jsx3("path", { d: "M1.68822 11.1602H0V11.4727H1.68822V11.1602Z", fill: "#FA98FF" }),
    /* @__PURE__ */ jsx3("path", { d: "M1.68822 9.98438H0V10.2969H1.68822V9.98438Z", fill: "#FA98FF" }),
    /* @__PURE__ */ jsx3("path", { d: "M1.68822 8.80859H0V9.12114H1.68822V8.80859Z", fill: "#FA98FF" }),
    /* @__PURE__ */ jsx3("path", { d: "M1.68822 7.62891H0V7.94145H1.68822V7.62891Z", fill: "#FA98FF" }),
    /* @__PURE__ */ jsx3("path", { d: "M1.68822 6.44922H0V6.76176H1.68822V6.44922Z", fill: "#FA98FF" }),
    /* @__PURE__ */ jsx3("path", { d: "M1.68822 5.27344H0V5.58598H1.68822V5.27344Z", fill: "#FA98FF" }),
    /* @__PURE__ */ jsx3("path", { d: "M1.68822 4.09375H0V4.40629H1.68822V4.09375Z", fill: "#FA98FF" }),
    /* @__PURE__ */ jsx3("path", { d: "M1.68822 2.91797H0V3.23051H1.68822V2.91797Z", fill: "#FA98FF" })
  ] });
};

// src/useIconColor.ts
import { useTheme } from "@mui/material";
var useIconColor = (color) => {
  const { palette } = useTheme();
  if (color === "primary" || color === "secondary" || color === "tertiary" || color === "error" || color === "warning" || color === "info" || color === "success") {
    return palette[color].main;
  }
  if (color === "textPrimary") {
    return palette.text.primary;
  }
  return "currentColor";
};
export {
  Circle,
  ConstantineSolid,
  TriangleRulerIcon,
  useIconColor
};
