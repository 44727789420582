import { APPLE_APP_STORE_LINK, GOOGLE_PLAY_LINK } from "@core/globalConstants";
import { AppBanner as CoralAppBanner } from "@krakentech/coral-molecules";
import { styled } from "@mui/material/styles";
import { Box } from "@octopus-energy/coral-mui";
import useTranslation from "next-translate/useTranslation";
import { useEffect, useState } from "react";

const AlignmentWrapper = styled(Box)(() => ({
  marginBottom: "2rem",
}));

export const AppBanner = () => {
  const [open, setOpen] = useState(false);
  const [href, setHref] = useState("");
  const { t } = useTranslation("common");

  useEffect(() => {
    let isMobile = false;
    let previouslyDismissed = false;

    if (navigator.userAgent.match(/Android/i)) {
      setHref(GOOGLE_PLAY_LINK);
      isMobile = true;
    } else if (navigator.userAgent.match(/iPhone/i)) {
      setHref(APPLE_APP_STORE_LINK);
      isMobile = true;
    }

    const previouslyDismissedCookie = document.cookie.replace(
      /(?:(?:^|.*;\s*)octopus-app-banner-dismissed\s*=\s*([^;]*).*$)|^.*$/,
      "$1"
    );
    if (previouslyDismissedCookie !== "") {
      previouslyDismissed = true;
    }

    if (isMobile && !previouslyDismissed) {
      setOpen(true);
    }
  }, []);

  const onClose = () => {
    setOpen(false);
    const expiryDate = new Date();
    expiryDate.setDate(expiryDate.getDate() + 90);
    document.cookie = `octopus-app-banner-dismissed=true; expires=${expiryDate}; path=/`;
  };
  return (
    <AlignmentWrapper>
      <CoralAppBanner open={open} onClose={onClose}>
        <CoralAppBanner.Body
          title={t("octopusEnergyApp")}
          content={t("downloadApp")}
        />
        <CoralAppBanner.Button title={t("view")} href={href} />
      </CoralAppBanner>
    </AlignmentWrapper>
  );
};
