import { gql } from "@apollo/client";
import {
  createUseQuery,
  createUseQueryFunction,
} from "@octopus-energy/apollo-client";
import {
  IsValidSubdomainQueryQuery,
  IsValidSubdomainQueryQueryVariables,
} from "./__generated__/useIsValidSubdomain.generated";

export const IS_VALID_SUBDOMAIN_QUERY = gql`
  query isValidSubdomainQuery($subdomain: String!) {
    isValidSubdomain(subdomain: $subdomain) {
      isValid
    }
  }
`;

export const useIsValidSubdomain = createUseQuery<
  IsValidSubdomainQueryQuery,
  IsValidSubdomainQueryQueryVariables
>(IS_VALID_SUBDOMAIN_QUERY);

export const useIsValidSubdomainFunction = createUseQueryFunction<
  IsValidSubdomainQueryQuery,
  IsValidSubdomainQueryQueryVariables
>(IS_VALID_SUBDOMAIN_QUERY);
