import { gql } from "@apollo/client";
import { createUseQuery } from "@octopus-energy/apollo-client";
import {
  GetDevicesQuery,
  GetDevicesQueryVariables,
} from "./__generated__/useQueryGetDevices.generated";

export const QUERY_GET_DEVICES = gql`
  query GetDevices(
    $accountNumber: String!
    $propertyId: ID
    $deviceId: String
  ) {
    devices(
      accountNumber: $accountNumber
      propertyId: $propertyId
      deviceId: $deviceId
    ) {
      ... on SmartFlexThermostat {
        id
        name
        deviceType
        provider
        make
        status {
          current
          isSuspended
          currentState
          ... on SmartFlexThermostatStatus {
            mode
            currentTemperature {
              value
              scale
            }
            targetCoolTemperature {
              value
              scale
            }
            targetHeatTemperature {
              value
              scale
            }
            timestamp
          }
        }
      }
    }
  }
`;

export const useQueryGetDevices = createUseQuery<
  GetDevicesQuery,
  GetDevicesQueryVariables
>(QUERY_GET_DEVICES);
