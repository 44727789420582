import { gql } from "@apollo/client";
import { createUseMutation } from "@octopus-energy/apollo-client";
import {
  GetEmbeddedSecretForNewPaymentInstructionWithoutAccountMutation,
  GetEmbeddedSecretForNewPaymentInstructionWithoutAccountMutationVariables,
} from "./__generated__/useGetEmbeddedSecretForNewPaymentInstructionWithoutAccount.generated";

export const MUTATION_GET_EMBEDDED_SECRET_FOR_NEW_PAYMENT_INSTRUCTION_WITHOUT_ACCOUNT = gql`
  mutation GetEmbeddedSecretForNewPaymentInstructionWithoutAccount(
    $input: GetEmbeddedSecretForNewPaymentInstructionWithoutAccountInput!
  ) {
    getEmbeddedSecretForNewPaymentInstructionWithoutAccount(input: $input) {
      secretKey
    }
  }
`;

export const useGetEmbeddedSecretForNewPaymentInstructionWithoutAccountMutation =
  createUseMutation<
    GetEmbeddedSecretForNewPaymentInstructionWithoutAccountMutation,
    GetEmbeddedSecretForNewPaymentInstructionWithoutAccountMutationVariables
  >(MUTATION_GET_EMBEDDED_SECRET_FOR_NEW_PAYMENT_INSTRUCTION_WITHOUT_ACCOUNT);
