import { alpha, useTheme } from "@mui/material";
import { InputBaseComponentProps } from "@mui/material/InputBase";
import { forwardRef, useImperativeHandle, useState } from "react";
import { OctopusEnergyThemeColor } from "@octopus-energy/coral-mui";

const StripeInput = forwardRef<
  InputBaseComponentProps["Component"],
  InputBaseComponentProps
>(function StripeInput(props, ref) {
  const { component: Component, options, ...other } = props;
  const theme = useTheme();
  const [mountNode, setMountNode] = useState<typeof Component | null>(null);

  useImperativeHandle(
    ref,
    () => ({
      focus: () => mountNode.focus(),
    }),
    [mountNode]
  );

  return (
    <Component
      onReady={setMountNode}
      options={{
        ...options,
        style: {
          base: {
            color:
              theme.palette.mode === "light"
                ? theme.palette.secondary.main
                : theme.palette.text.primary,
            fontSize: "1.25rem",
            fontFamily: theme.typography.fontFamily,
            ":focus": {
              color:
                theme.palette.mode === "light"
                  ? theme.palette.primary.main
                  : theme.palette.text.primary,
            },
            ":hover": {
              color:
                theme.palette.mode === "light"
                  ? theme.palette.primary.main
                  : theme.palette.text.primary,
            },
            "::placeholder": {
              color: alpha(
                theme.palette.mode === "light"
                  ? OctopusEnergyThemeColor.PURPLE_HAZE
                  : theme.palette.text.primary,
                0.75
              ),
            },
          },
          invalid: {
            color: theme.palette.error.main,
          },
        },
      }}
      {...other}
    />
  );
});

export default StripeInput;
