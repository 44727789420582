import { TextField } from "@mui/material";
import useTranslation from "next-translate/useTranslation";
import { useFormContext } from "react-hook-form";
import * as Yup from "yup";

export const useEmailValidation = () => {
  const { t } = useTranslation("enrollment/formFields");
  return Yup.string()
    .email(t("validation_emailNotValid"))
    .required(t("validation_emailNotEntered"));
};

export type EmailFormPartial = {
  emailAddress: string;
};

export const Email = () => {
  const { t } = useTranslation("enrollment/formFields");
  const {
    register,
    formState: { errors },
  } = useFormContext<EmailFormPartial>();
  return (
    <TextField
      {...register("emailAddress")}
      label={t("labels_email")}
      data-cy="email"
      required
      fullWidth
      error={Boolean(errors.emailAddress)}
      helperText={errors.emailAddress?.message || " "}
    />
  );
};
