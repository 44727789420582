import { Maybe } from "../types";

/**
 * Function that determines if the input is truthy or not
 * Returns true if truthy
 * Returns false if falsy
 */
export const isTruthy = <T>(i: Maybe<T>): i is T => {
  return Boolean(i);
};
