import { gql } from "@apollo/client";
import { createUseMutation } from "@octopus-energy/apollo-client";
import {
  UpdateCommsDeliveryPreferenceMutation,
  UpdateCommsDeliveryPreferenceMutationVariables,
} from "./__generated__/useUpdateCommsDeliveryPreferenceMutation.generated";

export const MUTATION_UPDATE_COMMS_DELIVERY_PREFERENCE = gql`
  mutation updateCommsDeliveryPreference(
    $input: UpdateCommsDeliveryPreferenceInput!
  ) {
    updateCommsDeliveryPreference(input: $input) {
      account {
        number
      }
    }
  }
`;

export const useUpdateCommsDeliveryPreferenceMutation = createUseMutation<
  UpdateCommsDeliveryPreferenceMutation,
  UpdateCommsDeliveryPreferenceMutationVariables
>(MUTATION_UPDATE_COMMS_DELIVERY_PREFERENCE);
