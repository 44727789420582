import { useEffect, useState, RefObject } from "react";

export type IntersectionObserverOptions = {
  options: {
    root: null;
    rootMargin: string;
    threshold: number;
  };
  ref?: RefObject<HTMLDivElement>;
  overide: boolean;
};

export const useIntersectionObserver = ({
  options,
  ref,
  overide,
}: IntersectionObserverOptions) => {
  const [visible, setVisible] = useState<boolean>(overide);
  const callbackFunction = (entry: IntersectionObserverEntry) => {
    setVisible(!overide ? (visible ? true : entry.isIntersecting) : true);
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      const observer = new IntersectionObserver(
        (entry) => callbackFunction(entry[0]),
        options
      );
      if (ref?.current) observer.observe(ref?.current);
      return () => {
        if (ref?.current) observer.unobserve(ref?.current);
      };
    }
  }, [ref, visible]);

  return typeof window !== "undefined" ? visible : true;
};
