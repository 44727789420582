import { apolloClient, KRAKEN_PROD } from "@octopus-energy/apollo-client";
import {
  ProductsWithConciseApplicableRatesQuery,
  ProductsWithConciseApplicableRatesQueryVariables,
} from "../../apiRequests/product/__generated__/useQueryProductsWithConciseApplicableRates.generated";
import { OctopusProductId } from "../../product/octopusProductConstants";
import { QUERY_PRODUCTS_WITH_CONCISE_APPLICABLE_RATES } from "./useQueryProductsWithConciseApplicableRates";
import {
  ConciseRatesOutput,
  ProductWithRatesOutput,
} from "../graphql-global-types";

/**
 * Function that calculates and returns the min or max rate including monthly subscription fees
 */

export const calculateRateWithMonthlySubscriptionFees = (
  rates: (ConciseRatesOutput | null)[] | null | undefined,
  minOrMax: "min" | "max"
) => {
  if (!rates?.length) {
    return null;
  }
  return Math[minOrMax](
    ...rates.map((r) =>
      parseFloat(r?.totalApplicableRateWithMonthlySubscriptionFees)
    )
  );
};

export type AvailableProductsWithConciseApplicableRates = {
  [key in OctopusProductId]: ProductWithRatesOutput & {
    minApplicableRateWithMonthlySubscriptionFees: number | null;
    maxApplicableRateWithMonthlySubscriptionFees: number | null;
  };
};

/**
 * Interval in seconds to revalidate the query for fetching available products with concise applicable rates
 */
export const REVALIDATE_INTERVAL_AVAILABLE_PRODUCTS_WITH_CONCISE_APPLICABLE_RATES =
  5 * 60;

/**
 * Server side query available products with concise applicable rates.
 * Also reformats data structure to be more frontend friendly.
 */
export const queryAvailableProductsWithConciseApplicableRates = async () => {
  const { data, error } = await apolloClient.query<
    ProductsWithConciseApplicableRatesQuery,
    ProductsWithConciseApplicableRatesQueryVariables
  >({
    query: QUERY_PRODUCTS_WITH_CONCISE_APPLICABLE_RATES,
    context: {
      clientName: KRAKEN_PROD,
    },
  });

  if (error) {
    throw new Error("Failed to fetch product prices");
  }

  const availableProductsWithConciseApplicableRates:
    | AvailableProductsWithConciseApplicableRates
    | undefined =
    // remove products that are unavailable (prevents overfetching)
    data.productsWithConciseApplicableRates
      ?.filter((p) => {
        if (p?.code) {
          return Object.values(OctopusProductId).includes(
            p.code as OctopusProductId
          );
        }
      })
      // convert ProductWithConciseApplicableRates to AvailableProductsWithConciseApplicableRates
      .reduce((a, k) => {
        const productId = k?.code as OctopusProductId;
        return {
          ...a,
          [productId]: {
            ...k,
            minApplicableRateWithMonthlySubscriptionFees:
              calculateRateWithMonthlySubscriptionFees(k?.rates, "min"),
            maxApplicableRateWithMonthlySubscriptionFees:
              calculateRateWithMonthlySubscriptionFees(k?.rates, "max"),
          },
        };
      }, {} as AvailableProductsWithConciseApplicableRates);

  return availableProductsWithConciseApplicableRates;
};
