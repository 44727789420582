import { AddressTdspMappingOutputType } from "@core/apiRequests/graphql-global-types";
import {
  Box,
  Dialog,
  DialogProps,
  DialogTitle,
  DialogTitleProps,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@octopus-energy/coral-mui";
import { useRouter } from "next/router";
import { useSelectQuotePageAddress } from "../quote/useSelectQuotePageAddress";
import { useSelectGetReadyAddress } from "./useSelectGetReadyAddress";

type VerifyAddressDialogProps = {
  open: DialogProps["open"];
  suggestedAddresses: (AddressTdspMappingOutputType & { uuid: string })[];
  title: DialogTitleProps["children"];
  setVerifyType: (type: "esiId" | "address" | null) => void;
  pushToNextStep?: boolean;
};

export const VerifyAddressDialog = ({
  open,
  suggestedAddresses,
  title,
  setVerifyType,
  pushToNextStep,
}: VerifyAddressDialogProps) => {
  const handleGetReadyVerifyAddress = useSelectGetReadyAddress(pushToNextStep);
  const handleQuoteFormVerifyAddress =
    useSelectQuotePageAddress(pushToNextStep);
  const router = useRouter();

  const handleListItemClick = () => {
    setVerifyType(null);
  };

  return (
    <Dialog open={open} onClose={() => setVerifyType(null)}>
      <Box maxWidth="md" p={2}>
        <DialogTitle sx={{ mb: 0, px: 1, pt: 4, pb: 0 }}>{title}</DialogTitle>
        <List sx={{ maxHeight: "500px" }} data-cy="verify-address">
          {suggestedAddresses.map((address, i) => (
            <ListItem
              key={address.uuid}
              button
              alignItems="center"
              data-cy={`address-${i}`}
              onClick={async () => {
                if (router.pathname.startsWith("/join/enrollment/quote")) {
                  await handleQuoteFormVerifyAddress(suggestedAddresses[i]);
                } else {
                  await handleGetReadyVerifyAddress(suggestedAddresses[i]);
                }
                handleListItemClick();
              }}
              sx={{
                cursor: "pointer",
                my: "1rem",
                py: ".25rem",
                px: "1.5rem",
                flexWrap: "wrap",
                backgroundColor: (theme) => theme.palette.common.white,
                borderRadius: ".5rem",
                color: (theme) => theme.palette.common.black,
                "&:hover": {
                  color: (theme) => theme.palette.common.white,
                },
              }}
            >
              <ListItemText>
                {address.address}{" "}
                {address.address2 ? address.address2 + ", " : ""}
                {address.city}, {address.state}, {address.postCode}
              </ListItemText>
              <ListItemText
                sx={{
                  mt: 0,
                }}
              >
                <Typography component="span" fontWeight="bold">
                  ESI: {address.esiId}
                </Typography>
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </Box>
    </Dialog>
  );
};
