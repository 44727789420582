import { Maybe } from "@core/types";
import { atom, useRecoilState } from "recoil";

/**
 * Sets up default global parameters recoil atom state.
 */

export type GlobalParametersStateType = {
  navigatorState: boolean;
  subDomain?: string;
  skipPayment?: boolean;
  audio_recording_id?: Maybe<string>;
};

export const globalParametersAtom = atom<GlobalParametersStateType>({
  key: "globalParametersEnrollment",
  default: {
    navigatorState: false,
    skipPayment: false,
  },
});

export const useGlobalParameterState = () =>
  useRecoilState(globalParametersAtom);
