import {
  QUERY_ACCOUNT,
  useChangePaymentScheduleTypeMutation,
  useQueryAccount,
  useQueryProperties,
} from "@core/apiRequests";
import {
  PaymentScheduleTypeChoices,
  PaymentType,
  ScheduleType,
} from "@core/apiRequests/graphql-global-types";
import { handleError } from "@core/error";
import {
  getPaymentScheduleType,
  getPlan,
  usePaymentInstructionAccountIdentifier,
  usePortalAccountNumber,
} from "@core/portalUtils";
import { useDeviceBreakpoint } from "@core/utils/useDeviceBreakpoint";
import { Checkbox, Skeleton, Typography } from "@krakentech/coral";
import {
  Box,
  Card,
  CardContent,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@octopus-energy/coral-mui";
import { format } from "date-fns";
import useTranslation from "next-translate/useTranslation";
import {
  ChangeEvent,
  FC,
  InputHTMLAttributes,
  useEffect,
  useState,
} from "react";
import { useBooleanState } from "react-use-object-state";
import { useSnackbarNotification } from "../molecules";

const paymentScheduleTypeChoices = {
  [ScheduleType.CardPayment]: PaymentScheduleTypeChoices.CardPayment,
  [ScheduleType.DirectDebit]: PaymentScheduleTypeChoices.DirectDebit,
  [ScheduleType.BacsTransfer]: PaymentScheduleTypeChoices.Manual,
  [ScheduleType.PaymentSlip]: PaymentScheduleTypeChoices.Manual,
};

const useIsPrepay = () => {
  const accountNumber = usePortalAccountNumber();

  const queryProperties = useQueryProperties({
    variables: {
      accountNumber,
      includeSubscriptionFees: false,
      includeFutureActiveAgreements: false,
    },
    onError: handleError,
  });

  return getPlan(queryProperties.data)?.isPrepay || false;
};

export type AutopaySettingsProps = {
  editing: ReturnType<typeof useBooleanState>;
  saveTrigger: ReturnType<typeof useBooleanState>;
  resetSaveTrigger: Function;
  cancelButtonClicked: ReturnType<typeof useBooleanState>;
};

export const AutopaySettings: FC<AutopaySettingsProps> = ({
  editing,
  saveTrigger,
  resetSaveTrigger,
  cancelButtonClicked,
}) => {
  const { isMobile } = useDeviceBreakpoint();
  const { t } = useTranslation("portalAccountSettings");
  const accountNumber = usePortalAccountNumber();
  const isPrepay = useIsPrepay();
  const paymentMethodCCAccountIdentifier =
    usePaymentInstructionAccountIdentifier(PaymentType.Card, accountNumber);
  const paymentMethodACHAccountIdentifier =
    usePaymentInstructionAccountIdentifier(
      PaymentType.DirectDebit,
      accountNumber
    );
  const [notification] = useSnackbarNotification();
  const autopayPreferenceTranslation = {
    [ScheduleType.CardPayment]: t("on"),
    [ScheduleType.DirectDebit]: t("on"),
    [ScheduleType.BacsTransfer]: t("off"),
    [ScheduleType.PaymentSlip]: "",
  };

  const autopayOn = useBooleanState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<ScheduleType | null>(null);

  const [availablePaymentMethods, setAvailablePaymentMethods] = useState<
    ScheduleType[]
  >([]);

  const autopayEnrollmentCheckboxChecked = useBooleanState(false);
  const showError = useBooleanState(false);

  const handleAutopayEnrollmentCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    autopayEnrollmentCheckboxChecked.setState(event.target.checked);
    if (event.target.checked) {
      showError.setFalse();
    }
  };

  const { data, loading } = useQueryAccount({
    variables: {
      accountNumber,
      activeOnDate: format(new Date(), "yyyy-MM-dd"),
    },
  });
  const paymentScheduleType = getPaymentScheduleType(data);

  useEffect(() => {
    const methods = [];
    if (paymentMethodCCAccountIdentifier)
      methods.push(ScheduleType.CardPayment);
    if (paymentMethodACHAccountIdentifier)
      methods.push(ScheduleType.DirectDebit);
    setAvailablePaymentMethods(methods);

    const currentScheduleType = paymentScheduleType;
    autopayOn.setState(
      currentScheduleType !== null &&
        currentScheduleType !== undefined &&
        currentScheduleType !== ScheduleType.BacsTransfer
    );
    setSelectedPaymentMethod(
      currentScheduleType && currentScheduleType !== ScheduleType.BacsTransfer
        ? currentScheduleType
        : null
    );
  }, [
    paymentScheduleType,
    paymentMethodCCAccountIdentifier,
    paymentMethodACHAccountIdentifier,
  ]);

  useEffect(() => {
    if (cancelButtonClicked.state) {
      const currentScheduleType =
        data?.account?.paymentSchedules?.edges[0]?.node?.scheduleType;
      autopayOn.setState(
        currentScheduleType !== null &&
          currentScheduleType !== undefined &&
          currentScheduleType !== ScheduleType.BacsTransfer
      );
      setSelectedPaymentMethod(
        currentScheduleType && currentScheduleType !== ScheduleType.BacsTransfer
          ? currentScheduleType
          : null
      );
      cancelButtonClicked.setFalse();
    }
  }, [cancelButtonClicked.state]);

  const [changeScheduleType] = useChangePaymentScheduleTypeMutation();

  useEffect(() => {
    if (saveTrigger.state) {
      if (
        (autopayOn.state && !selectedPaymentMethod) ||
        (autopayOn.state && !autopayEnrollmentCheckboxChecked.state)
      ) {
        showError.setTrue();
        resetSaveTrigger();
        return;
      }

      const newScheduleType = autopayOn.state
        ? selectedPaymentMethod
        : ScheduleType.BacsTransfer;

      if (newScheduleType) {
        changeScheduleType({
          variables: {
            input: {
              accountNumber,
              scheduleType: paymentScheduleTypeChoices[newScheduleType],
            },
          },
          refetchQueries: [
            {
              query: QUERY_ACCOUNT,
              variables: {
                accountNumber,
              },
            },
          ],
        }).then(() => {
          resetSaveTrigger();
          editing.setFalse();
          notification.success(t("autopayPreferenceSuccessPrompt"));
        });
      } else {
        resetSaveTrigger();
        editing.setFalse();
        notification.error(t("autopayPreferenceErrorPrompt"));
      }
    }
  }, [saveTrigger, autopayOn.state, autopayEnrollmentCheckboxChecked]);

  const handleAutopayToggle = (
    _: React.MouseEvent<HTMLElement>,
    newValue: string
  ) => {
    const newAutopayOn = newValue === "on";
    autopayOn.setState(newAutopayOn);

    if (
      newAutopayOn &&
      !selectedPaymentMethod &&
      availablePaymentMethods.length > 0
    ) {
      setSelectedPaymentMethod(availablePaymentMethods[0]);
    }
  };

  const handlePaymentMethodChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedPaymentMethod(e.target.value as ScheduleType);
  };

  if (loading) {
    return <Skeleton variant="rectangular" height={40} width="100%" />;
  }

  const shouldShowCheckbox = (
    currentType: ScheduleType,
    newType: ScheduleType
  ) =>
    (currentType === ScheduleType.BacsTransfer &&
      newType === ScheduleType.CardPayment) ||
    (currentType === ScheduleType.BacsTransfer &&
      newType === ScheduleType.DirectDebit) ||
    (currentType === ScheduleType.DirectDebit &&
      newType === ScheduleType.CardPayment) ||
    (currentType === ScheduleType.CardPayment &&
      newType === ScheduleType.DirectDebit);

  if (editing.state) {
    return (
      <>
        <Stack mt={2} width={isMobile ? "100%" : "50%"}>
          <Box
            display="flex"
            justifyContent="start"
            flexDirection={{ xs: "column", sm: "row" }}
            px={2}
            my={1}
          >
            <Stack>
              <Box mb={1}>
                <Typography variant="caption" color="tertiary">
                  {t("autopayPreferencePrompt")}
                </Typography>
              </Box>
              <Tooltip
                title={t("aPaymentMethodMustBeAdded")}
                disableFocusListener={availablePaymentMethods.length !== 0}
                disableHoverListener={availablePaymentMethods.length !== 0}
                disableTouchListener={availablePaymentMethods.length !== 0}
              >
                <ToggleButtonGroup
                  exclusive
                  value={autopayOn.state ? "on" : "off"}
                  onChange={handleAutopayToggle}
                  disabled={isPrepay}
                >
                  <ToggleButton
                    value="on"
                    disabled={availablePaymentMethods.length === 0}
                  >
                    {t("on")}
                  </ToggleButton>
                  <ToggleButton value="off">{t("off")}</ToggleButton>
                </ToggleButtonGroup>
              </Tooltip>
            </Stack>
          </Box>
        </Stack>

        {autopayOn.state && (
          <Stack px={2} my={1} direction="row">
            <Stack mr={2}>
              <Divider orientation="vertical" />
            </Stack>
            <Stack width="100%">
              <Stack>
                <Typography variant="caption" color="tertiary">
                  {t("autopayPaymentMethods")}
                </Typography>
              </Stack>
              <FormControl>
                <RadioGroup
                  name="AutopayChoices"
                  value={selectedPaymentMethod}
                  onChange={handlePaymentMethodChange}
                >
                  {availablePaymentMethods.map((method) => (
                    <Card
                      key={method}
                      color="background"
                      borderWidth="none"
                      sx={{ width: "100%", my: 1 }}
                    >
                      <CardContent>
                        <Stack width="100%">
                          <FormControlLabel
                            value={method}
                            control={<Radio />}
                            label={
                              <>
                                <Typography variant="caption" color="tertiary">
                                  {method === ScheduleType.CardPayment
                                    ? t("creditCard")
                                    : t("bankAccount")}
                                </Typography>
                                <Typography>
                                  {method === ScheduleType.CardPayment
                                    ? `${
                                        t("creditCardEndingIn") + " "
                                      } ${paymentMethodCCAccountIdentifier?.slice(
                                        -4
                                      )}`
                                    : `${
                                        t("bankAccountEndingIn") + " "
                                      } ${paymentMethodACHAccountIdentifier}`}
                                </Typography>
                              </>
                            }
                          />
                        </Stack>
                      </CardContent>
                    </Card>
                  ))}
                </RadioGroup>
              </FormControl>
            </Stack>
          </Stack>
        )}
        <Stack>
          <Box px={2} mt={{ xs: 0, sm: 2 }}>
            <Typography variant="body2">
              {paymentScheduleType &&
                selectedPaymentMethod &&
                autopayOn.state &&
                shouldShowCheckbox(
                  paymentScheduleType,
                  selectedPaymentMethod
                ) && (
                  <Checkbox
                    label={t("autopayPreferenceCheckboxPrompt")}
                    checked={autopayEnrollmentCheckboxChecked.state}
                    onChange={handleAutopayEnrollmentCheckboxChange}
                    error={showError.state}
                    errorMessage={t("pleaseCheckTheBoxAutopay")}
                    inputProps={
                      {
                        "data-cy": "autopay-checkbox",
                      } as InputHTMLAttributes<HTMLInputElement>
                    }
                  />
                )}
            </Typography>
          </Box>
        </Stack>
      </>
    );
  }

  return (
    <>
      <Box px={2} mt={3} mb={1} display="flex" justifyContent="space-between">
        <Typography>
          <Typography variant="caption" color="tertiary">
            {t("autopayPreferencePrompt")}
          </Typography>
          <Typography data-cy="autopay-preference">
            {autopayOn.state
              ? autopayPreferenceTranslation[
                  selectedPaymentMethod as ScheduleType
                ]
              : t("off")}
          </Typography>
        </Typography>
      </Box>

      {autopayOn.state && selectedPaymentMethod && (
        <Stack px={2} my={1} direction="row">
          <Stack mr={2}>
            <Divider orientation="vertical" />
          </Stack>
          <Stack width="100%">
            <Stack>
              <Typography variant="caption" color="tertiary">
                {t("autopayPaymentMethods")}
              </Typography>
            </Stack>
            <Card
              color="background"
              borderWidth="none"
              sx={{ width: "100%", my: 1 }}
            >
              <CardContent>
                <Typography variant="caption" color="tertiary">
                  {selectedPaymentMethod === ScheduleType.CardPayment
                    ? t("creditCard")
                    : t("bankAccount")}
                </Typography>
                <Typography>
                  {selectedPaymentMethod === ScheduleType.CardPayment
                    ? paymentMethodCCAccountIdentifier
                      ? `${
                          t("creditCardEndingIn") + " "
                        } ${paymentMethodCCAccountIdentifier.slice(-4)}`
                      : t("checkBackSoon")
                    : paymentMethodACHAccountIdentifier
                    ? `${
                        t("bankAccountEndingIn") + " "
                      } ${paymentMethodACHAccountIdentifier}`
                    : t("checkBackSoon")}
                </Typography>
              </CardContent>
            </Card>
          </Stack>
        </Stack>
      )}
    </>
  );
};
