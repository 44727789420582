import Head from "next/head";
import { useRouter } from "next/router";
import { FC, ReactNode } from "react";
// Pass prop called meta to this component and it'll generate all the meta tags for you :)
/*
    {
        title: '',
        description: '',
        image: '',
        contentType: 'Website'
    }

    If you want to manually add more tags in you can, just wrap it <Meta>Your extra tags here</Meta>
*/

export type MetaGenProps = {
  title: string;
  description: string;
  image: string;
  contentType: string;
  children?: ReactNode;
};

export const MetaGen: FC<MetaGenProps> = ({
  title,
  description,
  image,
  contentType,
  children,
}) => {
  const websiteURI = "https://www.octopusenergy.com";
  const siteName = "Octopus Energy USA";

  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} key="description" />
      <link
        rel="canonical"
        href={websiteURI + useRouter().asPath}
        key="canonical"
      />
      {/* Open Graph */}
      <meta property="og:title" content={title} key="ogTitle" />
      <meta property="og:type" content={contentType} key="ogType" />
      <meta
        property="og:url"
        content={websiteURI + useRouter().asPath}
        key="ogUrl"
      />
      <meta property="og:image" content={websiteURI + image} key="ogImage" />
      <meta
        property="og:description"
        content={description}
        key="ogDescription"
      />
      <meta property="og:site_name" content={siteName} key="ogSiteName" />
      <meta name="author" content="Octopus Energy USA" key="author" />
      {/* Twitter */}
      <meta
        name="twitter:card"
        content="summary_large_image"
        key="twitterCard"
      />
      <meta name="twitter:title" content={title} key="twitterTitle" />
      <meta
        name="twitter:image"
        content={websiteURI + image}
        key="twitterImage"
      />
      <meta
        name="twitter:description"
        content={description}
        key="twitterDescription"
      />
      <meta
        name="twitter:site:id"
        content="@OctopusEnergyUS"
        key="twitterHandle"
      />
      {children}
    </Head>
  );
};
