import { gql } from "@apollo/client";
import { createUseQuery } from "@octopus-energy/apollo-client";
import { ViewerQuery } from "./__generated__/useQueryViewer.generated";

export const QUERY_VIEWER = gql`
  query viewer {
    viewer {
      id
      givenName
      familyName
      email
      mobile
    }
  }
`;

export const useQueryViewer = createUseQuery<ViewerQuery, {}>(QUERY_VIEWER);
