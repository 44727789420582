import { gql } from "@apollo/client";
import { createUseMutation } from "@octopus-energy/apollo-client";
import {
  CustomerAcceptQuoteMutation,
  CustomerAcceptQuoteMutationVariables,
} from "./__generated__/useCustomerAcceptQuoteMutation.generated";

export const MUTATION_ACCEPT_CUSTOMER_QUOTE = gql`
  mutation CustomerAcceptQuote($input: CustomerAcceptQuoteInput!) {
    customerAcceptQuote(input: $input) {
      agreement {
        id
        validFrom
        validTo
        agreedAt
        terminatedAt
        autoTopUpAmountSelectedByUser
        product {
          id
          marketName
          code
          notes
          fullName
          displayName
          description
          availableFrom
          availableTo
          term
          endsAt
          availabilityStatus
          termsContractType
        }
        totalApplicableRate
      }
    }
  }
`;

export const useCustomerAcceptQuoteMutation = createUseMutation<
  CustomerAcceptQuoteMutation,
  CustomerAcceptQuoteMutationVariables
>(MUTATION_ACCEPT_CUSTOMER_QUOTE);
