import {
  useQueryAccount,
  useUpdateCommsPreferencesMutation,
} from "@core/apiRequests";
import { usePortalAccountNumber } from "@core/portalUtils";
import { Checkbox, Skeleton, Typography } from "@krakentech/coral";
import { Box, Stack } from "@octopus-energy/coral-mui";
import useTranslation from "next-translate/useTranslation";
import { ChangeEvent, FC, useEffect } from "react";
import { useBooleanState } from "react-use-object-state";

export type CommunicationMethodsPreferenceProps = {
  editing: ReturnType<typeof useBooleanState>;
  saveTrigger: ReturnType<typeof useBooleanState>;
  resetSaveTrigger: Function;
};

export const CommunicationMethodsPreference: FC<
  CommunicationMethodsPreferenceProps
> = ({ editing, saveTrigger, resetSaveTrigger }) => {
  const { t } = useTranslation("portalAccountSettings");
  const accountNumber = usePortalAccountNumber();

  const { data, loading, refetch } = useQueryAccount({
    variables: {
      accountNumber,
    },
  });
  const isOptedInSMS =
    data?.account?.users?.[0]?.preferences?.isOptedInToSmsMessages || false;

  const smsNotificationPreference = useBooleanState(isOptedInSMS);

  useEffect(() => {
    smsNotificationPreference.setState(isOptedInSMS);
  }, [isOptedInSMS]);

  const [updateCommsPreference] = useUpdateCommsPreferencesMutation();

  useEffect(() => {
    if (saveTrigger.state && smsNotificationPreference.state !== isOptedInSMS) {
      updateCommsPreference({
        variables: {
          input: {
            isOptedInToSmsMessages: smsNotificationPreference.state,
          },
        },
      }).then(() => {
        refetch();
        resetSaveTrigger();
      });
    }
  }, [saveTrigger.state]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    smsNotificationPreference.setState(e.target.checked);
  };

  if (loading) {
    return <Skeleton variant="rectangular" height={70} width="30%" />;
  }

  if (editing.state) {
    return (
      <form>
        <Box>
          <Typography variant="caption" color="tertiary">
            {t("communicationMethods")}
          </Typography>
          <Stack direction="row" my={1}>
            <Checkbox label={t("recommendedEmails")} checked disabled />
          </Stack>
          <Stack direction="row">
            <Checkbox
              checked={smsNotificationPreference.state}
              label={t("SMSNotifications")}
              onChange={handleChange}
            />
          </Stack>
        </Box>
      </form>
    );
  }
  return (
    <Box mt={2} mb={1}>
      <Typography variant="caption" color="tertiary">
        {t("communicationMethods")}
      </Typography>
      <Stack direction="row" my={1}>
        <Checkbox label={t("recommendedEmails")} checked disabled />
      </Stack>
      <Stack direction="row">
        <Checkbox
          checked={smsNotificationPreference.state}
          disabled
          label={t("SMSNotifications")}
        />
      </Stack>
    </Box>
  );
};
