import { gql } from "@apollo/client";
import {
  createUseQuery,
  createUseQueryFunction,
} from "@octopus-energy/apollo-client";
import {
  AccountQuery,
  AccountQueryVariables,
} from "./__generated__/useQueryAccount.generated";

export const QUERY_ACCOUNT = gql`
  query account($accountNumber: String!, $ledgerId: ID, $activeOnDate: Date) {
    account(accountNumber: $accountNumber) {
      balance
      billingAddress
      billingName
      canRequestRefund
      commsDeliveryPreference
      id
      isEligibleForIntelligentOctopus
      number
      campaigns {
        campaignExpiryDate
        name
        slug
        expiryDate
        startDate
      }
      ledgers(ledgerId: $ledgerId) {
        id
        name
        ledgerType
        balance
        affectsAccountBalance
        transactions(first: 30) {
          edges {
            node {
              ... on Payment {
                id
                amount
                postedDate
              }
            }
          }
        }
      }
      properties {
        id
        address
        postcode
        meterPoints {
          id
          loadZone
          serviceProvider
        }
      }
      bills(
        first: 50
        includeOpenStatements: false
        includeBillsWithoutPDF: false
      ) {
        edges {
          node {
            id
            fromDate
            toDate
            temporaryUrl
          }
        }
      }
      payments(first: 50) {
        edges {
          node {
            id
            paymentDate
            amount
            status
            transactionType
          }
        }
      }
      paymentSchedules(first: 1, activeOnDate: $activeOnDate) {
        edges {
          node {
            id
            scheduleType
          }
        }
      }
      users {
        id
        preferences {
          isOptedInToSmsMessages
        }
      }
      referralsCreated
      transactions(first: 50) {
        edges {
          node {
            ... on Charge {
              id
              amount
              title
              balanceCarriedForward
              postedDate
            }
            ... on Payment {
              id
              amount
              title
              balanceCarriedForward
              postedDate
            }
            ... on Refund {
              id
              amount
              title
              balanceCarriedForward
              postedDate
            }
            ... on Credit {
              id
              amount
              title
              balanceCarriedForward
              postedDate
            }
          }
        }
      }
    }
  }
`;

export const useQueryAccount = createUseQuery<
  AccountQuery,
  AccountQueryVariables
>(QUERY_ACCOUNT);

export const useQueryAccountFunction = createUseQueryFunction<
  AccountQuery,
  AccountQueryVariables
>(QUERY_ACCOUNT);
