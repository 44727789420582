import { IconArrowLeft } from "@krakentech/icons";
import { Box, Link, Typography } from "@octopus-energy/coral-mui";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import { FC } from "react";

export const PortalBackButton: FC = () => {
  const router = useRouter();
  const { t } = useTranslation("account/accountNumber");
  const accountNumber = router.query.accountNumber;

  return (
    <Link
      data-cy="portal-back-button"
      underline="hover"
      sx={{
        display: "flex",
        direction: "row",
        alignItems: "center",
        mb: 2,
      }}
      onClick={() =>
        router.push({
          pathname: `/account/${accountNumber}`,
        })
      }
    >
      <Box>
        <IconArrowLeft />
      </Box>
      <Typography variant="h5" sx={{ ml: 1 }}>
        {t("backToDashboard")}
      </Typography>
    </Link>
  );
};
