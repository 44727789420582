import useTranslation from "next-translate/useTranslation";
import { FC } from "react";
import { Typography, TypographyProps } from "@octopus-energy/coral-mui";

export type ProductCardRatePerKWHProps = {
  minPrice?: number | string | null;
  align?: TypographyProps["align"];
};

export const ProductCardRatePerKWH: FC<ProductCardRatePerKWHProps> = ({
  minPrice,
  align,
}) => {
  const { t } = useTranslation("product/productCard");

  return (
    <Typography
      variant="h1"
      component="h3"
      align={align}
      fontWeight="bold"
      mt={2}
      data-cy="product-rate-per-kwh"
    >
      {minPrice}
      <Typography variant="h3" fontWeight="bold" component="span">
        ¢
      </Typography>
      <Typography variant="h6" ml={1} component="span">
        {" "}
        {t("per")} kWh
      </Typography>
    </Typography>
  );
};
