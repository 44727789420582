import { ErrorBoundary as SentryErrorBoundary } from "@sentry/nextjs";
import { FC } from "react";
import { Error } from ".";

const CHUNK_LOAD_ERROR = "ChunkLoadError";

type ErrorBoundaryProps = {
  onReset?(
    error: Error | null,
    componentStack: string | null,
    eventId: string | null
  ): void;
  children: React.ReactNode;
};

export const ErrorBoundary: FC<ErrorBoundaryProps> = (props) => {
  return (
    <SentryErrorBoundary
      {...props}
      onError={(e) => {
        if (e.name === CHUNK_LOAD_ERROR) {
          window.location.reload();
        }
      }}
      fallback={() => (
        <Error
          pageTitle="Octopus Error"
          errorTitle="OctopusError"
          errorMessage="We encountered an unknown error."
        />
      )}
    />
  );
};
