import { useCreateMetaDataMutation } from "../../apiRequests";
import { LinkedObjectType } from "../../apiRequests/graphql-global-types";

export type UseCreateAccountMetaParameters = {
  data: {
    key: string;
    value: string;
  }[];
  linkedObjectType: LinkedObjectType;
  identifier: string;
  token: string;
};

export const useCreateAccountMeta = () => {
  const [createUserMeta] = useCreateMetaDataMutation();

  const addMetaToAccount = ({
    data,
    linkedObjectType,
    identifier,
    token,
  }: UseCreateAccountMetaParameters) => {
    return Promise.all(
      data.map(({ key, value }: UseCreateAccountMetaParameters["data"][0]) => {
        return createUserMeta({
          variables: {
            input: {
              identifier,
              linkedObjectType,
              key,
              value: JSON.stringify(value),
            },
          },
          context: {
            headers: {
              authorization: token,
            },
          },
        });
      })
    );
  };

  return { addMetaToAccount };
};
