import { IconSecure } from "@krakentech/icons";
import { Stack, Typography } from "@octopus-energy/coral-mui";
import useTranslation from "next-translate/useTranslation";
import { FC } from "react";

export type LockedInRateProps = {
  rateDurationInMonths?: number | string;
  mt?: number;
};

export const LockedInRate: FC<LockedInRateProps> = ({
  rateDurationInMonths = 12,
  mt,
}) => {
  const { t } = useTranslation("product/productCard");
  return (
    <Stack direction="row" alignItems="center" mt={mt}>
      <IconSecure data-cy="locked-in-rate-bullet-image" size={16} />
      <Typography variant="body2" ml={3} data-cy="locked-in-rate-bullet-text">
        {rateDurationInMonths}-{t("month")} {t("lockedInRate")}
      </Typography>
    </Stack>
  );
};
