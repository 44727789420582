import { handleError } from "@core/error";
import { useCreateQuote } from "@core/krakenHelpers/useCreateQuote";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import { useFormContext } from "react-hook-form";
import { useCustomerIsPTC, useEnrollment } from "../enrollmentUtils";
import { QuoteFormValues } from "./QuotePage";
import { useIsPostCodeQuotableFunction } from "@core/apiRequests/product/useQueryIsPostCodeQuotable";

export const useSetQuoteData = () => {
  const { t: tFormFields } = useTranslation("enrollment/formFields");
  const [createQuote] = useCreateQuote();
  const { monthlyKwhUsage } = useEnrollment();
  const router = useRouter();
  const enrollment = useEnrollment();
  const { getReady } = enrollment.formData;
  const checkProductAvailability = useIsPostCodeQuotableFunction();
  const customerIsPTC = useCustomerIsPTC();
  const { setError } = useFormContext<QuoteFormValues>();

  const setQuoteData = async (esiId: string | undefined | null) => {
    if (!getReady?.service_ZipCode) {
      return;
    }
    try {
      const response = await checkProductAvailability({
        variables: { postcode: getReady.service_ZipCode },
      });
      if (!response.data.isPostCodeQuotable?.isQuotable) {
        setError("service_ZipCode", {
          type: "manual",
          message: tFormFields("join_zipcode_notAvailable"),
        });
        return;
      }

      if (customerIsPTC) {
        await createQuote({
          emailAddress: "",
          postcode: getReady.service_ZipCode,
          kwhConsumption: monthlyKwhUsage,
          esiId: esiId || getReady.esiId,
        });
      }
      router.push({
        pathname: "/join/plans",
        query: {
          ...router.query,
          referrer: "zipcode",
          zipcode: getReady.service_ZipCode,
        },
      });
    } catch (error) {
      handleError(error);
    }
  };
  return setQuoteData;
};
