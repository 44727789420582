import {
  useQueryAccount,
  useQueryProperties,
  useUpdateAutoTopUpAmountMutation,
} from "@core/apiRequests";
import { handleError } from "@core/error";
import { formatAmount, usePortalAccountNumber } from "@core/portalUtils";
import { useDeviceBreakpoint } from "@core/utils/useDeviceBreakpoint";
import {
  Select,
  SelectProps,
  Tooltip,
  Typography,
  useCoralTheme,
} from "@krakentech/coral";
import { IconInfoOutlined } from "@krakentech/icons";
import {
  Box,
  Divider,
  Stack,
  autoTopOffAmounts,
} from "@octopus-energy/coral-mui";
import { addDays } from "date-fns";
import useTranslation from "next-translate/useTranslation";
import { FC, useEffect, useState } from "react";
import { useBooleanState } from "react-use-object-state";

export type PaymentPrepayTopoffSettingsProps = {
  editing: ReturnType<typeof useBooleanState>;
  saveTrigger: ReturnType<typeof useBooleanState>;
  resetSaveTrigger: Function;
};

export const PaymentPrepayTopoffSettings: FC<
  PaymentPrepayTopoffSettingsProps
> = ({ editing, saveTrigger, resetSaveTrigger }) => {
  const { isMobile } = useDeviceBreakpoint();
  const theme = useCoralTheme();
  const { t } = useTranslation("portalAccountSettings");
  const accountNumber = usePortalAccountNumber();
  const [lastSavedTime, setLastSavedTime] = useState<Date | null>(null);

  const { data: accountData } = useQueryAccount({
    variables: {
      accountNumber,
    },
    onError: handleError,
  });

  const accountLedgers = accountData?.account?.ledgers;
  const energyLedger = accountLedgers?.find((ledger) => {
    return ledger?.ledgerType === "ENERGY";
  });
  const paymentStatus =
    accountData?.account?.payments?.edges?.[0]?.node?.status;

  const { data } = useQueryProperties({
    variables: {
      accountNumber,
      includeSubscriptionFees: false,
      includeFutureActiveAgreements: false,
    },
    onError: handleError,
  });
  const userAutoTopOffAmount =
    data?.properties?.[0]?.meterPoints?.[0]?.agreements?.[0]
      ?.autoTopUpAmountSelectedByUser || 0;

  const [autoTopUp, setAutoTopUp] = useState<number>(userAutoTopOffAmount);

  useEffect(() => {
    if (userAutoTopOffAmount !== null) {
      setAutoTopUp(userAutoTopOffAmount);
    }
  }, [userAutoTopOffAmount]);

  useEffect(() => {
    const savedTime = localStorage.getItem("lastSavedTime");
    if (savedTime) {
      setLastSavedTime(new Date(savedTime));
    }
  }, []);

  const [updateTopOffAmountUser] = useUpdateAutoTopUpAmountMutation();

  useEffect(() => {
    if (saveTrigger.state && autoTopUp) {
      updateTopOffAmountUser({
        variables: {
          input: {
            accountNumber,
            paymentAmount: autoTopUp,
            ledgerId: Number(energyLedger?.id),
          },
        },
      }).then(() => {
        resetSaveTrigger();
        editing.setFalse();
      });
      const now = new Date();
      localStorage.setItem("lastSavedTime", now.toISOString());
      setLastSavedTime(now);
    }
  }, [saveTrigger.state, autoTopUp]);

  const handleChange: SelectProps["onChange"] = (value) =>
    setAutoTopUp(value?.value as number);

  const canEdit = () => {
    if (!lastSavedTime) {
      return true;
    }
    return new Date() > addDays(lastSavedTime, 1);
  };

  const canEditPaymentStatus = () => {
    if (
      paymentStatus === "PENDING" ||
      paymentStatus === "SCHEDULED" ||
      paymentStatus === "REQUESTED" ||
      paymentStatus === "CLEARED"
    ) {
      return false;
    }
    return true;
  };

  const isEditDisabled = !canEdit() || !canEditPaymentStatus();

  if (editing.state) {
    return (
      <Stack direction="row" mt={1} ml={2}>
        <Divider orientation="vertical" flexItem />
        <Stack direction="row">
          <Stack
            direction={{ sm: "row", xs: "column" }}
            px={2}
            mt={2}
            width={isMobile ? 300 : 500}
          >
            <Stack width="100%" direction="row" alignItems="center">
              <Box width={isMobile ? "70%" : "50%"} mr={2}>
                <Select
                  name="autoTopUpAmount"
                  disabled={isEditDisabled}
                  label={t("autoTopOffTitle")}
                  value={{
                    value: autoTopUp,
                    label: "$" + String(autoTopUp / 100),
                  }}
                  onChange={handleChange}
                  values={autoTopOffAmounts.map((option) => ({
                    key: option.label,
                    value: option.value,
                    label: "$" + option.label,
                  }))}
                />
              </Box>
              <Box width={isMobile ? "30%" : "50%"}>
                <Tooltip
                  title={
                    canEdit()
                      ? t("tooltipPaymentPending")
                      : t("tooltipAutoTopOffInfo")
                  }
                >
                  <IconInfoOutlined
                    size={18}
                    color={theme.color.tertiary.main}
                  />
                </Tooltip>
              </Box>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    );
  }

  return (
    <>
      <Stack
        mt={3}
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        mr={2}
      >
        <Box px={2} mb={1} display="flex">
          <Divider orientation="vertical" flexItem />
          <Stack ml={2}>
            <Typography variant="h6" color="tertiary">
              {t("autoTopOffTitle")}
            </Typography>
            <Typography
              dataAttributes={{
                "data-cy": "settings-topoff-amount",
              }}
            >
              ${formatAmount(autoTopUp || 0)}
            </Typography>
          </Stack>
        </Box>
      </Stack>
    </>
  );
};
