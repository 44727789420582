import { PaymentType } from "@core/apiRequests/graphql-global-types";
import { Button, Typography } from "@krakentech/coral";
import {
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
} from "@octopus-energy/coral-mui";
import useTranslation from "next-translate/useTranslation";
import { FC } from "react";

export type ReplacePaymentMethodDialogProps = {
  open: boolean;
  onClose: () => void;
  onReplaceConfirm: () => void;
  paymentMethodToBeDeleted: PaymentType.Card | PaymentType.DirectDebit;
  maskedAccountIdentifier: string | null | undefined;
};

export const ReplacePaymentMethodDialog: FC<
  ReplacePaymentMethodDialogProps
> = ({
  open,
  onClose,
  onReplaceConfirm,
  paymentMethodToBeDeleted,
  maskedAccountIdentifier,
}) => {
  const { t } = useTranslation("payments/payment-methods");

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Typography variant="h3">{t("replacePaymentMethod")}</Typography>
      </DialogTitle>
      <DialogContent>
        <Stack mb={1}>
          <Typography>{t("youreRemovingTheFollowingPaymentMethod")}</Typography>
          <Typography variant="body2">
            {t("onceYouMoveForward") + " "} <b>{t("youllNotBeAbleToUndo")}</b>
          </Typography>
        </Stack>
        <Card borderWidth="none" sx={{ width: "100%", mt: 3 }}>
          <CardContent>
            <Stack>
              <Typography variant="caption" color="tertiary">
                {paymentMethodToBeDeleted === PaymentType.Card
                  ? "Credit card"
                  : paymentMethodToBeDeleted === PaymentType.DirectDebit
                  ? "Bank account"
                  : null}
              </Typography>
              <Typography variant="body2">
                {paymentMethodToBeDeleted === PaymentType.Card
                  ? "Credit card ending in"
                  : paymentMethodToBeDeleted === PaymentType.DirectDebit
                  ? "Bank account ending in"
                  : null}{" "}
                {maskedAccountIdentifier}
              </Typography>
            </Stack>
          </CardContent>
        </Card>
        <Stack
          direction="row"
          display="flex"
          justifyContent="center"
          spacing={1}
          mt={4}
        >
          <Button fullWidth variant="outlined" onClick={onClose}>
            {t("cancel")}
          </Button>
          <Button fullWidth variant="contained" onClick={onReplaceConfirm}>
            {t("next")}
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
