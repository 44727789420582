import {
  addDays,
  isAfter,
  isBefore,
  isSameDay,
  isSunday,
  isToday,
  startOfDay,
  startOfTomorrow,
} from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";

// month is 0 indexed
const blackoutDays = [
  //ERCOT requested
  new Date(2024, 10, 8),
  new Date(2024, 10, 9),
  // Thanksgiving
  new Date(2024, 10, 28),
  new Date(2024, 10, 29),
  // Christmas
  new Date(2024, 11, 24),
  new Date(2024, 11, 25),
  // New years
  new Date(2025, 0, 1),
  // Martin Luther King Jr. Day
  new Date(2025, 0, 20),
  // Memorial Day
  new Date(2025, 4, 26),
  // Juneteenth
  new Date(2025, 5, 19),
  // Independence Day
  new Date(2025, 6, 4),
  // Labor Day
  new Date(2025, 8, 1),
  // Thanksgiving
  new Date(2025, 10, 27),
  new Date(2025, 10, 28),
  // Christmas
  new Date(2025, 11, 25),
  new Date(2025, 11, 26),
  // New years
  new Date(2026, 0, 1),
  // Martin Luther King Jr. Day
  new Date(2026, 0, 19),
  // Memorial Day
  new Date(2026, 4, 25),
  // Independence Day
  new Date(2026, 6, 3),
  // Labor Day
  new Date(2026, 8, 7),
  // Thanksgiving
  new Date(2026, 10, 26),
  new Date(2026, 10, 27),
  // Christmas
  new Date(2026, 11, 24),
  new Date(2026, 11, 25),
];

const latestHourToSwitch = () => {
  const dateTime = new Date();
  // 3:45pm CT
  dateTime.setHours(15);
  dateTime.setMinutes(45);
  dateTime.setSeconds(0);
  return zonedTimeToUtc(dateTime, "America/Chicago");
};

export enum BlockedDate {
  PAST,
  SUNDAY,
  FUTURE,
  BLACKOUT_DAY,
  LATE_IN_DAY,
}

/**
 * Function that takes a date and determins if a date should be disabled or not.
 * If it should be disabled, it returns the correct error message to use in helperText
 * @returns the correct error message to use as helpertext if the date is invalid, else return null
 * @example
 * if (getSwitchDateStatus(date) !== null) {
 *  setError("service_SwitchDate", {
      message: "Please select a different date",
    });
 * }
 */

export const getSwitchDateStatus = (date: Date): BlockedDate | null => {
  const today = startOfDay(new Date());

  if (isToday(date)) {
    date = new Date(); // today's date comes in as 00:00:00, need current time to calc latest hour to switch
  }

  return (
    // is in the past
    isBefore(startOfDay(date), today)
      ? BlockedDate.PAST
      : // is sunday
      date.getDay() === 0
      ? BlockedDate.SUNDAY
      : isAfter(startOfDay(date), addDays(today, 90))
      ? BlockedDate.FUTURE
      : // is a blackout day
      blackoutDays.some((blackoutDay) => isSameDay(blackoutDay, date))
      ? BlockedDate.BLACKOUT_DAY
      : // is after 3:45pm for same-day switch
      isAfter(date, latestHourToSwitch()) && isBefore(date, startOfTomorrow())
      ? BlockedDate.LATE_IN_DAY
      : null
  );
};

/**
 * Function finding the next available switch date
 * @returns the next available switch date
 * @example
 * getNextAvailableDate();
 */
export const getNextAvailableDate = (date: Date = new Date()): Date => {
  if (getSwitchDateStatus(date) !== null) {
    return getNextAvailableDate(addDays(date, 1));
  }
  return startOfDay(date);
};

const isHoliday = (date: Date) => {
  const formattedDate = startOfDay(date);
  return blackoutDays.some((holiday) => isSameDay(holiday, formattedDate));
};

export const isSundayAndHoliday = (date: Date) => {
  const formattedDate = startOfDay(date);
  return isSunday(date) || isHoliday(formattedDate);
};
