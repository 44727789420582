import { gql } from "@apollo/client";
import { createUseQuery } from "@octopus-energy/apollo-client";
import {
  LatestMoveOutDateQuery,
  LatestMoveOutDateQueryVariables,
} from "./__generated__/useQueryLatestMoveOutDate.generated";

export const QUERY_LATEST_MOVE_OUT_DATE = gql`
  query LatestMoveOutDate($accountNumber: String!) {
    latestMoveOutDate(accountNumber: $accountNumber) {
      latestMoveOutDate
    }
  }
`;

export const useQueryLatestMoveOutDate = createUseQuery<
  LatestMoveOutDateQuery,
  LatestMoveOutDateQueryVariables
>(QUERY_LATEST_MOVE_OUT_DATE);
