import { useRouter } from "next/router";
import { useCreateAccountMutation } from "../../apiRequests";
import {
  Communication_Preference,
  CreateAccountInput,
  LanguagePreferenceChoices,
  SalesSubchannel,
} from "../../apiRequests/graphql-global-types";
import { Locale } from "@core/constants";
import { useGlobalParameterState } from "../globalParameters/useGlobalParametersState";

/**
 * Creates a new user account in enrollment, subject to docs here:
 * https://oeus-graphql-api-docs.netlify.app/mutations.html#create-an-account-and-link-it-with-a-sales-affiliate
 *
 * Input Variables:
 * input: @CreateAccountInput
 * key: @string
 *
 * This second order function will automatically add communication & language preference, and also autofills, sales subdomain if there is one.
 * All of which is overidable if specified when invoking.
 *
 * Can be called sync or async.
 *
 * @example
 * const createAccount = useCreateAccount();
 * const {data} = createAccount(input, token).catch((error) => { console.log("Do something with the error here!")})
 *
 * @returns  createAccount: { account: { number: ACCOUNT_NUMBER } }
 */

export const useCreateAccount = () => {
  const locale = useRouter().locale;
  const [{ subDomain, audio_recording_id }] = useGlobalParameterState();
  const [createAccount] = useCreateAccountMutation();

  const languagePreference =
    locale === Locale.ENGLISH
      ? LanguagePreferenceChoices.English
      : LanguagePreferenceChoices.Spanish;

  return (
    input: Omit<CreateAccountInput, "salesInformation">,
    authorization: string
  ) =>
    createAccount({
      variables: {
        input: {
          accountUser: {
            languagePreference,
            communicationPreference: Communication_Preference.Online,
            ...input.accountUser,
          },
          billingAddress: input.billingAddress,
          salesInformation: {
            salesSubchannel: SalesSubchannel.Leviathan,
            salesAffiliateSubdomain: subDomain,
            audioRecordingId: audio_recording_id,
          },
          quoteCode: input.quoteCode,
        },
      },
      context: { headers: { authorization } },
    });
};
