import { TextField } from "@mui/material";
import useTranslation from "next-translate/useTranslation";
import { ChangeEventHandler, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import * as Yup from "yup";

export const usePhoneNumberValidation = () => {
  const { t } = useTranslation("enrollment/formFields");
  return Yup.string()
    .phone("US")
    .required(t("validation_phoneNotEntered"))
    .min(10, t("validation_phoneNotEntered"))
    .max(10, t("validation_phoneNotEntered"));
};

export type PhoneNumberFormPartial = {
  phoneNumber: string;
};

export const PhoneNumber = () => {
  const { t } = useTranslation("enrollment/formFields");
  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<PhoneNumberFormPartial>();

  const [phoneNumber, setPhoneNumber] = useState<string | undefined>(
    watch().phoneNumber || ""
  );

  function formatPhone(value: string, previousValue?: string) {
    // return nothing if no value
    // only allows 0-9 inputs

    if (!value) return value;

    const currentValue = value.replace(/[^\d]/g, "");
    const cvLength = currentValue.length;

    if (cvLength < 11) {
      setValue("phoneNumber", currentValue);
    }

    if (!previousValue || value.length > previousValue.length) {
      // returns: "x", "xx", "xxx"
      if (cvLength < 4) return currentValue;

      // returns: "xxx", "xxx-x", "xxx-xx", "xxx-xxx",
      if (cvLength < 7)
        return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;

      // returns: "xxx-xxx-", "xxx-xxx-x", "xxx-xxx-xx", "xxx-xxx-xxx", "-xxx-xxx-xxxx"

      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
        3,
        6
      )}-${currentValue.slice(6, 10)}`;
    }
  }

  const handleChange: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (event) => {
    const previousNumber = phoneNumber;
    const newValue = event.target.value.replace(/[^\w\s]/gi, "");
    setPhoneNumber(formatPhone(newValue, previousNumber));
  };

  useEffect(() => {
    if (phoneNumber && phoneNumber.length === 10) {
      setPhoneNumber(formatPhone(phoneNumber));
    }
  }, []);

  return (
    <TextField
      id="outlined-basic"
      required
      fullWidth
      label={t("labels_phone_label")}
      name="phoneNumber"
      value={phoneNumber}
      onChange={handleChange}
      onBlur={register("phoneNumber").onBlur}
      data-cy="phone-number"
      inputProps={{ maxLength: 14 }}
      error={Boolean(errors.phoneNumber)}
      helperText={errors.phoneNumber?.message || " "}
    />
  );
};
