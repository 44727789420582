import {
  Button,
  Dialog,
  DialogContent,
  Stack,
  Typography,
} from "@octopus-energy/coral-mui";
import { FC, ReactNode, useEffect, useState } from "react";
import {
  Country,
  ISOCountryCodes,
  ISOCountryCodesNames,
} from "../LocalizationTypes";
import { useRegionPreferences } from "../useRegionPreferences";
import { LocalizationBanner } from "./LocalizationBanner";

export type LocalizationBannerProps = {
  children: ReactNode;
};

export const LocalizationBannerWithDialog: FC<LocalizationBannerProps> = ({
  children,
}) => {
  const [showBanner, setShowBanner] = useState(false);
  const [region, setRegion] = useState<ISOCountryCodes | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [rememberChoice, setRememberChoice] = useState(false);
  const { userRequestedToHideDialog, clientCountry, setUserPreference } =
    useRegionPreferences();

  const getRegionFromCountry = (clientCountry: Country) => {
    const regions = Object.values(ISOCountryCodes);
    if (clientCountry !== ISOCountryCodes.US) {
      const detectRegion = regions.find((region) => region === clientCountry);
      if (detectRegion) {
        setRegion(detectRegion);
        setTimeout(() => {
          setShowBanner(true);
        }, 1000);
        return;
      }
      setRegion(ISOCountryCodes.GLOBAL);
      setTimeout(() => {
        setShowBanner(true);
      }, 1000);
    }
  };

  const handleChooseAnotherRegion = (chooseRegion: boolean) => {
    if (chooseRegion) {
      if (region !== ISOCountryCodes.GLOBAL) {
        setUserPreference({
          region,
          hide: true,
          redirect: true,
          remember: rememberChoice,
        });
      }
      setDialogOpen(true);
    }

    if (!chooseRegion) {
      setUserPreference({
        region,
        hide: true,
        remember: rememberChoice,
      });
      setShowBanner(false);
    }
  };

  const handleGlobalSelectRegion = (region: ISOCountryCodes) => {
    setUserPreference({
      region,
      hide: true,
      redirect: true,
      remember: rememberChoice,
    });
  };

  useEffect(() => {
    if (!userRequestedToHideDialog && clientCountry) {
      getRegionFromCountry(clientCountry);
    }
  }, [userRequestedToHideDialog, clientCountry]);

  return (
    <>
      {children}
      <LocalizationBanner
        handleChooseAnotherRegion={handleChooseAnotherRegion}
        onRememberMeChange={(value) => {
          setRememberChoice(value);
        }}
        rememberValue={rememberChoice}
        region={region}
        show={showBanner}
      />
      <Dialog
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
        }}
        data-cy="localization-region-select"
      >
        <DialogContent>
          <Typography variant="h3" textAlign="center" mt={6} maxWidth="400px">
            Which region do you want to go to?
          </Typography>
          <Stack
            direction="column"
            mt={3}
            spacing={2}
            alignItems="center"
            justifyContent="center"
          >
            {Object.keys(ISOCountryCodes).map((country, index) => {
              const countryCode = Object.keys(ISOCountryCodesNames).find(
                (key) => key === country
              ) as keyof typeof ISOCountryCodesNames;

              if (countryCode) {
                return (
                  <Button
                    variant="outlined"
                    key={index}
                    data-cy={`region-button-${ISOCountryCodes[
                      countryCode
                    ].toLowerCase()}`}
                    fullWidth
                    onClick={() => {
                      handleGlobalSelectRegion(countryCode as ISOCountryCodes);
                    }}
                  >
                    {ISOCountryCodesNames[countryCode]}
                  </Button>
                );
              }
            })}
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};
