import { gql } from "@apollo/client";
import {
  createUseQuery,
  createUseQueryFunction,
} from "@octopus-energy/apollo-client";
import {
  GetAccountReferralRewardsQuery,
  GetAccountReferralRewardsQueryVariables,
} from "./__generated__/useQueryAccountReferralAndRewards.generated";

export const QUERY_ACCOUNT_REFERRAL_AND_REWARDS = gql`
  query GetAccountReferralRewards($accountNumber: String!, $first: Int!) {
    account(accountNumber: $accountNumber) {
      id
      # Get the customer's referral links
      activeReferralSchemes {
        domestic {
          code
          referralUrl
          referralDisplayUrl
        }
      }
      referrals(first: $first) {
        edges {
          node {
            id
            referredUserName
            paymentDate
            paymentStatus
            referredUserJoinDate
            # Returns code that was applied between a referral
            code
            referredUserPaymentAmount
            referringUserPaymentAmount
            combinedPaymentAmount
          }
        }
      }
    }
  }
`;

export const useQueryAccountReferralAndRewards = createUseQuery<
  GetAccountReferralRewardsQuery,
  GetAccountReferralRewardsQueryVariables
>(QUERY_ACCOUNT_REFERRAL_AND_REWARDS);

export const useQueryAccountReferralAndRewardsFunction = createUseQueryFunction<
  GetAccountReferralRewardsQuery,
  GetAccountReferralRewardsQueryVariables
>(QUERY_ACCOUNT_REFERRAL_AND_REWARDS);
