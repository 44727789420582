import { Card, CardProps } from "@octopus-energy/coral-mui";
import { FC, ReactNode } from "react";

export type OctopusProductCardProps = CardProps & {
  children?: ReactNode;
  "data-cy"?: string;
};

export const ProductCard: FC<OctopusProductCardProps> = ({
  children,
  sx,
  ...props
}) => {
  return (
    <Card
      color="paper"
      sx={{
        height: "100%",
        width: "100%",
        position: "relative",
        ...sx,
      }}
      {...props}
    >
      {children}
    </Card>
  );
};
