import { IconQuestionOutlined, IconSolar } from "@krakentech/icons";
import {
  ClickAwayListener,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@octopus-energy/coral-mui";
import useTranslation from "next-translate/useTranslation";
import { FC } from "react";
import { useBooleanState } from "react-use-object-state";

export type SolarBuybackProps = {
  color1?: string;
  color2?: string;
  mt?: number;
};

export const SolarBuyback: FC<SolarBuybackProps> = ({ mt }) => {
  const { t } = useTranslation("product/productCard");
  const solarTooltipOpen = useBooleanState(false);

  return (
    <Stack direction="row" alignItems="center" mt={mt}>
      <IconSolar size={16} data-cy="solar-buyback-bullet-image" />
      <Typography variant="body2" ml={3} data-cy="solar-buyback-bullet-text">
        {t("solarBuyback")}
      </Typography>
      <ClickAwayListener onClickAway={solarTooltipOpen.setFalse}>
        <Tooltip
          title={t("solarBuyBackTooltip")}
          arrow
          onClose={solarTooltipOpen.setFalse}
          open={solarTooltipOpen.state}
          disableFocusListener
          disableHoverListener
          disableTouchListener
        >
          <IconButton
            onClick={solarTooltipOpen.setTrue}
            size="small"
            sx={{
              mb: 0,
              ml: 1,
            }}
          >
            <IconQuestionOutlined size={18} />
          </IconButton>
        </Tooltip>
      </ClickAwayListener>
    </Stack>
  );
};
