import { ApolloError } from "@apollo/client";
import {
  useGetEmbeddedSecretForNewPaymentInstructionMutation,
  useQueryAccountViewer,
} from "@core/apiRequests";
import { PaymentType } from "@core/apiRequests/graphql-global-types";
import { ConnectedBankAccount } from "@core/enrollment/finalize/payment/ConnectedBankAccount";
import { handleError } from "@core/error";
import { AutopayPreferenceChoices } from "@core/portalAccountSettings";
import { usePortalAccountNumber } from "@core/portalUtils";
import { Button } from "@krakentech/coral";
import {
  BankAccountDetailsType,
  EditAchPaymentMethodForm,
  EditAchPaymentMethodFormValues,
  Stack,
} from "@octopus-energy/coral-mui";
import { useStripe } from "@stripe/react-stripe-js";
import { StripeError } from "@stripe/stripe-js";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import { ChangeEvent, FC, FormEvent, useState } from "react";
import { useBooleanState } from "react-use-object-state";

export type EditAchPaymentMethodProps = {
  editAchPayment?: VoidFunction;
  error?: ApolloError | Error | null;
  onSubmit: (
    e: FormEvent,
    bankAccountInputArgs: EditAchPaymentMethodFormValues
  ) => void;
  stripeError: StripeError;
  stripeErrorMessage: string;
  setStripeError?: (val: string | null) => void;
  autopayPreference?: AutopayPreferenceChoices | null;
  handleAutopayPreferenceChange: (event: ChangeEvent<HTMLInputElement>) => void;
  showAutopayErrorMessage?: ReturnType<typeof useBooleanState>;
  handleAuthorizeAutopayCheckboxChange: (
    e: ChangeEvent<HTMLInputElement>
  ) => void;
  showAuthorizeAutopayError: ReturnType<typeof useBooleanState>;
  isPrepay: boolean | undefined | null;
  preEnrolledInAutopay: Boolean;
  isReplacingAutopay: boolean;
};

export const EditAchPaymentMethod: FC<EditAchPaymentMethodProps> = ({
  editAchPayment,
  error,
  onSubmit,
  stripeError,
  stripeErrorMessage,
  setStripeError,
  autopayPreference,
  handleAutopayPreferenceChange,
  showAutopayErrorMessage,
  handleAuthorizeAutopayCheckboxChange,
  showAuthorizeAutopayError,
  isPrepay,
  preEnrolledInAutopay,
  isReplacingAutopay,
}) => {
  const { t: tCommon } = useTranslation("common");
  const { t: tNewBankAccount } = useTranslation(
    "payments/add-new-bank-account"
  );
  const { t: tStripe } = useTranslation("enrollment/finalize");
  const stripe = useStripe();
  const [getEmbeddedSecretForNewPaymentInstruction] =
    useGetEmbeddedSecretForNewPaymentInstructionMutation();
  const accountNumber = usePortalAccountNumber();
  const { data } = useQueryAccountViewer({
    onError: handleError,
  });
  const [financialConnectionsDetails, setFinancialConnectionsDetails] =
    useState<BankAccountDetailsType>({
      last4: "",
      bank_name: "",
      id: "",
      secretKey: "",
    });
  const loading = useBooleanState(false);
  const showConnectBankButton = useBooleanState(
    !Boolean(financialConnectionsDetails.id)
  );
  const locale = useRouter().locale;
  const fullName = data?.viewer?.fullName;
  const email = data?.viewer?.email;

  const handleCancel = () => {
    setStripeError && setStripeError(null);
    editAchPayment && editAchPayment();
  };

  const handleStripeFinancialConnectionsSetup = async () => {
    loading.setTrue();
    try {
      const secretKey = await getEmbeddedSecretForNewPaymentInstruction({
        variables: {
          input: {
            accountNumber: accountNumber,
            instructionType: PaymentType.DirectDebit,
          },
        },
      })
        .then((res) => {
          return res.data?.getEmbeddedSecretForNewPaymentInstruction?.secretKey;
        })
        .catch(handleError);
      if (secretKey && fullName && email) {
        const collectBankAccount = await stripe?.collectBankAccountForSetup({
          clientSecret: secretKey,
          params: {
            payment_method_type: "us_bank_account",
            payment_method_data: {
              billing_details: {
                name: fullName,
                email: email,
              },
            },
          },
          expand: ["payment_method"],
        });
        const paymentMethod = collectBankAccount?.setupIntent?.payment_method;
        if (
          typeof paymentMethod !== "string" &&
          paymentMethod?.id &&
          paymentMethod.us_bank_account?.last4 &&
          paymentMethod.us_bank_account.bank_name
        ) {
          setFinancialConnectionsDetails({
            id: paymentMethod.id,
            last4: paymentMethod.us_bank_account?.last4,
            bank_name: paymentMethod.us_bank_account?.bank_name,
            secretKey,
          });
          showConnectBankButton.setFalse();
        } else {
          setStripeError &&
            setStripeError(collectBankAccount?.error?.message as string);
          showConnectBankButton.setTrue();
        }
      }
    } catch (err) {
      handleError(err);
    }
    loading.setFalse();
  };

  return (
    <Stack spacing={2}>
      {showConnectBankButton.state && (
        <Button
          color="secondary"
          fullWidth
          onClick={handleStripeFinancialConnectionsSetup}
          id="link-element"
        >
          {loading.state && !financialConnectionsDetails?.id
            ? "Connecting..."
            : tStripe("connectWithStripe")}
        </Button>
      )}
      {!loading.state && financialConnectionsDetails.id && (
        <ConnectedBankAccount
          last4={financialConnectionsDetails.last4}
          bank_name={financialConnectionsDetails.bank_name}
        />
      )}
      <EditAchPaymentMethodForm
        financialConnectionsDetails={financialConnectionsDetails}
        labels={{
          submit: tCommon("submit"),
          authorization: tNewBankAccount("authorization"),
        }}
        onSubmit={onSubmit}
        errorMessage={
          stripeErrorMessage
            ? stripeErrorMessage
            : stripeError
            ? stripeError.message
            : error?.message
        }
        onCancel={handleCancel}
        locale={locale}
        autopayPreference={autopayPreference}
        handleAutopayPreferenceChange={handleAutopayPreferenceChange}
        showAutopayErrorMessage={showAutopayErrorMessage}
        handleAuthorizeAutopayCheckboxChange={
          handleAuthorizeAutopayCheckboxChange
        }
        showAuthorizeAutopayError={showAuthorizeAutopayError}
        isPrepay={Boolean(isPrepay)}
        preEnrolledInAutopay={preEnrolledInAutopay}
        isReplacingAutopay={isReplacingAutopay}
      />
    </Stack>
  );
};
