export enum OctopusProductIdKeys {
  OCTO_PLUS_12M = "OCTO_PLUS_12M",
  OCTO_THERMOSTAT_12M = "OCTO_THERMOSTAT_12M",
  OCTO_EV_12M = "OCTO_EV_12M",
  OCTO_PLUS_24M = "OCTO_PLUS_24M",
  OCTO_PLUS_36M = "OCTO_PLUS_36M",
  OCTO_GO_12M = "OCTO_GO_12M",
  RIVIAN_TIME_OF_USE = "RIVIAN_TIME_OF_USE",
}

export enum OctopusProductId {
  OCTO_PLUS_12M = "OCTOPLUS-12M",
  OCTO_THERMOSTAT_12M = "OCTO-THERMOSTAT-12",
  OCTO_EV_12M = "OCTO-EV-12",
  OCTO_PLUS_24M = "OCTOPLUS-24M",
  OCTO_PLUS_36M = "OCTOPLUS-36M",
  OCTO_GO_12M = "OCTOGO-12M",
  RIVIAN_TIME_OF_USE = "RIVIAN-TIME-OF-USE",
}

export enum OctopusProductKrakenId {
  OCTO_PLUS_12M = "38",
  OCTO_THERMOSTAT_12M = "172",
  OCTO_EV_12M = "171",
  OCTO_PLUS_36M = "37",
  OCTO_GO_12M = "39",
  RIVIAN_TIME_OF_USE = "242",
}

export enum OctopusProductTranslation {
  OCTO_PLUS_12M = "enrollment/products/octoPlus12",
  //eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  OCTO_THERMOSTAT_12M = "enrollment/products/octoPlus12",
  //eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  OCTO_EV_12M = "enrollment/products/octoPlus12",
  OCTO_PLUS_24M = "enrollment/products/octoPlus24",
  OCTO_PLUS_36M = "enrollment/products/octoPlus36",
  OCTO_GO_12M = "enrollment/products/octoGo12",
  //eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  RIVIAN_TIME_OF_USE = "enrollment/products/octoPlus12",
}
