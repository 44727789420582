import { IconHidePassword, IconShowPassword } from "@krakentech/icons";
import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@octopus-energy/coral-mui";
import useTranslation from "next-translate/useTranslation";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useBooleanState } from "react-use-object-state";
import * as Yup from "yup";

export type CreatePasswordFormPartial = {
  password: string;
  confirmPassword: string;
};

export const passwordValidation = Yup.string()
  .min(8, "minimum")
  .required("Please enter your password");
export const confirmPasswordValidation = Yup.string()
  .min(8, "minimum")
  .oneOf([Yup.ref("password"), null], "Passwords must match")
  .required("Please confirm your password.");

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CreatePassword = (params?: { formik?: any }) => {
  const { t } = useTranslation("enrollment/passwordAndAgreements");

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const { register, setValue } = useFormContext<CreatePasswordFormPartial>();

  const showPassword = useBooleanState(false);

  const contains8Chars = password.length > 7;
  const containsAnUpperCase = /[A-Z]/.test(password);
  const containsANumber = /\d/.test(password);
  const passwordsMatch = password === confirmPassword;

  const textFieldProps = {
    required: true,
    fullWidth: true,
    type: showPassword.state ? "text" : "password",
    onBlur: params?.formik?.handleBlur,
    InputProps: {
      endAdornment: (
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={showPassword.toggle}
            edge="end"
          >
            {showPassword.state ? <IconHidePassword /> : <IconShowPassword />}
          </IconButton>
        </InputAdornment>
      ),
    },
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      mt={2}
      data-cy="password-validation"
    >
      <TextField
        {...textFieldProps}
        {...register("password")}
        data-cy="password"
        id={params?.formik ? "password" : undefined}
        name={params?.formik ? "password" : undefined}
        value={params?.formik ? password : undefined}
        label={t("password")}
        onChange={(e) => {
          setPassword(e.target.value);
          setValue("password", e.target.value);
          params?.formik?.setFieldValue("password", e.target.value);
        }}
        error={
          Boolean(password) &&
          !contains8Chars &&
          !containsAnUpperCase &&
          !containsANumber
        }
      />
      <TextField
        {...textFieldProps}
        {...register("confirmPassword")}
        data-cy="password-repeat"
        sx={{ mt: 2 }}
        id={params?.formik ? "confirmPassword" : undefined}
        name={params?.formik ? "confirmPassword" : undefined}
        value={confirmPassword}
        label={t("confirmYourPassword")}
        onChange={(e) => {
          setConfirmPassword(e.target.value);
          setValue("confirmPassword", e.target.value);
          params?.formik?.setFieldValue("confirmPassword", e.target.value);
        }}
        error={Boolean(confirmPassword) && !passwordsMatch}
      />
      <Typography variant="caption" mt={2} height={18}>
        {t("passwordMustInclude")}
      </Typography>
      <Typography
        variant="caption"
        color={contains8Chars ? "success" : "error"}
        height={18}
      >
        {t("atLeast8Characters")}
      </Typography>
      <Typography
        variant="caption"
        color={containsAnUpperCase ? "success" : "error"}
        height={18}
      >
        {t("atLeast1UppercaseLetter")}
      </Typography>
      <Typography
        variant="caption"
        color={containsANumber ? "success" : "error"}
        height={18}
      >
        {t("atLeast1Number")}
      </Typography>
      <Typography
        variant="caption"
        color={passwordsMatch && Boolean(confirmPassword) ? "success" : "error"}
        height={18}
      >
        {t("passwordsMustMatch")}
      </Typography>
    </Box>
  );
};
