import { gql } from "@apollo/client";
import { createUseMutation } from "@octopus-energy/apollo-client";
import {
  ObtainKrakenTokenMutation,
  ObtainKrakenTokenMutationVariables,
} from "./__generated__/useObtainKrakenTokenMutation.generated";

export const MUTATION_OBTAIN_KRAKEN_TOKEN = gql`
  mutation obtainKrakenToken($input: ObtainJSONWebTokenInput!) {
    obtainKrakenToken(input: $input) {
      token
      refreshToken
      refreshExpiresIn
      payload
    }
  }
`;

export const useObtainKrakenTokenMutation = createUseMutation<
  ObtainKrakenTokenMutation,
  ObtainKrakenTokenMutationVariables
>(MUTATION_OBTAIN_KRAKEN_TOKEN);
