import { gql } from "@apollo/client";
import { createUseMutation } from "@octopus-energy/apollo-client";
import {
  ChangePaymentScheduleTypeMutation,
  ChangePaymentScheduleTypeMutationVariables,
} from "./__generated__/useChangePaymentScheduleTypeMutation.generated";

export const MUTATION_CHANGE_PAYMENT_SCHEDULE_TYPE = gql`
  mutation ChangePaymentScheduleType($input: ChangePaymentScheduleInput!) {
    changePaymentScheduleType(input: $input) {
      typeUpdated
      changesValidOn
    }
  }
`;

export const useChangePaymentScheduleTypeMutation = createUseMutation<
  ChangePaymentScheduleTypeMutation,
  ChangePaymentScheduleTypeMutationVariables
>(MUTATION_CHANGE_PAYMENT_SCHEDULE_TYPE);
