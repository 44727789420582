import { keyframes, styled } from "@mui/material";
import { Box } from "@octopus-energy/coral-mui";
import Image from "next/image";
import { FC, ReactNode } from "react";

type AnimationWrapperProps = {
  scale: number;
  duration: number;
};
export type AnimatedCloudProps = {
  children: ReactNode;
};
const Cloud = styled("div")(() => ({
  background: "#FFFFFF",
  borderRadius: "6.25rem",
  boxShadow: "0 8px 5px rgb(0 0 0 / 10%)",
  height: "120px",
  position: "relative",
  width: "350px",
  "&:before": {
    background: "#FFFFFF",
    content: '""',
    position: "absolute",
    zIndex: -1,
    borderRadius: "200px",
    width: "180px",
    height: "180px",
    right: "50px",
    top: "-90px",
  },
  "&:after": {
    background: "#FFFFFF",
    content: '""',
    position: "absolute",
    zIndex: -1,
    borderRadius: "100px",
    width: "100px",
    height: "100px",
    left: "50px",
    top: "-50px",
  },
}));
const CloudBackgroundWrapper = styled("div")(() => ({
  bottom: 0,
  left: 0,
  paddingTop: "50px",
  position: "absolute",
  right: 0,
  top: 0,
  zIndex: 1,
}));
const animatedCloud = keyframes`
    0% {
        margin-left: -1000px;
    }
    100% {
        margin-left: 100%
    }
`;
const AnimationWrapper = styled("div")<AnimationWrapperProps>(
  ({ scale, duration }) => ({
    transform: `scale(${scale})`,
    animation: `${animatedCloud} ${duration}s linear infinite`,
  })
);
const CloudContainer = styled("div")(() => ({
  background:
    "linear-gradient( 180deg, rgba(193, 244, 253, 1) 0%, rgba(34, 201, 246, 1) 43% )",
  textAlign: "center",
  padding: "3rem",
  overflow: "hidden",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  width: "100vw",
  position: "relative",
  left: "50%",
  right: "50%",
  marginLeft: "-50vw",
  marginRight: "-50vw",
}));
const float = keyframes`
     0% {
       transform: translatey(0px);
    }
     50% {
       transform: translatey(-30px);
    }
     100% {
      transform: translatey(0px);
     }
`;
const SuperConstantineWrapper = styled("div")(() => ({
  position: "absolute",
  top: "8%",
  left: "10px",
  zIndex: 2,
  marginTop: 50,
  animation: `${float} 6s ease-in-out infinite`,
}));
export const AnimatedCloud: FC<AnimatedCloudProps> = ({ children }) => {
  return (
    <CloudContainer>
      <CloudBackgroundWrapper>
        <AnimationWrapper scale={0.65} duration={35}>
          <Cloud />
        </AnimationWrapper>
        <AnimationWrapper scale={0.3} duration={20}>
          <Cloud />
        </AnimationWrapper>
        <AnimationWrapper scale={0.5} duration={30}>
          <Cloud />
        </AnimationWrapper>
        <AnimationWrapper scale={0.4} duration={18}>
          <Cloud />
        </AnimationWrapper>
        <AnimationWrapper scale={0.55} duration={25}>
          <Cloud />
        </AnimationWrapper>
      </CloudBackgroundWrapper>
      <SuperConstantineWrapper>
        <Box mt={{ xs: 12, sm: 0 }}>
          <Image
            src="/images/super-constantine.png"
            alt="SuperConstantine"
            width={296}
            height={213}
            unoptimized
          />
        </Box>
      </SuperConstantineWrapper>
      {children}
    </CloudContainer>
  );
};
