import { useOctopusProduct } from "@core/enrollment";
import {
  evBrands,
  evModels,
  thermostatBrands,
  thermostatModels,
} from "@core/enrollment/ioBrands";
import { OctopusProductId } from "@core/product";
import { Maybe } from "@core/types";
import { IconInfo } from "@krakentech/icons";
import { MenuItem, useMediaQuery } from "@mui/material";
import { Theme } from "@mui/material/styles";
import {
  Alert,
  Box,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Link,
  Stack,
  TextField,
  Typography,
} from "@octopus-energy/coral-mui";
import { add, format } from "date-fns";
import useTranslation from "next-translate/useTranslation";
import { FC, InputHTMLAttributes, useState } from "react";
import { useBooleanState } from "react-use-object-state";
import { RenewalAgreementsForm } from "./RenewalAgreementsForm";
import { useEvaluateContractStatus } from "./useEvaluateContractStatus";
import { IOProduct } from "@core/enrollment/IOEnrollmentConfirmationDialog";

export type RenewalConfirmationDialogProps = {
  open: boolean;
  onClose: VoidFunction;
  productId: OctopusProductId;
  renewalQuoteCode?: string;
  renewalStartDate?: Date;
  withEV?: boolean;
  withThermostat?: boolean;
};

export const RenewalConfirmationDialog: FC<RenewalConfirmationDialogProps> = ({
  open,
  onClose,
  productId,
  renewalQuoteCode,
  renewalStartDate,
  withEV,
  withThermostat,
}) => {
  const { t } = useTranslation("product/productCard");
  const selectedRenewalProduct = useOctopusProduct(productId);
  const ioAgreementChecked = useBooleanState(false);
  const criticalCareChecked = useBooleanState(false);
  const [productModel, setProductModel] = useState("");
  const [selectedIOProduct, setSelectedIOProduct] =
    useState<Maybe<IOProduct>>(undefined);
  const fullscreenInformationDialog = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const { currentValidTo } = useEvaluateContractStatus();
  const renewalEndDate =
    currentValidTo && add(new Date(currentValidTo), { years: 1 });

  const disableConfirmButton = () => {
    if (withEV || withThermostat) {
      if (
        !criticalCareChecked.state ||
        !ioAgreementChecked.state ||
        selectedIOProduct === IOProduct.OTHER ||
        (selectedIOProduct &&
          (withEV ? evModels : thermostatModels).hasOwnProperty(
            selectedIOProduct
          ) &&
          !productModel)
      ) {
        return true;
      }
    }
    return false;
  };

  return (
    <Dialog
      maxWidth="sm"
      fullScreen={fullscreenInformationDialog}
      open={open}
      data-cy="renewal-confirmation-dialog"
    >
      <DialogContent data-cy="renewal-confirmation-dialog-content">
        <Stack>
          <Typography variant="h3" sx={{ mb: 2 }}>
            {t("finalizeYourRenewal")}
          </Typography>
          <Typography variant="body2">
            {t("youreRenewingWith")} {selectedRenewalProduct?.displayName}
            {withThermostat && t("withAConnectedThermostat")}
            {withEV && t("withAConnectedEV")}. {t("yourNewPlanWillStartOn")}{" "}
            <strong>
              {currentValidTo && format(new Date(currentValidTo), "M/d/yy")}{" "}
            </strong>
            {t("andEndOn")}{" "}
            <strong>
              {renewalEndDate && format(new Date(renewalEndDate), "M/d/yy")}
            </strong>
            .
          </Typography>
        </Stack>
        {(withEV || withThermostat) && (
          <>
            <Typography variant="body2" fontWeight="bold" sx={{ mb: 1 }}>
              {t(
                withThermostat
                  ? "Select your thermostat"
                  : "Select your electric vehicle"
              )}
            </Typography>
            <Alert
              variant="outlined"
              color="tertiary"
              severity="warning"
              icon={
                <Stack justifyContent="center">
                  <IconInfo size={22} />
                </Stack>
              }
            >
              <Typography color="tertiary" variant="h5">
                {t(`io${withThermostat ? "Thermostat" : "EV"}AgreementTitle`)}
              </Typography>
            </Alert>
            <TextField
              data-cy="io-enrollment-confirmation-dialog-textfield"
              label={t(
                withThermostat
                  ? "Smart thermostat brand"
                  : "Electric vehicle make"
              )}
              onChange={(e) => {
                setSelectedIOProduct(e.target.value as IOProduct);
                setProductModel("");
                criticalCareChecked.setFalse();
                ioAgreementChecked.setFalse();
              }}
              select
              fullWidth
              required
              sx={{ mt: 3 }}
              value={selectedIOProduct}
            >
              {(withEV ? evBrands : thermostatBrands).map((t) => (
                <MenuItem key={t.value} value={t.value} data-cy={t.value}>
                  {t.label}
                </MenuItem>
              ))}
              {
                <MenuItem value={IOProduct.OTHER}>
                  {t("ioOtherDevice")}
                </MenuItem>
              }
            </TextField>

            {(withEV ? evModels : thermostatModels).hasOwnProperty(
              selectedIOProduct as IOProduct
            ) && (
              <TextField
                data-cy="io-enrollment-confirmation-dialog-textfield"
                label={
                  withEV ? "Electric vehicle model" : "Smart thermostat model"
                }
                onChange={(e) => {
                  setProductModel(e.target.value);
                }}
                select
                fullWidth
                required
                sx={{ mt: 3 }}
              >
                {selectedIOProduct &&
                  (withEV
                    ? evModels[selectedIOProduct]
                    : thermostatModels[selectedIOProduct]
                  ).map((t) => (
                    <MenuItem key={t.value} value={t.value} data-cy={t.value}>
                      {t.label}
                    </MenuItem>
                  ))}
              </TextField>
            )}

            {withThermostat && selectedIOProduct === IOProduct.HONEYWELL && (
              <Typography variant="body2" mt={1}>
                {t("ioThermostatHoneywellNote")}
                <Typography
                  variant="inherit"
                  fontWeight="bold"
                  component="span"
                >
                  {t("ioThermostatHoneywellNoteBold")}
                </Typography>
              </Typography>
            )}
            <Typography mt={3}>
              {selectedIOProduct === IOProduct.OTHER && (
                <>
                  <b>
                    {" "}
                    {t(
                      `io${
                        withThermostat ? "Thermostat" : "EV"
                      }AgreementDescriptionSubtitle`
                    )}
                  </b>{" "}
                  {t(
                    `io${
                      withThermostat ? "Thermostat" : "EV"
                    }AgreementDescriptionText`
                  )}{" "}
                  <Link
                    href="https://octopus.typeform.com/to/Iw0w5xOG"
                    target="_blank"
                    rel="noopener"
                  >
                    {withEV && t("ioContactUs")}
                  </Link>
                </>
              )}
            </Typography>

            {selectedIOProduct && selectedIOProduct !== IOProduct.OTHER && (
              <FormGroup sx={{ mt: 3 }}>
                <FormControlLabel
                  sx={{
                    ".MuiFormControlLabel-asterisk": {
                      display: "none",
                    },
                  }}
                  control={
                    <Checkbox
                      required
                      checked={ioAgreementChecked.state}
                      onChange={ioAgreementChecked.toggle}
                      inputProps={
                        {
                          "data-cy":
                            "io-enrollment-confirmation-dialog-checkbox",
                        } as InputHTMLAttributes<HTMLInputElement>
                      }
                      data-cy="io-enrollment-confirmation-dialog-checkbox-text"
                    />
                  }
                  label={
                    <Typography>
                      {t(
                        `io${
                          withThermostat ? "Thermostat" : "EV"
                        }AgreementCheckboxLabel`
                      )}
                    </Typography>
                  }
                />
                <FormControlLabel
                  sx={{
                    ".MuiFormControlLabel-asterisk": {
                      display: "none",
                    },
                    mt: 2,
                  }}
                  control={
                    <Checkbox
                      required
                      checked={criticalCareChecked.state}
                      onChange={criticalCareChecked.toggle}
                      inputProps={
                        {
                          "data-cy":
                            "io-enrollment-critical-care-confirmation-dialog-checkbox",
                        } as InputHTMLAttributes<HTMLInputElement>
                      }
                      data-cy="io-enrollment-critical-care-confirmation-dialog-checkbox-text"
                    />
                  }
                  label={<Typography>{t("agreeToCriticalCare")}</Typography>}
                />
              </FormGroup>
            )}
          </>
        )}
        {productId && (
          <RenewalAgreementsForm
            productId={productId}
            renewalQuoteCode={renewalQuoteCode}
            renewalStartDate={renewalStartDate}
            withEV
            withThermostat
            disabled={disableConfirmButton()}
          />
        )}
        <Box display="flex" justifyContent="center" mt={3}>
          <Link
            data-cy="io-enrollment-cancel-button"
            onClick={() => {
              onClose();
              ioAgreementChecked.setFalse();
              setProductModel("");
              setSelectedIOProduct(undefined);
            }}
            sx={{ textDecoration: "underline" }}
          >
            {t("ioAgreementCancel")}
          </Link>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
