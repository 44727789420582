import { getGlobalParametersSessionData } from ".";
import { useEffect } from "react";
import { useGlobalParameterState } from "./useGlobalParametersState";

/**
 * Checks session storage to see if global parameter state for enrollment has been stored, will only
 * execute on initial impression.
 */

export const useCheckGlobalParametersState = () => {
  const [, setGlobalParametersState] = useGlobalParameterState();

  useEffect(() => {
    setGlobalParametersState(getGlobalParametersSessionData());
  }, [setGlobalParametersState]);
};
