import { useRouter } from "next/router";

/**
 * Hook returning a customer's account number from the URL inside of portal
 * @returns the customer's account number
 * @example
 * const AccountNumber = usePortalAccountNumber();
 */
export const usePortalAccountNumber = (): string =>
  (useRouter().query.accountNumber || "") as string;
