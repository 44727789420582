import { useCustomerIsRivian } from "@core/enrollment";
import { useFeatureFlag } from "@core/featureFlags";
import { Maybe } from "@core/types";
import { useTheme } from "@mui/material";
import { Stack } from "@octopus-energy/coral-mui";
import { FC } from "react";
import {
  AllPaperless,
  AutopayRequired,
  BillCredit,
  ChangesMonthly,
  EarlyTerminationFee,
  NoBaitAndSwitch,
  OctoGridboost,
  PeakAndOffPeakHours,
} from "./Bullets";
import { Compatibility } from "./Compatibility";
import { FullyRenewable } from "./FullyRenewable";
import { LockedInRate } from "./LockedInRate";
import { SolarBuyback } from "./SolarBuyback";

export type ProductCardBulletsProps = {
  isMTM?: boolean;
  isPrepay?: boolean;
  lockedInDuration?: number;
  withEV?: Maybe<boolean>;
  withThermostat?: Maybe<boolean>;
};

export const ProductCardBullets: FC<ProductCardBulletsProps> = ({
  isMTM,
  isPrepay,
  lockedInDuration,
  withEV,
  withThermostat,
}) => {
  const theme = useTheme();
  const customerIsRivian = useCustomerIsRivian();
  const autopayRequiredFeature = useFeatureFlag("autopayRequired").active;

  const lockedInRate = !isMTM && !customerIsRivian,
    changesMonthly = isMTM,
    fullyRenewable = true,
    peakAndOffPeakHours = customerIsRivian,
    billCredit = customerIsRivian,
    solarBuyback = true,
    noBaitAndSwitch = !isMTM && !customerIsRivian,
    allPaperless = isPrepay,
    autopayRequired = isPrepay || autopayRequiredFeature,
    octoGridboost = customerIsRivian,
    earlyTerminationFee = !isMTM,
    compatibility = withEV || withThermostat;

  return (
    <Stack spacing={2} data-cy="plan-bullets" flexWrap="wrap">
      {autopayRequired && <AutopayRequired />}
      {lockedInRate && <LockedInRate rateDurationInMonths={lockedInDuration} />}
      {changesMonthly && <ChangesMonthly />}
      {fullyRenewable && <FullyRenewable />}
      {peakAndOffPeakHours && <PeakAndOffPeakHours />}
      {billCredit && <BillCredit />}
      {solarBuyback && (
        <SolarBuyback
          color1={
            isMTM ? theme.palette.secondary.dark : theme.palette.primary.light
          }
          color2={isMTM ? theme.palette.text.primary : "#FFBAFF"}
        />
      )}
      {noBaitAndSwitch && <NoBaitAndSwitch />}
      {allPaperless && <AllPaperless />}
      {octoGridboost && <OctoGridboost />}
      {earlyTerminationFee && <EarlyTerminationFee />}
      {compatibility && (
        <Compatibility withEV={withEV} withThermostat={withThermostat} />
      )}
    </Stack>
  );
};
