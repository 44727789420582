import { useEnrollment } from "@core/enrollment";
import { handleApolloMutationError } from "@core/error";
import {
  obtainNewKrakenToken,
  useCreateQuoteMutation,
  useEmailQuoteMutation,
} from "../apiRequests";
import {
  EstimationInterval,
  PaymentMethods,
} from "../apiRequests/graphql-global-types";
import { useOctopusProducts } from "../product/useOctopusProducts";
import { sortQuotedProducts } from "./sortProducts/sortQuotedProducts";

export type CreateQuoteProps = {
  emailAddress?: string;
  kwhConsumption?: string | number;
  postcode?: string;
  esiId?: string;
};

export const useCreateQuote = () => {
  const [, setOctopusProducts] = useOctopusProducts();
  const { setQuoteCode } = useEnrollment();
  const [emailQuote] = useEmailQuoteMutation();
  const [createQuoteMutation, createQuoteOptions] = useCreateQuoteMutation();

  const createQuote = async ({
    emailAddress,
    esiId,
    kwhConsumption,
    postcode,
  }: CreateQuoteProps = {}) => {
    const { token } = await obtainNewKrakenToken();
    const res = await createQuoteMutation({
      variables: {
        input: {
          postCode: postcode || "",
          paymentMethod: PaymentMethods.CreditCard,
          esiId,
          extraCriteria: {
            kwhConsumption,
            kwhUsagePeriod: EstimationInterval.Monthly,
            email: emailAddress,
          },
        },
      },
      context: {
        headers: {
          authorization: token,
        },
      },
    });
    const quoteCode = res.data?.createQuote?.quote?.code;
    const quotedProducts = res.data?.createQuote?.quote?.quotedProducts;
    if (quoteCode) {
      setQuoteCode(quoteCode);
      if (emailAddress) {
        emailQuote({
          variables: {
            input: {
              email: emailAddress,
              quoteCode,
            },
          },
          context: {
            headers: {
              authorization: token,
            },
          },
          onError: handleApolloMutationError,
        });
      }
    }
    setOctopusProducts({
      octopusProducts: sortQuotedProducts(quotedProducts),
    });
    return res.data?.createQuote;
  };
  return [createQuote, createQuoteOptions] as const;
};
