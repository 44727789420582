import { Theme, useMediaQuery } from "@mui/material";
import { Skeleton, Stack } from "@octopus-energy/coral-mui";
import { format } from "date-fns";
import useTranslation from "next-translate/useTranslation";
import Image from "next/image";
import { FC, useEffect, useState } from "react";
import { useBooleanState } from "react-use-object-state";
import deleteButton from "../../public/images/delete.svg";
import replaceButton from "../../public/images/replace.svg";
import {
  QUERY_DEFAULT_PAYMENT_INSTRUCTION,
  QUERY_IS_AUTO_PAY_ENABLED,
  useChangePaymentScheduleTypeMutation,
  useInvalidatePaymentInstructionMutation,
  useQueryAccount,
  useQueryDefaultPaymentInstruction,
  useQueryProperties,
} from "../apiRequests";
import {
  PaymentScheduleTypeChoices,
  PaymentType,
  ScheduleType,
} from "../apiRequests/graphql-global-types";
import { handleApolloMutationError, handleError } from "../error";
import { useSnackbarNotification } from "../molecules";
import {
  getPaymentScheduleType,
  getPlan,
  usePortalAccountNumber,
} from "../portalUtils";
import { DeletePaymentMethodDialog } from "./DeletePaymentMethodDialog";
import { AutopayPreferenceChoices } from "./PaymentCreditCardSettings";
import { ReplacePaymentMethodDialog } from "./ReplacePaymentMethodDialog";

const useDefaultPaymentInstructionData = (paymentType: PaymentType) => {
  const accountNumber = usePortalAccountNumber();

  const { data } = useQueryDefaultPaymentInstruction({
    variables: {
      accountNumber,
      instructionType: paymentType,
    },
  });

  return data;
};

const useChangeScheduleType = () => {
  const accountNumber = usePortalAccountNumber();
  const [changeScheduleType] = useChangePaymentScheduleTypeMutation();

  const updateScheduleType = (scheduleType: PaymentScheduleTypeChoices) => {
    return changeScheduleType({
      variables: {
        input: {
          accountNumber,
          scheduleType,
        },
      },
    });
  };

  return updateScheduleType;
};

export type DeletePaymentMethodSettingsProps = {
  additionalPaymentType: PaymentType;
  loading: boolean;
  paymentMethodId: string;
  refetch: VoidFunction;
  editing: ReturnType<typeof useBooleanState>;
  addCreditCardTrigger?: ReturnType<typeof useBooleanState>;
  addBankAccountTrigger?: ReturnType<typeof useBooleanState>;
  preEnrolledInAutopay: ReturnType<typeof useBooleanState>;
  setAutopayPreference?: (val: AutopayPreferenceChoices | null) => void;
  maskedAccountIdentifier: string | null | undefined;
  isReplacingAutopay: ReturnType<typeof useBooleanState>;
};

export const DeletePaymentMethodSettings: FC<
  DeletePaymentMethodSettingsProps
> = ({
  additionalPaymentType,
  loading,
  paymentMethodId,
  refetch,
  editing,
  addCreditCardTrigger,
  addBankAccountTrigger,
  preEnrolledInAutopay,
  setAutopayPreference,
  maskedAccountIdentifier,
  isReplacingAutopay,
}) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const { t } = useTranslation("payments/payment-methods");
  const accountNumber = usePortalAccountNumber();
  const changeScheduleType = useChangeScheduleType();
  const [notification] = useSnackbarNotification();
  const deleteProcessing = useBooleanState(false);
  const openDeleteDialog = useBooleanState(false);
  const openReplaceDialog = useBooleanState(false);
  const [selectedOption, setSelectedOption] = useState("");

  const handleOpenDeleteDialog = () => {
    openDeleteDialog.setTrue();
  };

  const handleCloseDeleteDialog = () => {
    openDeleteDialog.setFalse();
    setSelectedOption("");
  };

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
  };

  const handleOpenReplaceDialog = () => {
    openReplaceDialog.setTrue();
  };

  const handleCloseReplaceDialog = () => {
    openReplaceDialog.setFalse();
  };

  const queryProperties = useQueryProperties({
    variables: { accountNumber, includeSubscriptionFees: true },
    onError: handleError,
  });

  const { isPrepay } = getPlan(queryProperties.data);

  const atLeastOnePaymentForPrepay =
    useDefaultPaymentInstructionData(additionalPaymentType)
      ?.defaultPaymentInstruction?.status === "ACTIVE";

  const creditCardOnFile =
    useDefaultPaymentInstructionData(PaymentType.Card)
      ?.defaultPaymentInstruction?.status === "ACTIVE";

  const bankAccountOnFile =
    useDefaultPaymentInstructionData(PaymentType.DirectDebit)
      ?.defaultPaymentInstruction?.status === "ACTIVE";

  const { data } = useQueryAccount({
    variables: {
      accountNumber,
      activeOnDate: format(new Date(), "yyyy-MM-dd"),
    },
  });

  const autopayMethod = getPaymentScheduleType(data);

  const paymentMethodToBeDeleted =
    additionalPaymentType === PaymentType.DirectDebit
      ? PaymentType.Card
      : PaymentType.DirectDebit;

  const isDeletingAutopayMethod =
    (autopayMethod === ScheduleType.CardPayment &&
      paymentMethodToBeDeleted === PaymentType.Card) ||
    (autopayMethod === ScheduleType.DirectDebit &&
      paymentMethodToBeDeleted === PaymentType.DirectDebit);

  useEffect(() => {
    if (isDeletingAutopayMethod) {
      isReplacingAutopay.setTrue();
    }
  });

  const [deletePaymentMethod] = useInvalidatePaymentInstructionMutation({
    variables: {
      input: {
        accountNumber,
        id: paymentMethodId,
      },
    },
    onError: handleApolloMutationError,
    refetchQueries: [
      {
        query: QUERY_DEFAULT_PAYMENT_INSTRUCTION,
        variables: {
          accountNumber,
          instructionType: additionalPaymentType,
        },
      },
      {
        query: QUERY_DEFAULT_PAYMENT_INSTRUCTION,
        variables: {
          accountNumber,
        },
      },
      {
        query: QUERY_IS_AUTO_PAY_ENABLED,
        variables: {
          accountNumber,
        },
      },
    ],
  });

  const handleReplaceConfirm = async () => {
    deleteProcessing.setTrue();
    await deletePaymentMethod();
    await refetch();
    deleteProcessing.setFalse();
    handleCloseReplaceDialog();
    paymentMethodToBeDeleted === PaymentType.DirectDebit
      ? addBankAccountTrigger?.setTrue()
      : addCreditCardTrigger?.setTrue();
  };

  const handleDeleteConfirm = async (option: string) => {
    const performDeletionAndCheck = async () => {
      deleteProcessing.setTrue();
      await deletePaymentMethod();
      await refetch();
      deleteProcessing.setFalse();
    };

    switch (option) {
      case "replaceAutopayBankAccount":
        await performDeletionAndCheck();
        preEnrolledInAutopay.setTrue();
        handleCloseDeleteDialog();
        setAutopayPreference?.(AutopayPreferenceChoices.YES);
        addBankAccountTrigger?.setTrue();
        break;

      case "getStartedWithBankAccountAutopay":
        await performDeletionAndCheck();
        preEnrolledInAutopay.setTrue();
        handleCloseDeleteDialog();
        setAutopayPreference?.(AutopayPreferenceChoices.YES);
        addBankAccountTrigger?.setTrue();
        break;

      case "switchToBankAccountAutopay":
        await performDeletionAndCheck();
        changeScheduleType(PaymentScheduleTypeChoices.DirectDebit);
        notification.success(t("paymentMethodDeleted"));
        handleCloseDeleteDialog();
        editing.setFalse();
        break;

      case "replaceAutopayCreditCard":
        await performDeletionAndCheck();
        preEnrolledInAutopay.setTrue();
        handleCloseDeleteDialog();
        setAutopayPreference?.(AutopayPreferenceChoices.YES);
        addCreditCardTrigger?.setTrue();
        break;

      case "getStartedWithCreditCardAutopay":
        await performDeletionAndCheck();
        preEnrolledInAutopay.setTrue();
        handleCloseDeleteDialog();
        setAutopayPreference?.(AutopayPreferenceChoices.YES);
        addCreditCardTrigger?.setTrue();
        break;

      case "switchToCreditCardAutopay":
        await performDeletionAndCheck();
        changeScheduleType(PaymentScheduleTypeChoices.CardPayment);
        notification.success(t("paymentMethodDeleted"));
        handleCloseDeleteDialog();
        editing.setFalse();
        break;

      case "turnOffAutopay":
        await performDeletionAndCheck();
        changeScheduleType(PaymentScheduleTypeChoices.Manual);
        notification.success(t("paymentMethodDeleted"));
        handleCloseDeleteDialog();
        editing.setFalse();
        break;

      case "turnOffAutopayAndAddBankAccount":
        await performDeletionAndCheck();
        preEnrolledInAutopay.setFalse();
        changeScheduleType(PaymentScheduleTypeChoices.Manual);
        handleCloseDeleteDialog();
        addBankAccountTrigger?.setTrue();
        break;

      case "turnOffAutopayAndAddCreditCard":
        await performDeletionAndCheck();
        preEnrolledInAutopay.setFalse();
        changeScheduleType(PaymentScheduleTypeChoices.Manual);
        handleCloseDeleteDialog();
        addCreditCardTrigger?.setTrue();
        break;

      case "addNewBankAccount":
        await performDeletionAndCheck();
        addBankAccountTrigger?.setTrue();
        handleCloseDeleteDialog();
        break;

      case "addNewCreditCard":
        await performDeletionAndCheck();
        addCreditCardTrigger?.setTrue();
        handleCloseDeleteDialog();
        break;

      case "confirmDeletion":
        await performDeletionAndCheck();
        notification.success(t("paymentMethodDeleted"));
        handleCloseDeleteDialog();
        editing.setFalse();
        break;

      default:
        break;
    }
  };

  if (
    (isPrepay && !atLeastOnePaymentForPrepay) ||
    (isPrepay && isDeletingAutopayMethod)
  ) {
    return null;
  }

  return loading ? (
    <Skeleton variant="text" height={35} width="20%" />
  ) : (
    <>
      <Stack
        spacing={1}
        direction={isMobile ? "column" : "row"}
        alignItems="flex-end"
      >
        <Image
          src={replaceButton}
          alt="replace button"
          width={75}
          height={24}
          onClick={handleOpenReplaceDialog}
          style={{ cursor: "pointer" }}
        />
        <Image
          src={deleteButton}
          alt="delete button"
          width={75}
          height={24}
          onClick={handleOpenDeleteDialog}
          style={{ cursor: "pointer" }}
        />
      </Stack>
      <ReplacePaymentMethodDialog
        open={openReplaceDialog.state}
        onClose={handleCloseReplaceDialog}
        onReplaceConfirm={handleReplaceConfirm}
        paymentMethodToBeDeleted={paymentMethodToBeDeleted}
        maskedAccountIdentifier={maskedAccountIdentifier}
      />
      <DeletePaymentMethodDialog
        open={openDeleteDialog.state}
        onClose={handleCloseDeleteDialog}
        onDeleteConfirm={handleDeleteConfirm}
        selectedOption={selectedOption}
        onOptionChange={handleOptionChange}
        paymentMethodToBeDeleted={paymentMethodToBeDeleted}
        creditCardOnFile={creditCardOnFile}
        bankAccountOnFile={bankAccountOnFile}
        isDeletingAutopayMethod={isDeletingAutopayMethod}
        isPrepay={isPrepay}
      />
    </>
  );
};
