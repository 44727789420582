import { gql } from "@apollo/client";
import { createUseQuery } from "@octopus-energy/apollo-client";
import {
  GetQuoteQuery,
  GetQuoteQueryVariables,
} from "./__generated__/useGetQuote.generated";

export const GET_QUOTE_QUERY = gql`
  query getQuote($code: String!) {
    getQuote(code: $code) {
      quoteId
      code
      isExpired
      quotedProducts {
        product {
          code
          displayName
          description
          fullName
          id
          term
          efls {
            id
            url
            version
            loadZone
            serviceProvider
            language
          }
        }
        estimatedDetails {
          kwhUsage
          dayPercentage
          nightPercentage
          usagePeriod
          monthlyCostEstimate
          annualCostEstimate
          applicableRates {
            totalApplicableRate
            totalApplicableRateWithMonthlySubscriptionFees
            kwhUsage
            serviceProvider
            serviceProviderConsumptionRate
            serviceProviderStandingRate
            loadZone
            totalApplicableDayRate
            totalApplicableNightRate
            totalApplicableDayRateWithMonthlySubscriptionFees
            totalApplicableNightRateWithMonthlySubscriptionFees
            loadZoneDayTimeConsumptionRate
            loadZoneNightTimeConsumptionRate
            loadZoneConsumptionRate
            serviceProviderStandingRateUnitType
            serviceProviderConsumptionRateUnitType
            loadZoneDayTimeRateUnitType
            loadZoneNightTimeRateUnitType
            loadZoneConsumptionRateUnitType
            evDiscountRate
            thermostatDiscountRate
            totalApplicableRateWithEv
            totalApplicableRateWithThermostat
            totalApplicableRateWithMonthlySubscriptionFeesEv
            totalApplicableRateWithMonthlySubscriptionFeesThermostat
            __typename
          }
        }
        expiresAt
        loadZone
        serviceProvider
      }
    }
  }
`;

export const useGetQuote = createUseQuery<
  GetQuoteQuery,
  GetQuoteQueryVariables
>(GET_QUOTE_QUERY);
