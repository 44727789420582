import { useCallback, useEffect, useState } from "react";
import { useRouter } from "next/router";
import { IntlProvider } from "react-intl";
import i18n from "../../i18n.json";

export const AppIntlProvider = ({ children }) => {
  // Grab the route and the local from the router
  const { route, locale, defaultLocale } = useRouter();

  const [localeMessages, setLocaleMessages] = useState({});

  // Check the i18n.json file for details of where to find translations for the given route
  const localFileLocations = i18n.pages[route];

  // Set up a callback to loop through the found locations and import the translations,
  // then set them into local state.
  const fetchLocaleMessages = useCallback(async () => {
    if (!localFileLocations) {
      return;
    }

    setLocaleMessages({});

    localFileLocations.forEach(async (localFileLocation) => {
      const response = await import(
        `../../locales/${locale}/${localFileLocation}`
      );
      const newLocaleMessages = { ...localeMessages, ...response.default };

      setLocaleMessages(newLocaleMessages);
    });
  }, [locale, localFileLocations]);

  // When the above callback changes (usually on page change), import new messages
  useEffect(() => {
    fetchLocaleMessages();
  }, [fetchLocaleMessages]);

  // There is a split second between changing languages where the messages state is empty, though you can't see it as a user.
  // If we use the normal error functionality, react-intl becomes super moany.
  // This lets react-intl moan at us only when the message state isn't completely empty. If it is, we have bigger problems!
  const errorHandler = useCallback(() => {}, [localeMessages]);

  // Return the react-intl provider with the found messages, and the current locale
  return (
    <IntlProvider
      messages={localeMessages}
      locale={locale}
      defaultLocale={defaultLocale}
      onError={errorHandler}
    >
      {children}
    </IntlProvider>
  );
};
