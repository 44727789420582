import { gql } from "@apollo/client";
import { createUseQuery } from "@octopus-energy/apollo-client";
import {
  GetPropertiesQuery,
  GetPropertiesQueryVariables,
} from "./__generated__/useQueryProperties.generated";

export const QUERY_PROPERTIES = gql`
  query GetProperties(
    $accountNumber: String!
    $includeSubscriptionFees: Boolean!
    $includeFutureActiveAgreements: Boolean
  ) {
    properties(accountNumber: $accountNumber) {
      id
      meterPoints {
        id
        esiId
        agreements(
          includeFutureActiveAgreements: $includeFutureActiveAgreements
        ) {
          id
          autoTopUpAmountSelectedByUser
          totalApplicableRate(includeSubscriptionFees: $includeSubscriptionFees)
          totalApplicableDayRate
          totalApplicableNightRate
          product {
            id
            code
            displayName
            prepay
          }
          efls {
            id
            language
            url
          }
          validTo
          validFrom
        }
      }
    }
  }
`;

export const useQueryProperties = createUseQuery<
  GetPropertiesQuery,
  GetPropertiesQueryVariables
>(QUERY_PROPERTIES);
