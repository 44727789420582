import { ServiceProvider } from "@core/apiRequests/graphql-global-types";
import { GetCurrentAndHistoricalProductPriceHistoryQuery } from "@core/apiRequests/kraken/__generated__/useQueryGetCurrentAndHistoricalProductPriceHistory.generated";
import { addDays, format, subMonths } from "date-fns";
import { es } from "date-fns/locale";

export type Row = {
  [key: string]: string | number;
};

type Column = {
  id: string;
  name: string;
};

export const serviceProviders: Column[] = [
  { id: ServiceProvider.Centerpoint, name: "CNP" },
  { id: ServiceProvider.Oncor, name: "Oncor" },
  { id: ServiceProvider.Tnmp, name: "TNMP" },
  { id: ServiceProvider.AepTcc, name: "AEP TCC" },
  { id: ServiceProvider.AepTnc, name: "AEP TNC" },
];

const today = new Date();

export const periodStartAt = format(
  subMonths(today, 13),
  "yyyy-MM-dd'T'HH:mm:ssXXX"
);
export const periodEndAt = format(today, "yyyy-MM-dd'T'HH:mm:ssXXX");

/**
 * Flattens the data set and extracts the unique dates (effectiveDate) for the past 13 months.
 * Returns kWh price for each of the four service providers (i.e. columns) in given time frame (i.e. rows)
 * Formats kWh price to one decimal place / if price does not exist a hyphen (-) is assigned
 * Formats the date string to Month-year format.
 * Tranlates months to spanish if "es" parameter is passed (via locale)
 */
export function getKwhPricePeriods(
  data: GetCurrentAndHistoricalProductPriceHistoryQuery | undefined,
  lang: string | undefined
): Row[] {
  const pricePeriodInMonths = Array.from(
    new Set(
      data?.currentAndHistoricalProductPriceHistory?.flatMap(
        (product) =>
          product?.priceHistory?.flatMap((price) =>
            price?.prices?.map((p) => p?.effectiveDate)
          ) ?? []
      )
    )
  ).slice(0, 13);

  const locale = lang === "es" ? es : undefined;
  const kWhPricePeriod = pricePeriodInMonths.map((date: string) => {
    const formattedDate = format(addDays(new Date(date), 1), "MMMM-yy", {
      locale,
    });
    const kWhPrice: Row = {
      effectiveDate: formattedDate,
    };
    serviceProviders.forEach((column) => {
      const serviceProvider = column.id;
      const price = data?.currentAndHistoricalProductPriceHistory
        ?.flatMap(
          (product) =>
            product?.priceHistory?.find(
              (p) => p?.serviceProvider === serviceProvider
            )?.prices ?? []
        )
        .find((p) => p?.effectiveDate === date);
      const formattedKwhPrice = price?.kwhPrice;
      kWhPrice[serviceProvider] =
        formattedKwhPrice !== undefined && formattedKwhPrice !== null
          ? Number(formattedKwhPrice).toFixed(1)
          : "-";
    });
    return kWhPrice;
  });

  return kWhPricePeriod;
}
