import { ApolloError } from "@apollo/client";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const toError = (e: any) => {
  if (e instanceof Error) {
    return e;
  }
  const apolloError = e as ApolloError;
  if (apolloError.graphQLErrors.length) {
    const message = apolloError.message;
    const { errorCode, errorDescription, errorType } =
      (apolloError.graphQLErrors?.[0].extensions as {
        errorCode?: string;
        errorDescription?: string;
        errorType?: string;
      }) || {};
    return {
      message,
      code: errorCode,
      description: errorDescription,
      type: errorType,
    };
  }
  return null;
};
