import { gql } from "@apollo/client";
import { createUseMutation } from "@octopus-energy/apollo-client";
import {
  VerifyAccountMutation,
  VerifyAccountMutationVariables,
} from "./__generated__/useVerifyAccountMutation.generated";

export const VERIFY_ACCOUNT_MUTATION = gql`
  mutation VerifyAccount($input: VerifyAccountInput!) {
    verifyAccount(input: $input) {
      isVerified
    }
  }
`;

export const useVerifyAccountMutation = createUseMutation<
  VerifyAccountMutation,
  VerifyAccountMutationVariables
>(VERIFY_ACCOUNT_MUTATION);
