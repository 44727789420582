import { useEnrollmentV2 } from "@core/apiRequests/enrollment/useEnrollmentV2";
import { AccountCampaignChoices } from "@core/apiRequests/graphql-global-types";
import { useCustomerIsRivian } from "@core/enrollment";
import { Chip, Stack } from "@octopus-energy/coral-mui";
import useTranslation from "next-translate/useTranslation";
import { FC, ReactNode } from "react";

export type ProductCardTitleProps = {
  children: ReactNode;
  withThermostat?: boolean;
  withEV?: boolean;
  suppressCardHeaders?: boolean;
};

export const ProductCardTitle: FC<ProductCardTitleProps> = ({
  children,
  withThermostat,
  withEV,
  suppressCardHeaders = false,
}) => {
  const { t: tProductCard } = useTranslation("product/productCard");
  const customerIsRivian = useCustomerIsRivian();
  const [{ smartDevices }] = useEnrollmentV2();
  const thermostatSelected = smartDevices.includes(
    AccountCampaignChoices.SmartThermostat
  );
  const evSelected = smartDevices.includes(
    AccountCampaignChoices.ElectricVehicle
  );

  const recommendedPlan =
    evSelected && thermostatSelected
      ? withThermostat
      : evSelected
      ? withEV
      : withThermostat;

  return (
    <>
      {!suppressCardHeaders && (
        <Stack direction="row" justifyContent="center" minHeight={45}>
          {(withThermostat || withEV || customerIsRivian) && (
            <Chip
              data-cy="io-product-chip"
              color="primary"
              variant={
                recommendedPlan || customerIsRivian ? undefined : "outlined"
              }
              label={
                customerIsRivian
                  ? tProductCard("timeOfUseProduct")
                  : "Intelligent Octopus"
              }
            />
          )}
        </Stack>
      )}
      <Stack
        mt={2}
        direction="row"
        alignItems="start"
        justifyContent="center"
        flexWrap="wrap"
        gap={1}
        textAlign="center"
        minHeight={70}
      >
        {children}
      </Stack>
    </>
  );
};
