import { AppProps } from "next/dist/shared/lib/router/router";
import { useRouter } from "next/router";
import React from "react";
import { useAuth } from "./useAuth";
/**
 * Returns a HOC which either renders the page if logged in or null if not logged in
 * If not logged, the user is redirected to the login page
 *
 * @returns {React.FC}
 */

export const withAuth = (WrappedComponent: React.FC<AppProps["pageProps"]>) => {
  return (props: AppProps["pageProps"]) => {
    const router = useRouter();
    const [{ authed, loading }] = useAuth();
    if (loading) {
      return null;
    }
    // return authed component/page
    if (authed) {
      return <WrappedComponent {...props} />;
    }
    // redirect to sign in page
    router.replace({
      pathname: "/signin",
      query: { returnUrl: router.asPath },
    });
    return null;
  };
};
