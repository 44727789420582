import { TextField } from "@octopus-energy/coral-mui";
import useTranslation from "next-translate/useTranslation";
import { ChangeEvent, useEffect, useState } from "react";
import { useGetReadyFormContext } from ".";

export const SocialSecurityNumberField = () => {
  const { t } = useTranslation("enrollment/formFields");

  const {
    register,
    trigger,
    formState: { defaultValues, errors },
    setValue,
  } = useGetReadyFormContext();

  const [socialSecurity, setSocialSecurity] = useState(
    defaultValues?.socialSecurityNumber || ""
  );

  function formatSocial(value: string, previousValue: string) {
    // return nothing if no value
    // only allows 0-9 inputs

    if (!value) return value;

    const currentValue = value.replace(/[^\d]/g, "");
    const cvLength = currentValue.length;

    if (cvLength <= 9) {
      setValue("socialSecurityNumber", currentValue);
    }

    if (!previousValue || value.length > previousValue.length) {
      // returns: "x", "xx", "xxx"
      if (cvLength < 4) return currentValue;

      // returns: "xxx", "xxx-x", "xxx-xx", "xxx-xxx",
      if (cvLength < 6)
        return `${currentValue.slice(0, 3)}-${currentValue.slice(3)}`;

      // returns: "xxx-xxx-", "xxx-xxx-x", "xxx-xxx-xx", "xxx-xxx-xxx", "-xxx-xxx-xxxx"
      return `${currentValue.slice(0, 3)}-${currentValue.slice(
        3,
        5
      )}-${currentValue.slice(5, 9)}`;
    }
    return "";
  }

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const previousNumber = socialSecurity;
    setSocialSecurity(formatSocial(event.target.value, previousNumber));
  };

  useEffect(() => {
    if (socialSecurity && socialSecurity.length === 9) {
      setSocialSecurity(formatSocial(socialSecurity, ""));
    }
  }, []);

  return (
    <TextField
      {...register("socialSecurityNumber")}
      sx={{ width: "60%", mb: 2 }}
      label={t("labels_social")}
      variant="outlined"
      value={socialSecurity}
      onChange={handleChange}
      onBlur={() => {
        trigger("socialSecurityNumber");
      }}
      data-cy="social-security-number"
      error={Boolean(errors.socialSecurityNumber)}
      helperText={errors.socialSecurityNumber?.message || " "}
    />
  );
};
