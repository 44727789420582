import {
  obtainNewKrakenToken,
  useRunCreditCheckMutation,
  useUpdateQuoteUserMutation,
} from "@core/apiRequests";
import { SalesSubchannel } from "@core/apiRequests/graphql-global-types";
import { handleError } from "@core/error";
import { isPrepayProduct } from "@core/product";
import { format } from "date-fns";
import { useRouter } from "next/router";
import { CreditCheckStatus } from "../enrollmentConstants";
import {
  useEnrollment,
  useSelectedEnrollmentProduct,
} from "../enrollmentUtils";
import { CreditCheckType, GetReadyFormData } from "./useGetReadyForm";

export const useProcessQuoteAndRunCreditCheck = () => {
  const enrollment = useEnrollment();
  const [updateQuoteUser] = useUpdateQuoteUserMutation();
  const [runCreditCheck] = useRunCreditCheckMutation();
  const selectedProduct = useSelectedEnrollmentProduct();
  const quoteCode = enrollment.quoteCode || "";
  const router = useRouter();

  const processQuoteAndRunCreditCheck = async (
    formValues: GetReadyFormData
  ) => {
    if (!formValues) {
      return;
    }
    // fetch kraken auth token
    let authorization: string | undefined;
    try {
      authorization = (await obtainNewKrakenToken()).token;
    } catch (e) {
      handleError(e);
      return;
    }
    // skip credit check if prepay product
    if (!isPrepayProduct(selectedProduct?.productID)) {
      // skip credit check if customer selected opt out
      if (formValues.creditCheckType === CreditCheckType.SKIP_WITH_DEPOSIT) {
        enrollment.setCreditCheckStatus(CreditCheckStatus.OPT_OUT);
      } else {
        // run credit check
        try {
          const { data } = await runCreditCheck({
            variables: {
              input: {
                firstName: formValues.firstName.trim(),
                lastName: formValues.lastName.trim(),
                address: formValues.service_Address.trim(),
                address2: formValues.service_AptSuite.trim(),
                city: formValues.service_City.trim(),
                state: formValues.service_State.trim(),
                zipcode: formValues.service_ZipCode.trim(),
                ssn: formValues.socialSecurityNumber.trim(),
                birthday: format(new Date(formValues.DOB), "yyyy-MM-dd"),
                salesSubchannel: SalesSubchannel.Leviathan,
                quoteCode,
              },
            },
            context: {
              headers: {
                authorization,
              },
            },
          });
          enrollment.setCreditCheckStatus(
            data?.runCreditCheck?.passedCreditCheck
              ? CreditCheckStatus.PASS
              : CreditCheckStatus.FAIL
          );
        } catch (error) {
          handleError(error, {
            tags: { gql: "runCreditCheck" },
          });
          enrollment.setCreditCheckStatus(CreditCheckStatus.ERROR);
        }
      }
    }
    // update quoting information
    if (enrollment.quoteCode) {
      const desiredSwitchDate = formValues.service_SwitchDate;
      try {
        await updateQuoteUser({
          variables: {
            input: {
              quoteCode: enrollment.quoteCode,
              givenName: formValues.firstName,
              familyName: formValues.lastName,
              addressLine1: formValues.service_Address,
              addressLine2: formValues.service_AptSuite,
              city: formValues.service_City,
              state: formValues.service_State,
              postCode: formValues.service_ZipCode,
              email: formValues.emailAddress,
              phone: formValues.phoneNumber,
              desiredSwitchDate: desiredSwitchDate
                ? format(desiredSwitchDate, "yyyy-MM-dd")
                : undefined,
            },
          },
          context: {
            headers: {
              authorization,
            },
          },
        });
      } catch (e) {
        handleError(e);
      }
    }

    // go to next step
    router.push(`/join/enrollment/finalise-your-order`, undefined, {
      shallow: true,
      scroll: true,
    });
  };
  return processQuoteAndRunCreditCheck;
};
