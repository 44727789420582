import { gql } from "@apollo/client";
import { createUseQuery } from "@octopus-energy/apollo-client";
import {
  ProductsWithConciseApplicableRatesQuery,
  ProductsWithConciseApplicableRatesQueryVariables,
} from "./__generated__/useQueryProductsWithConciseApplicableRates.generated";

export const QUERY_PRODUCTS_WITH_CONCISE_APPLICABLE_RATES = gql`
  query ProductsWithConciseApplicableRates($filterRatesBy: ConciseRatesInput) {
    productsWithConciseApplicableRates {
      id
      code
      displayName
      rates(filterRatesBy: $filterRatesBy) {
        totalApplicableRateWithMonthlySubscriptionFees
        totalApplicableDayRateWithMonthlySubscriptionFees
        totalApplicableNightRateWithMonthlySubscriptionFees
      }
    }
  }
`;

export const useQueryProductsWithConciseApplicableRates = createUseQuery<
  ProductsWithConciseApplicableRatesQuery,
  ProductsWithConciseApplicableRatesQueryVariables
>(QUERY_PRODUCTS_WITH_CONCISE_APPLICABLE_RATES);
