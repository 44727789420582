type NavigatorTerms = {
  variant: "body1" | "body2";
  alignment?: "left" | "center";
  fontWeight?: string;
  content_en: string;
  content_es: string;
};

export const navigatorTerms: NavigatorTerms[] = [
  {
    variant: "body1",
    content_en: "Thank you for choosing Octopus Energy",
    content_es: "Gracias por elegir Octopus Energy",
  },
  {
    variant: "body1",
    content_en:
      "We have confirmed how much you will pay per kilowatt of electricity which is fixed for the duration of your plan. You will be billed for your usage plus additional utility fees based on your area. We have also confirmed that the monthly fee for the product is $10 a month, this amount is fixed per month for the duration of the plan. We have confirmed whether or not your product has an early termination fee, and if it does, you will need to pay the amount if you cancel services before your plan's expiration date.",
    content_es:
      "Hemos confirmado cuánto pagará por kilovatio de electricidad, que es fijo durante la duración de su plan. Se le facturará por su uso más tarifas de servicios adicionales según su área. También hemos confirmado que la tarifa mensual del producto es de $10 al mes, esta cantidad es fija por mes mientras dure el plan. Hemos confirmado si su producto tiene o no un cargo por cancelación anticipada y, si lo tiene, deberá pagar el monto si cancela los servicios antes de la fecha de vencimiento de su plan.",
  },
  {
    variant: "body1",
    content_en:
      "You have confirmed the plan and payment details and have provided us with accurate registration information.",
    content_es:
      "Ha confirmado el plan y los detalles de pago y nos ha proporcionado información de registro precisa.",
  },
  {
    variant: "body1",
    fontWeight: "500",
    content_en: "We have confirmed that:",
    content_es: "Hemos confirmado que:",
  },
  {
    variant: "body1",
    fontWeight: "500",
    content_en:
      "● You agree to the Terms of Service (TOS) governing energy services provided by Octopus Energy.",
    content_es:
      "● Usted acepta los Términos de servicio (TOS) que rigen los servicios de energía proporcionados por Octopus Energy.",
  },
  {
    variant: "body1",
    fontWeight: "500",
    content_en:
      "● You understand that you have the right to rescind your agreement for electric services, without any fees or penalties, by midnight of the third (3rd) business day after the date you first receive your TOS.",
    content_es:
      "● Usted comprende que tiene derecho a rescindir su contrato de servicios eléctricos, sin cargos ni multas, antes de la medianoche del tercer (3er) día hábil después de la fecha en que recibió sus TOS por primera vez.",
  },
  {
    variant: "body1",
    fontWeight: "500",
    content_en:
      "● You understand that the legal documents mentioned are available online at octopusenergy.com/legal and accessible for review from the website homepage.",
    content_es:
      "● Usted comprende que los documentos legales mencionados están disponibles en línea en octopusenergy.com/legal y accesibles para su revisión desde la página de inicio del sitio web.",
  },
  {
    variant: "body1",
    fontWeight: "500",
    content_en:
      "● You are happy that Octopus Energy provides their Terms of Service via email to the email address provided.",
    content_es:
      "● Está satisfecho de que Octopus Energy proporcione sus Términos de servicio por correo electrónico a la dirección de correo electrónico proporcionada.",
  },
  {
    variant: "body1",
    fontWeight: "500",
    content_en:
      "● You are over 18 years old and legally authorized to select/change your REP for the service/address listed.",
    content_es:
      "● Tiene más de 18 años y está legalmente autorizado para seleccionar/cambiar su REP para el servicio/dirección indicados.",
  },
  {
    variant: "body1",
    fontWeight: "500",
    content_en:
      "● You are responsible for any cancellation fees resulting from switching providers that are charged by your current electricity provider.",
    content_es:
      "● Usted es responsable de cualquier tarifa de cancelación que resulte del cambio de proveedores que cobre su proveedor de electricidad actual.",
  },
  {
    variant: "body1",
    content_en:
      "You have reviewed and understand all the information provided.",
    content_es: "Ha revisado y comprende toda la información proporcionada.",
  },
  {
    variant: "body1",
    content_en:
      "You have confirmed that you agree with all the above information and are happy to have Octopus Energy as your energy provider.",
    content_es:
      "Ha confirmado que está de acuerdo con toda la información anterior y está feliz de tener a Octopus Energy como su proveedor de energía.",
  },
  {
    variant: "body1",
    content_en: "Sign and date to acknowledge the above:",
    content_es: "Firme y ponga la fecha para reconocer lo mencionado arriba:",
  },
];
