import { useEnrollmentV2 } from "@core/apiRequests/enrollment/useEnrollmentV2";
import { gTagEvent } from "@core/googleAnalytics";
import { Maybe } from "@core/types";
import {
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { StripeCardNumberElement, StripeError } from "@stripe/stripe-js";
import { format, formatISO, set } from "date-fns";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import { Dispatch, SetStateAction } from "react";
import { useEnrollment } from "..";
import {
  obtainNewKrakenToken,
  useAddAccountCampaignMutation,
  useAddPropertyToShellAccountMutation,
  useApplyReferralCodeMutation,
  useEnrollmentAccountResetPasswordMutation,
  useGetEmbeddedSecretForNewPaymentInstructionMutation,
  useObtainKrakenTokenMutation,
  useQueryAccountFunction,
  useQueryAccountReferralAndRewardsFunction,
  useStorePaymentInstructionMutation,
  useUpdateCommsDeliveryPreferenceMutation,
} from "../../apiRequests";
import { useUpdateLanguagePreferenceMutation } from "../../apiRequests/account/useUpdateLanguagePreferenceMutations";
import {
  AccountCampaignChoices,
  CommsDeliveryPreference,
  PaymentType,
} from "../../apiRequests/graphql-global-types";
import { handleError } from "../../error";
import {
  extractReferralCode,
  getFriendReferral,
  setCodeTypeApplied,
  setUserFriendReferral,
} from "../../friendReferral";
import { useSnackbarNotification } from "../../molecules";
import {
  setCardDeclinedButAllowedToContinue,
  setCustomerIsAffiliateSale,
  useCustomerIsPTC,
  useSelectedEnrollmentProduct,
} from "../enrollmentUtils";
import { useGlobalParameterState } from "../globalParameters/useGlobalParametersState";
import { useCollectInitialPayment } from "./useCollectInitialPayment";
import { useCreateAccount } from "./useCreateAccount";
import { FinalizeFormData } from "./useFinalizeForm";
import { useFeatureFlag } from "@core/featureFlags";

const useUpdateSmartDevices = () => {
  const [{ smartDevices }] = useEnrollmentV2();
  const [addAccountCampaign] = useAddAccountCampaignMutation();
  return (accountNumber: string, authorization: string) => {
    try {
      smartDevices.forEach((campaign) => {
        addAccountCampaign({
          variables: {
            input: {
              accountNumber,
              campaign,
            },
          },
          context: {
            headers: {
              authorization,
            },
          },
        });
      });
    } catch (e) {
      handleError(e);
    }
  };
};

type UseSubmitFinalizeProps = {
  onChangeSubmitting: (submitting: boolean) => void;
  onStripeError: Dispatch<SetStateAction<Maybe<StripeError>>>;
};

export const useSubmitFinalize = ({
  onChangeSubmitting,
  onStripeError,
}: UseSubmitFinalizeProps) => {
  const { t } = useTranslation("enrollment/formFields");
  const enrollment = useEnrollment();
  const router = useRouter();
  const productId = useSelectedEnrollmentProduct()?.krakenID as string;
  const [addAccountCampaign] = useAddAccountCampaignMutation();
  const getAccountReferralCode = useQueryAccountReferralAndRewardsFunction();
  const [updateLanguagePreference] = useUpdateLanguagePreferenceMutation();
  const collectInitialPayment = useCollectInitialPayment();
  const [updateCommsDeliveryPreference] =
    useUpdateCommsDeliveryPreferenceMutation();
  const [authenticateUser] = useObtainKrakenTokenMutation();
  const [{ navigatorState, skipPayment, subDomain }] =
    useGlobalParameterState();
  const [snackbarNotification] = useSnackbarNotification();
  const [addPropertyToShellAccount] = useAddPropertyToShellAccountMutation();
  const createAccount = useCreateAccount();
  const queryAccount = useQueryAccountFunction();
  const [enrollmentAccountPasswordReset] =
    useEnrollmentAccountResetPasswordMutation();
  const [applyReferralCode] = useApplyReferralCodeMutation();
  const customerIsPTC = useCustomerIsPTC();
  const elements = useElements();
  const stripe = useStripe();
  const [getEmbeddedSecretForNewPaymentInstruction] =
    useGetEmbeddedSecretForNewPaymentInstructionMutation();
  const [storePaymentInstruction] = useStorePaymentInstructionMutation();
  const updateSmartDevices = useUpdateSmartDevices();
  const financialConnectionsSecret =
    enrollment.financialConnectionsDetails?.secretKey;
  const financialConnectionsId = enrollment.financialConnectionsDetails?.id;
  const { active: financialConnectionsActive } = useFeatureFlag(
    "stripeFinancialConnections"
  );

  const submitFinalize = async (values: FinalizeFormData) => {
    const { getReady } = enrollment.formData;
    const withEV = enrollment.withEV;
    const withThermostat = enrollment.withThermostat;
    const quoteCode = enrollment.quoteCode;

    const navigatorSkipPayment = navigatorState && skipPayment;

    if (!getReady) {
      return;
    }

    onChangeSubmitting(true);

    gTagEvent({
      action: "click",
      category: "finalize_click",
      label: "Customer Info",
      value: {
        firstName: enrollment.formData.getReady?.firstName.trim() || "",
        lastName: enrollment.formData.getReady?.lastName.trim() || "",
        email: enrollment.formData.getReady?.emailAddress.trim() || "",
        phone: enrollment.formData.getReady?.phoneNumber.trim() || "",
      },
    });

    try {
      const orgToken = await obtainNewKrakenToken().then((data) => data.token);

      // Create account
      let krakenAccountID = enrollment.krakenAccountID || "";
      try {
        if (!enrollment.krakenAccountID) {
          const { data: accountDetails } = await createAccount(
            {
              accountUser: {
                givenName: getReady.firstName.trim(),
                familyName: getReady.lastName.trim(),
                email: getReady.emailAddress.trim(),
                mobile: getReady.phoneNumber.trim(),
                landline: getReady.phoneNumber.trim(),
                dateOfBirth: format(new Date(getReady.DOB), "yyyy-MM-dd"),
              },
              billingAddress: {
                address1:
                  !navigatorSkipPayment && values?.differentBilling
                    ? values.billing_Address.trim()
                    : getReady.service_Address.trim(),
                address2:
                  !navigatorSkipPayment && values?.differentBilling
                    ? values.billing_AptSuite.length === 0
                      ? " "
                      : values.billing_AptSuite.trim()
                    : getReady.service_AptSuite.length === 0
                    ? " "
                    : getReady.service_AptSuite.trim(),
                city:
                  !navigatorSkipPayment && values?.differentBilling
                    ? values.billing_City.trim()
                    : getReady.service_City.trim(),
                state:
                  !navigatorSkipPayment && values?.differentBilling
                    ? values.billing_State.trim()
                    : getReady.service_State.trim(),
                zipCode:
                  !navigatorSkipPayment && values?.differentBilling
                    ? values.billing_ZipCode.trim()
                    : getReady.service_ZipCode.trim(),
              },
              quoteCode,
            },
            orgToken
          );
          const accountNumber =
            accountDetails?.createAccount?.account?.number ||
            accountDetails?.createAccount?.account?.id;
          if (accountNumber) {
            krakenAccountID = accountNumber;
          }
          enrollment.setKrakenAccountID(krakenAccountID);
        }
      } catch (e) {
        if (
          e instanceof Error &&
          (e.message.includes("Invalid mobile number") ||
            e.message.includes("Invalid email address"))
        ) {
          snackbarNotification.error(t("enrollmentDataError"), {
            autoHideDuration: null,
          });
        }
        handleError(e);
        onChangeSubmitting(false);
        return;
      }

      // Get stripe secret to create payment inscruction
      if (!navigatorSkipPayment && !financialConnectionsId) {
        const secretKey = await getEmbeddedSecretForNewPaymentInstruction({
          variables: {
            input: {
              accountNumber: krakenAccountID,
              instructionType: PaymentType.Card,
            },
          },
          context: {
            headers: {
              authorization: orgToken,
            },
          },
        })
          .then((res) => {
            return res.data?.getEmbeddedSecretForNewPaymentInstruction
              ?.secretKey;
          })
          .catch(handleError);

        if (secretKey && !financialConnectionsId) {
          const result = await stripe?.confirmCardSetup(secretKey, {
            payment_method: {
              card: elements?.getElement(
                CardNumberElement
              ) as StripeCardNumberElement,
              billing_details: {
                name: values.differentBilling
                  ? values.billing_firstName.trim() +
                    " " +
                    values.billing_lastName.trim()
                  : getReady.firstName.trim() + " " + getReady.lastName.trim(),

                address: {
                  line1: values.differentBilling
                    ? values.billing_Address.trim()
                    : getReady.service_Address.trim(),
                  line2: values.differentBilling
                    ? values.billing_AptSuite.trim() || " "
                    : getReady.service_AptSuite.trim() || " ",
                  city: values.differentBilling
                    ? values.billing_City.trim()
                    : getReady.service_City.trim(),
                  state: values.differentBilling
                    ? values.billing_State.trim()
                    : getReady.service_State.trim(),
                  postal_code: values.differentBilling
                    ? values.billing_ZipCode.trim()
                    : getReady.service_ZipCode.trim(),
                },
              },
            },
          });

          if (result?.error && result.error.code !== "card_declined") {
            onChangeSubmitting(false);
            onStripeError(result.error);
            return;
          } else if (result?.error && result.error.code === "card_declined") {
            setCardDeclinedButAllowedToContinue();
          } else if (result?.setupIntent?.payment_method) {
            enrollment.setPaymentMethodId(
              result.setupIntent?.payment_method as string
            );
          }

          if (result?.setupIntent) {
            try {
              await storePaymentInstruction({
                variables: {
                  input: {
                    accountNumber: krakenAccountID,
                    validFrom: formatISO(new Date()),
                    vendorReference: result?.setupIntent
                      ?.payment_method as string,
                    instructionType: PaymentType.Card,
                  },
                },
                context: {
                  headers: {
                    authorization: orgToken,
                  },
                },
              });
            } catch (e) {
              handleError(e);
              onChangeSubmitting(false);
              onStripeError(t("validation_creditCardNotValid"));
              return;
            }
          }
        }
      }

      if (
        financialConnectionsActive &&
        financialConnectionsSecret &&
        financialConnectionsId
      ) {
        const confirmBank = await stripe?.confirmUsBankAccountSetup(
          financialConnectionsSecret,
          {
            payment_method: financialConnectionsId,
          }
        );
        if (confirmBank?.setupIntent) {
          try {
            await storePaymentInstruction({
              variables: {
                input: {
                  accountNumber: krakenAccountID,
                  validFrom: formatISO(new Date()),
                  vendorReference: confirmBank?.setupIntent
                    ?.payment_method as string,
                  instructionType: PaymentType.DirectDebit,
                },
              },
              context: {
                headers: {
                  authorization: orgToken,
                },
              },
            });
          } catch (e) {
            handleError(e);
            onStripeError(t("bankAccountNotValid"));
            onChangeSubmitting(false);
            return;
          }
        }
      }

      await addPropertyToShellAccount({
        variables: {
          input: {
            accountNumber: krakenAccountID,
            esiId: getReady.esiId,
            addressLine1: getReady.service_Address.trim(),
            addressLine2: getReady.service_AptSuite.trim(),
            addressLine3: "",
            city: getReady.service_City.trim(),
            state: getReady.service_State.trim(),
            postcode: getReady.service_ZipCode.trim(),
            enrollmentType: getReady.service_SwitchType,
            productId,
            effectiveFrom: formatISO(
              set(new Date(getReady.service_SwitchDate || new Date()), {
                hours: 0,
                minutes: 0,
                seconds: 0,
                milliseconds: 0,
              })
            ),
            setupAutopay: values.autopay,
            autoTopUpPaymentAmount: parseInt(values.autoTopUpAmount) || 7500,
            quoteCode,
          },
        },
        context: {
          headers: {
            authorization: orgToken,
          },
        },
      });

      await enrollmentAccountPasswordReset({
        variables: {
          input: {
            accountNumber: krakenAccountID,
            emailAddress: getReady?.emailAddress.trim(),
            password: values.password,
          },
        },
        context: {
          headers: {
            authorization: orgToken,
          },
        },
      });

      const authenticateUserResponse = await authenticateUser({
        variables: {
          input: {
            email: getReady.emailAddress.trim(),
            password: values.password,
          },
        },
      });
      const authToken = authenticateUserResponse.data?.obtainKrakenToken?.token;

      if (authToken) {
        try {
          await updateCommsDeliveryPreference({
            variables: {
              input: {
                accountNumber: krakenAccountID,
                commsDeliveryPreference: values.paperlessBilling
                  ? CommsDeliveryPreference.Email
                  : CommsDeliveryPreference.PostalMail,
              },
            },
            context: {
              headers: {
                Authorization: authToken,
              },
            },
          });

          await updateLanguagePreference({
            variables: {
              input: {
                accountNumber: krakenAccountID,
                languagePreference: values.languagePreferenceChoice,
              },
            },
            context: {
              headers: {
                Authorization: authToken,
              },
            },
          });

          // Get ledger ID
          let ledgerId = enrollment.ledgerId || "";
          try {
            if (!ledgerId) {
              const { data: newAccountData } = await queryAccount({
                variables: {
                  accountNumber: krakenAccountID,
                },
                context: {
                  headers: {
                    authorization: authToken,
                  },
                },
              });
              const energyLedgerId = newAccountData?.account?.ledgers?.find(
                (ledger) => ledger?.ledgerType === "ENERGY"
              )?.id;
              if (energyLedgerId) {
                ledgerId = energyLedgerId;
              }
              enrollment.setLedgerId(energyLedgerId);
            }
          } catch (e) {
            handleError(e);
            onChangeSubmitting(false);
            return;
          }

          (withEV || withThermostat) &&
            !customerIsPTC &&
            (await addAccountCampaign({
              variables: {
                input: {
                  accountNumber: krakenAccountID,
                  campaign: withEV
                    ? AccountCampaignChoices.Octo12Ev
                    : AccountCampaignChoices.Octo12Therm,
                },
              },
              context: {
                headers: {
                  Authorization: authToken,
                },
              },
            }));

          updateSmartDevices(krakenAccountID, authToken);

          const queryReferral = await getAccountReferralCode({
            variables: {
              accountNumber: krakenAccountID,
              first: 1,
            },
            context: {
              headers: {
                Authorization: authToken,
              },
            },
          });

          const userFriendReferralCode =
            queryReferral.data?.account?.activeReferralSchemes?.domestic
              ?.referralUrl;

          if (userFriendReferralCode) {
            setUserFriendReferral(
              extractReferralCode(userFriendReferralCode as string) as string
            );
          }

          setCustomerIsAffiliateSale(Boolean(subDomain));

          const friendReferral = getFriendReferral();
          if (friendReferral?.code) {
            try {
              const res = await applyReferralCode({
                variables: {
                  input: {
                    accountNumber: krakenAccountID,
                    reference: friendReferral.code,
                  },
                },
                context: {
                  headers: {
                    authorization: authToken,
                  },
                },
              });
              if (
                res.data?.applyReferralCode?.rewardOutput?.rewardApplied &&
                friendReferral.codeType
              ) {
                setCodeTypeApplied(friendReferral.codeType);
              }
            } catch (e) {
              setCodeTypeApplied("error");
              handleError(e);
              onChangeSubmitting(false);
              return;
            }
          }

          if (navigatorState) {
            router.push({
              pathname: "/join/enrollment/lets-agree-to-agree",
            });
            return;
          }

          await collectInitialPayment({
            accountNumber: krakenAccountID,
            ledgerId,
            authorizationHeader: orgToken,
          });
          router.push({
            pathname: "/join/check-your-email",
          });
        } catch (error) {
          handleError(error);
        }
      }
    } catch (error) {
      handleError(error);
      onChangeSubmitting(false);
      if (
        error instanceof Error &&
        error.message.includes("An internal error occurred.")
      ) {
        snackbarNotification.error(t("enrollmentInternalError")),
          { autoHideDuration: null };
      } else snackbarNotification.error((error as Error)?.message);
    }
  };

  return submitFinalize;
};
