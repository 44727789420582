import {
  EflType,
  QuotedProduct,
  QuotedProducts,
  ServiceProvider,
} from "../../apiRequests/graphql-global-types";
import { OctopusProductId } from "../../product";
import { isTruthy } from "../../utils";

const returnEFLObject = (efl: EflType, lang: string) => {
  if (!efl.language) {
    throw new Error("EFL language is missing.");
  }
  if (efl?.language === lang) {
    return {
      language: efl.language,
      serviceProvider: efl.serviceProvider || null,
      url: efl.url || null,
    };
  }
  return {};
};

const sortEfls = (product: QuotedProduct) => {
  const serviceProviders = product.efls?.map((efl) => {
    return {
      name: efl?.serviceProvider,
      efl_en: efl?.language ? returnEFLObject(efl, "en") : {},
      efl_es: efl?.language ? returnEFLObject(efl, "es") : {},
    };
  });

  return [
    ...new Set(serviceProviders as ProductListType[0]["serviceProviders"]),
  ];
};

export const sortQuotedProducts = (
  quotedProducts?: (QuotedProducts | null)[] | null,
  renewal: boolean = false
): ProductListType => {
  if (!quotedProducts) {
    return [] as ProductListType;
  }
  const productArray: ProductListType = quotedProducts
    .map((products) => {
      const product = products?.product;
      const productKey = renewal
        ? product?.code
        : (
            Object.keys(OctopusProductId) as Array<
              keyof typeof OctopusProductId
            >
          ).find(
            (productKey) => OctopusProductId[productKey] === product?.code
          );

      if (!productKey) return undefined;

      const basePrice =
        products?.estimatedDetails?.applicableRates
          ?.totalApplicableRateWithMonthlySubscriptionFees || undefined;
      const discountEVPrice =
        products?.estimatedDetails?.applicableRates
          ?.totalApplicableRateWithMonthlySubscriptionFeesEv || undefined;
      const discountThermostatPrice =
        products?.estimatedDetails?.applicableRates
          ?.totalApplicableRateWithMonthlySubscriptionFeesThermostat ||
        undefined;
      const peakPrice =
        products?.estimatedDetails?.applicableRates
          ?.totalApplicableDayRateWithMonthlySubscriptionFees || undefined;
      const offPeakPrice =
        products?.estimatedDetails?.applicableRates
          ?.totalApplicableNightRateWithMonthlySubscriptionFees || undefined;

      return {
        krakenID: product?.id || "",
        displayName: product?.displayName || "",
        productID: renewal
          ? (productKey as unknown as OctopusProductId)
          : OctopusProductId[productKey as keyof typeof OctopusProductId],
        prices: {
          basePrice: Number(basePrice).toFixed(1) || undefined,
          discountEVPrice: Number(discountEVPrice).toFixed(1) || undefined,
          discountThermostatPrice:
            Number(discountThermostatPrice).toFixed(1) || undefined,
          peakPrice: Number(peakPrice).toFixed(1) || undefined,
          offPeakPrice: Number(offPeakPrice).toFixed(1) || undefined,
        },
        monthlyEstimate: products?.estimatedDetails?.monthlyCostEstimate,
        term: products?.product?.term || undefined,
        serviceProviders: products?.serviceProvider
          ? sortEfls(product as QuotedProduct)
          : [],
      };
    })
    .filter(isTruthy);

  if (productArray.filter((product) => product !== null).length === 0) {
    throw new Error("Product feed is empty! No Available products.");
  }

  return productArray;
};

export type ProductListType = {
  krakenID: string;
  productID: OctopusProductId;
  displayName: string;
  prices: {
    basePrice?: string;
    discountEVPrice?: string;
    discountThermostatPrice?: string;
    peakPrice?: string;
    offPeakPrice?: string;
  };
  monthlyEstimate?: number;
  term?: number;
  serviceProviders: {
    name?: ServiceProvider | null;
    efl_en?: {
      langauge?: string | null;
      serviceProvider?: ServiceProvider | null;
      url?: string;
    };
    efl_es?: {
      langauge?: string | null;
      serviceProvider?: ServiceProvider | null;
      url?: string;
    };
  }[];
}[];
