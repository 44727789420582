import { Theme, useMediaQuery } from "@mui/material";
import { styled } from "@mui/system";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from "@octopus-energy/coral-mui";
import { motion } from "framer-motion";
import Image from "next/image";
import { FC } from "react";
import { ISOCountryCodes } from "../LocalizationTypes";
import { REGION_LANG } from "../regionData";

export type BannerProps = {
  handleChooseAnotherRegion: (chooseRegion: boolean) => void;
  onRememberMeChange: (value: boolean) => void;
  rememberValue: boolean;
  region: ISOCountryCodes | null;
  show: boolean;
};

const BannerBox = styled(motion.div)(({ theme }) => ({
  position: "fixed",
  width: "100%",
  bottom: "-100%",
  left: 0,
  zIndex: 30,
  backgroundColor: theme.palette.secondary.main,
  borderColor: "",
  padding: "2rem 0rem 2rem 0rem",
  [theme.breakpoints.down("md")]: {
    paddingLeft: "1rem",
    paddingRight: "1rem",
  },
}));

export const LocalizationBanner: FC<BannerProps> = ({
  handleChooseAnotherRegion,
  onRememberMeChange,
  rememberValue,
  region,
  show,
}) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  return (
    <BannerBox
      data-cy="localization-banner"
      data-region={region}
      initial={{ bottom: "-100%" }}
      animate={show ? { bottom: "0%" } : { bottom: "-100%" }}
      transition={{ type: "linear", duration: 0.8 }}
    >
      <Stack
        direction={isMobile ? "column" : "row"}
        maxWidth="lg"
        margin="auto"
        spacing={2}
        justifyContent="space-between"
      >
        <Stack
          direction={isMobile ? "column" : "row"}
          spacing={2}
          alignItems="center"
          justifyContent="center"
        >
          {!isMobile && (
            <Box width={130} height={102}>
              <Image
                src="/images/oe-globe.svg"
                alt=""
                width={130}
                height={102}
              />
            </Box>
          )}
          <Box>
            <Typography
              variant="h5"
              component="h2"
              fontSize={isMobile ? "1rem" : undefined}
              textAlign={isMobile ? "center" : "left"}
              data-cy="localization-title"
            >
              {REGION_LANG[region || ISOCountryCodes.GLOBAL].mainMessage}
            </Typography>
            <Typography
              variant="h3"
              fontSize={isMobile ? "1.5rem" : undefined}
              textAlign={isMobile ? "center" : "left"}
              data-cy="localization-subheading"
            >
              {REGION_LANG[region || ISOCountryCodes.GLOBAL].subMessage}
            </Typography>
          </Box>
        </Stack>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexWrap="wrap"
          maxWidth={isMobile ? "100%" : "250px"}
        >
          <Box
            display="flex"
            flexDirection={isMobile ? "row" : "column"}
            width="100%"
            justifyContent="center"
          >
            <Button
              fullWidth
              size="small"
              sx={{ mx: 1, my: 0.5, boxShadow: "none" }}
              onClick={() => handleChooseAnotherRegion(true)}
              data-cy="localization-confirm"
            >
              {REGION_LANG[region || ISOCountryCodes.GLOBAL].yes}
            </Button>
            <Button
              fullWidth
              size="small"
              sx={{ mx: 1, my: 0.5, boxShadow: "none" }}
              onClick={() => handleChooseAnotherRegion(false)}
              data-cy="localization-reject"
            >
              {REGION_LANG[region || ISOCountryCodes.GLOBAL].no}
            </Button>
          </Box>
          <FormControlLabel
            sx={{
              width: isMobile ? "auto" : "100%",
              mt: 1,
              display: "flex",
              justifyContent: "flex-start",
              textAlign: "left",
              mx: 0,
            }}
            value={rememberValue}
            control={
              <Checkbox
                inputProps={{ "aria-label": "controlled" }}
                data-cy="localization-remember"
                onChange={(event) => {
                  onRememberMeChange(event.target.checked);
                }}
              />
            }
            label={REGION_LANG[region || ISOCountryCodes.GLOBAL].remember}
          />
        </Box>
      </Stack>
    </BannerBox>
  );
};
