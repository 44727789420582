import { gql } from "@apollo/client";
import { createUseMutation } from "@octopus-energy/apollo-client";
import {
  CustomerRevokeFutureAgreementsMutation,
  CustomerRevokeFutureAgreementsMutationVariables,
} from "./__generated__/useCustomerRevokeFutureAgreementsMutation.generated";

export const CUSTOMER_REVOKE_FUTURE_AGREEMENTS = gql`
  mutation CustomerRevokeFutureAgreements(
    $input: CustomerRevokeFutureAgreementsInput!
  ) {
    customerRevokeFutureAgreements(input: $input) {
      successfulRevocation
    }
  }
`;

export const useCustomerRevokeFutureAgreements = createUseMutation<
  CustomerRevokeFutureAgreementsMutation,
  CustomerRevokeFutureAgreementsMutationVariables
>(CUSTOMER_REVOKE_FUTURE_AGREEMENTS);
