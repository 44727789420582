import { zeroPad } from "./stringUtils";

/**
 * Function that returns true if the date is DST.
 *
 * In an area that observes DST the offset from UTC time in January will be different
 * than the one in July. JavaScript returns a greater value during Standard Time. Getting
 * the minimum offset between January and July will get the timezone offset during DST.
 * If the date's timezone is equal to that minimum value then we are in DST.
 * https://stackoverflow.com/questions/11887934/how-to-check-if-dst-daylight-saving-time-is-in-effect-and-if-so-the-offset
 */
export const isDST = (date: Date) => {
  const jan = new Date(date.getFullYear(), 0, 1).getTimezoneOffset();
  const jul = new Date(date.getFullYear(), 6, 1).getTimezoneOffset();
  return Math.max(jan, jul) !== date.getTimezoneOffset();
};

/**
 * Function that takes date, time, and timezones and returns a formatted string to feed
 * into the date constructor
 */
export const formatDateConstructorString = (
  date: Date,
  {
    time,
    timeZoneOffsetDst,
    timeZoneOffset,
  }: {
    time: string;
    timeZoneOffsetDst: string;
    timeZoneOffset: string;
  }
) => {
  const year = date.getFullYear();
  const month = zeroPad(date.getMonth() + 1, 2, "0");
  const day = zeroPad(date.getDate(), 2, "0");
  return `${year}-${month}-${day}T${time}-${
    isDST(date) ? timeZoneOffsetDst : timeZoneOffset
  }`;
};

export const formatDateISOToMMDDyyyy = (isoString: string | number | Date) => {
  const date = new Date(isoString);
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
};

export const getUTCDate = (dateString: Date) => {
  const date = new Date(dateString);
  return date.toUTCString().split(" ").slice(1, 4).join(" ");
};
