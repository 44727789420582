import { gql } from "@apollo/client";
import {
  createUseQuery,
  createUseQueryFunction,
} from "@octopus-energy/apollo-client";
import {
  GetTdspRatesAvailableQuery,
  GetTdspRatesAvailableQueryVariables,
} from "./__generated__/useCheckIfAvailable.generated";

export const CHECK_AVAILABLE_QUERY = gql`
  query getTdspRatesAvailable($postcode: String, $esiId: String) {
    products {
      id
      code
      rates(postcode: $postcode, esiId: $esiId) {
        tdspRates {
          consumptionRates {
            serviceProvider
          }
        }
      }
    }
  }
`;

export const useCheckIfAvailable = createUseQuery<
  GetTdspRatesAvailableQuery,
  GetTdspRatesAvailableQueryVariables
>(CHECK_AVAILABLE_QUERY);

export const useCheckIfAvailableFunction = createUseQueryFunction<
  GetTdspRatesAvailableQuery,
  GetTdspRatesAvailableQueryVariables
>(CHECK_AVAILABLE_QUERY);
