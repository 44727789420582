import { Alert } from "@krakentech/coral";
import { Box } from "@octopus-energy/coral-mui";
import { setBlokData } from "@octopus-energy/storyblok-cms";
import { SbBlokData } from "@storyblok/react";
import { FC } from "react";

type GlobalOutageAlertProps = {
  globalBannerContent?: (SbBlokData | undefined)[];
};

export const GlobalOuttageAlert: FC<GlobalOutageAlertProps> = ({
  globalBannerContent,
}) => {
  return (
    <Box mt={-4.5}>
      <Alert severity="warning" icon={<div />}>
        <Box textAlign="center">
          {globalBannerContent &&
            globalBannerContent.map((blok) => blok && setBlokData(blok))}
        </Box>
      </Alert>
    </Box>
  );
};
