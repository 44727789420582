/* To be combined with CreditCardDetailEnrollment once light/dark variant textfield atoms are created. */
import { AutopayPreferenceChoices } from "@core/portalAccountSettings";
import { Radio, Typography } from "@krakentech/coral";
import { styled } from "@mui/system";
import { Stack, TextField } from "@octopus-energy/coral-mui";
import useTranslation from "next-translate/useTranslation";
import { MakeAPaymentFormValues } from "pages/account/[accountNumber]/payments/make-a-payment";
import { ChangeEvent, FC, useState } from "react";
import { FieldErrors, useFormContext } from "react-hook-form";
import { useBooleanState } from "react-use-object-state";
import {
  StripeTextFieldCVC,
  StripeTextFieldExpiry,
  StripeTextFieldNumber,
} from "./StripeTextFields";

const CustomTextField = styled(TextField)({
  "& .MuiFormHelperText-root.Mui-error": {
    color: "#FF3E68",
  },
});

type CreditCardDetailProps = {
  errors?: FieldErrors<MakeAPaymentFormValues>;
  showZip?: boolean;
  handleChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  showAutopay?: boolean;
  showAutopayErrorMessage?: ReturnType<typeof useBooleanState>;
  isPrepay?: boolean | undefined | null;
  preEnrolledInAutopay: Boolean;
  isReplacingAutopay?: boolean;
};

export const CreditCardDetail: FC<CreditCardDetailProps> = ({
  errors,
  showZip,
  handleChange,
  showAutopay = false,
  showAutopayErrorMessage,
  isPrepay = false,
  preEnrolledInAutopay = false,
  isReplacingAutopay = false,
}) => {
  const { t } = useTranslation("payments/add-new-card");
  const { register } = useFormContext();

  const [state, setState] = useState({
    cardNumberComplete: false,
    expiredComplete: false,
    cvcComplete: false,
    cardNumberError: undefined,
    expiredError: undefined,
    cvcError: undefined,
  });

  const onElementChange =
    (field: string, errorField: string) =>
    ({
      complete,
      error = { message: null },
    }: {
      complete: boolean;
      error?: { message: null | string };
    }) => {
      setState({ ...state, [field]: complete, [errorField]: error.message });
    };

  const { cardNumberError, expiredError, cvcError } = state;

  return (
    <>
      <Stack direction={{ xs: "column", md: "row" }}>
        <Stack
          spacing={{ xs: 1, sm: 2, md: 3 }}
          direction={{ xs: "column", sm: "row" }}
          mt={2}
          width="100%"
        >
          <StripeTextFieldNumber
            data-cy="credit-card"
            error={Boolean(cardNumberError)}
            labelErrorMessage={cardNumberError}
            onChange={onElementChange("cardNumberComplete", "cardNumberError")}
          />
        </Stack>
      </Stack>
      <Stack
        spacing={{ xs: 1, sm: 2, md: 3 }}
        direction={{ xs: "column", sm: "row" }}
        mt={1}
        width="100%"
      >
        <StripeTextFieldCVC
          data-cy="security-code"
          error={Boolean(cvcError)}
          labelErrorMessage={cvcError}
          onChange={onElementChange("cvcComplete", "cvcError")}
        />
        <StripeTextFieldExpiry
          data-cy="expiration"
          error={Boolean(expiredError)}
          labelErrorMessage={expiredError}
          onChange={onElementChange("expiredComplete", "expiredError")}
        />
        {showZip && (
          <CustomTextField
            data-cy="postal-code"
            {...register("zipCode")}
            error={errors ? Boolean(errors.zipCode) : undefined}
            helperText={errors ? errors.zipCode?.message : null}
            fullWidth
            label={t("zipcode")}
            placeholder="12345"
            InputLabelProps={{
              shrink: true,
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& > input": {
                  fontSize: "1.1rem",
                  fontWeight: "bold",
                  fontFamily: "Chromatophore ,helvetica ,arial ,sans-serif",
                  "::placeholder": {
                    opacity: 0.7,
                  },
                },
              },
            }}
          />
        )}
      </Stack>
      {showAutopay && !isPrepay && !preEnrolledInAutopay && (
        <Stack>
          <Radio
            title={
              <Typography variant="body2">
                {isReplacingAutopay ? (
                  <>
                    <b>{t("youreReplacingYourAutoPay")}</b> {t("ifYouSelectNo")}
                  </>
                ) : (
                  t("doYouWantToEnroll")
                )}
              </Typography>
            }
            name="autopayPreference"
            errorMessage="Please select an option"
            onChange={handleChange}
            error={showAutopayErrorMessage?.state && !preEnrolledInAutopay}
          >
            <Stack direction="row" spacing={1} mt={1}>
              <Radio.Option value={AutopayPreferenceChoices.YES}>
                {t("yes")}
              </Radio.Option>
              <Radio.Option value={AutopayPreferenceChoices.NO}>
                No
              </Radio.Option>
            </Stack>
          </Radio>
        </Stack>
      )}
    </>
  );
};
