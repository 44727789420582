import { CardContent, Skeleton, Stack } from "@octopus-energy/coral-mui";
import { ProductCard } from "../ProductCard";

export const EnrollmentProductSkeleton = () => {
  return (
    <ProductCard>
      <CardContent>
        <Stack direction="column" alignItems="center">
          {/* title */}
          <Skeleton variant="text" width="75%" height={65} />
          {/* rate */}
          <Skeleton variant="text" width="40%" height={50} />
          {/* rate breakdown */}
          <Skeleton variant="text" width="100%" height={25} sx={{ mt: 4 }} />
          <Skeleton variant="text" width="100%" height={25} />
          {/* bullets */}
          <Skeleton variant="text" width="50%" height={30} sx={{ mt: 4 }} />
          <Skeleton variant="text" width="50%" height={30} />
          <Skeleton variant="text" width="50%" height={30} />
          <Skeleton variant="text" width="50%" height={30} />
          {/* description */}
          <Skeleton variant="text" width="100%" height={25} sx={{ mt: 4 }} />
          <Skeleton variant="text" width="100%" height={25} />
          <Skeleton variant="text" width="100%" height={25} />
          <Skeleton variant="text" width="100%" height={25} />
          {/* submit button */}
          <Skeleton
            variant="rectangular"
            width="100%"
            height={60}
            sx={{ mt: 4 }}
          />
          {/* learn more button */}
          <Skeleton variant="text" width="50%" height={25} sx={{ mt: 2 }} />
        </Stack>
      </CardContent>
    </ProductCard>
  );
};
