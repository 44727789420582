import { ApolloError, BaseMutationOptions } from "@apollo/client";
import { isClientSide } from "@core/utils";
import * as Sentry from "@sentry/nextjs";
import { CaptureContext } from "@sentry/types";
import { AxiosError } from "axios";
import router from "next/router";
import { getGlobalParametersSessionData } from "../enrollment";
import { isDevEnv } from "../env";

/**
 * Function that returns account number for customer portal
 */
const getPortalAccountNumber = () => {
  return router.query.accountNumber as string;
};

/**
 * Function that returns account number for enrollment
 */
const getEnrollmentAccountNumber = () => {
  const enrollmentContext = window.sessionStorage.getItem("enrollmentContext");
  if (enrollmentContext) {
    return JSON.parse(enrollmentContext).krakenAccountID;
  }
};

export const handleError = (
  e: ApolloError | Error | unknown | AxiosError,
  captureContext?: CaptureContext
) => {
  Sentry.withScope((scope) => {
    // extra subdomain
    const { subDomain } = getGlobalParametersSessionData();
    if (subDomain) {
      scope.setExtra("Affiliate subdomain code", subDomain);
    }
    // tag account number
    const accountNumber = isClientSide()
      ? getPortalAccountNumber() ?? getEnrollmentAccountNumber()
      : null;
    if (accountNumber) {
      scope.setTag("account", accountNumber);
    }
    // log error with context
    Sentry.captureException(e, captureContext);
  });
  if (isDevEnv()) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
};

/**
 * As of Apollo 3.6.10, client options used for mutation calls
 * are available as the second argument to the onCompleted
 * and onError callback functions
 */
export const handleApolloMutationError = (
  e: ApolloError,
  clientOptions?: BaseMutationOptions
) => {
  if (isDevEnv()) {
    // eslint-disable-next-line no-console
    console.error(clientOptions);
  }
  handleError(e);
};
