import { loadStripe } from "@stripe/stripe-js";
import { Elements, ElementProps } from "@stripe/react-stripe-js";
import { FC } from "react";
import { ReactNode } from "react";

const stripe = loadStripe(
  process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY as string,
  {
    stripeAccount: process.env.NEXT_PUBLIC_STRIPE_ACCOUNT_ID,
  }
);

export type StripeElementsProps = Omit<ElementProps, "stripe"> & {
  children: ReactNode;
};

/**
 * Stripe elements provider
 * @example
 * <StripeElements >...</StripeElements>
 */
export const StripeElements: FC<StripeElementsProps> = (props) => (
  <Elements stripe={stripe} {...props} />
);
