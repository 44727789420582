import { useCustomerIsRivian, useEnrollment } from "@core/enrollment";
import { Maybe } from "@core/types";
import {
  Box,
  Stack,
  StackProps,
  Typography,
  TypographyProps,
} from "@octopus-energy/coral-mui";
import useTranslation from "next-translate/useTranslation";
import { FC } from "react";

export type ProductCardMonthlyEstimateProps = {
  minPrice?: number | string | null;
  monthlyEstimateTextProps?: TypographyProps<"h4">;
  monthlyRateProps?: TypographyProps<"h3">;
  stackProps?: StackProps;
  offPeakPrice?: Maybe<number | string>;
};

export const ProductCardMonthlyEstimate: FC<
  ProductCardMonthlyEstimateProps
> = ({
  minPrice,
  monthlyEstimateTextProps,
  monthlyRateProps,
  stackProps,
  offPeakPrice,
}) => {
  const { t } = useTranslation("product/productCard");
  const { monthlyKwhUsage } = useEnrollment();
  const usageEstimate = monthlyKwhUsage || 1000;
  const customerIsRivian = useCustomerIsRivian();

  return (
    <Box>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="center"
        {...stackProps}
      >
        <Typography
          align="center"
          component="h3"
          fontWeight="bold"
          variant="h1"
          data-cy="product-rate-per-kwh"
          {...monthlyRateProps}
        >
          {customerIsRivian
            ? offPeakPrice + "¢/kWh"
            : "$" +
              ((Number(minPrice) / 100) * Number(usageEstimate)).toFixed(0)}
        </Typography>
      </Stack>
      <Stack>
        <Typography
          align="center"
          component="h4"
          variant="body2"
          data-cy="monthly-estimate-text"
          {...monthlyEstimateTextProps}
        >
          {customerIsRivian
            ? t("offPeakRate")
            : `${t("monthlyEstimate")}${" "}${Number(monthlyKwhUsage).toFixed(
                0
              )} kWh`}
        </Typography>
      </Stack>
    </Box>
  );
};
