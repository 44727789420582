import { useEffect } from "react";
import {
  getRefreshToken,
  getTimeTillJwtExpires,
  storeAuthToken,
  storeRefreshToken,
} from ".";
import { useObtainKrakenTokenMutation } from "../apiRequests";
import { handleError } from "../error";
import { useAuth } from "./useAuth";
import { useLogout } from "./useLogout";

const ONE_MIN = 60_000;

export const useRefreshAuthToken = () => {
  const [{ loading, jwtTokenExpiresIn }, setAuthed] = useAuth();

  const logout = useLogout();

  const [refreshAuthenticationToken] = useObtainKrakenTokenMutation({
    onCompleted: (data) => {
      if (!data.obtainKrakenToken?.refreshToken) {
        throw new Error("Refresh auth token not provided");
      }
      const {
        refreshToken,
        token,
        payload: { exp: jwtExpTime },
      } = data.obtainKrakenToken;
      storeAuthToken(`JWT ${token}`);
      storeRefreshToken(refreshToken);
      setAuthed({
        authed: true,
        loading: false,
        jwtTokenExpiresIn: getTimeTillJwtExpires(jwtExpTime),
      });
    },
    onError: (e) => {
      handleError(e);
      logout();
      setAuthed({ authed: false, loading: false });
    },
  });

  const refreshToken = getRefreshToken();

  // refresh token on first run
  useEffect(() => {
    if (!refreshToken) {
      setAuthed({ authed: false, loading: false });
      return;
    }
    if (loading) {
      refreshAuthenticationToken({
        variables: {
          input: {
            refreshToken,
          },
        },
      });
    }
  }, [refreshAuthenticationToken, loading, refreshToken]);

  // refresh the user's auth token 1 min before expiry
  useEffect(() => {
    if (!jwtTokenExpiresIn || !refreshToken) return;
    const timer = setTimeout(() => {
      refreshAuthenticationToken({
        variables: {
          input: {
            refreshToken,
          },
        },
      });
    }, jwtTokenExpiresIn - ONE_MIN);
    return () => clearTimeout(timer);
  }, [jwtTokenExpiresIn, refreshToken]);
};
