import { differenceInMilliseconds, fromUnixTime } from "date-fns";
import { storeAuthToken, storeRefreshToken } from ".";
import { useObtainKrakenTokenMutation } from "../apiRequests";
import { useAuth } from "./useAuth";

export const getTimeTillJwtExpires = (jwtExpTime: number) => {
  return differenceInMilliseconds(fromUnixTime(jwtExpTime), new Date());
};

export const useLogin = () => {
  const [, setAuthed] = useAuth();

  const [createAuthenticationToken] = useObtainKrakenTokenMutation({
    onCompleted: (data) => {
      if (!data.obtainKrakenToken?.refreshToken) {
        throw new Error("Authorization refresh token is empty");
      }
      const {
        refreshToken,
        token,
        payload: { exp: jwtExpTime },
      } = data.obtainKrakenToken;
      storeAuthToken(`JWT ${token}`);
      storeRefreshToken(refreshToken);
      setAuthed({
        authed: true,
        loading: false,
        jwtTokenExpiresIn: getTimeTillJwtExpires(jwtExpTime),
      });
    },
  });

  return (input: { email: string; password: string }) =>
    createAuthenticationToken({ variables: { input } });
};
