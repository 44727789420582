import { ShellAccountEnrollmentTypeEnum } from "@core/apiRequests/graphql-global-types";
import { yupResolver } from "@hookform/resolvers/yup";
import useTranslation from "next-translate/useTranslation";
import { useController, useForm, useFormContext } from "react-hook-form";
import * as Yup from "yup";
import "yup-phone";
import { useEnrollment } from "..";
import { EmailFormPartial, useEmailValidation } from "./Email";
import {
  PhoneNumberFormPartial,
  usePhoneNumberValidation,
} from "./PhoneNumber";
import {
  ServiceAddressPartial,
  useServiceAddressValidation,
} from "./ServiceAddress";
import { useDOBValidation } from "./DateOfBirth";

export enum CreditCheckType {
  RUN_CREDIT_CHECK = "RUN_CREDIT_CHECK",
  SKIP_WITH_DEPOSIT = "SKIP_WITH_DEPOSIT",
}

export type PersonalDetails = PhoneNumberFormPartial &
  EmailFormPartial & {
    firstName: string;
    lastName: string;
    DOB: string;
    socialSecurityNumber: string;
  };

export type ServiceAddress = ServiceAddressPartial;

export type SwitchDetails = {
  service_SwitchType: ShellAccountEnrollmentTypeEnum;
  service_SwitchDate: Date | undefined;
};

export type CreditCheckOrDeposit = {
  creditCheckType: CreditCheckType;
};

export type CriticalCare = {
  criticalCare: boolean;
};

export type GetReadyFormData = PersonalDetails &
  ServiceAddress &
  SwitchDetails &
  CreditCheckOrDeposit &
  CriticalCare;

export const personalDetailsInitialValues: PersonalDetails = {
  firstName: "",
  lastName: "",
  emailAddress: "",
  phoneNumber: "",
  DOB: "",
  socialSecurityNumber: "",
};

export const serviceAddressInitialValues: ServiceAddress = {
  searchByESI: false,
  service_Address: "",
  service_AptSuite: "",
  service_City: "",
  service_State: "TX",
  service_ZipCode: "",
  esiId: "",
  premiseType: null,
  meterType: null,
  serviceProvider: null,
  loadZone: null,
};

export const switchDetailsInitialValues: SwitchDetails = {
  service_SwitchType: ShellAccountEnrollmentTypeEnum.PendingSwitchRequest,
  service_SwitchDate: undefined,
};

const creditCheckOrDepositInitialValues: CreditCheckOrDeposit = {
  creditCheckType: CreditCheckType.RUN_CREDIT_CHECK,
};

const criticalCareInitialValues: CriticalCare = {
  criticalCare: false,
};

export const getReadyFormInitialValues: GetReadyFormData = {
  ...personalDetailsInitialValues,
  ...switchDetailsInitialValues,
  ...serviceAddressInitialValues,
  ...creditCheckOrDepositInitialValues,
  ...criticalCareInitialValues,
};

const usePersonalDetailsValidationShape = () => {
  const { t } = useTranslation("enrollment/formFields");

  return {
    firstName: Yup.string()
      .min(1, t("validation_firstName"))
      .required(t("validation_firstName")),
    lastName: Yup.string()
      .min(1, t("validation_lastName"))
      .required(t("validation_lastName")),
    emailAddress: useEmailValidation(),
    phoneNumber: usePhoneNumberValidation(),
    DOB: useDOBValidation(),
  };
};

const useSwitchDetailsValidation = () => {
  const { t } = useTranslation("enrollment/formFields");
  return {
    service_SwitchDate: Yup.string()
      .nullable(t("validation_switchDateNotSelected"))
      .required(t("validation_switchDateNotSelected")),
    service_SwitchType: Yup.string().required(),
  };
};

const useCreditCheckValidationShape = () => {
  const { t } = useTranslation("enrollment/formFields");
  return {
    creditCheckType: Yup.string(),
    socialSecurityNumber: Yup.string().when("creditCheckType", {
      is: CreditCheckType.RUN_CREDIT_CHECK,
      then: Yup.string()
        .min(9, t("validation_socialNotValid"))
        .max(9, t("validation_socialNotValid"))
        .required(t("validation_socialNotEntered")),
    }),
  };
};

const useValidationSchema = () => {
  return Yup.object().shape({
    ...usePersonalDetailsValidationShape(),
    ...useServiceAddressValidation(),
    ...useSwitchDetailsValidation(),
    ...useCreditCheckValidationShape(),
  });
};

export const useGetReadyForm = () => {
  const enrollment = useEnrollment();
  const validationSchema = useValidationSchema();

  return useForm<GetReadyFormData>({
    mode: "onTouched",
    resolver: yupResolver(validationSchema),
    defaultValues: enrollment.formData.getReady
      ? {
          ...enrollment.formData.getReady,
          creditCheckType: enrollment.creditCheckType,
        }
      : {
          ...getReadyFormInitialValues,
          emailAddress: getReadyFormInitialValues.emailAddress,
          creditCheckType: enrollment.creditCheckType,
        },
  });
};

export const useGetReadyFormContext = useFormContext<GetReadyFormData>;
export const useGetReadyFormController = useController<GetReadyFormData>;
