import { useRouter } from "next/router";
import { storeLocationPreferences } from "./localizationUtils";
import { useLocation } from "./useLocation";
import { REGION_URLS } from "./regionData";
import { ISOCountryCodes } from "./LocalizationTypes";

export type SetUserPreferencesParams = {
  region: ISOCountryCodes | null;
  hide?: boolean;
  redirect?: boolean;
  remember: boolean;
};

/**
 * Used for setting & fetching users region preferences, will redirect to region passed to setUserPreference if true.
 * Also stores preferences in local storage.
 *
 * @returns {
 *  userRequestedToHideDialog: user's saved preference if they asked for dialog to go away.
 *
 *  clientCountry: which country the client is in see type ISOCountryCodes for relevant octo regions
 *
 *  ipAddress: the ip address of the client
 *
 *  setUserPreference: function to set users preferences in state & storage
 *
 *  locationResponse: this is the cached api response from ipapi. NOTE will only be valid if there isnt already a cached preference.
 * }
 *
 */

export const useRegionPreferences = () => {
  const router = useRouter();
  const [location, setLocation] = useLocation();
  const {
    userRequestedToHideDialog,
    clientCountry,
    ipAddress,
    responseCache: locationResponse,
  } = location;

  const setUserPreference = ({
    region,
    hide = false,
    redirect = false,
    remember,
  }: SetUserPreferencesParams) => {
    if (remember) {
      storeLocationPreferences({
        userRequestedRedirect: redirect,
        userRequestedToHideDialog: hide,
        regionToRedirectTo: region,
      });
    }

    setLocation({
      ...location,
      userRequestedToHideDialog: hide,
      userRequestedRedirect: redirect,
      regionToRedirectTo: region,
    });

    if (redirect && region) {
      const url = REGION_URLS[region];
      router.push(url);
    }
  };

  return {
    userRequestedToHideDialog,
    clientCountry,
    ipAddress,
    setUserPreference,
    locationResponse,
  };
};
