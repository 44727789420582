import { gql } from "@apollo/client";
import { createUseQuery } from "@octopus-energy/apollo-client";
import {
  GetEligibilityStatusQuery,
  GetEligibilityStatusQueryVariables,
} from "./__generated__/useQueryGetEligibilityStatus.generated";

export const QUERY_GET_ELIGIBILITY_STATUS = gql`
  query GetEligibilityStatus($id: ID!) {
    property(id: $id) {
      id
      meterPoints {
        id
        agreements {
          id
          isEligibleForRenewal
          isExportingGenerationReadings
        }
      }
    }
  }
`;

export const useQueryGetEligibilityStatus = createUseQuery<
  GetEligibilityStatusQuery,
  GetEligibilityStatusQueryVariables
>(QUERY_GET_ELIGIBILITY_STATUS);
