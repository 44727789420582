import { gql } from "@apollo/client";
import { createUseQuery } from "@octopus-energy/apollo-client";
import {
  GetCurrentAndHistoricalProductPriceHistoryQuery,
  GetCurrentAndHistoricalProductPriceHistoryQueryVariables,
} from "./__generated__/useQueryGetCurrentAndHistoricalProductPriceHistory.generated";

export const QUERY_GET_CURRENT_AND_HISTORICAL_PRODUCT_PRICE_HISTORY = gql`
  query GetCurrentAndHistoricalProductPriceHistory(
    $filterBy: CurrentAndHistoricalProductPricingHistoryInput!
  ) {
    currentAndHistoricalProductPriceHistory(filterBy: $filterBy) {
      fullName
      displayName
      priceHistory {
        serviceProvider
        prices {
          kwhPrice
          effectiveDate
        }
      }
    }
  }
`;

export const useQueryGetCurrentAndHistoricalProductPriceHistory =
  createUseQuery<
    GetCurrentAndHistoricalProductPriceHistoryQuery,
    GetCurrentAndHistoricalProductPriceHistoryQueryVariables
  >(QUERY_GET_CURRENT_AND_HISTORICAL_PRODUCT_PRICE_HISTORY);
