import { Locale } from "@core/constants";
import { useDeviceBreakpoint } from "@core/utils/useDeviceBreakpoint";
import {
  Card,
  CardContent,
  Stack,
  Typography,
} from "@octopus-energy/coral-mui";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import { useContext } from "react";
import { EnrollmentContext } from "../enrollmentContext";
import { useIsAmsmMeter } from "../enrollmentUtils";

const formatPhone = (phoneNumber: string): string =>
  `(${phoneNumber?.slice(0, 3)}) ${phoneNumber?.slice(
    3,
    6
  )}-${phoneNumber?.slice(6, 11)}`;

const CustomerDetailsSummary = () => {
  const enrollmentContext = useContext(EnrollmentContext);
  const { t } = useTranslation("enrollment/customerDetailsSummary");
  const router = useRouter();
  const lang = router.locale;
  const locale = lang === Locale.SPANISH ? es : undefined;
  const { isAmsmMeter } = useIsAmsmMeter();
  const { isMobile } = useDeviceBreakpoint();
  const formattedSwitchDate = format(
    new Date(enrollmentContext.formData.getReady?.service_SwitchDate || ""),
    "MMMM dd, yyyy",
    { locale }
  );

  return (
    <Card sx={{ p: 4, mt: 4, border: 0 }} data-cy="customer-details-card">
      <CardContent data-cy="customer-details-card-content">
        <Stack width="100%" data-cy="customer-details-summary-header">
          <Typography
            variant={isMobile ? "h5" : "h3"}
            mb={2}
            width="100%"
            datay-cy="customer-details-summary-title"
          >
            {t("customerDetailsSummary")}
          </Typography>
          <Typography
            variant={isMobile ? "body2" : "h6"}
            data-cy="customer-details-will-send-email"
          >
            {t("willSendEmailVerification")}
          </Typography>
        </Stack>
        <Stack direction={isMobile ? "column" : "row"} mt={2}>
          <Stack width={isMobile ? "100%" : "50%"}>
            <Typography variant="caption">{t("name")} </Typography>
            <Typography variant="h6" datay-cy="customer-details-name">
              {enrollmentContext.formData.getReady?.firstName}{" "}
              {enrollmentContext.formData.getReady?.lastName}
            </Typography>
            <Typography variant="caption" mt={2}>
              {t("phoneNumber")}
            </Typography>
            <Typography variant="h6" datay-cy="customer-details-phone">
              {formatPhone(
                String(enrollmentContext.formData.getReady?.phoneNumber)
              )}
            </Typography>
            <Typography variant="caption" mt={2}>
              {t("serviceAddress")}
            </Typography>
            <Typography variant="h6" datay-cy="customer-details-address">
              {enrollmentContext.formData.getReady?.service_Address}{" "}
              {enrollmentContext.formData.getReady?.service_AptSuite}
            </Typography>
            <Typography variant="h6" datay-cy="customer-details-city-state">
              {enrollmentContext.formData.getReady?.service_City},{" "}
              {enrollmentContext.formData.getReady?.service_State}{" "}
              {enrollmentContext.formData.getReady?.service_ZipCode}
            </Typography>
          </Stack>
          <Stack
            width="50%"
            mt={isMobile ? 2 : 0}
            datay-cy="customer-details-switch-date"
          >
            <Typography variant="caption">{t("switchDate")} </Typography>
            <Typography
              variant="h6"
              datay-cy="customer-details-formatted-switch-date"
            >
              {formattedSwitchDate}
            </Typography>
            <Typography variant="caption" mt={2}>
              {t("esi")}
            </Typography>
            <Typography variant="h6" datay-cy="customer-details-esi">
              {enrollmentContext.formData.getReady?.esiId}
            </Typography>
          </Stack>
        </Stack>
        <Stack>
          {isAmsmMeter && (
            <Typography mt={4}>
              {t("haveAManualMeter")}{" "}
              <Typography component="span" fontWeight="bold">
                {t("switchingCanTakeFourDays")}{" "}
              </Typography>
              {t("contactTeamToSignUp")}
            </Typography>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default CustomerDetailsSummary;
