import { gql } from "@apollo/client";
import { createUseQuery } from "@octopus-energy/apollo-client";
import {
  LanguagePreferenceQuery,
  LanguagePreferenceQueryVariables,
} from "./__generated__/useQueryLanguagePreference.generated";

export const QUERY_LANG_PREFERENCE = gql`
  query LanguagePreference($accountNumber: String!) {
    languagePreference(accountNumber: $accountNumber)
  }
`;

export const useQueryLanguagePreference = createUseQuery<
  LanguagePreferenceQuery,
  LanguagePreferenceQueryVariables
>(QUERY_LANG_PREFERENCE);
