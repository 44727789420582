import { atom, useRecoilState } from "recoil";
import { SbBlokData } from "@storyblok/react";

type GlobalOutageAlert = {
  showAlert: boolean;
  alertContent?: (SbBlokData | undefined)[];
};

export const globalOutageAlertAtom = atom<GlobalOutageAlert>({
  key: "globalOutageAlert",
  default: {
    showAlert: false,
    alertContent: undefined,
  },
});

/**
 * Hook that returns the current authed state of the portal
 */
export const useGlobalOutageAlert = () => useRecoilState(globalOutageAlertAtom);
