import { atom, useRecoilState } from "recoil";
import type {
  Country,
  IPResponseBody,
  ISOCountryCodes,
} from "./LocalizationTypes";

export type Location = {
  ipAddress: string | null;
  clientCountry: Country | null;
  responseCache: IPResponseBody | null;
  userRequestedToHideDialog: boolean;
  userRequestedRedirect: boolean;
  regionToRedirectTo: ISOCountryCodes | null;
};

export const LocationAtomDefaults = {
  ipAddress: null,
  clientCountry: null,
  responseCache: null,
  userRequestedToHideDialog: false,
  userRequestedRedirect: false,
  regionToRedirectTo: null,
};

export const locationAtom = atom<Location>({
  key: "location",
  default: LocationAtomDefaults,
});

/**
 * Hook that returns the current state of the
 */
export const useLocation = () => useRecoilState(locationAtom);
