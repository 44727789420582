import {
  MenuItem,
  TextField,
  TextFieldProps,
  Typography,
} from "@octopus-energy/coral-mui";
import useTranslation from "next-translate/useTranslation";
import { useFormContext } from "react-hook-form";
import { LanguagePreferenceChoices } from "../../apiRequests/graphql-global-types";

export type CommunicationsPreferenceFormPartial = {
  languagePreferenceChoice: LanguagePreferenceChoices;
};

export const CommunicationsPreferences = (props: TextFieldProps) => {
  const { t } = useTranslation("enrollment/formFields");
  const { register } = useFormContext<CommunicationsPreferenceFormPartial>();
  return (
    <>
      <Typography
        variant="h6"
        fontWeight="bold"
        mb={1}
        data-cy="communications-preference-section-title"
      >
        {t("finalise_languagePreference_title")}
      </Typography>
      <TextField
        select
        label={t("finalise_languagePreference_prompt")}
        fullWidth
        sx={{ mt: 1, pr: 0.5 }}
        data-cy="communications-preference-select"
        {...register("languagePreferenceChoice")}
        {...props}
      >
        <MenuItem
          value={LanguagePreferenceChoices.English}
          data-cy="communication-english"
        >
          {t("finalise_languagePreference_buttonEnglish")}
        </MenuItem>
        <MenuItem
          value={LanguagePreferenceChoices.Spanish}
          data-cy="communication-spanish"
        >
          {t("finalise_languagePreference_buttonSpanish")}
        </MenuItem>
      </TextField>
    </>
  );
};
