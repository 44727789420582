import { gql } from "@apollo/client";
import { createUseMutation } from "@octopus-energy/apollo-client";
import {
  StorePaymentInstructionMutation,
  StorePaymentInstructionMutationVariables,
} from "./__generated__/useStorePaymentInstruction.generated";

const MUTATION_STORE_PAYMENT_INSTRUCTION = gql`
  mutation StorePaymentInstruction($input: StorePaymentInstructionInput!) {
    storePaymentInstruction(input: $input) {
      paymentInstruction {
        id
      }
    }
  }
`;

export const useStorePaymentInstructionMutation = createUseMutation<
  StorePaymentInstructionMutation,
  StorePaymentInstructionMutationVariables
>(MUTATION_STORE_PAYMENT_INSTRUCTION);
