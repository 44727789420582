import { handleApolloMutationError } from "@core/error";
import {
  useCollectDepositMutation,
  useCreateDepositAgreementMutation,
  useRecordDepositAgreementAcceptedMutation,
} from "../../apiRequests";
import { CollectDepositStatusChoices } from "../../apiRequests/graphql-global-types";

const buildAuthorizationContext = (authorization: string) => ({
  headers: {
    authorization,
  },
});

/**
 * Hook that returns a function to collect a deposit
 */
export const useCollectDeposit = () => {
  const [createDepositAgreement] = useCreateDepositAgreementMutation();
  const [recordDepositAgreemnt] = useRecordDepositAgreementAcceptedMutation();
  const [collectDepositMutation] = useCollectDepositMutation();

  /**
   * Function that creates, records, and collects a deposit
   */
  const collectDeposit = async ({
    accountNumber,
    authorizationHeader,
  }: {
    accountNumber: string;
    authorizationHeader: string;
  }) => {
    const context = buildAuthorizationContext(authorizationHeader);
    const depositKey = `deposit-key-${accountNumber}`;
    await createDepositAgreement({
      variables: {
        input: {
          accountNumber,
          depositKey,
          reason: `Deposit agreement created via consumer site enrollment.`,
        },
      },
      context,
      onError: (err) => {
        handleApolloMutationError(err);
      },
    });
    await recordDepositAgreemnt({
      variables: {
        input: {
          accountNumber,
          depositKey,
        },
      },
      context,
      onError: (err) => {
        handleApolloMutationError(err);
      },
    });
    const { data } = await collectDepositMutation({
      variables: {
        input: {
          accountNumber,
          depositKey,
          idempotencyKey: `INITIAL-DEPOSIT-AMOUNT-${accountNumber}`,
        },
      },
      context,
      onError: (err) => {
        handleApolloMutationError(err);
      },
    });
    return {
      status:
        data?.collectDeposit?.payment?.status ||
        CollectDepositStatusChoices.Failed,
    };
  };

  return collectDeposit;
};
