import { gql } from "@apollo/client";
import { createUseMutation } from "@octopus-energy/apollo-client";
import {
  InitiateStandalonePaymentMutation,
  InitiateStandalonePaymentMutationVariables,
} from "./__generated__/useInitiateStandalonePayment.generated";

export const MUTATION_INITIATE_STANDALONE_PAYMENT = gql`
  mutation InitiateStandalonePayment($input: InitiateStandalonePaymentInput!) {
    initiateStandalonePayment(input: $input) {
      payment {
        retrievalToken
        secretToken
      }
    }
  }
`;

export const useInitiateStandalonePaymentMutation = createUseMutation<
  InitiateStandalonePaymentMutation,
  InitiateStandalonePaymentMutationVariables
>(MUTATION_INITIATE_STANDALONE_PAYMENT);
