import { gql } from "@apollo/client";
import { createUseMutation } from "@octopus-energy/apollo-client";
import {
  UpdateUserMutation,
  UpdateUserMutationVariables,
} from "./__generated__/useUpdateUserMutation.generated";

export const MUTATION_UPDATE_USER = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      viewer {
        id
        email
        givenName
        familyName
        mobile
      }
    }
  }
`;

export const useUpdateUserMutation = createUseMutation<
  UpdateUserMutation,
  UpdateUserMutationVariables
>(MUTATION_UPDATE_USER);
