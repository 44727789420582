import { gql } from "@apollo/client";
import { createUseMutation } from "@octopus-energy/apollo-client";
import {
  UpdateLanguagePreferenceMutation,
  UpdateLanguagePreferenceMutationVariables,
} from "./__generated__/useUpdateLanguagePreferenceMutations.generated";

export const MUTATION_UPDATE_LANG_PREFERENCE = gql`
  mutation UpdateLanguagePreference($input: LanguagePreferenceInput!) {
    updateLanguagePreference(input: $input) {
      languagePreference
    }
  }
`;

export const useUpdateLanguagePreferenceMutation = createUseMutation<
  UpdateLanguagePreferenceMutation,
  UpdateLanguagePreferenceMutationVariables
>(MUTATION_UPDATE_LANG_PREFERENCE);
