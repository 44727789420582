/* To be combined with CreditCardDetail once light/dark variant textfield atoms are created. */
import { useFinalizeForm } from "@core/enrollment";
import {
  Card,
  CardContent,
  Grid,
  Stack,
  ThemeProvider,
  Typography,
  octopusLightTheme,
} from "@octopus-energy/coral-mui";
import useTranslation from "next-translate/useTranslation";
import { FC } from "react";
import {
  StripeTextFieldCVC,
  StripeTextFieldExpiry,
  StripeTextFieldNumber,
} from "../../../stripe/StripeTextFields";
import { IconSecure } from "@krakentech/icons";

export const CreditCardDetailEnrollment: FC<{
  formik?: ReturnType<typeof useFinalizeForm>;
}> = ({ formik }) => {
  const { t, lang } = useTranslation("common");
  const english = lang === "en";

  const onElementChange =
    (field: string, fieldEmpty: string, errorField: string) =>
    ({
      complete,
      empty,
      error = { message: null },
    }: {
      complete: boolean;
      empty: boolean;
      error?: { message: null | string };
    }) => {
      formik?.setFieldValue(field, complete);
      formik?.setFieldValue(fieldEmpty, empty);
      formik?.setFieldValue(errorField, Boolean(error.message));
    };

  return (
    <ThemeProvider theme={octopusLightTheme}>
      <Card data-cy="credit-card-input-card">
        <Grid
          container
          bgcolor="secondary.main"
          py={1.75}
          px={{ sm: 3, md: 4 }}
          flexDirection={{ xs: "column", md: "row" }}
          alignContent="center"
        >
          <Grid item md={7.5}>
            <Typography
              color="secondary.contrastText"
              variant="h6"
              ml={{ xs: english ? 4 : -2, md: 0 }}
            >
              {t("creditCardHeader")}
            </Typography>
          </Grid>
          <Grid item md={4.5}>
            <Stack direction="row">
              <Typography
                color="secondary.contrastText"
                variant="caption"
                mr={{ xs: 1, sm: 2, md: 3 }}
              >
                {t("secureTransaction")}
              </Typography>
              <IconSecure />
            </Stack>
          </Grid>
        </Grid>
        <CardContent sx={{ p: 2, mb: -2 }}>
          <Stack direction={{ xs: "column", md: "row" }}>
            <Stack
              spacing={{ xs: 1, sm: 2, md: 3 }}
              direction={{ xs: "column", sm: "row" }}
              mt={2}
              width="100%"
            >
              <StripeTextFieldNumber
                data-cy="credit-card"
                error={Boolean(formik?.values.cardNumberError)}
                labelErrorMessage={formik?.values.cardNumberError}
                onChange={onElementChange(
                  "cardNumberComplete",
                  "cardNumberEmpty",
                  "cardNumberError"
                )}
              />
            </Stack>
          </Stack>
          <Stack
            spacing={{ xs: 1, sm: 2, md: 3 }}
            direction={{ xs: "column", sm: "row" }}
            mt={1}
            width="100%"
          >
            <StripeTextFieldCVC
              data-cy="security-code"
              error={Boolean(formik?.values.cvcError)}
              labelErrorMessage={formik?.values.cvcError}
              onChange={onElementChange(
                "cvcComplete",
                "cvcNumberEmpty",
                "cvcError"
              )}
            />
            <StripeTextFieldExpiry
              data-cy="expiration"
              error={Boolean(formik?.values.expiredError)}
              labelErrorMessage={formik?.values.expiredError}
              onChange={onElementChange(
                "expiredComplete",
                "expiredEmpty",
                "expiredError"
              )}
            />
          </Stack>
        </CardContent>
      </Card>
    </ThemeProvider>
  );
};
