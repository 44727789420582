import {
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@octopus-energy/coral-mui";
import useTranslation from "next-translate/useTranslation";
import { InputHTMLAttributes } from "react";
import { useFormContext } from "react-hook-form";

type PaperlessPreferencesProps = {
  checkboxProps?: CheckboxProps;
};

export type PaperlessBillingFormPartial = {
  paperlessBilling: boolean;
};

export const PaperlessPreferences = ({
  checkboxProps,
}: PaperlessPreferencesProps) => {
  const { t } = useTranslation("enrollment/formFields");
  const { register, watch } = useFormContext<PaperlessBillingFormPartial>();
  return (
    <>
      <Typography
        variant="h6"
        fontWeight="bold"
        mb={1}
        data-cy="paperless-billing-section-title"
      >
        {t("finalise_paperless_billing_title")}
      </Typography>
      <FormGroup data-cy="go-paperless">
        <FormControlLabel
          control={
            <Checkbox
              inputProps={
                {
                  "data-cy": "paperless-billing-checkbox",
                } as InputHTMLAttributes<HTMLInputElement>
              }
              data-cy="paperless-billing"
              {...register("paperlessBilling")}
              checked={watch().paperlessBilling}
              {...checkboxProps}
            />
          }
          label={
            <Typography variant="body2" data-cy="paperless-billing-label">
              {t("finalise_paperless_billing_prompt")}{" "}
              <strong data-cy="paperless-billing-label-disconnection">
                {t("finalise_paperless_billing_prompt_disconnection")}
              </strong>
            </Typography>
          }
        />
      </FormGroup>
    </>
  );
};
