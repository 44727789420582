import { useApolloClient } from "@apollo/client";
import { handleError } from "@core/error";
import { useRouter } from "next/router";
import { removeAuthToken, removeRefreshToken } from ".";
import { useAuth } from "./useAuth";

export const useLogout = () => {
  const client = useApolloClient();
  const [, setAuth] = useAuth();
  const router = useRouter();

  return async () => {
    setAuth({ authed: false, loading: false });
    // clear tokens from web storage
    removeRefreshToken();
    removeAuthToken();
    // Important security measure.
    // When a user logs out we must clear the GQL cache for persisted queried data
    client
      .clearStore()
      .then(() => {
        router.push("/signin");
      })
      .catch((e) => {
        handleError(e);
      });
  };
};
