import axios, { AxiosResponse } from "axios";

export const sendFileToS3 = async (url: string, fields: string, file: Blob) => {
  const jsonFields = JSON.parse(fields);
  const formData = new FormData();

  Object.keys(jsonFields).map((key) => {
    formData.append(key, jsonFields[key]);
  });

  formData.append("file", file);

  return axios.post<AxiosResponse>(url, formData);
};
