import {
  EflFileLoadZone,
  EflFileServiceProvider,
} from "@core/apiRequests/graphql-global-types";
import { Locale } from "@core/constants";

/**
 * Builds the EFL url for "pseudo static" hosting
 */
export const buildEflUrl = (
  id: string | null,
  serviceProvider: EflFileServiceProvider,
  lang: Locale,
  loadZone?: EflFileLoadZone
) => {
  return `/efl?id=${id}&service-provider=${serviceProvider}&load-zone=${loadZone}&lang=${lang}`;
};

export const PREPAID_DISCLOSURE_STATEMENT_URL_ENGLISH =
  "https://assets.octopusenergy.com/x/e674fcbc28/pds-en.pdf";
export const PREPAID_DISCLOSURE_STATEMENT_URL_SPANISH =
  "https://assets.octopusenergy.com/x/98964a5efb/pds-es.pdf";

export const TERMS_OF_SERVICE_URL_ENGLISH =
  "https://assets.octopusenergy.com/x/c67e66d0f8/tos-en.pdf";
export const TERMS_OF_SERVICE_URL_SPANISH =
  "https://assets.octopusenergy.com/x/7fe1c63dc7/tos-es.pdf";

export const TERMS_OF_USE_URL_ENGLISH =
  "https://assets.octopusenergy.com/x/72fe7da1bd/tou-en.pdf";
export const TERMS_OF_USE_URL_SPANISH =
  "https://assets.octopusenergy.com/x/41e60dd86f/tou-es.pdf";

export const YOUR_RIGHTS_AS_A_CUSTOMER_URL_ENGLISH =
  "https://assets.octopusenergy.com/x/5926b3a49f/yrac-en.pdf";
export const YOUR_RIGHTS_AS_A_CUSTOMER_URL_SPANISH =
  "https://assets.octopusenergy.com/x/53d5e1f085/yrac-es.pdf";

export const INTELLIGENT_OCTOPUS_URL_ENGLISH =
  "https://assets.octopusenergy.com/x/e71b875d6d/intelligent-octopus-en.pdf";
export const INTELLIGENT_OCTOPUS_URL_SPANISH =
  "https://assets.octopusenergy.com/x/1ada6bc3dc/intelligent-octopus-es.pdf";

export const DRIVEFREE_AT_HOME_CHARGING_URL_ENGLISH =
  "https://assets.octopusenergy.com/x/85167bf5b9/drivefree-at-home-charging-agreement-en.pdf";

export const DRIVEFREE_AT_HOME_CHARGING_URL_SPANISH =
  "https://assets.octopusenergy.com/x/c627640a22/drivefree-at-home-charging-agreement-es.pdf";
