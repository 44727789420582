import { gql } from "@apollo/client";
import { createUseMutation } from "@octopus-energy/apollo-client";
import {
  StoreAchDirectDebitInstructionMutation,
  StoreAchDirectDebitInstructionMutationVariables,
} from "./__generated__/useStoreAchDirectDebitInstructionMutation.generated";

export const MUTATION_STORE_ACH_DIRECT_DEBIT_INSTRUCTION = gql`
  mutation storeAchDirectDebitInstruction(
    $input: StoreAchDirectDebitInstructionInput!
  ) {
    storeAchDirectDebitInstruction(input: $input) {
      paymentInstruction {
        id
        status
      }
    }
  }
`;

export const useStoreAchDirectDebitInstructionMutation = createUseMutation<
  StoreAchDirectDebitInstructionMutation,
  StoreAchDirectDebitInstructionMutationVariables
>(MUTATION_STORE_ACH_DIRECT_DEBIT_INSTRUCTION);
