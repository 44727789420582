import { gql } from "@apollo/client";
import {
  createUseQuery,
  createUseQueryFunction,
} from "@octopus-energy/apollo-client";
import {
  IsPostCodeQuotableQuery,
  IsPostCodeQuotableQueryVariables,
} from "./__generated__/useQueryIsPostCodeQuotable.generated";

export const QUERY_IS_POST_CODE_QUOTABLE = gql`
  query isPostCodeQuotable($postcode: String!) {
    isPostCodeQuotable(postcode: $postcode) {
      isQuotable
      hasMultipleTdspsOrLoadZones
    }
  }
`;

export const useIsPostCodeQuotable = createUseQuery<
  IsPostCodeQuotableQuery,
  IsPostCodeQuotableQueryVariables
>(QUERY_IS_POST_CODE_QUOTABLE);

export const useIsPostCodeQuotableFunction = createUseQueryFunction<
  IsPostCodeQuotableQuery,
  IsPostCodeQuotableQueryVariables
>(QUERY_IS_POST_CODE_QUOTABLE);
