import { useSnackbarNotification } from "@core/molecules";
import useTranslation from "next-translate/useTranslation";
import Head from "next/head";
import { useRouter } from "next/router";
import { useState } from "react";
import { FormProvider, SubmitHandler } from "react-hook-form";
import { useBooleanState } from "react-use-object-state";
import { useEnrollment } from "../enrollmentUtils";
import { GetReadyForm } from "./GetReadyForm";
import { VerifyAddressDialog } from "./VerifyAddressDialog";
import { GetReadyFormData, useGetReadyForm } from "./useGetReadyForm";
import { useVerifyAddress } from "./useVerifyAddress";
import { useProcessQuoteAndRunCreditCheck } from "./useProcessQuoteAndRunCreditCheck";

export const GetReady = () => {
  const { t } = useTranslation("enrollment/formFields");
  const router = useRouter();
  const [snackbarNotification] = useSnackbarNotification();
  const enrollment = useEnrollment();
  const form = useGetReadyForm();
  const [verifyType, setVerifyType] = useState<"esiId" | "address" | null>(
    null
  );
  const { verifyAddress, suggestedAddresses } = useVerifyAddress(setVerifyType);
  const pushToNextStep = useBooleanState(false);
  const processQuoteAndRunCreditCheck = useProcessQuoteAndRunCreditCheck();

  const onSubmit: SubmitHandler<GetReadyFormData> = async (values) => {
    enrollment.setFormData((prevState) => ({
      ...prevState,
      getReady: values,
    }));

    if (values.criticalCare) {
      router.back();
      return;
    }

    if (enrollment.zipCodeHasMultipleTdspsOrLoadZones) {
      await processQuoteAndRunCreditCheck(form.getValues());
      return pushToNextStep.setFalse();
    }

    if (form.formState.isValid) {
      if (values.esiId && values.searchByESI) {
        verifyAddress(values);
        setVerifyType("esiId");
        return pushToNextStep.setTrue();
      } else {
        verifyAddress(values);
        setVerifyType("address");
        return pushToNextStep.setTrue();
      }
    } else {
      snackbarNotification.error(t("creditCheckIncompleteFields"));
      return pushToNextStep.setFalse();
    }
  };

  return (
    <div data-cy="get-ready-enrollment-step">
      <Head>
        <title>{t("heading_GetReady")} | Octopus Energy</title>
      </Head>
      <FormProvider {...form}>
        <GetReadyForm
          onSubmit={onSubmit}
          verifyAddress={verifyAddress}
          setVerifyType={setVerifyType}
        />
        <VerifyAddressDialog
          open={Boolean(verifyType)}
          setVerifyType={setVerifyType}
          title={
            verifyType === "address"
              ? t("verify_AddressNotif")
              : t("verify_ESINotif")
          }
          suggestedAddresses={suggestedAddresses}
          pushToNextStep={pushToNextStep.state}
        />
      </FormProvider>
    </div>
  );
};
