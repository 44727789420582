import { gql } from "@apollo/client";
import { createUseQuery } from "@octopus-energy/apollo-client";
import { AccountViewerQuery } from "./__generated__/useQueryAccountViewer.generated";

/* 
addressLine1 = Billing Address 
addressLine2 = Apt/Suite/# 
addressLine3 = Additional Address Info
addressLine4 = City 
addressLine5 = State 
postcode = zipcode  
*/

export const QUERY_ACCOUNT_VIEWER = gql`
  query accountViewer {
    viewer {
      id
      preferredName
      givenName
      fullName
      email
      mobile
      accounts {
        address {
          streetAddress
          name
        }
        ... on AccountType {
          properties {
            addressLine1
            addressLine2
            addressLine3
            addressLine4
            addressLine5
            postcode
          }
        }
        number
        status
        billingName
        balance
      }
    }
  }
`;

export const useQueryAccountViewer = createUseQuery<AccountViewerQuery, {}>(
  QUERY_ACCOUNT_VIEWER
);
