export type Address = {
  address: string;
  address2: string;
  state: string;
  city: string;
  postCode: string;
  esiId: string;
};

/**
 * Function that compares the address fields of two objects.
 * If the street addresses are the same, it extracts and compares the numeric
 * parts of the address2 fields to sort by apartment number.
 */

export const sortAddresses = (a: Address, b: Address) => {
  if (a.address < b.address) return -1;
  if (a.address > b.address) return 1;
  const aptA = a.address2 ? parseInt(a.address2.replace(/\D/g, ""), 10) : 0;
  const aptB = b.address2 ? parseInt(b.address2.replace(/\D/g, ""), 10) : 0;
  return aptA - aptB;
};

/**
(1) If a.address is lexicographically (alphabetically) less than b.address, it returns -1, meaning a should come before b in the sorted order.
(2) a.address2 and b.address2 may contain non-numeric characters, so replace(/\D/g, "") removes all non-digit characters from the strings. 
(4) parseInt(..., 10) converts the cleaned-up string to an integer (base 10).
(5) If aptA is less than aptB, it returns a negative value (meaning a should come before b).
 */
