import { gql } from "@apollo/client";
import { createUseMutation } from "@octopus-energy/apollo-client";
import {
  CollectDepositMutation,
  CollectDepositMutationVariables,
} from "./__generated__/useCollectDepositMutation.generated";

export const MUTATION_COLLECT_DEPOSIT = gql`
  mutation collectDeposit($input: CollectDepositInput!) {
    collectDeposit(input: $input) {
      payment {
        status
        paymentDate
      }
    }
  }
`;

export const useCollectDepositMutation = createUseMutation<
  CollectDepositMutation,
  CollectDepositMutationVariables
>(MUTATION_COLLECT_DEPOSIT);
