import { gql } from "@apollo/client";
import { createUseMutation } from "@octopus-energy/apollo-client";
import {
  UpdatePasswordMutation,
  UpdatePasswordMutationVariables,
} from "./__generated__/useUpdatePasswordMutation.generated";

export const MUTATION_UPDATE_PASSWORD = gql`
  mutation UpdatePassword($input: UpdatePasswordInput!) {
    updatePassword(input: $input) {
      viewer {
        id
      }
    }
  }
`;

export const useUpdatePasswordMutation = createUseMutation<
  UpdatePasswordMutation,
  UpdatePasswordMutationVariables
>(MUTATION_UPDATE_PASSWORD);
