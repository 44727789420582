import { statesList } from "@core/constants";
import {
  Grid,
  MenuItem,
  TextField,
  TextFieldProps,
} from "@octopus-energy/coral-mui";
import useTranslation from "next-translate/useTranslation";

type BillingAddressFormProps = {
  firstNameTextFieldProps?: TextFieldProps;
  lastNameTextFieldProps?: TextFieldProps;
  billingAddressTextFieldProps?: TextFieldProps;
  billingApartmentSuiteTextFieldProps?: TextFieldProps;
  billingCityTextFieldProps?: TextFieldProps;
  billingStateTextFieldProps?: TextFieldProps;
  billingZipCodeTextFieldProps?: TextFieldProps;
};

export type BillingAddressFormPartial = {
  billingFirstName?: string;
  billingLastName?: string;
  billingStreet?: string;
  billingAptSuiteNumber?: string;
  billingCity?: string;
  billingState?: string;
  billingZipCode?: string;
};

export const BillingAddress = ({
  firstNameTextFieldProps,
  lastNameTextFieldProps,
  billingAddressTextFieldProps,
  billingApartmentSuiteTextFieldProps,
  billingCityTextFieldProps,
  billingStateTextFieldProps,
  billingZipCodeTextFieldProps,
}: BillingAddressFormProps) => {
  const { t } = useTranslation("enrollment/formFields");
  return (
    <Grid container spacing={2} mt={1}>
      <Grid item xs={12} md={6}>
        <TextField
          data-cy="billing-first-name"
          label={t("labels_firstName")}
          fullWidth
          {...firstNameTextFieldProps}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          data-cy="billing-last-name"
          label={t("labels_lastName")}
          fullWidth
          {...lastNameTextFieldProps}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <TextField
          data-cy="billing-address"
          label={t("payment_billingAddress")}
          fullWidth
          {...billingAddressTextFieldProps}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          data-cy="billing-aptsuite"
          label={t("labels_addressApt")}
          fullWidth
          {...billingApartmentSuiteTextFieldProps}
        />
      </Grid>
      <Grid item xs={12} md={5}>
        <TextField
          data-cy="billing-city"
          label={t("payment_billingCity")}
          fullWidth
          {...billingCityTextFieldProps}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField
          data-cy="billing-state"
          label={t("payment_billingState")}
          fullWidth
          select
          {...billingStateTextFieldProps}
        >
          {statesList.map((state) => (
            <MenuItem
              key={state.name}
              value={state.abbreviation}
              data-cy={`billing-state-dropdown-${state.abbreviation}`}
            >
              {state.abbreviation}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          data-cy="billing-zipcode"
          label={t("payment_billingZipCode")}
          fullWidth
          {...billingZipCodeTextFieldProps}
        />
      </Grid>
    </Grid>
  );
};
