export const MONTHLY_MEMBERSHIP_FEE = 10;
export const PREPAY_INITIAL_DEPOSIT_AMOUNT = 75_00;
export const CREDIT_CHECK_PASS_INITIAL_DEPOSIT_AMOUNT = 0;
export const CREDIT_CHECK_FAILURE_INITIAL_DEPOSIT_AMOUNT = 300_00;

export enum CreditCheckStatus {
  PASS,
  FAIL,
  ERROR,
  OPT_OUT,
}
