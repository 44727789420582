import { usePortalAccountNumber } from "@core/portalUtils";
import { Select, SelectProps, Skeleton, Typography } from "@krakentech/coral";
import { Box, Stack } from "@octopus-energy/coral-mui";
import useTranslation from "next-translate/useTranslation";
import { FC, useEffect, useState } from "react";
import { useBooleanState } from "react-use-object-state";
import { useQueryLanguagePreference } from "../apiRequests/account/useQueryLanguagePreference";
import { useUpdateLanguagePreferenceMutation } from "../apiRequests/account/useUpdateLanguagePreferenceMutations";
import { LanguagePreferenceChoices } from "../apiRequests/graphql-global-types";
import { handleError } from "../error";
import { useDeviceBreakpoint } from "../utils/useDeviceBreakpoint";

export type LanguagePreferenceProps = {
  editing: ReturnType<typeof useBooleanState>;
  saveTrigger: ReturnType<typeof useBooleanState>;
  resetSaveTrigger: Function;
};

export const LanguagePreference: FC<LanguagePreferenceProps> = ({
  editing,
  saveTrigger,
  resetSaveTrigger,
}) => {
  const accountNumber = usePortalAccountNumber();
  const { isMobile } = useDeviceBreakpoint();
  const { t } = useTranslation("portalAccountSettings");
  const langPreferenceTranslation = {
    [LanguagePreferenceChoices.English]: t("languagePreferenceDropdownEnglish"),
    [LanguagePreferenceChoices.Spanish]: t("languagePreferenceDropdownSpanish"),
  };

  const [languagePreference, setLanguagePreference] =
    useState<LanguagePreferenceChoices | null>(null);

  const { loading, data, refetch } = useQueryLanguagePreference({
    variables: {
      accountNumber,
    },
    onError: handleError,
  });

  useEffect(() => {
    setLanguagePreference(
      data?.languagePreference || LanguagePreferenceChoices.English
    );
  }, [data?.languagePreference]);

  const [updateLanguagePreference] = useUpdateLanguagePreferenceMutation();

  useEffect(() => {
    if (saveTrigger.state && languagePreference !== data?.languagePreference) {
      updateLanguagePreference({
        variables: {
          input: {
            accountNumber,
            languagePreference,
          },
        },
      }).then(() => {
        refetch();
        resetSaveTrigger();
      });
    }
  }, [saveTrigger]);

  const handleChange: SelectProps["onChange"] = (value) =>
    setLanguagePreference(value?.value as LanguagePreferenceChoices);

  return (
    <>
      {loading ? (
        <Skeleton variant="rectangular" height={50} width="10%" />
      ) : editing.state ? (
        <Stack my={3} width={isMobile ? "100%" : "35%"}>
          <Select
            name="languagePreferenceChoices"
            label={t("language")}
            value={{
              value: languagePreference || "",
              label:
                langPreferenceTranslation[
                  languagePreference as LanguagePreferenceChoices
                ],
            }}
            onChange={handleChange}
            data-cy="language-dropdown"
            values={[
              {
                value: LanguagePreferenceChoices.English,
                label: t("languagePreferenceDropdownEnglish"),
              },
              {
                value: LanguagePreferenceChoices.Spanish,
                label: t("languagePreferenceDropdownSpanish"),
              },
            ]}
          />
        </Stack>
      ) : (
        <Box my={2}>
          <Stack>
            <Typography variant="caption" color="tertiary">
              {t("language")}
            </Typography>
          </Stack>
          <Stack>
            <Typography variant="body1" data-cy="language-preference">
              {
                langPreferenceTranslation[
                  languagePreference as LanguagePreferenceChoices
                ]
              }
            </Typography>
          </Stack>
        </Box>
      )}
    </>
  );
};
