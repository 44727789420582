import { getBalanceDisplay, usePortalAccountNumber } from "@core/portalUtils";
import { useQueryAccount } from "../apiRequests";
import { handleError } from "../error";

export const useBillingBreakdown = () => {
  const accountNumber = usePortalAccountNumber();
  const { data, loading, error } = useQueryAccount({
    variables: {
      accountNumber,
    },
    onError: handleError,
  });

  const ledgers = data?.account?.ledgers || null;
  const accountBalance = data?.account?.balance || null;

  const isPrepay = ledgers?.find((ledger) => {
    return ledger?.ledgerType === "ACCRUALS_BALANCE_AFFECTED";
  });

  const previousPayments = data?.account?.payments?.edges[0]
    ? data?.account.payments.edges
    : null;

  const isPendingPayment = Boolean(
    previousPayments?.find((payment) => {
      return payment?.node?.status?.includes("PENDING");
    })
  );

  const currentBalanceDisplay = getBalanceDisplay(accountBalance);

  return {
    accountBalance,
    currentBalanceDisplay,
    error,
    isPendingPayment,
    isPrepay,
    loading,
    previousPayments,
  };
};
