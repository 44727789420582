import { Router, useRouter } from "next/router";
import { FC } from "react";
import { isClientSide } from ".";

type RouterPushParams = Parameters<Router["push"]>;

export type RedirectProps = {
  to: {
    url: RouterPushParams[0];
    as?: RouterPushParams[1];
    options?: RouterPushParams[2];
  };
};

export const Redirect: FC<RedirectProps> = ({ to: { url, as, options } }) => {
  const router = useRouter();
  if (isClientSide()) {
    router.push(url, as, options);
  }
  return null;
};
