/** ISO 3166-1 alpha-2 Country Codes relevant to OE 8 */
export enum ISOCountryCodes {
  DE = "DE", // Germany
  ES = "ES", // Spain
  FR = "FR", // France
  IT = "IT", // Italy
  JP = "JP", // Japon
  NZ = "NZ", // New Zealand
  US = "US", // United States
  GB = "GB", // United Kingdom
  GLOBAL = "GLOBAL", // Generic
}

// Country code name translations
export enum ISOCountryCodesNames {
  DE = "🇩🇪 Deutschland",
  ES = "🇪🇸 España",
  FR = "🇫🇷 France",
  IT = "🇮🇹 Italia",
  JP = "🇯🇵 日本",
  NZ = "🇳🇿 New Zealand",
  GB = "🇬🇧 United Kingdom",
}

export type Country = ISOCountryCodes | string;

export type IPResponseBody = {
  ip: string;
  city: string;
  region: string;
  region_code: string;
  country: Country; // << See ISOCountry Codes
  country_name: string;
  continent_code: string;
  in_eu: boolean;
  postal: string;
  latitude: number;
  longitude: number;
  timezone: string;
  utc_offset: string;
  country_calling_code: string;
  currency: string;
  languages: string;
  asn: string;
  org: string;
};
