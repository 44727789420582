import { Maybe } from "../types";

export const USAGE_ESTIMATE = 1000;
const MEMBER_PRICE = 1000 / USAGE_ESTIMATE;

export const withOctopusMembership = (
  price: Maybe<number | string>,
  decimalPlaces = 1
): string | undefined => {
  if (typeof price === "string") {
    return (parseFloat(price) + MEMBER_PRICE).toFixed(decimalPlaces);
  }
  if (typeof price === "number") {
    return (price + MEMBER_PRICE).toFixed(decimalPlaces);
  }
  return undefined;
};
