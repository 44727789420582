import { gql } from "@apollo/client";
import { createUseQuery } from "@octopus-energy/apollo-client";
import {
  PropertyWithMetersAgreementsAndFeesQuery,
  PropertyWithMetersAgreementsAndFeesQueryVariables,
} from "./__generated__/useQueryPropertyWithMetersAgreementsAndFees.generated";

export const QUERY_PROPERTY_WITH_METERS_AGREEMENTS_AND_FEES = gql`
  query PropertyWithMetersAgreementsAndFees($id: ID!) {
    property(id: $id) {
      id
      meterPoints {
        id
        agreements {
          id
          earlyTerminationFee
          earlyTerminationFeeExpiration
        }
      }
    }
  }
`;

export const useQueryPropertyWithMetersAgreementsAndFees = createUseQuery<
  PropertyWithMetersAgreementsAndFeesQuery,
  PropertyWithMetersAgreementsAndFeesQueryVariables
>(QUERY_PROPERTY_WITH_METERS_AGREEMENTS_AND_FEES);
