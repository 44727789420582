import { gql } from "@apollo/client";
import { createUseQuery } from "@octopus-energy/apollo-client";
import {
  FindAddressOrEsiQuery,
  FindAddressOrEsiQueryVariables,
} from "./__generated__/useFindAddressOrESI.generated";

export const ADDRESS_LOOKUP_QUERY = gql`
  query findAddressOrESI(
    $address: String
    $address2: String
    $state: String
    $city: String
    $postCode: String
    $esiId: String
  ) {
    addressLookup(
      address: $address
      address2: $address2
      state: $state
      city: $city
      postCode: $postCode
      esiId: $esiId
    ) {
      address
      address2
      state
      city
      state
      postCode
      esiId
    }
  }
`;

export const useFindAddressESI = createUseQuery<
  FindAddressOrEsiQuery,
  FindAddressOrEsiQueryVariables
>(ADDRESS_LOOKUP_QUERY);
