import { useQueryAccount } from "@core/apiRequests";
import { usePortalAccountNumber } from "@core/portalUtils";
import { Typography, useCoralTheme } from "@krakentech/coral";
import { Stack } from "@octopus-energy/coral-mui";
import useTranslation from "next-translate/useTranslation";
import { useEffect, useState } from "react";

export const MailingAddress = () => {
  const accountNumber = usePortalAccountNumber();
  const theme = useCoralTheme();
  const { t } = useTranslation("portalAccountSettings");
  const { data } = useQueryAccount({
    variables: {
      accountNumber,
    },
  });

  const billingName = data?.account?.billingName;
  const billingAddress = data?.account?.billingAddress;

  const [streetAddress, setStreetAddress] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [stateZip, setStateZip] = useState<string>("");

  const capitalizeWords = (str: string) =>
    str.toLowerCase().replace(/(?:^|\s)\S/g, (a) => a.toUpperCase());

  useEffect(() => {
    if (billingAddress) {
      const splitAddress = billingAddress?.split(",");
      setStreetAddress(capitalizeWords(splitAddress[0]) || "");
      setCity(capitalizeWords(splitAddress[1]) || "");
      setStateZip(splitAddress[2] || "");
    }
  }, [data]);

  return (
    <>
      <Stack
        borderLeft="2px solid"
        borderColor={theme.color.secondary.main}
        pl={2}
      >
        <Typography variant="caption" color="tertiary">
          {t("mailingAddress")}
        </Typography>
        <Typography variant="body1">{billingName}</Typography>
        <Typography variant="body1">{streetAddress}</Typography>
        <Typography variant="body1">
          {city.trim()}
          {", "}
          {stateZip.trim()}
        </Typography>
      </Stack>
    </>
  );
};

export default MailingAddress;
