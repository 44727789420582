import { gql } from "@apollo/client";
import { createUseMutation } from "@octopus-energy/apollo-client";
import {
  UpdateAutoTopUpAmountMutation,
  UpdateAutoTopUpAmountMutationVariables,
} from "./__generated__/useUpdateAutoTopUpAmountMutation.generated";
export const MUTATION_UPDATE_AUTO_TOP_UP_AMOUNT = gql`
  mutation UpdateAutoTopUpAmount($input: UpdateAutoTopUpAmountInput!) {
    updateAutoTopUpAmount(input: $input) {
      schedule {
        id
        paymentAmount
      }
    }
  }
`;
export const useUpdateAutoTopUpAmountMutation = createUseMutation<
  UpdateAutoTopUpAmountMutation,
  UpdateAutoTopUpAmountMutationVariables
>(MUTATION_UPDATE_AUTO_TOP_UP_AMOUNT);
