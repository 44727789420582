import { Card, Typography } from "@krakentech/coral";
import { BankAccountDetailsType, Stack } from "@octopus-energy/coral-mui";
import useTranslation from "next-translate/useTranslation";
import { FC } from "react";

export const ConnectedBankAccount: FC<
  Omit<BankAccountDetailsType, "id" | "secretKey">
> = ({ last4, bank_name }) => {
  const { t } = useTranslation("enrollment/finalize");

  return (
    <Card padding="small" borderless>
      <Stack spacing={0.5}>
        <b>
          <Typography color="tertiary-main" variant="caption">
            {t("bankAccount")}
          </Typography>
        </b>
        <Typography>
          {bank_name} {t("accountEndingIn")} {last4}
        </Typography>
      </Stack>
    </Card>
  );
};
