import { gql } from "@apollo/client";
import { createUseMutation } from "@octopus-energy/apollo-client";
import {
  SetEmailAndPhoneMutation,
  SetEmailAndPhoneMutationVariables,
} from "./__generated__/useSetEmailAndPhoneMutation.generated";

export const MUTATION_SET_EMAIL_AND_PHONE = gql`
  mutation SetEmailAndPhone($input: SetEmailAndPhoneInput!) {
    setEmailAndPhone(input: $input) {
      accountNumber
      result
      possibleErrors {
        code
      }
    }
  }
`;

export const useSetEmailAndPhoneMutation = createUseMutation<
  SetEmailAndPhoneMutation,
  SetEmailAndPhoneMutationVariables
>(MUTATION_SET_EMAIL_AND_PHONE);
