import { gql } from "@apollo/client";
import { createUseMutation } from "@octopus-energy/apollo-client";
import {
  CollectPaymentMutation,
  CollectPaymentMutationVariables,
} from "./__generated__/useCollectPayment.generated";

export const MUTATION_COLLECT_PAYMENT = gql`
  mutation CollectPayment($input: CollectPaymentInput!) {
    collectPayment(input: $input) {
      payment {
        status
      }
    }
  }
`;

export const useCollectPayment = createUseMutation<
  CollectPaymentMutation,
  CollectPaymentMutationVariables
>(MUTATION_COLLECT_PAYMENT);
