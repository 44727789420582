import { Address, sortAddresses } from "@core/utils";
import {
  Autocomplete,
  CircularProgress,
  TextField,
} from "@octopus-energy/coral-mui";
import useTranslation from "next-translate/useTranslation";
import { FC } from "react";
import { useBooleanState } from "react-use-object-state";
import { useQueryAddressLookup } from "../../apiRequests";
import { useGetReadyFormContext } from "./useGetReadyForm";

const buildFullAddress = (addressLookupDataEntry: Address | string | null) => {
  if (!addressLookupDataEntry || typeof addressLookupDataEntry === "string")
    return "";
  const { address, address2, city, postCode } = addressLookupDataEntry;
  return `${address}, ${address2 ? address2 + ", " : ""}${city}, ${postCode}`;
};

type AddressAutocompleteProps = {
  disabled?: boolean;
};

export const AddressAutocomplete: FC<AddressAutocompleteProps> = ({
  disabled = false,
}) => {
  const { t } = useTranslation("enrollment/formFields");
  const open = useBooleanState(false);
  const {
    setValue,
    watch,
    formState: { errors },
    register,
    resetField,
    clearErrors,
    trigger,
  } = useGetReadyFormContext();

  const address = {
    address: watch().service_Address,
    address2: watch().service_AptSuite,
    state: watch().service_State,
    city: watch().service_City,
    postCode: watch().service_ZipCode,
    esiId: watch().esiId,
  };

  const { loading, data } = useQueryAddressLookup({
    variables: {
      address: address.address,
      address2: address.address2 || null,
      city: address.city || null,
      state: address.state || null,
      postcode: address.postCode || null,
      esiId: address.esiId || null,
    },
    skip: !address.address,
  });

  const addressAutocompleteOptions = (
    data?.addressLookup?.filter((a) => a) || []
  )
    .map((a) => ({
      address: a?.address || "",
      address2: a?.address2 || "",
      state: a?.state || "",
      city: a?.city || "",
      postCode: a?.postCode || "",
      esiId: a?.esiId || "",
      premiseType: a?.premiseType,
      meterType: a?.meterType,
      serviceProvider: a?.serviceProvider,
      loadZone: a?.loadZone,
    }))
    .sort(sortAddresses)
    .slice(0, 100);

  return (
    <Autocomplete
      open={open.state}
      onOpen={open.setTrue}
      onClose={open.setFalse}
      options={addressAutocompleteOptions}
      getOptionLabel={buildFullAddress}
      loading={loading}
      disabled={disabled}
      fullWidth
      freeSolo
      noOptionsText={t("none-found")}
      inputValue={address.address || ""}
      onChange={(_, newAddress) => {
        setValue("esiId", "");
        setValue("serviceProvider", null);
        setValue("loadZone", null);
        if (newAddress && typeof newAddress !== "string") {
          setValue("service_Address", newAddress.address);
          setValue("service_AptSuite", newAddress.address2);
          setValue("service_City", newAddress.city);
          setValue("service_State", newAddress.state);
          setValue("service_ZipCode", newAddress.postCode);
          setValue("esiId", newAddress.esiId);
          setValue("premiseType", newAddress.premiseType || null);
          setValue("meterType", newAddress.meterType || null);
          setValue("serviceProvider", newAddress.serviceProvider || null);
          setValue("loadZone", newAddress.loadZone || null);
          clearErrors([
            "service_Address",
            "service_AptSuite",
            "service_City",
            "service_State",
            "service_ZipCode",
            "searchByESI",
          ]);
          trigger();
        }
      }}
      onBlur={register("service_Address").onBlur}
      onInputChange={(e, newAddressValue, reason) => {
        // only change address input if the user types it in, not if they click an address
        if ((e && e.type !== "click") || reason === "clear") {
          setValue("service_Address", newAddressValue);
          resetField("esiId");
          setValue("premiseType", null);
          setValue("meterType", null);
          clearErrors("premiseType");
          clearErrors("meterType");
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          required={!watch().searchByESI}
          name="service_Address"
          label={t("labels_address2")}
          data-cy="auto-fill-address"
          disabled={disabled}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading && <CircularProgress color="inherit" size={20} />}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          error={Boolean(
            errors.service_Address?.message ||
              errors.meterType?.message ||
              errors.premiseType?.message
          )}
          helperText={
            errors.service_Address?.message ||
            errors.meterType?.message ||
            errors.premiseType?.message ||
            " "
          }
        />
      )}
    />
  );
};
