import { gql } from "@apollo/client";
import { createUseMutation } from "@octopus-energy/apollo-client";
import {
  UpdateQuoteUserMutation,
  UpdateQuoteUserMutationVariables,
} from "./__generated__/useUpdateQuoteUserMutation.generated";

export const MUTATION_UPDATE_QUOTE_USER = gql`
  mutation updateQuoteUser($input: UpdateQuoteUserInput!) {
    updateQuoteUser(input: $input) {
      stored
    }
  }
`;

export const useUpdateQuoteUserMutation = createUseMutation<
  UpdateQuoteUserMutation,
  UpdateQuoteUserMutationVariables
>(MUTATION_UPDATE_QUOTE_USER);
