import { isClientSide } from "@core/utils";
import { useRouter } from "next/router";
import {
  getAffiliateRedirect,
  setAffiliateRedirect,
  useSetGlobalParametersState,
} from ".";
import {
  obtainNewKrakenToken,
  useIsValidSubdomainFunction,
  useQueryGetAffiliateLinkFunction,
} from "../apiRequests";
import { isProdEnv } from "../env";
import { handleError } from "../error";
import { useSnackbarNotification } from "../molecules";
import { useEffect } from "react";

/**
 * Sets affiliate sub domain in enrollment global parameters. Note this will validate subdomain too, if it's
 * invalid it will redirect to 404.
 *
 * @returns (subDomain: string) => void;
 */

export type SetAffiliateSubdomainVariables = {
  subDomain: string | undefined;
  navigatorState: boolean;
  onSuccess?: (affiliateCode?: string) => void;
  onError?: (errorMessage: string, affiliateCode?: string) => void;
};

export const useSetAffiliateSubDomain = () => {
  const [notification] = useSnackbarNotification();
  const checkSubdomain = useIsValidSubdomainFunction();
  const checkSalesChannel = useQueryGetAffiliateLinkFunction();
  const router = useRouter();
  const redirect = router.query.redirect || getAffiliateRedirect() || "";
  const setParameter = useSetGlobalParametersState();

  useEffect(() => {
    if (redirect) {
      setAffiliateRedirect(redirect as string);
    }
  }, [redirect]);

  const setAffiliateSubdomain = async ({
    subDomain,
    navigatorState,
    onSuccess,
    onError,
  }: SetAffiliateSubdomainVariables) => {
    if (!isClientSide()) {
      return;
    }

    try {
      if (!subDomain) throw new Error("No subdomain entered.");

      const { token } = await obtainNewKrakenToken();

      const {
        data: { isValidSubdomain },
      } = await checkSubdomain({
        context: {
          headers: {
            Authorization: token,
          },
        },
        variables: {
          subdomain: subDomain,
        },
      });

      //FIELD_SALES needs to route through Navigator for the LOA
      if (isValidSubdomain?.isValid) {
        const { data } = await checkSalesChannel({
          context: {
            headers: {
              Authorization: token,
            },
          },
          variables: {
            subdomain: subDomain,
          },
        });

        if (data?.affiliateLink?.organisation?.salesChannel === "FIELD_SALES") {
          navigatorState = true;
        }
      }

      if (!isValidSubdomain?.isValid && !isProdEnv) {
        throw new Error(
          "Affiliate domain invalid, please contact Octopus Energy."
        );
      }
      if (!isProdEnv) {
        notification.success(
          `Now using enrollment as: ${subDomain}, close your tab to reset affiliate code.`
        );
      }

      setParameter({ navigatorState, subDomain });

      if (onSuccess) {
        onSuccess(subDomain);
        return;
      }

      if (redirect) {
        router.replace(`/${encodeURI(redirect as string)}`);
      } else {
        router.replace("/join/plans");
      }
    } catch (error) {
      handleError(error);
      if (!isProdEnv) {
        notification.error(
          "Failed to validate affiliate domain, please contact Octopus Energy."
        );
      }
      if (onError) {
        onError(
          "Failed to validate affiliate domain, please contact Octopus Energy.",
          subDomain
        );
        return;
      }
      router.replace("/404");
    }
  };

  return setAffiliateSubdomain;
};
