/**
 * Dead simple function that converts a base64 string to
 * a native blob file.
 *
 * @param base64 (must be a base 64 string url)
 * @returns blob of file type
 */

export const convertBase64ToBlob = (base64: string): Promise<Blob> => {
  return fetch(base64).then((res) => res.blob());
};
