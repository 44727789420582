import { GlobalParametersStateType } from "./useGlobalParametersState";

/**
 * Utils for session data specifically for Global Parameters
 */
/**
 * Global Parameters Session Data Key
 */
export const GLOBAL_PARAMETERS_SESSION_DATA = "globalParameterSessionData";

/**
 * Get Global Parameters session data from storage
 * Will parse sessionStorage into JS Object if there, and return @GlobalParametersStateType
 * else will return null;
 */

export const getGlobalParametersSessionData = (): GlobalParametersStateType => {
  if (typeof window !== "undefined") {
    const JSONString = window.sessionStorage.getItem(
      GLOBAL_PARAMETERS_SESSION_DATA
    );
    if (JSONString) {
      return JSON.parse(JSONString) as GlobalParametersStateType;
    }
  }
  return {
    navigatorState: false,
    skipPayment: false,
  };
};

/**
 *  Store navigator session data in storage
 *  Accepts an object
 */

export const storeGlobalParametersSessionData = (
  data: GlobalParametersStateType
) => {
  if (typeof window !== "undefined") {
    window.sessionStorage.setItem(
      GLOBAL_PARAMETERS_SESSION_DATA,
      JSON.stringify(data)
    );
  }
};

/**
 *  Remove global parameters session data in storage
 */

export const removeGlobalParametersSessionData = () => {
  if (typeof window !== "undefined") {
    window.sessionStorage.removeItem(GLOBAL_PARAMETERS_SESSION_DATA);
  }
};
